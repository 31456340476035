import { Table, Typography } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useSelector } from "react-redux";
import { getDefaultCurrencyCode } from "../../utils/helper";
const { Text } = Typography;
const columns: ColumnsType<any> = [
  {
    title: "Supplier",
    dataIndex: "supplier",
    key: "supplier",
    render: (text: string) => <a>{text}</a>,
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
  },
  {
    title: "Brand",
    dataIndex: "brand",
    key: "brand",
  },
  {
    title: "Model",
    dataIndex: "model",
    key: "model",
  },
  {
    title: "Specification",
    dataIndex: "description",
    key: "description",
  },
  {
    title: "Quantity",
    dataIndex: "quantity",
    key: "quantity",
  },
  {
    title: "Price/Unit",
    dataIndex: "priceUnit",
    key: "priceUnit",
  },
];

const PurchaseOrderConfirmTable = () => {
  const { proforma, company } = useSelector((state: any) => state);

  const data = proforma?.all?.data?.map((el: any) => {
    return {
      _id: el?._id,
      supplier: el?.supplier?.name,
      type: el?.quotationItem?.product?.type,
      brand: el?.quotationItem?.product?.brand,
      description: el?.quotationItem?.specification
        ?.slice(2, 6)
        ?.map((item: any) => (
          <div className="flex items-center gap-2">
            <span className="font-bold text-xs">{item[0]}: </span>
            <span className=" text-sm">{item[1]}</span>
          </div>
        )),
      model: el?.quotationItem?.product?.model,
      priceUnit: (+el?.priceUnit)?.toLocaleString(),
      quantity: el?.qtyAvailable,
      validityDate: el?.validityDate,
    };
  });

  return (
    <div>
      <Table
        columns={columns}
        dataSource={data}
        style={{ width: "100%" }}
        summary={(pageData) => {
          let totalCash = 0;
          // let totalRepayment = 0;

          pageData.forEach(({ priceUnit, quantity }) => {
            totalCash +=
              quantity *
              Number(priceUnit.replace(/[^\d.-]/g, "").replace(",", "."));
          });

          return (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0} colSpan={6}>
                  <Text style={{ fontWeight: "bolder" }}>
                    {" "}
                    {`Balance [${getDefaultCurrencyCode(company)}]`}{" "}
                  </Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1}>
                  <Text style={{ fontWeight: "bolder" }}>
                    {totalCash.toLocaleString()}
                  </Text>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          );
        }}
      />
    </div>
  );
};

export default PurchaseOrderConfirmTable;
