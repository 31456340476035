import { Form, Input } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  getOneCompanyAction,
  updateCompanyActions,
} from "../../store/setting/company/actions";
import { COMPANY_PROFILE_ID } from "../../utils/constants";

const AddCompanyWarehouse = (props: any) => {
  const { auth, setting, company } = useSelector((state: any) => state);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const companyId = COMPANY_PROFILE_ID;

  // Check if we are editing an existing warehouse
  const warehouseToEdit = props?.updateWarehouse;
  const isUpdate = !!props?.updateWarehouse?._id;

  useEffect(() => {
    if (warehouseToEdit) {
      form.setFieldsValue({
        name: warehouseToEdit?.name,
        province: warehouseToEdit?.shopLocation?.province,
        district: warehouseToEdit?.shopLocation?.district,
        lat: warehouseToEdit?.shopLocation?.coordinate?.lat,
        lng: warehouseToEdit?.shopLocation?.coordinate?.lng,
      });
    }
  }, [warehouseToEdit, form]);
  const onFinish = async (values: any) => {
    if (auth?.token && auth?.user?._id) {
      const existingWarehouses = company?.selected?.warehouse || [];

      let updatedWarehouses;

      if (warehouseToEdit && warehouseToEdit?._id) {
        updatedWarehouses = existingWarehouses?.map((warehouse: any) =>
          warehouse._id === warehouseToEdit?._id
            ? {
                ...warehouse,
                name: values?.name,
                shopLocation: {
                  province: values?.province,
                  district: values?.district,
                  coordinate: { lat: values?.lat, lng: values?.lng },
                },
              }
            : warehouse
        );
      } else {
        // Add new warehouse
        const newWarehouse = {
          name: values?.name,
          shopLocation: {
            province: values?.province,
            district: values?.district,
            coordinate: { lat: values?.lat, lng: values?.lng },
          },
        };
        updatedWarehouses = [...existingWarehouses, newWarehouse];
      }

      await updateCompanyActions(
        companyId as string,
        { warehouse: updatedWarehouses },
        auth?.token
      )(dispatch);

      await getOneCompanyAction(companyId as string, auth.token)(dispatch);
      form.resetFields();
      props?.onCancel();
    }
  };

  return (
    <div className="mt-8 w-full px-8">
      <h1 className="text-base font-medium text-center mb-4">
        {isUpdate ? "Update Warehouse" : "Create Warehouse"}
      </h1>
      <Form
        layout="vertical"
        form={form}
        name="register"
        onFinish={onFinish}
        initialValues={warehouseToEdit}
      >
        <div>
          <Form.Item
            label="Name"
            name="name"
            rules={[{ required: true, message: "Please input name!" }]}
          >
            <Input className="w-full" />
          </Form.Item>
          <Form.Item
            label="Province"
            name="province"
            rules={[{ required: true, message: "Please input province!" }]}
            style={{ width: "100%" }}
          >
            <Input className="w-full" />
          </Form.Item>
          <Form.Item
            label="District"
            name="district"
            rules={[{ required: true, message: "Please input district!" }]}
            style={{ width: "100%" }}
          >
            <Input className="w-full" />
          </Form.Item>
          <h1 className="my-4 text-base font-medium">Add Location</h1>
          <Form.Item
            label="Latitude"
            name="lat"
            rules={[{ required: true, message: "Please input latitude!" }]}
            style={{ width: "100%" }}
          >
            <Input className="w-full" />
          </Form.Item>
          <Form.Item
            label="Longitude"
            name="lng"
            rules={[{ required: true, message: "Please input longitude!" }]}
            style={{ width: "100%" }}
          >
            <Input className="w-full" />
          </Form.Item>
        </div>
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <LoadingButton
            sx={{ textTransform: "none", minWidth: "50%" }}
            type="submit"
            variant="contained"
            loading={setting?.isFetching}
          >
            {isUpdate ? "Update" : "Save"}
          </LoadingButton>
        </Form.Item>
      </Form>
    </div>
  );
};
export default AddCompanyWarehouse;
