import react from "react";
import { Select } from "antd";
import { useSelector } from "react-redux";

const EmployeeFilter = (props: any) => {
  const { setting } = useSelector((state: any) => state);
  return (
    <>
      <Select
        showSearch
        style={{ width: 200 }}
        allowClear
        placeholder="Filter By Role"
        optionFilterProp="children"
        filterOption={(input, option) =>
          (option?.label ?? "").toString().includes(input)
        }
        loading={setting.isFetching}
        disabled={setting.isFetching}
        filterSort={(optionA, optionB) =>
          (optionA?.label ?? "")

            .toString()
            .toLowerCase()
            .localeCompare((optionB?.label ?? "").toString().toLowerCase())
        }
        options={[
          { label: "Admin", value: "admin" },
          { label: "Sales-Agent", value: "sales-agent" },
          { label: "Supervisor", value: "sales-supervisor" },
          { label: "Finance", value: "finance-manager" },
          { label: "Inventory", value: "inventory" },
          { label: "Technician", value: "technician" },
          { label: "Dev", value: "dev" },
          { label: "Partner", value: "partner" },
        ]}
        onChange={(value: any) => {
          !value && props?.setRole("");
          value && props?.setRole(value);
        }}
      />
    </>
  );
};
export default EmployeeFilter;
