import React, { useEffect, useState } from "react";
import Statistics from "./Statistics";
import { DatePicker } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllSaveTheChildrenAction,
  getTransactionByCustomersReportAction,
} from "../../../store/report/actions";
import { CustomerTransactionReportHeaders, CustomerTransactionReportSumaryHeaders } from "../../../components/csvHeaders/Headers";
import dayjs, { Dayjs } from "dayjs";
import CSVExportButton from "../../../components/cards/NoSale/CSVExportButton";
import { getTransactionByCustomer_CSVAction } from "../../../store/csvDownload/actions";
const { RangePicker } = DatePicker;
const monthFormat = "YYYY/MM";
import CustomerTransactionTable from "../../../components/tables/report/customerTransactionTable";
import type { RadioChangeEvent } from "antd";
import { Radio } from "antd";
import SavetheChildrentable from "../../../components/tables/saveTheChildrenTable/SavetheChildrentable";
const OverView = () => {
  const { auth, layout, CSV ,report} = useSelector((state: any) => state);
  // const formattedDate = new Date().toISOString().slice(0, 7);
  // const [selectedMonth, setSelectedMonth] = useState<string | string[]>([
  //   formattedDate,
  //   formattedDate,
  // ]);
  const currentYear = new Date().getFullYear();
  const firstDate = new Date(currentYear, 0, 1).toISOString().slice(0, 7); 
  const secondDate = new Date(currentYear + 1, 11, 31).toISOString().slice(0, 7); 
  
  const [selectedMonth, setSelectedMonth] = useState<string[]>([firstDate, secondDate]);
  const [limit, setLimit] = useState(15);
  const [page, setPage] = useState(1);
  const [selectedStatus, setSelectedStatus] = useState("");
  const channelId = auth?.user?.channel?.at(0)?._id;
  const [value, setValue] = useState("not-extracted");

  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value);
  };

  const dispatch = useDispatch();
  useEffect(() => {
    if (layout?.openCSVButton && auth?.token)
      getTransactionByCustomer_CSVAction(
        auth?.token,
        `?month1=${selectedMonth[0]}&month2=${selectedMonth[1]}${
          selectedStatus ? `&status=${selectedStatus}` : ""
        }&limit=${report?.customerTransaction?.total}&page=0${channelId ? `&channel=${channelId}` : ""}`
      )(dispatch);
  }, [layout?.openCSVButton]);

  useEffect(() => {
    if (auth?.token)
      getTransactionByCustomersReportAction(
        auth?.token,
        `?month1=${selectedMonth[0]}&month2=${selectedMonth[1]}${
          selectedStatus ? `&status=${selectedStatus}` : ""
        }&limit=${limit}&page=0${channelId ? `&channel=${channelId}` : ""}`
      )(dispatch);
  }, [
    auth?.token,
    dispatch,
    selectedMonth,
    selectedStatus,
    channelId,
    limit,
    page,
  ]);

  useEffect(() => {
    if (auth?.token)
      getAllSaveTheChildrenAction(
        auth?.token,
        `?month1=${selectedMonth[0]}&month2=${selectedMonth[1]}${
          selectedStatus ? `&status=${selectedStatus}` : ""
        }&limit=${limit}&page=0${channelId ? `&channel=${channelId}` : ""}`
      )(dispatch);
  }, [
    auth?.token,
    dispatch,
    selectedMonth,
    selectedStatus,
    channelId,
    limit,
    page,
  ]);

  const csvData = CSV?.csvDownloadedData?.data?.flatMap((item: any) => {
    return (
      item?.allInvoices?.map((el: any) => {
        return {
          mPayment: item?.amountToBePaid,
          customerName: `${item?.customer?.firstName ?? " "} ${
        item?.customer?.lastname ?? ""
      }`,
          mPaid: item?.AmountPaid,
          transactionId: el?.transaction,
          mPaidremain: item?.AmountRemain,
          accountType: el?.account?.type,
          paidAt:el?.payment?.map((data:any)=>{
          const  formattedDate = dayjs(data?.date).format("YYYY-MM-DD HH:mm:ss");
          return `${formattedDate}${el?.payment?.length > 1 ? "  " : ""}`;
          }),
          customerTel:
            el?.account?.customer?.phone || el?.account?.customer?.msisdn,
          date: dayjs(el?.dueDate).format("YYYY-MM-DD HH:mm:ss"),
          status: el?.status,
        };
      }) || []
    );
  });
  const csvDataSummary = CSV?.csvDownloadedData?.data?.flatMap((item: any) => {
    return {
      amountPaid: item?.AmountPaid,
      amountToBePaid: item?.amountToBePaid,
      AmountRemain: item?.AmountRemain,
      endingDate:item?.endingDate 
      ? dayjs(item?.endingDate).format("YYYY-MM-DD HH:mm:ss") 
      : "N/A",
      customer: `${item?.customer?.firstName ?? " "} ${
        item?.customer?.lastname ?? ""
      }`,
      phone: item?.customer?.otherMsisdns?.at(0),
    };
  });

  const onRangeChange = (
    dates: null | (Dayjs | null)[],
    dateStrings: string[]
  ) => {
    if (dates) {
      setSelectedMonth(dateStrings);
    }
  };

  return (
    <div className="mt-8 px-4">
      <div className="flex items-center justify-between mb-2.5 text-black">
        <RangePicker
          picker="month"
          onChange={onRangeChange}
          allowClear={false}
          defaultValue={[
            dayjs(firstDate, monthFormat),
            dayjs(secondDate, monthFormat),
          ]}
          className="h-10"
        />
        <div className="flex justify-end gap-4 ">
          <Radio.Group onChange={onChange} value={value} className="mt-2">
            <Radio value={"not-extracted"}>Summary</Radio>
            <Radio value={"extracted"}>Extracted</Radio>
          </Radio.Group>
          {value === "not-extracted" ? (
            <CSVExportButton
              csvHeaders={CustomerTransactionReportSumaryHeaders}
              csvData={csvDataSummary}
              filename={`Transaction_Report_Summary.csv`}
            />
          ) : (
            <CSVExportButton
              csvHeaders={CustomerTransactionReportHeaders}
              csvData={csvData}
              filename={`Transaction_Report_extracted.csv`}
            />
          )}
        </div>
      </div>
      <div className="flex flex-col space-y-10">
        <Statistics />

        {value === "not-extracted" ? <CustomerTransactionTable
          setPage={setPage}
          limit={limit}
          selectedStatus={selectedStatus}
          setSelectedStatus={setSelectedStatus}
          setLimit={setLimit}
        />:
        <SavetheChildrentable
          setPage={setPage}
          limit={limit}
          selectedStatus={selectedStatus}
          setSelectedStatus={setSelectedStatus}
          setLimit={setLimit}
        />}
      </div>
    </div>
  );
};

export default OverView;
