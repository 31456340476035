import React from "react";
import { XAxis, Tooltip, ResponsiveContainer } from "recharts";
import { LineChart, Line, YAxis, CartesianGrid } from "recharts";
import { useSelector } from "react-redux";
import moment from "moment";
import { getDefaultCurrencyCode } from "../../../utils/helper";
import { calculatePercentageChange } from "../../../utils/converter";
import OverViewCard from "../../../pages/dashboard/dashboardComponents/OveviewCards";
import { FaArrowDownLong, FaArrowUpLong } from "react-icons/fa6";
import iconTuo from "../../../assets/icons/new/moneys.svg";
import iconTwo from "../../../assets/icons/new/box-tick.svg";

const DashboardTopSection = (props: any) => {
  const { company, overView } = useSelector((state: any) => state);




  const data = props?.data?.map((el: any) => {
    if (props?.periodValue === "year") {
      return {
        name: el?.month,
        year1: el?.year1Amount,
        year2: el?.year2Amount,
      };
    }
    if (props?.periodValue === "weekly") {
      return {
        date: moment(el?.date).format("MMM-DD"),
        Amount: el?.totalAmount,
      };
    }
    if (props?.periodValue === "month") {
      return {
        date: moment(el?.date).format("MMM-DD"),
        Amount: el?.totalAmount,
      };
    }
    if (props?.periodValue === "daily") {
      return {
        hour: el?.hour,
        Yesterday: el?.day1Amount,
        Today: el?.day2Amount,
      };
    } else {
      return {
        date: moment(el?.date).format("MMM-DD"),
        Amount: el?.totalAmount,
      };
    }
  });

  const yearSum =
    Array.isArray(props?.data) && props?.data.length > 0
      ? props?.data.reduce(
          (acc: any, item: any) => {
            acc.year1Sum += item?.year1Amount || 0;
            acc.year2Sum += item?.year2Amount || 0;
            return acc;
          },
          { year1Sum: 0, year2Sum: 0 }
        )
      : { year1Sum: 0, year2Sum: 0 };

  const monthSum =
    Array.isArray(props?.data) && props?.data.length > 0
      ? props?.data?.reduce(
          (acc: any, item: any) => {
            acc.monthSum += item?.totalAmount || 0;
            return acc;
          },
          { monthSum: 0 }
        )
      : { monthSum: 0 };

  const weekSum =
    Array.isArray(props?.data) && props?.data.length > 0
      ? props?.data?.reduce(
          (acc: any, item: any) => {
            acc.weekSum += item?.totalAmount || 0;
            return acc;
          },
          { weekSum: 0 }
        )
      : { weekSum: 0 };

  const daySum =
    Array.isArray(props?.data) && props?.data.length > 0
      ? props?.data?.reduce(
          (acc: any, item: any) => {
            acc.day1Sum += item?.day1Amount || 0;
            acc.day2Sum += item?.day2Amount || 0;
            return acc;
          },
          { day1Sum: 0, day2Sum: 0 }
        )
      : { day1Sum: 0, day2Sum: 0 };

  // quantity comparison
  const totalCurrentDevice = overView?.quantityRevenue?.data?.day2Quantity;
  const totalComparedDevice = overView?.quantityRevenue?.data?.day1Quantity;
  const percentageChange:any = calculatePercentageChange(
    totalCurrentDevice,
    totalComparedDevice
  );
  // expected amount comparison
  const totalCurrentexpected =
    overView?.quantityRevenue?.data?.expectedAmountDay2;
  const totalComparedexpected =
    overView?.quantityRevenue?.data?.dexpectedAmountDay1;
  const expectedPercentageChange = calculatePercentageChange(
    totalCurrentexpected,
    totalComparedexpected
  );
  return (
    <div className="flex flex-col w-full 2xl:flex-row  xh-fit mx-auto mt-4 gap-y-6 gap-x-6">
      <div className="bg-[#fff] w-full 2xl:w-[76%] h-[30rem] px-8 py-3 rounded-md text-[#030229]">
        <div className="flex justify-between">
          <div className="flex flex-col gap-1">
            <h1 className="font-normal text-[16px] opacity-90 my-1">
              Total Revenue
            </h1>
            <div className="ml-1">
              <p className="text-[#030229] font-medium text-[14px]">
                {getDefaultCurrencyCode(company)}{" "}
                {props?.periodValue === "year"
                  ? yearSum?.year1Sum?.toLocaleString()
                  : props?.periodValue === "month"
                  ? monthSum?.monthSum?.toLocaleString()
                  : props?.periodValue === "weekly"
                  ? weekSum?.weekSum?.toLocaleString()
                  : props?.periodValue === "daily"
                  ? daySum?.day2Sum?.toLocaleString()
                  : monthSum?.monthSum?.toLocaleString()}
              </p>
              <div className="text-[#030229]  opacity-60 font-medium text-[14px]">
                {props?.periodValue === "daily" && (
                  <p>
                    {getDefaultCurrencyCode(company)}{" "}
                    {daySum?.day1Sum?.toLocaleString() ?? 0}
                  </p>
                )}
                {props?.periodValue === "year" && (
                  <p>
                    {getDefaultCurrencyCode(company)}{" "}
                    {yearSum?.year2Sum?.toLocaleString() ?? 0}
                  </p>
                )}
              </div>
            </div>
          </div>
          {(props?.periodValue === "year" ||
            props?.periodValue === "daily") && (
            <div className="flex gap-5">
              <div className="flex gap-2">
                <div className="w-4 h-3 rounded-sm bg-[#605BFF] mt-1"></div>{" "}
                <p className="text-[#030229] text-sm">
                  {props?.periodValue === "year"
                    ? "This year"
                    : props?.periodValue === "daily"
                    ? "Today"
                    : ""}
                </p>
              </div>
              <div className="flex gap-2">
                <div className="w-4 h-3 rounded-sm bg-[#BDBDBD] mt-1"></div>{" "}
                <p className="text-[#030229] text-sm">
                  {props?.periodValue === "year"
                    ? "Last year"
                    : props?.periodValue === "daily"
                    ? "Yesterday"
                    : ""}
                </p>
              </div>
            </div>
          )}

          <div></div>
        </div>
        <ResponsiveContainer width="100%" height={350} className="mt-4">
          <LineChart
            data={data}
            margin={{
              top: 5,
              right: 10,
              left: 30,
              bottom: 5,
            }}
          >
            <defs>
              <filter id="shadow" x="-50%" y="-50%" width="200%" height="200%">
                <feDropShadow
                  dx="0"
                  dy="5"
                  stdDeviation="6"
                  floodColor="#605BFF"
                  floodOpacity="0.6"
                />
              </filter>
            </defs>
            <defs>
              <filter id="shadow2" x="-50%" y="-50%" width="200%" height="200%">
                <feDropShadow
                  dx="0"
                  dy="5"
                  stdDeviation="6"
                  floodColor="#BDBDBD"
                  floodOpacity="0.6"
                />
              </filter>
            </defs>
            <CartesianGrid strokeDasharray="3 3" opacity={0.2} />
            <XAxis
              fontSize={12}
              strokeWidth={0.1}
              dataKey={
                props?.periodValue === "year"
                  ? "name"
                  : props?.periodValue === "month"
                  ? "date"
                  : props?.periodValue === "weekly"
                  ? "date"
                  : props?.periodValue === "daily"
                  ? "hour"
                  : props?.periodValue === "range"
                  ? "date"
                  : ""
              }
            />
            <YAxis fontSize={12} strokeWidth={0.1} />
            <Tooltip />
            <Line
              type="monotone"
              dataKey={
                props?.periodValue === "year"
                  ? "year1"
                  : props?.periodValue === "month"
                  ? "Amount"
                  : props?.periodValue === "weekly"
                  ? "Amount"
                  : props?.periodValue === "daily"
                  ? "Today"
                  : props?.periodValue === "range"
                  ? "Amount"
                  : ""
              }
              stroke="#605BFF"
              strokeWidth={1}
              dot={false}
              filter="url(#shadow)"
            />

            <Line
              type="monotone"
              dataKey={
                props?.periodValue === "year"
                  ? "year2"
                  : props?.periodValue === "daily"
                  ? "Yesterday"
                  : ""
              }
              stroke="#BDBDBD"
              strokeWidth={1}
              dot={false}
              filter="url(#shadow2)"
            />
          </LineChart>
        </ResponsiveContainer>
        {props?.periodValue === "daily" && (
          <p className="text-center opacity-80 font-normal text-[13px] mb-3">
            Hours
          </p>
        )}
      </div>
      <div className="grid grid-cols-2 2xl:flex 2xl:flex-col gap-4 2xl:w-[25%]">
        <OverViewCard
          title={"Total Device Financed"}
          amount={`${
            overView?.quantityRevenue?.data?.day2Quantity || 0
          } Pieces`}
          icon={<img src={iconTwo} alt="" />}
          comparedAmount={`${
            overView?.quantityRevenue?.data?.day1Quantity || 0
          } Pieces`}
          parcent={
            <p
              className={`${
                percentageChange?.type === "increase"
                  ? "text-[#57C38A]"
                  : percentageChange?.type === "decrease"
                  ? "text-[#F1595C]"
                  : "text-[#605BFF]"
              }`}
            >
              {" "}
              {`${percentageChange?.percentage}%`}
            </p>
          }
          icon2={
            percentageChange?.type === "increase" ? (
              <FaArrowUpLong color="#57C38A" />
            ) : percentageChange?.type === "decrease" ? (
              <FaArrowDownLong color="#F1595C" />
            ) : (
              ""
            )
          }
          compareText={`${
            percentageChange?.type === "increase"
              ? "Increased"
              : percentageChange?.type === "decrease"
              ? "Decreased"
              : "Increased"
          }`}
          description={"All devices sold under device financing"}
        />
        <OverViewCard
          title={"Total Amount Earned"} // TODO: please change data mapped
          amount={` ${getDefaultCurrencyCode(company)}  ${
            overView?.quantityRevenue?.data?.day2Amount || 0
          }`}
          icon={<img src={iconTuo} alt="" />}
          comparedAmount={`${getDefaultCurrencyCode(company)} ${
            overView?.quantityRevenue?.data?.day1Amount || 0
          }`}
          parcent={
            <p
              className={`${
                expectedPercentageChange?.type === "increase"
                  ? "text-[#57C38A]"
                  : expectedPercentageChange?.type === "decrease"
                  ? "text-[#F1595C]"
                  : "text-[#605BFF]"
              }`}
            >
              {" "}
              {`${expectedPercentageChange?.percentage}%`}
            </p>
          }
          textColor={"[#57C38A]"}
          icon2={
            expectedPercentageChange?.type === "increase" ? (
              <FaArrowUpLong color="#57C38A" />
            ) : expectedPercentageChange?.type === "decrease" ? (
              <FaArrowDownLong color="#F1595C" />
            ) : (
              ""
            )
          }
          compareText={`${
            expectedPercentageChange?.type === "increase"
              ? "Increased"
              : expectedPercentageChange?.type === "decrease"
              ? "Decreased"
              : "Increased"
          }`}
          description={<p>Total Amount Earned (New Subscribers) on selected day.</p>}
        />
      </div>
    </div>
  );
};
export default DashboardTopSection;
