import { Box, Checkbox, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Badge, Divider } from "antd";
import { searchValue } from "../../../utils/setColor";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const Component = (props: any) => {
  const navigate = useNavigate();
  console.log("props vv propsprops",props)
  return (
    <div className="relative min-w-[300px] h-44 xpx-2 pb-4 bg-white text-black rounded-md shadow">
      <Badge.Ribbon
        style={{ padding: "4px" }}
        color={searchValue(props?.data?.status)}
        text={<span className="capitalize">{props?.data?.status}</span>}
      >
        {props?.route === "channel" && (
          <div className="absolute top-0 left-0 z-10">
            <Checkbox
              id={props?.data._id}
              onChange={props?.handleSelected}
              {...label}
              color="success"
            />
          </div>
        )}
        <div
          onClick={() =>
            navigate(`/inventory/ws/out/${props.data.data.warehouseOutId}`)
          }
        >
          <Stack spacing={2} sx={{ paddingLeft: "10px" }}>
            <Stack
              direction="row"
              justifyContent={"space-between"}
              alignItems="center"
              sx={{ width: "100%" }}
              className="pt-4"
            >
              <Stack spacing={0}>
                <h1 className="text-base font-semibold leading-3 text-gray-900 pb-1.5">
                  {props?.data?.warehouseOutId}
                </h1>
                <p className="text-sm text-blue-400">
                  {`${props?.data?.data?.stockRequest?.shopId?.name || "N/A"}`}
                </p>
              </Stack>
            </Stack>
            <Divider />
            <Box sx={{ width: "100%" }}>
              <Stack
                direction="row"
                justifyContent={"space-between"}
                alignItems="flex-end"
                sx={{ width: "100%" }}
              >
                <Stack spacing={0}>
                  <p className="text-xs">
                    Approved By:{" "}
                    <span className="font-semibold text-xs pl-2">
                      {props?.data?.data?.stockRequest?.approvedBy?.names}
                    </span>
                  </p>
                  <p className="text-xs">
                    Approved On:{" "}
                    <span className="font-semibold text-xs pl-2">
                      {props?.data?.data?.updatedAt?.split("T")[0]}
                    </span>
                  </p>
                  <p className="text-xs">
                    Created On:
                    <span className="font-semibold text-xs pl-2">
                      {props?.data?.date}
                    </span>
                  </p>
                  {props?.data?.data?.deliverer && <p className="text-xs">
                    Deliverer:
                    <span className="font-semibold text-xs pl-2">
                      {props?.data?.data?.deliverer?.name}
                    </span>
                  </p>}
                </Stack>
                <p className="text-xs ">
                  <span className="font-semibold text-xs pr-3">
                    {props?.data?.data?.list?.length} items
                  </span>
                </p>
              </Stack>
            </Box>
          </Stack>
        </div>
      </Badge.Ribbon>
    </div>
  );
};

export default Component;
