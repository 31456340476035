import React from "react";
import { XAxis, Tooltip, ResponsiveContainer } from "recharts";
import "./styles.css";
import { LineChart, Line, YAxis, CartesianGrid } from "recharts";
import { Select } from "antd";
import { getDefaultCurrencyCode } from "../../../../utils/helper";
import { useSelector } from "react-redux";
const OverviewChart = () => {
  const { company } = useSelector((state: any) => state);
  const datas = [{}];

  const onChange = (value: string) => {
    console.log(`selected ${value}`);
  };

  const onSearch = (value: string) => {
    console.log("search:", value);
  };
  const periodOptions = [
    { label: "Monhtly", value: "month" },
    { label: "Weekly", value: "week" },
    { label: "Day", value: "day" },
  ];

  return (
    <div className="bg-[#fff] w-full h-[30rem] p-8 rounded-md">
      <div className="flex justify-between">
        <div className="flex flex-col gap-y-2">
          <div className="text-[#030229] text-base">Total Revenue</div>
          <div className="text-[#0F0F47] font-medium text-base">
            <p>
              {`${getDefaultCurrencyCode(company)} 0/`}
              <span className="text-[#03022980] font-normal">0 Pieces</span>
            </p>
          </div>
          <div className="text-[#03022980] font-medium text-base">
            <p>
              {`${getDefaultCurrencyCode(company)} 0/`}
              <span className="text-[#03022980] font-normal">0 Pieces</span>
            </p>
          </div>
        </div>
        <div className="flex gap-5">
          <div className="flex gap-2">
            <div className="w-4 h-4 rounded-sm bg-[#605BFF] mt-1"></div>{" "}
            <p className="text-[#030229]">ToDay</p>
          </div>
          <div className="flex gap-2">
            <div className="w-4 h-4 rounded-sm bg-[#BDBDBD] mt-1"></div>{" "}
            <p className="text-[#030229]">Yesterday</p>
          </div>
        </div>
        <Select
          showSearch
          placeholder="Select Period"
          style={{ width: 160 }}
          defaultValue="month"
          optionFilterProp="label"
          onChange={onChange}
          onSearch={onSearch}
          options={periodOptions}
        />
      </div>
      <ResponsiveContainer width="100%" height={300} className="mt-5">
        <LineChart data={datas}>
          <defs>
            <filter id="shadow" x="-50%" y="-50%" width="200%" height="200%">
              <feDropShadow
                dx="0"
                dy="5"
                stdDeviation="4"
                floodColor="#605BFF"
                floodOpacity="0.5"
              />
            </filter>
          </defs>
          <defs>
            <filter id="shadow2" x="-50%" y="-50%" width="200%" height="200%">
              <feDropShadow
                dx="0"
                dy="5"
                stdDeviation="4"
                floodColor="#BDBDBD"
                floodOpacity="0.5"
              />
            </filter>
          </defs>
          <CartesianGrid strokeDasharray="" opacity={0.1} />
          <XAxis dataKey="name" padding={{ left: 30, right: 30 }} />
          <YAxis />
          <Tooltip />
          <Line
            type="monotone"
            dataKey="ToDay"
            stroke="#605BFF"
            strokeWidth={3}
            dot={false}
            activeDot={{ r: 8 }}
            filter="url(#shadow)"
          />
          <Line
            type="monotone"
            dataKey="Yesterday"
            stroke="#BDBDBD"
            strokeWidth={3}
            dot={false}
            filter="url(#shadow2)"
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default OverviewChart;
