import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import DetailsHeaderActionBar from "../../../components/cards/DetailsPage/DetailsHeaderActionBar";
import PurchaseOrderConfirmTable from "../../../components/tables/PurchaseOrderConfirmTable";
import { useDispatch, useSelector } from "react-redux";
import { getAllSelectedProformasAction } from "../../../store/proforma/actions";
import { getAllQuotationsAction } from "../../../store/quotation/actions";
import { createPurchaseOrderAction } from "../../../store/purchase/actions";
import { LoadingButton } from "@mui/lab";
import { Button } from "antd";

const PurchaseOrderConfirm = () => {
  const { po_id } = useParams();
  const { quotation, auth, purchase } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  React.useEffect(() => {
    auth?.token && getAllSelectedProformasAction(auth?.token, po_id)(dispatch);
    auth?.token &&
      getAllQuotationsAction(auth?.token, `?_id=${po_id}`)(dispatch);
  }, [auth?.token, dispatch, po_id]);

  const handleConfirm = async () => {
    auth?.token &&
      (await createPurchaseOrderAction(auth?.token, po_id as string, {
        isNotify: true,
        notification: {
          action: "PO Created",
          role: ["admin", "finance", "finance-manager", "inventory"],
          message: `New Purchase Order ${quotation?.all?.data[0]?.quotationId} has been created`,
          title: "Purchase Order",
        },
      })(dispatch));
    navigate("/purchase/po");
  };

  return (
    <div className="text-black p-5 mt-3 space-y-2">
      <DetailsHeaderActionBar
        pageName="Orders"
        title={"Purchase Order | RFQ " + quotation?.all?.data[0]?.quotationId}
      />
      <div className="p-5 bg-white rounded-lg space-y-6">
        <div className="flex justify-between font-semibold text-lg space-y-2">
          <div>
            <p>Purchase Order</p>
            <p className="text-blue-500 text-lg">
              {quotation?.all?.data[0]?.quotationId}
            </p>
          </div>
          <div>
            <div className="flex items-center justify-center text-xs w-fit p-2 bg-slate-200 rounded-md">
              <p className="px-2">Status</p>
              <span className="py-1 px-3 text-white text-xs bg-gray-400 rounded-sm">
                {"P.O Draft"}
              </span>
            </div>
          </div>
        </div>
        <PurchaseOrderConfirmTable />
        <div className="space-x-2">
          <LoadingButton
            variant="contained"
            onClick={handleConfirm}
            loading={quotation?.isFetching || purchase?.isFetching}
            disabled={quotation?.isFetching}
          >
            Confirm PO
          </LoadingButton>
          <Button onClick={() => navigate("/purchase/po")} danger>
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PurchaseOrderConfirm;
