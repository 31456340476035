import React from "react";
import { PieChart, Pie, Cell } from "recharts";
interface pieProp {
  data?: any;
  colors?: any;
  totalLabel?: any;
  totalValue?: any;
  pieWidth?: number;
  pieHeight?: number;
  innerRad?: number;
  outerRad?: number;
  top?: any;
  left?: any;
  height?: any;
  contentWidth?: any;
  gridNumber?: number;
  hiddeValue?: boolean;
}
export default function SubscriptionCustomizedPieChart({
  data,
  colors,
  totalLabel,
  totalValue,
  pieWidth,
  pieHeight,
  innerRad,
  outerRad,
  top,
  left,
  height,
  contentWidth,
  gridNumber,
  hiddeValue,
}: pieProp) {
  return (
    <div>
      <div
        style={{
          position: "relative",
          width: "400px",
          height: height,
          margin: "0 auto",
        }}
      >
        <PieChart width={pieWidth} height={pieHeight}>
          <Pie
            data={data}
            innerRadius={innerRad}
            outerRadius={outerRad}
            fill="#605BFF"
            dataKey="value"
          >
            {data?.map((entry: any, index: any) => (
              <Cell
                key={`cell-${index}`}
                fill={colors[index % colors.length]}
              />
            ))}
          </Pie>
        </PieChart>
        <div
          style={{
            position: "absolute",
            top: top,
            left: left,
            transform: "translate(-50%, -50%)",
            textAlign: "center",
          }}
        >
          <p className="text-center font-medium text-base text-[#030229]">
            {totalValue}
          </p>
          <p className="text-center text-[#030229]">{totalLabel}</p>
        </div>
      </div>

      <div
        className={`"${contentWidth} hidden mx-auto xgrid grid-cols-${
          gridNumber || 2
        } gap-2 mt-[10px] items-center justify-center ${hiddeValue && "pl-28"}`}
      >
        {data?.map((entry: any, index: any) => (
          <div
            key={`legend-${index}`}
            style={{
              display: "flex",
              alignItems: "center",
              margin: "0 10px",
            }}
          >
            <div
              style={{
                width: "15px",
                height: "15px",
                backgroundColor: colors[index % colors.length],
                marginRight: "8px",
              }}
            />
            <span style={{ fontSize: "14px", color: "#030229" }}>
              {entry?.name}{" "}
              <span className="pl-5">{hiddeValue ? "" : entry?.value}</span>
            </span>
          </div>
        ))}
      </div>
    </div>
  );
}
