/* eslint-disable react/no-children-prop */
import { Divider, notification, Skeleton } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as AgentBadge } from "../assets/icons/AgentBadge.svg";

import { loginAction, userProfileBasicAction } from "../store/auth/authActions";
import { useSelector, useDispatch } from "react-redux";
import LoadingButton from "@mui/lab/LoadingButton";
import { ReactComponent as ForceLogo } from "../assets/icons/dashboard/Samphone_Logo.svg";
// import ForceLogo from "../assets/icons/dashboard/Samphone_Logo.svg";
// import ForceLogoFull from "../assets/icons/Force_Logo_SVG.svg";
import EmailIcon from "@mui/icons-material/Email";
import BarcodeScanner from "../components/elements/BarcodeScanner";
import InputField from "../components/elements/InputField";
import { Stack } from "@mui/material";

export default function App() {
  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const [askPassword, setAskPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const { auth } = useSelector((state: any) => state);
  const dispatch = useDispatch();

  const getCode = (code: string) => {
    try {
      const payload = JSON.parse(code);
      setEmail(payload?.email);
      setAskPassword(true);
    } catch (err) {
      notification.error({
        message: "Invalid qr code, please use a valid qr code",
      });
      console.error(err);
    }
  };

  useEffect(() => {
    email && userProfileBasicAction(email)(dispatch);
  }, [email, dispatch]);

  useEffect(() => {
    auth?.token && navigate("/dashboard");

    !auth?.isAuth &&
      auth.error &&
      notification.warning({ message: auth?.error?.error });
  }, [auth, navigate]);

  return (
    <div className=" h-screen font-exo py-4 text-black flex justify-center flex-1 lg:flex-none flex-col bg-slate-50">
      <div className=" mx-auto w-[350px] xl:w-[450px] flex flex-col justify-center space-y-4 relative">
        <div className="bg-slate-50 min-h-[450px] bg-opacity-50 px-[7%] py-[4%] lx:px-[10%] lx:py-[7%] space-y-4 shadow rounded-lg text-center">
          {/* <img src={ForceLogo} alt="" className="h-8 mx-auto w-52 ml-16" /> */}
          <ForceLogo className="w-36 text-sm absolute top-0 left-24 font-bold" />
          <section className="pt-14">
            {askPassword && auth.profile && (
              <Stack
                alignItems={"center"}
                justifyContent="center"
                direction={"column"}
                spacing={2}
              >
                {auth?.isFetching && <Skeleton.Image active={true} />}
                {auth?.isFetching && <Skeleton.Input active={true} />}
                {!auth?.isFetching && (
                  <>
                    <img
                      src={auth?.profile?.data?.picture}
                      alt="Profile"
                      className="w-[30%] rounded-2xl"
                    />
                    <p className="font-helv text-lg xl:text-xl capitalize">
                      {auth?.profile?.data?.names}
                    </p>
                    <p className="font-medium text-lg xl:text-sm capitalize">
                      {auth?.profile?.data?.role.split("-").join(" ")}
                    </p>
                  </>
                )}
              </Stack>
            )}
            {!askPassword && (
              <>
                <div className="font-doublebold text-lg xl:text-xl">
                  Hello Samphone heroes, 👋🏿
                </div>
                <div className="text-gray-300 text-sm xl:text-lg">
                  Scan your ID card to log in
                </div>
              </>
            )}
          </section>
          <div className="flex flex-col items-center justify-center ">
            {!isCameraOpen && !askPassword && <AgentBadge className="h-52" />}
            {isCameraOpen && (
              <BarcodeScanner
                modalIsOpen={isCameraOpen}
                setModalIsOpen={setIsCameraOpen}
                getCode={getCode}
              />
            )}
            {askPassword && (
              <InputField onChange={(e: any) => setPassword(e.target?.value)} />
            )}
          </div>
          <div className="flex justify-center">
            <LoadingButton
              className="!flex !justify-center !items-center w-full h-10 !rounded-xl !bg-[#7F66FA] !text-white"
              onClick={() => {
                if (askPassword) {
                  loginAction({ email: email, password: password })(dispatch);
                } else {
                  setIsCameraOpen(true);
                }
              }}
              loading={auth?.isFetching}
            >
              {askPassword ? "Login" : "Scan"}
            </LoadingButton>
          </div>
          <div className="text-center text-gray-300 text-sm uppercase">
            version 0.2.1
          </div>
        </div>
        <Divider children={"Or"} />
        <div className="">
          <LoadingButton
            className="flex justify-center w-full h-12 text-white !rounded-xl !bg-white !bg-opacity-50 gap-2"
            onClick={() => {
              navigate("/login");
            }}
            loading={false}
          >
            <EmailIcon /> Login with Email
          </LoadingButton>
        </div>
      </div>
    </div>
  );
}
