import React, { useEffect, useState } from "react";
import { LinearProgress, Stack, styled } from "@mui/material";
import MetricsCard from "../../../components/cards/purchase/MetricsCard";
import type { DatePickerProps, TableColumnsType } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getRecentPurchaseOrdersAction } from "../../../store/purchase/actions";
import moment from "moment";
import {
  getPiecesSoldStatsActions,
  getProductsStatsActions,
} from "../../../store/dashboard/actions";
import {
  Card,
  Checkbox,
  Progress,
  Switch,
  Table,
  Carousel,
  Skeleton,
  Tag,
} from "antd";
import Wifi from "../../../assets/Icon.png";
import warehouseInIcon from "../../../assets/Icon.svg";
import warehouseOutIcon from "../../../assets/warehouseout.svg";
import stockrequestIcon from "../../../assets/warehouseout.svg";
import productIcon from "../../../assets/Iconproduct.svg";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import {
  getAllStatisticsAction,
  getAllStatisticsSummaryAction,
  getRecentActivityctions,
  getStockRequestOverViewActions,
  getTopSellingProductActions,
} from "../../../store/inventory/actions";
import { DateRangePicker } from "react-date-range";
import { enUS } from "date-fns/locale";
import { StatisticsCard } from "../../../components/cards/statistics/StatisticsCard";
import LoadingCard from "../../../components/cards/LoadingCard";
import { searchValue } from "../../../utils/setColor";

const PageView = () => {
  const { auth, inventory } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const formattedDate = new Date().toISOString().slice(0, 10);
  let today = moment();
  let previousDate = today.subtract(1, "days");
  let formattedPreviousDate = previousDate.format("YYYY-MM-DD");
  const [warehouseIn, setWarehouseIn] = useState(true);
  const [warehouseOut, setWarehouseOut] = useState(true);
  const [stockRequest, setStockRequest] = useState(true);
  const [checkedValue, setCheckedValue] = useState("$product.brand");
  const [enteredDate, setEnteredDate] = useState<any>(formattedDate);
  const [compareDate, setCompareDate] = useState<any>(formattedPreviousDate);
  const onChange = (e: any) => {
    setCheckedValue(e.target?.value);
  };
  const getLastWeekDate = () => {
    const today = new Date();
    const lastWeek = new Date(today);
    lastWeek.setDate(lastWeek.getDate() - 6);
    lastWeek.setHours(0, 0, 0, 0);
    return lastWeek;
  };
  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate: getLastWeekDate(),
    endDate: new Date(),
    key: "selection",
  });

  const handleSwitchChange = (setter: any, checked: any) => {
    setter(checked);
  };

  const formatDate = (date: any) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const dateOnly = formatDate(selectedDateRange.startDate);
  const endOnly = formatDate(selectedDateRange.endDate);

  const handleSelect = (ranges: any) => {
    setSelectedDateRange(ranges.selection);
  };

  React.useEffect(() => {
    auth?.token && getRecentPurchaseOrdersAction(auth?.token)(dispatch);
  }, [auth?.token, dispatch]);

  useEffect(() => {
    if (auth?.token && enteredDate) {
      getProductsStatsActions(
        auth?.token,
        `?date1=${enteredDate}&date2=${compareDate}&selectBy=model`
      )(dispatch);
      getPiecesSoldStatsActions(
        auth?.token,
        `?date1=${enteredDate}&date2=${compareDate}&selectBy=model`
      )(dispatch);

      getAllStatisticsSummaryAction(auth?.token)(dispatch);
    }
  }, [auth?.token, dispatch, enteredDate, compareDate]);

  useEffect(() => {
    auth?.token && getAllStatisticsAction(auth?.token)(dispatch);
  }, [dispatch, auth?.token]);

  const totalCount = inventory?.topSelling?.data.reduce(
    (acc: any, el: any) => acc + el.count,
    0
  );

  useEffect(() => {
    if (auth?.token && dateOnly && endOnly) {
      getStockRequestOverViewActions(
        auth?.token,
        `?startingDate=${dateOnly}&endingDate=${endOnly}`
      )(dispatch);
    }
  }, [auth?.token, dateOnly, endOnly, dispatch]);

  useEffect(() => {
    auth?.token &&
      getTopSellingProductActions(
        auth?.token,
        `?brand=${checkedValue}`
      )(dispatch);
  }, [auth?.token, checkedValue, dispatch]);

  useEffect(() => {
    auth?.token && getRecentActivityctions(auth?.token, "")(dispatch);
  }, [auth?.token, dispatch]);

  const columns: TableColumnsType<any> = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Activity",
      dataIndex: "activity",
      key: "activity",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Total Amount",
      dataIndex: "totalAmount",
      key: "totalAmount",
    },
    {
      title: "Done By",
      dataIndex: "doneBy",
      key: "doneBy",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (record: any) => {
        return (
          <Tag color={searchValue(record)} className="py-1 w-32 text-center">
            {record}
          </Tag>
        );
      },
    },
    // {
    //   title: "Action",
    //   dataIndex: "action",
    //   key: "action"
    // },
  ];

  const RecentData = inventory?.recentActivity?.data?.map((el: any) => {
    return {
      key: el?._id,
      date: el?.warehouseItem?.createdAt?.slice(0, 10),
      quantity: el?.warehouseItem?.purchaseItem?.quantity,
      totalAmount: el?.warehouseItem?.purchaseItem?.priceUnit,
      doneBy: el?.warehouseItem?.purchaseItem?.quotationItem?.createdBy?.names,
      status: el?.warehouseItem?.status,
    };
  });
  const totalwarehousesummary: any =
    inventory?.inventorySummary?.data?.warehouseInSummary?.reduce(
      (sum: any, item: any) => sum + (item?.value || 0),
      0
    );
  const totalwarehousesOutSummary =
    inventory?.inventorySummary?.data?.warehouseOutSummary?.reduce(
      (sum: any, item: any) => sum + (item?.value || 0),
      0
    );
  const totalstockRequestSummary =
    inventory?.inventorySummary?.data?.stockRequestSummary?.reduce(
      (sum: any, item: any) => sum + (item?.value || 0),
      0
    );
  const yAxisTickFormatter = (tick: any) => {
    return tick;
  };

  const data1 = inventory?.stockrequest?.data;

  const getOrdinalSuffix = (day: number) => {
    if (day >= 11 && day <= 13) {
      return "th";
    }
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };
  const option = [
    {
      label: "Brand",
      value: "$product.brand",
    },
    {
      label: "Type",
      value: "$product.type",
    },
    {
      label: "Model",
      value: "$product.model",
    },
  ];
  const customTickFormatter = (tick: any) => {
    const date = new Date(tick);
    const day = date.getDate();
    const suffix = getOrdinalSuffix(day);

    return `${day}${suffix}`;
  };
  const stockRequestSummaryData =
    inventory?.inventorySummary?.data?.stockRequestSummary?.map((el: any) => {
      return {
        value: el?.value,
        label: el?.label,
      };
    });
  const warehoseInSummaryData =
    inventory?.inventorySummary?.data?.warehouseInSummary?.map((el: any) => {
      return {
        value: el?.value,
        label: el?.label,
      };
    });
  const warehoseOutSummaryData =
    inventory?.inventorySummary?.data?.warehouseOutSummary
      ?.slice(0, 5)
      ?.map((el: any) => {
        return {
          value: el?.value,
          label: el?.label,
        };
      });

  return (
    <div className="text-black py-6">
      <Stack spacing={3}>
        <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 text-white gap-3 w-[100%]">
          {inventory?.isFetching ? (
            <LoadingCard />
          ) : (
            <Card
              bordered={false}
              style={{ height: 130 }}
              className="flex-auto"
            >
              <div className="flex flex-row gap-3 items-center">
                <div className="h-[100%]">
                  <img src={Wifi} alt="" />
                </div>
                <div>
                  <div className="text-xl pb-2 text-[#030229be] font-semibold mt-2">
                    {inventory.inventorystats?.data?.currentStock || 0}{" "}
                  </div>
                  <div className="font-poppins text-base font-normal text-[#030229a8]">
                    Total Current Stock
                  </div>
                </div>
              </div>
            </Card>
          )}
          {inventory?.isFetching ? (
            <LoadingCard />
          ) : (
            <Card
              bordered={false}
              style={{ height: 130 }}
              className="flex-auto"
            >
              <div className="flex flex-row gap-3 items-center">
                <div className="h-[100%]">
                  <img src={warehouseInIcon} />
                </div>
                <div>
                  <div className="text-xl pb-2 text-[#030229be] font-semibold mt-2">
                    {inventory.inventorystats?.data?.statusIn || 0}
                  </div>
                  <div className="font-poppins text-base font-normal text-[#030229a8]">
                    Total Warehouse In
                  </div>
                </div>
              </div>
            </Card>
          )}
          {inventory?.isFetching ? (
            <LoadingCard />
          ) : (
            <Card
              bordered={false}
              style={{ height: 130 }}
              className="flex-auto"
            >
              <div className="flex flex-row gap-3 items-center">
                <div className="h-[100%]">
                  <img src={warehouseOutIcon} />
                </div>
                <div>
                  <div className="text-xl pb-2 text-[#030229be] font-semibold mt-2">
                    {inventory.inventorystats?.data?.statusOut || 0}
                  </div>
                  <div className="font-poppins text-lg font-normal text-[#030229a8]">
                    Total Warehouse Out{" "}
                  </div>
                </div>
              </div>
            </Card>
          )}
          {inventory?.isFetching ? (
            <LoadingCard />
          ) : (
            <Card style={{ height: 130 }} className="flex-auto">
              <div className="flex flex-row gap-3 items-center">
                <div className="text-center">
                  <img src={stockrequestIcon} />
                </div>
                <div>
                  <div className="text-xl pb-2 text-[#030229be] font-semibold mt-2">
                    {inventory?.inventorystats?.data?.totalStockRequest || 0}
                  </div>
                  <div className="font-poppins text-base font-normal text-[#030229a8]">
                    Total Stock Request{" "}
                  </div>
                </div>
              </div>
            </Card>
          )}
          {inventory?.isFetching ? (
            <LoadingCard />
          ) : (
            <Card style={{ height: 130 }} className="flex-auto">
              <div className="flex flex-row gap-5 items-center">
                <div className="text-center">
                  <img src={productIcon} />
                </div>
                <div>
                  <div className="text-xl pb-2 text-[#030229be] font-semibold mt-2">
                    {inventory?.inventorystats?.data?.totalProducts || 0}
                  </div>
                  <div className="font-poppins text-base font-normal text-[#030229a8]">
                    Total Products{" "}
                  </div>
                </div>
              </div>
            </Card>
          )}
        </div>
        <div className="grid lg:grid-cols-2 2xl:grid-cols-3 text-white gap-6 w-[100%] pl-3">
          <div className="bg-white p-4 2xl:ws-[32%] h-[280px] rounded-md relative">
            <h2 className="text-base font-poppins font-medium mb-4 text-black pl-5">
              Warehouse In Summary
            </h2>
            {inventory?.isFetching ? (
              <Skeleton />
            ) : (
              <StatisticsCard
                total={totalwarehousesummary}
                data={warehoseInSummaryData || []}
              />
            )}
          </div>
          <div className="bg-white p-4 2xl:ws-[32%] h-[280px] rounded-md relative">
            <h2 className="text-base font-poppins font-medium mb-4 text-black pl-5">
              Warehouse Out Summary
            </h2>
            {inventory?.isFetching ? (
              <Skeleton className="w-full" />
            ) : (
              <StatisticsCard
                total={totalwarehousesOutSummary}
                data={warehoseOutSummaryData || []}
              />
            )}
          </div>
          <div className="bg-white p-4 2xl:ws-[32%] h-[280px] rounded-md relative">
            <h2 className="text-base font-poppins font-medium mb-4 text-black pl-5">
              Stock Request Summary
            </h2>
            {inventory?.isFetching ? (
              <Skeleton />
            ) : (
              <StatisticsCard
                total={totalstockRequestSummary}
                data={stockRequestSummaryData || []}
              />
            )}
          </div>
        </div>

        <div className="w-[100%]  p-3 rounded-lg flex flex-wrap gap-5">
          <Card bordered={false} className="w-[100%] xl:w-[65%] pt-3 px-4">
            <div className="flex justify-between items-center mb-1">
              <h2 className="text-lg font-poppins text-black">Analytics</h2>
              <div className="mb-1 flex flex-row items-center gap-3">
                <div className="flex items-center text-[#030229] gap-3">
                  <Switch
                    size="small"
                    checked={warehouseIn}
                    onChange={(checked) =>
                      handleSwitchChange(setWarehouseIn, checked)
                    }
                    style={{
                      backgroundColor: warehouseIn ? "#AFADFF" : "#D9D9D9",
                    }}
                  />
                  <span>Warehouse In</span>
                </div>
                <div className="flex items-center text-[#030229] gap-3">
                  <Switch
                    size="small"
                    checked={warehouseOut}
                    onChange={(checked) =>
                      handleSwitchChange(setWarehouseOut, checked)
                    }
                    style={{
                      backgroundColor: warehouseOut ? "#FD9AAC" : "#D9D9D9",
                    }}
                  />
                  <span>Warehouse Out</span>
                </div>
                <div className="flex items-center text-[#030229] gap-3">
                  <Switch
                    size="small"
                    checked={stockRequest}
                    onChange={(checked) =>
                      handleSwitchChange(setStockRequest, checked)
                    }
                    style={{
                      backgroundColor: stockRequest ? "#87D48F" : "#D9D9D9",
                    }}
                  />
                  <span>Stock Request</span>
                </div>
              </div>
              {/* <Select
                className="text-sm"
                defaultValue="This Month"
                onChange={handleMonthChange}
                style={{ width: 130 }}
              >
                <Option value="January">January</Option>
                <Option value="February">February</Option>
                <Option value="March">March</Option>
                <Option value="April">April</Option>
                <Option value="May">May</Option>
                <Option value="June">June</Option>
                <Option value="July">July</Option>
                <Option value="August">August</Option>
                <Option value="September">September</Option>
                <Option value="October">October</Option>
                <Option value="November">November</Option>
                <Option value="December">December</Option>
              </Select> */}
            </div>
            <div>
              <ResponsiveContainer width="100%" height={300}>
                <LineChart
                  margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                  data={data1}
                >
                  <defs>
                    <filter id="shadow" width="200%" height="200%">
                      <feDropShadow
                        dx="5"
                        dy="5"
                        stdDeviation="5"
                        floodColor="#605BFF"
                      />
                    </filter>
                  </defs>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    dataKey="date"
                    axisLine={false}
                    tickLine={false}
                    tick={{ fontSize: 15 }}
                    tickMargin={15}
                    tickFormatter={customTickFormatter}
                  />
                  <YAxis
                    axisLine={false}
                    tickLine={false}
                    tickFormatter={yAxisTickFormatter}
                    tickMargin={10}
                  />
                  <Tooltip />
                  {warehouseIn && (
                    <Line
                      type="monotone"
                      dataKey="status in"
                      stroke="#AFADFF"
                      strokeWidth={3}
                      activeDot={{ r: 8 }}
                      dot={{
                        fill: "#FFFFFF",
                        stroke: "#605BFF",
                        strokeWidth: 2,
                      }}
                    />
                  )}
                  {warehouseOut && (
                    <Line
                      type="monotone"
                      dataKey="status out"
                      stroke="#FD9AAC"
                      strokeWidth={3}
                      activeDot={{ r: 8 }}
                      dot={{
                        fill: "#FFFFFF",
                        stroke: "#605BFF",
                        strokeWidth: 2,
                      }}
                    />
                  )}
                  {stockRequest && (
                    <Line
                      type="monotone"
                      dataKey="shopStockReq"
                      stroke="#87D48F"
                      strokeWidth={3}
                      activeDot={{ r: 8 }}
                      dot={{
                        fill: "#FFFFFF",
                        stroke: "#605BFF",
                        strokeWidth: 2,
                      }}
                    />
                  )}
                </LineChart>
              </ResponsiveContainer>
            </div>
          </Card>
          <DateRangePicker
            className="w-[100%] xl:w-[30%] mt-4 xl:mt-0 pb-4"
            onChange={handleSelect}
            showPreview
            // moveRangeOnFirstSelection={true}
            months={1}
            ranges={[selectedDateRange]}
            direction="vertical"
            locale={enUS}
          />
        </div>
        <div className="w-full   rounded-lg space-y-4 flex flex-col justify-between lg:flex-row">
          <Card bordered={false} className=" lg:w-[60%] xl:w-[68%] pt-3 px-4">
            <div className="text-poppins text-lg font-normal text-[#030229]">
              Recent Activities
            </div>
            <div>
              <Table
                columns={columns}
                dataSource={RecentData}
                loading={inventory?.isFetching}
                scroll={{ x: true }}
                className="w-[100%] md:w-auto"
              />
            </div>
          </Card>
          <Card bordered={false} className="lg:w-[38%] xl:w-[30%] pt-3">
            <div className="flex flex-col md:flex-row items-row justify-between">
              <div className="text-poppins  text-base font-normal text-[#030229]">
                Top-selling Products
              </div>
              {option.map((el: any) => {
                return (
                  <Checkbox
                    key={el?.value}
                    value={el?.value}
                    checked={checkedValue === el?.value}
                    onChange={onChange}
                    className="custom-checkbox"
                  >
                    <span className="pt-2"> {el?.label}</span>
                  </Checkbox>
                );
              })}
            </div>
            <div className="flex flex-row items-row justify-between pt-4 ml-20">
              <div className="w-full text-[#030229B2] text-sm">Name</div>
              <div className="w-full text-[#030229B2] text-sm">Sales</div>
            </div>
            {inventory?.topSelling?.data?.map((el: any) => {
              const percentage =
                totalCount > 0 ? (el.count / totalCount) * 100 : 0;
              return (
                <>
                  {inventory?.isFetching ? (
                    <Skeleton className="mt-2" avatar paragraph={{ rows: 4 }} />
                  ) : (
                    <div className="flex flex-row items-row py-2 border rounded-md px-4 my-1">
                      <div className="w-14 h-14 bg-[#F4F4F6] rounded-md p-1 ">
                        <Carousel autoplay>
                          {el?.images?.length > 0 ? (
                            el?.images?.map((item: any, imgIndex: number) => {
                              return (
                                <img
                                  key={imgIndex}
                                  src={item}
                                  className="w-14 h-14"
                                />
                              );
                            })
                          ) : (
                            <img
                              src="https://user-images.githubusercontent.com/47315479/81145216-7fbd8700-8f7e-11ea-9d49-bd5fb4a888f1.png"
                              className="w-14 h-10 bg-gray-300"
                            />
                          )}
                        </Carousel>
                      </div>
                      <div className="p-2 w-20 flex item-center justify-center">
                        {el.name}
                      </div>
                      <Progress
                        percent={parseInt(percentage.toFixed(2))}
                        strokeColor={"#605BFF"}
                        className="w-40 mt-2"
                      />
                      <div className="p-2">Sold Out</div>
                    </div>
                  )}
                </>
              );
            })}
          </Card>
        </div>
      </Stack>
    </div>
  );
};

export default PageView;
