import React, { useState, useEffect } from "react";
import { Table, Tag, Tooltip } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useSelector, useDispatch } from "react-redux";
import { getAllTransactionInRangeAction } from "../../../store/pos/actions";
import { searchValue } from "../../../utils/setColor";
import ScrollableFrame from "../../layout/ScrollableFrame";
import { SalesReportHeader } from "../../csvHeaders/Headers";
import ProductFilter from "../../../components/filters/POSTransactionsFiltering";
import PaidOnForm from "../../forms/PaidOnForm";
import moment from "moment";
import ThreeDotDropdown from "./threeDotDropdown";
import DetailsModel from "../../Modals/DetailsModel";
import AmountPaidForm from "../../forms/AmountPaidAndPaymentMethodForm";

import CSVExportButton from "../../cards/NoSale/CSVExportButton";
import { getFinanceTransaction_CSVAction } from "../../../store/csvDownload/actions";
import { getDefaultCurrencyCode } from "../../../utils/helper";
interface DataType {
  [x: string]: any;
  key: React.Key;
  name: string;
  age: number;
  address: string;
}

const SalesTable = (props: any) => {
  const { pos, auth, layout, CSV, company } = useSelector(
    (state: any) => state
  );
  const [openPaidOnModal, setOpenPaidOnModal] = useState(false);
  const [openAmountModal, setOpenAmountModal] = useState(false);
  const channels = auth?.user?.channel;
  const initialChannel = channels && channels?.at(0)?._id;
  let today = moment();
  const formattedDate = new Date().toISOString().slice(0, 10);
  let previousDate = today.subtract(1, "days");
  let formattedPreviousDate = previousDate.format("YYYY-MM-DD");
  const [selectedDate, setSelectedDate] = useState<any>(formattedPreviousDate);
  const [selectedEndDate, setSelectedEndDate] = useState<any>(formattedDate);
  const [selectType, setSelectType] = useState("");
  const [selectBrand, setSelectBrand] = useState("");
  const [selectedStatus, setSelectedStatus] = useState();
  const [setSelectedShop] = useState();
  const [selectedChannel, setSelectedChannel] = useState("");
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(15);
  const [paidOnData, setPaidOnData] = useState<any>("");
  const [dataToUpdate, setDataToUpdate] = useState<any>("");
  const [selectedAction, setSelectedAction] = useState("");

  const paidOnModel = (value: any, action: string) => {
    setSelectedAction(action);
    setPaidOnData(value);
    setOpenPaidOnModal(true);
    setOpenAmountModal(false);
  };

  const onCancelPaidOnModel = () => {
    setPaidOnData("");
    setSelectedAction("");
    setOpenPaidOnModal(false);
  };

  const amountPaidModel = (value: any, action: any) => {
    setSelectedAction(action);
    setDataToUpdate(value);
    setOpenAmountModal(true);
    setOpenPaidOnModal(false);
  };

  const onCancelAmountPaidModel = () => {
    setDataToUpdate("");
    setSelectedAction("");
    setOpenAmountModal(false);
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "Date",
      dataIndex: "paidOn",
      key: "paidOn",
    },
    {
      title: "Customer",
      dataIndex: "customer",
      key: "customer",
      render: (text, record) => (
        <>
          <p>{text || "N/A"}</p>
          <p>{record?.costumerPhone || "N/A"}</p>
        </>
      ),
    },
    {
      title: "Agent",
      dataIndex: "agent",
      key: "agent",
      render: (text) => (
        <>
          <p className="capitalize">{text}</p>
        </>
      ),
    },

    {
      title: "Shop",
      dataIndex: "shop",
      key: "shop",
      render: (text, record) => (
        <>
          <p>{record?.data?.shopStockItem?.shop?.name ?? "N/A"}</p>
          <p>{record?.data?.shopStockItem?.shop?.channel?.name ?? "N/A"}</p>
        </>
      ),
    },
    {
      title: "Model",
      dataIndex: "model",
      key: "model",
      render: (text, record) => (
        <Tooltip title={`${record.specification}`}>
          <p>{text}</p>
        </Tooltip>
      ),
    },
    {
      title: "SN/Bar Code",
      dataIndex: "barCode",
      key: "barCode",
    },
    {
      title: "Data Activated",
      dataIndex: "dataActivated",
      key: "dataActivated",
      render: (text: any, record: any) => (
        <p>{`${record?.dataActivated ? "Yes" : " No"}`}</p>
      ),
    },
    {
      title: `Amount Paid (${getDefaultCurrencyCode(company)})`,
      dataIndex: "amount",
      key: "amount",
      render: (text, record) => (
        <>
          <Tag className="font-bold">{record?.amount}</Tag>
          {/* //TODO: will be enabled later */}
          {/* <Tag className="font-bold">
            {record?.data?.successPayment?.length > 0
              ? record?.data?.successPayment
                  ?.reduce((sum: number, a: any) => (sum = sum + a?.amount), 0)
                  ?.toLocaleString()
              : record?.data?.cart?.payment
                  ?.reduce((sum: number, a: any) => (sum = sum + a?.amount), 0)
                  ?.toLocaleString()}
          </Tag> */}
        </>
      ),
    },
    {
      title: "Payment Method",
      dataIndex: "payment",
      key: "payment",
      render: (_text: any, record: any) => (
        <p>
          {record?.data?.cart?.payment?.map((d: any) =>
            d?.mode?.split("_")?.join(" ")
          )}
        </p>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 100,
      render: (text, record) => (
        <span className="flex items-center">
          <Tag
            color={searchValue(record?.data?.status)}
            className="capitalize min-w-[60px] text-center py-0.5"
          >
            {record?.data?.status}{" "}
          </Tag>
        </span>
      ),
    },
    {
      title: `${!["mascom"]?.includes(auth?.user?.tag) ? "Action" : ""} `,
      dataIndex: "action",
      key: "action",
      width: 100,
      render: (_text, record) => {
        return (
          <>
            {["admin", "dev"]?.includes(auth?.user?.role) &&
              !["mascom"]?.includes(auth?.user?.tag) &&
              record?.status?.toLowerCase() === "paid" && (
                <span className="flex items-center">
                  <ThreeDotDropdown
                    onPaidOn={() => paidOnModel(record?.data, "paidOn")}
                    onAmountPaid={() =>
                      amountPaidModel(record?.data?.cart, "amount")
                    }
                    onPaymentMethod={() =>
                      amountPaidModel(record?.data?.cart, "paymentMethod")
                    }
                    onRevertSale={() => paidOnModel(record?.data, "revert")}
                  />
                </span>
              )}
          </>
        );
      },
    },
  ];

  const encodedBrand = selectBrand && encodeURIComponent(selectBrand);

  useEffect(() => {
    if (selectedDate || selectedEndDate) {
      auth?.token &&
        getAllTransactionInRangeAction(
          auth?.token,
          `?startDate=${selectedDate}&endDate=${selectedEndDate}&limit=${limit}&page=${
            page - 1
          }&channel=${
            initialChannel ?? selectedChannel
          }&type=${selectType}&brand=${encodedBrand}${
            layout?.selectedShop ? `&shop=${layout?.selectedShop}` : ""
          }`
        )(dispatch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    auth?.token,
    dispatch,
    limit,
    page,
    selectedStatus,
    selectedDate,
    selectedEndDate,
    selectType,
    encodedBrand,
    initialChannel,
    layout?.selectedShop,
  ]);

  useEffect(() => {
    if (layout?.openCSVButton)
      auth?.token &&
        getFinanceTransaction_CSVAction(
          auth?.token,
          `?startDate=${selectedDate}&endDate=${selectedEndDate}&limit=${
            pos?.transaction?.total
          }&page=0&channel=${
            initialChannel ?? selectedChannel
          }&type=${selectType}&brand=${encodedBrand}${
            layout?.selectedShop ? `&shop=${layout?.selectedShop}` : ""
          }`,
          CSV.fileToExport
        )(dispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layout?.openCSVButton]);

  const value = pos?.transaction?.data?.transactions ?? pos?.transaction?.data;

  const dataToDisplay = value?.map((el: any) => {
    return {
      key: el?._id,
      date_time: el?.createdAt?.split("T")[0],
      paidOn: el?.paidOn?.slice(0, 10),
      agent: el?.createdBy?.names,
      model: el?.shopStockItem?.requestedItem?.product?.product?.model,
      specification: `${el?.shopStockItem?.requestedItem?.product?.specification
        ?.map((d: any) => d[1] && `${d[0]}: ${d[1]}`)
        ?.join(", ")}`,
      amount: el?.payment?.amount,
      status: el?.status,
      barCode: el?.warehouseProduct?.serialNumber,
      dataActivated: el?.warehouseProduct?.dataActivated,
      transactionId: el?.cart?.cartId,
      customer: el?.cart?.customer?.name,
      costumerPhone: el?.cart?.customer?.phone || el?.cart?.customer?.msisdn,
      data: el,
    };
  });

  const csvData = CSV?.csvDownloadedData?.data?.map((el: any) => {
    return {
      date_time: el?.createdAt?.slice(0, 10),
      paidOn: new Date(el?.paidOn?.slice(0, 10))?.toLocaleDateString("en-GB"),
      customer: el?.cart?.customer?.name || "N/A",
      customerPhone: el?.cart?.customer?.phone || "N/A",
      agent: el?.createdBy?.names,
      status: el?.status,
      region: el?.shopStockItem?.shop?.region || "N/A",
      shop:
        el?.shopStockItem?.shop?.type !== "service-center"
          ? el?.shopStockItem?.shop?.name +
            " " +
            el?.shopStockItem?.shop?.channel?.name
          : el?.shopStockItem?.shop?.name,
      model: el?.shopStockItem?.requestedItem?.product?.product?.model,
      type: el?.shopStockItem?.requestedItem?.product?.product?.type,
      brand: el?.shopStockItem?.requestedItem?.product?.product?.brand,
      barCode: el?.warehouseProduct?.serialNumber,
      imei2: el?.warehouseProduct?.imei2 ?? "N/A",
      dataActivated: el?.warehouseProduct?.dataActivated ? "YES" : "NO",
      storage: el?.shopStockItem?.requestedItem?.product?.specification
        ?.filter((item: any) =>
          ["capacity", "capacity (rom)", "rom", "capacity(rom)"]?.includes(
            item[0]?.toLowerCase()
          )
        )
        ?.map((item: any) => item[1]),
      memory: el?.shopStockItem?.requestedItem?.product?.specification
        ?.filter((item: any) =>
          ["memory", "memory (ram)", "ram", "memory(ram)"]?.includes(
            item[0]?.toLowerCase()
          )
        )
        ?.map((item: any) => item[1]),
      retailPrice:
        (
          el?.cart?.selectedPlan?.duration *
          el?.cart?.selectedPlan?.monthlyInstalment
        )?.toLocaleString() || "N/A",
      methodOfPayment: el?.cart?.payment
        ?.map((d: any) => d?.mode)
        ?.join(" and "),
      transactionId: el?.cart?.payment?.at(0)?.momoTransactionId ?? "N/A",
      district: el?.shopStockItem?.shop?.shopLocation?.district ?? "N/A",
      totalAmount:
        el?.successPayment?.length > 0
          ? el?.successPayment
              ?.map((d: any) => d?.amount?.toLocaleString())
              ?.join(" + ")
          : el?.cart?.payment
              ?.map((d: any) => d?.amount?.toLocaleString())
              ?.join(" + "),
    };
  });

  return (
    <>
      <div className="relative text-black mt-4">
        <div className="flex items-center justify-between xmb-4 mx-4">
          <h1 className="font-bold text-xl pt-2">Sales Report</h1>
          <div className="flex justify-end gap-4 py-4">
            <CSVExportButton
              csvHeaders={SalesReportHeader}
              csvData={csvData}
              filename={`Sales Report.csv`}
            />
          </div>
        </div>
        <ProductFilter
          setSelectedShop={setSelectedShop}
          setSelectedStatus={setSelectedStatus}
          setSelectedDate={setSelectedDate}
          setSelectedEndDate={setSelectedEndDate}
          setSelectedChannel={setSelectedChannel}
          selectedChannel={selectedChannel}
          selectedDate={selectedDate}
          selectedEndDate={selectedEndDate}
          setSelectType={setSelectType}
          setSelectBrand={setSelectBrand}
          selectType={selectType}
          selectBrand={selectBrand}
          limit={limit}
          page={page}
          route={props?.route}
        />

        <ScrollableFrame
          setPage={setPage}
          setLimit={setLimit}
          limit={limit}
          total={pos?.transaction?.total}
          count={Math.ceil(pos?.transaction?.total / limit)}
        >
          <Table
            columns={columns}
            dataSource={dataToDisplay}
            pagination={false}
            loading={pos?.isFetching}
          />
        </ScrollableFrame>
      </div>

      <DetailsModel
        isModalOpen={openPaidOnModal}
        handleOnCancel={onCancelPaidOnModel}
        component={
          <PaidOnForm
            cartId={paidOnData?.cart?._id}
            dataToUpdate={paidOnData}
            onCancel={onCancelPaidOnModel}
            selectedDate={selectedDate}
            selectedEndDate={selectedEndDate}
            action={selectedAction}
          />
        }
      />
      <DetailsModel
        isModalOpen={openAmountModal}
        handleOnCancel={onCancelAmountPaidModel}
        component={
          <AmountPaidForm
            cartId={dataToUpdate?._id}
            dataToUpdate={dataToUpdate}
            onCancel={onCancelAmountPaidModel}
            action={selectedAction}
            selectedDate={selectedDate}
            selectedEndDate={selectedEndDate}
          />
        }
      />
    </>
  );
};

export default SalesTable;
