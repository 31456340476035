import { CSVLink } from "react-csv";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  handleDataToExport,
  openCSVButton,
} from "../../../store/layout/actions";
import { myCSVAction } from "../../../store/csvDownload";
import { Button } from "antd";
import { format } from "date-fns";
import { DownloadOutlined } from "@ant-design/icons";

interface CSVTableDownloadProps {
  columns?: any[];
  csvHeaders: { label: string; key: string }[];
  csvData: any[];
  filename: any;
  directory?: any;
}
const CSVExportButton: React.FC<CSVTableDownloadProps> = ({
  csvHeaders,
  csvData,
  filename,
  directory,
}) => {
  const { CSV } = useSelector((state: any) => state);
  const csvLinkRef = React.useRef<any>(null);
  const dispatch = useDispatch();

  const today = new Date();
  const currentDay = format(today, "yyyy-MM-dd");

  const handleButtonClick = async (fileName: string) => {
    await handleDataToExport(directory)(dispatch);
    await openCSVButton(true)(dispatch);
    dispatch(myCSVAction.setFileToExport(fileName));
  };

  useEffect(() => {
    if (CSV?.allowcsvDownload && csvLinkRef && csvLinkRef.current) {
      csvLinkRef.current.link.click();
    }
    openCSVButton(false)(dispatch);
    handleDataToExport(null)(dispatch);
    dispatch(myCSVAction.setCSVDownloadedData(null));
    dispatch(myCSVAction.setAllowCSVDownload(false));
    dispatch(myCSVAction.setIsFileDownload(false));
    dispatch(myCSVAction.setFileToExport("csv"));
  }, [CSV?.allowcsvDownload]);

  return (
    <div onClick={() => handleButtonClick("csv")}>
      <Button
        type="primary"
        icon={<DownloadOutlined />}
        size={"small"}
        loading={CSV?.isFetching}
        disabled={CSV?.isFetching}
        className="py-4"
      >
        Export CSV
      </Button>
      <CSVLink
        data={csvData ?? []}
        headers={csvHeaders}
        ref={csvLinkRef}
        filename={`${currentDay}_${filename}`}
      />
    </div>
  );
};

export default CSVExportButton;
