import React from "react";
import { Box, Stack } from "@mui/material";
import { Badge, Divider, Space } from "antd";
import { useNavigate } from "react-router-dom";
import ProfileAvatal from "../buttons/ProfileAvatal";
import { searchValue } from "../../utils/setColor";

const Component = (props: any) => {
  const navigate = useNavigate();
  return (
    <>
      <Badge.Ribbon
        text={
          <span
            style={{
              fontSize: "12px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              fontWeight: 400,
              paddingTop: "1.5px",
            }}
          >
            {props?.data?.status}
          </span>
        }
        color={searchValue(props?.data?.status)}
        style={{ width: "5rem", padding: "4px 1px" }}
      >
        <div
          className="w-[384px] h-[220px] text-[#030229] bg-white border-[.5px] border-[#D9DBE930] rounded-[10px] shadow-[1px_3px_16px_0px_rgba(0,0,0,0.07)] p-6 font-normal"
          onClick={() => {
            // if (props?.data?.status !== "Rejected") {
            navigate(`/customers/${props?.data?._id}`);
            // }
          }}
        >
          <div className="flex gap-6 pb-2">
            <div style={{ width: "2rem" }}>
              <ProfileAvatal
                picture={props?.data?.picture}
                name={props?.data?.name || "N/A"}
                noActiveBadge={true}
              />
            </div>
            <div className="flex flex-col">
              <h1 className="text-[16px]">
                {props.data?.name?.toUpperCase() || "N/A"}
              </h1>
              <p className="text-[14px] text-[#605BFF]">
                {props?.data?.nationalId || "N/A"}
              </p>
            </div>
          </div>
          <Divider />
          <div className="flex flex-col text-[14px]">
            <Space>
              <p className="opacity-50 ">Email : </p>
              <p className="text-[#030229] ">{props.data?.email || "N/A"}</p>
            </Space>
            <Space>
              <p className="opacity-50 ">Phone : </p>
              <p className="text-[#030229] ">
                {props.data?.phone || props.data?.msisdn || "N/A"}
              </p>
            </Space>
            <Space>
              <p className="opacity-50 ">Country : </p>
              <p className="text-[#030229] ">
                {" "}
                {props.data?.address
                  ? props.data.address.split(",").pop().trim()
                  : "N/A"}
              </p>
            </Space>
          </div>
        </div>
      </Badge.Ribbon>
    </>
  );
};

export default Component;
