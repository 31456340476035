import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import ItemManagement from "./ItemManagement";
import Overview from "./Overview";
import CustomerManagement from "./CustomerManagement";
import SalesManagement from "./SalesManagement";
import PurchaseManagement from "./PuchaseManagement";
import ImportManagement from "./ImportManagement";
import SideBar from "../components/SideBar";
import OpeningClosingStock from "./Opening&ClosingStockManagement";
import StockStatus from "./StockStatus";
import StockInHistory from "./StockInHistory";
import StockOutHistory from "./StockOutHistory";

const EBMOverview = (props: any) => {
  const { hash, pathname } = useLocation();
  const selectedTabs = hash.replace(/^#/, "");
  console.log("path", hash, "")
  const [active, setActive] = useState(() => {
    return localStorage.getItem("activeEbmTab") || selectedTabs || "ebmOverview";
  });
  const [isHover, setIsHover] = useState(false);

  return (
    <div className="text-black mt-[8px]">
      <SideBar active={active} setActive={setActive} isHover={isHover} setIsHover={setIsHover} />
      <div className={`transition-all duration-700 ${isHover ? "ml-[24rem] " : "ml-32"}  pt-5`}>
        {active === "ebmOverview" && <Overview />}
        {active === "ebmItem" && <ItemManagement />}
        {active === "ebmCustomer" && <CustomerManagement />}
        {/* {active === "ebmInsurer" && <InsurerManagement />} */}
        {active === "ebmSales" && <SalesManagement />}
        {active === "ebmPurchase" && <PurchaseManagement />}
        {active === "ebmImport" && <ImportManagement />}
        {active === "ebmStockOpening" && <OpeningClosingStock />}
        {active === "ebmStockStatus" && <StockStatus />}
        {active === "ebmStockIn" && <StockInHistory />}
        {active === "ebmStockOut" && <StockOutHistory />}
      </div>
    </div>
  );
};

export default EBMOverview;
