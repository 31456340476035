import HeaderComponent from "../../../components/HeaderComponent";
import { Stack, Skeleton, useMediaQuery } from "@mui/material";
import WareHouseInGrid from "../../../components/grids/WareHouseInGrid";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import DetailsHeaderActionBar from "../../../components/cards/DetailsPage/DetailsHeaderActionBar";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/system";
import {
  getAllDeliveryNoteAction,
  getDeliveryNoteStatusCountAction,
} from "../../../store/wareHouse/actions";
import WarehouseFilter from "../../../components/filters/WarehouseFilter";
import ScrollableFrame from "../../../components/layout/ScrollableFrame";
import { warehouseInIncludeItem } from "../../../assets/data/includedItem";

const PageView = (_props: any) => {
  const { wareHouse, auth, layout } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selecteStataus, setSelecteStatus] = useState("");
  const [limit, setLimit] = useState(25);
  const [page, setPage] = useState(1);

  const isOnTablet = useMediaQuery("(max-width: 960px)");

  const handleCancel = () => {
    // console.log("Clossing.....");
    setIsModalOpen(false);
  };

  useEffect(() => {
    auth?.token &&
      getAllDeliveryNoteAction(
        auth?.token,
        `?limit=${limit}&page=${page - 1}&field=${warehouseInIncludeItem}${
          selecteStataus !== "" ? `&status=${selecteStataus}` : ""
        }`
      )(dispatch);
  }, [auth?.token, dispatch, limit, page, selecteStataus]);

  const values = wareHouse?.all?.data;
  const data: {
    id: any;
    deliveryID: any;
    pOrderID: any;
    arrivalDate: any;
    status: any;
    qnt: any;
    warehouseID: any;
    data?: any;
  }[] = [];

  values &&
    values?.forEach((el: any) => {
      data?.push({
        id: el._id,
        deliveryID: el.deliveryID,
        pOrderID: el.pOrderID.pOrderID,
        arrivalDate: el.arrivalDate,
        status: el?.status,
        warehouseID: el?.warehouseID,
        qnt: el?.listOfItems?.reduce(
          (sum: number, a: any) => (sum = sum + a?.qtySent),
          0
        ),
        data: el,
      });
    });

  const goBack = () => {
    navigate(-1);
  };
  useEffect(() => {
    if (auth?.token) {
      getDeliveryNoteStatusCountAction(auth?.token)(dispatch);
    }
  }, [auth?.token, dispatch]);

  const csvData = data?.map((record: any) => ({
    date: record?.data?.updatedAt?.slice(1, 10),
    model: record?.data?.listOfItems?.map(
      (d: any) => d?.purchaseItem?.quotationItem?.product?.model
    ),
    quantity: record?.qnt,
    supplier: record?.data?.pOrderID?.supplier?.name,
    supplierTIN: record?.data?.pOrderID?.supplier?.tin || "N/A",
    supplierPhone: record?.data?.pOrderID?.supplier?.phone,
    supplierEmail: record?.data?.pOrderID?.supplier?.email,
    delivererName: record?.data?.shippingDetails?.name,
    delivererPhone: record?.data?.shippingDetails?.contact,
    shippingTypeName: record?.data?.shippingDetails?.shiptype,
  }));

  const total = wareHouse?.warehouseInTabs?.data?.reduce(
    (curr: any, item: any) => curr + item?.count,
    0
  );

  return (
    <Stack spacing={1}>
      <div className="text-black pt-2 pb-2 pl-4">
        <DetailsHeaderActionBar
          title={"in"}
          pageName="Warehouse"
          goBack={goBack}
        />
      </div>
      <div className="flex items-center justify-between text-black pr-20 pl-4">
        <HeaderComponent
          isNotCollapse={true}
          isNotAddButton={true}
          filterCard={
            <WarehouseFilter
              setSelecteStataus={setSelecteStatus}
              total={total}
              data={wareHouse?.warehouseInTabs?.data}
            />
          }
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          handleCancel={handleCancel}
        />
        {/* <CsvDownloadButton
          csvHeaders={WarehouseInHeaders}
          csvData={csvData}
          filename={`Warehouse In Statistics.csv`}
        /> */}
      </div>
      <Stack spacing={1}>
        <Box>
          <ScrollableFrame
            setPage={setPage}
            setLimit={setLimit}
            limit={limit}
            total={wareHouse?.all?.total}
            count={Math.ceil(wareHouse?.all?.total / limit)}
            customSize={300}
          >
            <div className="">
              {wareHouse.isFetching && (
                <Stack direction={"row"} spacing={2}>
                  {[1, 2, 3].map(() => (
                    <Box sx={{ width: 270 }}>
                      <Skeleton sx={{ height: 70, m: 0 }} />
                      <Stack direction={"row"} justifyContent="space-between">
                        <Skeleton animation="wave" sx={{ width: "40%" }} />
                        <Skeleton animation={false} sx={{ width: "30%" }} />
                      </Stack>
                    </Box>
                  ))}
                </Stack>
              )}
              {!wareHouse.isFetching && (
                <div
                  className={` grid sm:grid-cols-2 md:${
                    isOnTablet ? "grid-cols-2" : "grid-cols-2"
                  } xl:grid-cols-3 2xl:grid-cols-4 gap-5 ${
                    layout?.isSideNavOpen ? "" : " "
                  }`}
                >
                  <WareHouseInGrid data={data} />
                </div>
              )}
            </div>
          </ScrollableFrame>
        </Box>
      </Stack>
    </Stack>
  );
};

export default PageView;
