import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Select,
  Switch,
  Button,
  Table,
  Popconfirm,
  Space,
} from "antd";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import {
  getAllChannelAction,
  getAllShopAction,
  getPricelistItemAction,
} from "../../store/channel/actions";
import {
  addNewDiscountAction,
  getAllDiscountAction,
  getAllModelsAction,
  updateDiscountAction,
} from "../../store/discount/actions";
import { SearchProps } from "antd/es/input";
import { getDefaultCurrencyCode } from "../../utils/helper";
import { getActivePrice } from "../../utils/converter";

const { TextArea } = Input;
const { Option } = Select;

interface PriceListItem {
  key: number;
  model: string;
  description: string;
  price: number;
  discount: number;
  amount: number;
}

interface FormValues {
  name: string;
  discount: number;
  description: string;
  shop: string[];
  priceList: string;
  productPlan?: string;
  amount?: number;
  discountType: "percent" | "amount";
}

interface CreateNewDiscountProps {
  onCancel: () => void;
  dataToUpdate: any | null;
  isModalOpen: boolean;
}

export const CreateNewDiscount: React.FC<CreateNewDiscountProps> = ({
  onCancel,
  dataToUpdate,
  isModalOpen,
}) => {
  const [form] = Form.useForm<FormValues>();
  const dispatch = useDispatch();
  const { channel, auth, discount, company } = useSelector(
    (state: any) => state
  );

  const [isApplicableToAllShops, setIsApplicableToAllShops] = useState(false);
  const [priceListItems, setPriceListItems] = useState<any[]>([]);
  const [selectedPriceListItem, setSelectedPriceListItem] = useState<
    string | undefined
  >(undefined);
  const [discountType, setDiscountType] = useState<"percent" | "amount">(
    "percent"
  );
  const [selectedPriceListItems, setSelectedPriceListItems] = useState<any>([]);
  const [isAddPlanClicked, setIsAddPlanClicked] = useState<any>(false);
  // Add this with your other state declarations
  const [isFieldsDisabled, setIsFieldsDisabled] = useState(false);

  useEffect(() => {
    if (auth?.token) {
      getAllChannelAction(auth.token)(dispatch);
      getAllShopAction(
        auth.token,
        "limit=1000&field=-channel,name,-createdBy"
      )(dispatch);
      getAllModelsAction(auth.token, "?")(dispatch);
    }
  }, [auth?.token, dispatch]);

  useEffect(() => {
    if (auth?.token && channel?.selected?._id) {
      getPricelistItemAction(
        auth.token,
        `?channel=${channel.selected._id}`
      )(dispatch);
    }
  }, [auth?.token, channel?.selected?._id, dispatch]);

  useEffect(() => {
    if (isModalOpen) {
      if (dataToUpdate) {
        form.setFieldsValue({
          name: dataToUpdate.name,
          discount: dataToUpdate.percentage,
          description: dataToUpdate?.description,
          // productPlan: dataToUpdate.productPlan?.map((el: any) => el?._id),
          shop: dataToUpdate.shop.map((el: any) => el?._id),
          discountType: dataToUpdate?.discountType,
          amount: dataToUpdate?.amount,
        });
        setIsApplicableToAllShops(dataToUpdate.isAllowedToAll);

        const existingPriceListItems = dataToUpdate.productPlan.map(
          (item: any) => {
            const capacity = item?.specification?.find((spec: any) =>
              ["capacity (rom)", "rom", "capacity"].includes(
                spec[0].toLowerCase()
              )
            );
            const memory = item?.specification?.find((spec: any) =>
              ["memory (ram)", "ram", "memory"].includes(spec[0].toLowerCase())
            );

            return {
              key: item?._id,
              model: item?.product?.model,
              description: item?.specification?.map((specific: any) => {
                return <>{specific},</>;
              }),
              price: item?.prices[0]?.value || 0,
              discount: dataToUpdate.percentage || 0,
              amount: dataToUpdate?.amount,
              capacity: capacity,
              memory: memory,
            };
          }
        );
        setPriceListItems(existingPriceListItems);
        setIsFieldsDisabled(dataToUpdate.productPlan?.length > 0);
      } else {
        form.resetFields();
        setIsApplicableToAllShops(false);
        setPriceListItems([]);
        setIsFieldsDisabled(false);
      }
    }
  }, [isModalOpen, dataToUpdate, form]);

  const calculateValues = (price: any, discountValue: number) => {
    if (discountType === "percent") {
      const amount = (price * discountValue) / 100;
      return {
        discount: `${discountValue}`,
        amount: amount,
      };
    } else {
      const percent = ((discountValue / price) * 100).toFixed(2);
      return {
        discount: `${percent}`,
        amount: discountValue,
      };
    }
  };

  const onFinish = async (values: FormValues) => {
    const activePrice = priceListItems?.at(0)?.price;

    const discountValue =
      form.getFieldValue(discountType === "percent" ? "discount" : "amount") ||
      0;
    const { discount, amount } = calculateValues(activePrice, discountValue);
    const payload = {
      isAllowedToAll: isApplicableToAllShops,
      ...values,
      channel: channel?.selected?._id,
      percentage: discount,
      amount: amount,
    };

    if (dataToUpdate) {
      await updateDiscountAction(
        dataToUpdate._id,
        auth?.token,
        payload,
        "?"
      )(dispatch).then(() => onCancel());
    } else {
      await addNewDiscountAction(
        auth?.token,
        payload,
        "?"
      )(dispatch).then(() => onCancel());
    }
    channel?.selected?._id &&
      getAllDiscountAction(
        auth?.token,
        `?channel=${channel?.selected?._id}`
      )(dispatch);
    form.resetFields();
  };

  const addPriceListItem = () => {
    setIsAddPlanClicked(true);
    const discountValue =
      form.getFieldValue(discountType === "percent" ? "discount" : "amount") ||
      0;

    // Ensure selectedPriceListItems is treated as an array
    const itemsToProcess = Array.isArray(selectedPriceListItems)
      ? selectedPriceListItems
      : [selectedPriceListItems]; // Convert single value to an array

    const newItems = itemsToProcess
      .map((itemId: any) => {
        const priceListItemData = channel?.priceListItems?.data.find(
          (item: any) => item?._id === itemId
        );

        if (priceListItemData) {
          const price =
            priceListItemData?.prices?.find((price: any) => price.isActive)
              ?.value || 0;
          const capacity = priceListItemData.specification?.find((spec: any) =>
            ["capacity (rom)", "rom", "capacity"].includes(
              spec[0].toLowerCase()
            )
          );
          const memory = priceListItemData.specification?.find((spec: any) =>
            ["memory (ram)", "ram", "memory"].includes(spec[0].toLowerCase())
          );

          const { discount, amount } = calculateValues(price, discountValue);

          return {
            key: priceListItemData?._id,
            model: priceListItemData.product?.model || "N/A",
            description: `${capacity ? capacity[1] : ""} ${
              memory ? memory[1] : ""
            }`.trim(),
            price: price,
            discount: discount,
            amount: amount,
            capacity: capacity ? capacity[1] : "",
            memory: memory ? memory[1] : "",
          };
        }
        return null;
      })
      .filter(Boolean);

    setPriceListItems([...priceListItems, ...newItems]);
    setSelectedPriceListItems([]); // Clear the selected items
    form.setFieldValue('productPlan', undefined);
    setIsFieldsDisabled(true);
  };

  const handleDelete = (key: number) => {
    const updatedItems = priceListItems.filter((item) => item.key !== key);
    setPriceListItems(updatedItems);

    // If there are no items left, re-enable the fields
    if (updatedItems.length === 0) {
      setIsFieldsDisabled(false);
    }
  };

  const columns = [
    {
      title: <span className="text-poppins text-sm">Model</span>,
      dataIndex: "model",
      key: "model",
    },
    {
      title: <span className="text-poppins text-sm">specification</span>,
      dataIndex: "description",
      key: "description",
      width: 100,
      render: (text: any, record: any) => (
        <div className="flex flex-col text-xs">
          <div>{record.capacity}</div>
          <div>{record.memory}</div>
        </div>
      ),
    },
    {
      title: (
        <span className="text-poppins text-xs">{`Price (${getDefaultCurrencyCode(
          company
        )})`}</span>
      ),
      dataIndex: "price",
      key: "price",
    },
    {
      title: <span className="text-poppins text-sm">Percent</span>,
      dataIndex: "discount",
      key: "discount",
    },
    {
      title: (
        <span className="text-poppins text-sm">{`Amount (${getDefaultCurrencyCode(
          company
        )})`}</span>
      ),
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: <span className="text-poppins text-sm">Action</span>,
      key: "action",
      render: (text: any, record: PriceListItem) => (
        <Popconfirm
          title="Are you sure to delete this item?"
          onConfirm={() => handleDelete(record.key)}
        >
          <Button danger icon={<DeleteOutlined />} />
        </Popconfirm>
      ),
    },
  ];

  const onSearch: SearchProps["onSearch"] = (value) => {
    console.log("Searched value:", value);
  };

  const handleDiscountTypeChange = (value: "percent" | "amount") => {
    setDiscountType(value);
    form.setFieldsValue({
      discount: undefined,
      amount: undefined,
    });

    // Update existing price list items with new calculations
    if (priceListItems.length > 0) {
      const updatedItems: any = priceListItems.map((item) => {
        const discountValue =
          form.getFieldValue(value === "percent" ? "discount" : "amount") || 0;
        const { discount, amount } = calculateValues(item.price, discountValue);
        return {
          ...item,
          discount,
          amount,
        };
      });
      setPriceListItems(updatedItems);
    }
  };

  const calculateAmount = (value: number, price: number) => {
    if (discountType === "percent") {
      return price - (price * value) / 100;
    }
    return value;
  };
  return (
    <Form
      form={form}
      onFinish={onFinish}
      layout="vertical"
      style={{ maxHeight: "750px", overflowY: "auto" }}
    >
      <div style={{ marginRight: "15px" }}>
        <div style={{ display: "flex", gap: "16px" }}>
          <Form.Item
            name="name"
            label="Name"
            rules={[{ required: true, message: "Please input the name!" }]}
            style={{ flex: 1 }}
          >
            <Input />
          </Form.Item>

          <Form.Item name="discountType" label="Discount Type">
            <Select
              onChange={handleDiscountTypeChange}
              disabled={isFieldsDisabled}
            >
              <Select.Option value="percent">Percent</Select.Option>
              <Select.Option value="amount">Amount</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            name={discountType === "percent" ? "discount" : "amount"}
            label={discountType === "percent" ? "Percent" : "Amount"}
            rules={[
              { required: true, message: `Please input the ${discountType}!` },
            ]}
          >
            <Input
              type="number"
              disabled={isFieldsDisabled}
              onChange={(e) => {
                if (discountType === "percent") {
                  const selectedItem = channel?.priceListItems?.data.find(
                    (item: any) => item?._id === selectedPriceListItem
                  );
                  if (selectedItem) {
                    const price = selectedItem.prices[0]?.value || 0;
                    const amount = calculateAmount(
                      Number(e.target.value),
                      price
                    );
                    form.setFieldsValue({ amount });
                  }
                }
              }}
            />
          </Form.Item>
        </div>

        {/* {discountType === "percent" && (
          <Form.Item name="amount" label="Calculated Amount">
            <Input type="number" />
          </Form.Item>
        )} */}

        <Form.Item
          name="description"
          label="Description"
          rules={[
            { required: false, message: "Please input the description!" },
          ]}
        >
          <TextArea rows={4} />
        </Form.Item>

        <div style={{ display: "flex", gap: "16px", marginBottom: "16px" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "8px",
              flex: 1,
            }}
          >
            <Form.Item
            
              name="shop"
              label="Select Shop"
              style={{ flex: 1 }}
              rules={[
                {
                  required: !isApplicableToAllShops,
                  message: "Please select a shop!",
                },
              ]}
            >
              <Select
                disabled={isApplicableToAllShops}
                mode="multiple"
                onSearch={onSearch}
                filterOption={(input, option) =>
                  (option?.children as unknown as string)
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {channel?.allShop?.data.map((shop: any) => (
                  <Option key={shop?._id} value={shop?._id}>
                    {shop?.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Is Applicable To All Shops"
              valuePropName="checked"
            >
              <Switch
                onChange={(checked) => setIsApplicableToAllShops(checked)}
                checked={isApplicableToAllShops}
              />
            </Form.Item>
          </div>

          <div style={{ flex: 1 }}>
            <Form.Item
              name="productPlan"
              label="Select Plan"
              rules={[{ required: false, message: "Please select a plan!" }]}
            >
              <Select
                // mode="multiple"
                showSearch
                onSearch={onSearch}
                filterOption={(input, option) =>
                  (option?.children as unknown as string)
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                onChange={(values) => {
                  setSelectedPriceListItems(values);

                  const discountValue = form.getFieldValue("discount");
                  const amountValue = form.getFieldValue("amount");

                  const lastSelectedItem = channel?.priceListItems?.data.find(
                    (item: any) => item?._id === values[values.length - 1]
                  );

                  if (lastSelectedItem) {
                    const price = lastSelectedItem.prices[0]?.value || 0;
                    if (discountType === "percent") {
                      const calculatedAmount = calculateAmount(
                        discountValue,
                        price
                      );
                      form.setFieldsValue({ amount: calculatedAmount });
                    } else {
                      form.setFieldsValue({ amount: amountValue });
                    }
                  }
                }}
              >
                {channel?.priceListItems?.data.map((item: any) => (
                  <Option key={item?._id} value={item?._id}>
                    {item?.product?.model}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
        </div>

        <Form.Item>
          <Button
            type="primary"
            onClick={addPriceListItem}
            icon={<PlusOutlined />}
          >
            Add Plan
          </Button>
        </Form.Item>

        <Table
          columns={columns.map((column) => ({
            ...column,
            onCell: () => ({
              className: "truncate",
            }),
          }))}
          dataSource={priceListItems}
          pagination={false}
          rowKey="key"
          className="overflow-x-auto"
        />

        <Form.Item style={{ marginTop: "10px" }}>
          <Button
            type="primary"
            htmlType="submit"
            loading={discount.isFetching}
          >
            {dataToUpdate ? "Update Discount" : "Create Discount"}
          </Button>
          <Button style={{ marginLeft: "8px" }} onClick={onCancel}>
            Cancel
          </Button>
        </Form.Item>
      </div>
    </Form>
  );
};
