import react, { useEffect, useState } from "react";
import SettingButton from "../SettingButton";
import { Form, Input, Button, Spin, Tabs, TabsProps, Select } from "antd";
import React from "react";
import PhoneInput from "react-phone-input-2";
import { useDispatch, useSelector } from "react-redux";
import {
  getOneCompanyAction,
  updateCompanyActions,
} from "../../../../store/setting/company/actions";
import { BsTelephoneFill } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import { LoadingOutlined } from "@ant-design/icons";
import * as ciIcons from "react-icons/ci";
import { HiUserGroup } from "react-icons/hi2";
import { FaLocationDot, FaCity } from "react-icons/fa6";
import { PiFlagBold } from "react-icons/pi";
import UploadComponent from "../../../../components/forms/Upload";
import UploadSubLogo from "../../../../components/forms/UploadSubLogo";
import "cropperjs/dist/cropper.css";
import AllBusinessProducts from "./Products";
import CompanyWarehouse from "./CompanyWarehouse";
import CompanyCurrency from "./Currency";
import TrackingComponent from "./TrackingComponent";
import { countries } from "countries-list";
import { COMPANY_PROFILE_ID } from "../../../../utils/constants";

const { Option } = Select;

const CompanyInformation = () => {
  const { auth, layout, company } = useSelector((state: any) => state);
  const [form] = Form.useForm();
  const [pictures, setPictures] = useState([]);
  const [subLogo, setSubLogo] = useState([]);
  const [editCompanyInformation, setEditCompanyInformation] = useState(false);
  const [editCompanyLogo, setEditCompanyLogo] = useState(false);
  const [countriesList, setCountriesList] = useState<any>([]);
  const dispatch = useDispatch();

  const HandleEditCompanyInformation = () => {
    setEditCompanyInformation(true);
  };
  const HandleEditCompanyLogo = () => {
    setEditCompanyLogo(true);
  };
  const styles = {
    customElement: {
      boxShadow: "0px 10px 20px 0px rgba(194, 194, 194, 0.16)",
    },
    customShadowCard: {
      boxShadow: "0px 1px 2px 2px rgba(194, 194, 194, 0.06)",
    },
  };
  const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
  };

  const onChangeTabs = (key: string) => {
    console.log(key);
  };
  const styless = {
    boxShadow: "0px 10px 20px 0px rgba(194, 194, 194, 0.16)",
    custom: {
      boxShadow: "0px 2px 2px 0px rgba(15, 15, 71, 0.04)",
      border: "1px solid rgba(15, 15, 71, 0.10)",
    },
  };
  useEffect(() => {
    const countriesArray: any = Object.entries(countries).map(
      ([code, country]) => ({
        code,
        name: country.name,
        capital: country.capital,
      })
    );
    setCountriesList(countriesArray);
  }, []);

  const handleCountryChange = (value: any) => {
    const selectedCountry: any = countriesList.find(
      (country: { name: any }) => country.name === value
    );
    if (selectedCountry) {
      form.setFieldsValue({ city: selectedCountry.capital });
    }
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: (
        <h1 className="text-sm font-medium text-[#030229] opacity-70">
          Business Products ({company?.allBusiness?.total || 0})
        </h1>
      ),
      children: <AllBusinessProducts />,
    },
    {
      key: "2",
      label: (
        <h1 className="text-sm font-medium text-[#030229] opacity-70">
          Warehouses ({company?.selected?.warehouse?.length || 0})
        </h1>
      ),
      children: <CompanyWarehouse />,
    },
    {
      key: "3",
      label: (
        <h1 className="text-sm font-medium text-[#030229] opacity-70">
          Currencies ({company?.selected?.currency?.length || 0})
        </h1>
      ),
      children: <CompanyCurrency />,
    },
    {
      key: "4",
      label: (
        <h1 className="text-sm font-medium text-[#030229] opacity-70">
          Tracking Type ({company?.trackingType?.data.length || 0})
        </h1>
      ),
      children: <TrackingComponent />,
    },
  ];
  const companyId = COMPANY_PROFILE_ID;

  useEffect(() => {
    auth?.token &&
      getOneCompanyAction(companyId as string, auth.token)(dispatch);
  }, [auth.token, dispatch, companyId]);

 

  const onUpdateCompany = async (values: any) => {
    const images = pictures.map((pic: any) => pic?.response?.data?.secure_url);
    const image = subLogo.map((pic: any) => pic?.response?.data?.secure_url);

    auth?.token &&
      auth?.user?._id &&
      (await updateCompanyActions(
        companyId as string,
        {
          ...values,
          address: {
            country: values.country,
            provence: values.provence,
            city: values.city,
            zipCode: values.zipCode,
          },
          logo: images[0],
          subLog: image[0],
        },
        auth?.token
      )(dispatch));
    await getOneCompanyAction(companyId as string, auth.token)(dispatch);
  };

  useEffect(() => {
    form.setFieldsValue({
      name: company?.selected?.name,
      email: company?.selected?.email,
      phone: company?.selected?.phone,
      logo: company?.selected?.logo,
      subLog: company?.selected?.subLog,
      country: company?.selected?.address?.country,
      provence: company?.selected?.address?.provence,
      zipCode: company?.selected?.address?.zipCode,
      city: company?.selected?.address?.city,
    });
  });
  return (
    <>
      <div>
        {["admin", "dev"]?.includes(auth?.user?.role?.toLowerCase()) && (
          <div
            className="bg-white pl-5 xl:pl-20 pt-2 flex rounded-md gap-1"
            style={styles.customElement}
          >
            <div
              className={` pt-6 ${
                layout.isSideNavOpen
                  ? "w-[150px] xl:w-[200px] 2xl:w-[300px]"
                  : "w-[250px] 2xl:w-[350px]"
              } `}
            >
              <h1 className="text-[#030229] flex gap-5 text-base font-normal">
                Company Logo
                {!editCompanyLogo && (
                  <ciIcons.CiEdit
                    fill="#605BFF"
                    size={25}
                    onClick={HandleEditCompanyLogo}
                    className="cursor-pointer"
                  />
                )}
              </h1>
              <p className="text-[#03022980] text-sm font-normal pt-2">
                Update your company logo and then choose where you want it to
                display.
              </p>
            </div>
            <div>
              <div></div>
              <Form
                name="validate_other"
                {...formItemLayout}
                onFinish={onUpdateCompany}
                style={{ maxWidth: 1000 }}
              >
                <div className="flex gap-8">
                  <Form.Item>
                    <div
                      className={`xl:border xl:border-dashed xl:border-gray-300 py-2 rounded-md h-[150px] ${
                        layout.isSideNavOpen
                          ? "md:w-[100px] 2xl:w-[300px]"
                          : "md:w-[120px] 2xl:w-[340px]"
                      } `}
                    >
                      {editCompanyLogo ? (
                        <div className=" pl-10 2xl:pl-32">
                          <UploadComponent
                            setPictures={setPictures}
                            maxFileSize={2}
                            limit={1}
                            default={
                              auth?.user && [
                                {
                                  status: "done",
                                  url: company?.selected?.logo,
                                },
                              ]
                            }
                          />

                          {/* <App /> */}
                        </div>
                      ) : (
                        <img
                          src={company?.sizedLog?.data?.secure_url}
                          className=" w-full md:w-32 h-20 m-auto rounded-sm"
                          alt=""
                        />
                      )}
                      <p className=" text-[#03022940] pl-5 py-3 xl:p-3 text-center  font-medium text-sm">
                        <span className="">Company Logo</span>{" "}
                      </p>
                    </div>
                  </Form.Item>
                  <Form.Item>
                    <div
                      className={`xl:border xl:border-dashed xl:border-gray-300 py-2 rounded-md h-[150px]  ${
                        layout.isSideNavOpen
                          ? "md:w-[100px] 2xl:w-[300px]"
                          : "md:w-[140px] 2xl:w-[340px]"
                      } `}
                    >
                      <div className="pl-5 pdt-5">
                        {editCompanyLogo ? (
                          <div className="pl-10 2xl:pl-32">
                            <UploadSubLogo
                              setSubLogo={setSubLogo}
                              limit={1}
                              default={
                                auth?.user && [
                                  {
                                    status: "done",
                                    url: company?.selected?.subLog,
                                  },
                                ]
                              }
                            />
                          </div>
                        ) : (
                          <img
                            src={company?.selected?.subLog}
                            className="w-full md:w-32 h-20 m-auto rounded-sm"
                            alt=""
                          />
                        )}
                      </div>
                      <p className=" text-[#03022940] text-center pl-5 py-3 xl:p-3 font-medium text-sm">
                        <span className="">Company LogoMark</span>{" "}
                      </p>
                    </div>
                  </Form.Item>
                </div>
                {editCompanyLogo && (
                  <div className="float-right flex gap-5 ">
                    <Form.Item>
                      <Button
                        htmlType="submit"
                        className="bg-[#605BFF] border-none text-white px-6 h-10"
                      >
                        {company?.isFetching ? (
                          <Spin
                            indicator={
                              <LoadingOutlined
                                style={{ fontSize: 24, color: "white" }}
                                color="white"
                                spin
                              />
                            }
                          />
                        ) : (
                          "Save Changes"
                        )}
                      </Button>
                    </Form.Item>

                    <Button
                      onClick={() => setEditCompanyLogo(false)}
                      className="bg-[#605BFF] border-none text-white px-6 h-10"
                    >
                      Cancel
                    </Button>
                  </div>
                )}
              </Form>
            </div>
          </div>
        )}
        <div
          className="bg-white pl-20 py-8 rounded-md mt-4"
          style={styles.customElement}
        >
          <h1 className="text-[#0F0F47] flex gap-5 text-lg font-[600] ">
            Company Information{" "}
            {!editCompanyInformation && (
              <ciIcons.CiEdit
                fill="#605BFF"
                size={25}
                onClick={HandleEditCompanyInformation}
                className="mt-[2px] cursor-pointer"
              />
            )}
          </h1>
          {["admin", "dev"]?.includes(auth?.user?.role?.toLowerCase()) ? (
            <div className="mt-[52px]">
              <Form
                form={form}
                name="validateOnly"
                layout="vertical"
                onFinish={onUpdateCompany}
                autoComplete="off"
                style={{ maxWidth: "90%" }}
              >
                <div className="flex gap-8 w-full">
                  <Form.Item
                    name="name"
                    label={
                      <span className="text-[#0F0F47] pl-1 text-sm font-[600]">
                        Company Name
                      </span>
                    }
                    className="w-full"
                  >
                    <Input
                      disabled={!editCompanyInformation}
                      placeholder="Please enter your company name"
                      className="pl-4 h-[52px] w-[100%] lg:max-2xxl:w-[190px] lg:max-2xxl:h-[42px] bg-[#EFF0F6] border-none"
                    />
                  </Form.Item>
                  <Form.Item
                    name="email"
                    label={
                      <span className="text-[#0F0F47] pl-1 text-sm font-[600]">
                        Email
                      </span>
                    }
                    className="w-full"
                  >
                    <Input
                      disabled={!editCompanyInformation}
                      placeholder="Please enter your email"
                      className="pl-4 h-[52px] w-[100%] lg:maxx-2xl:w-[190px] lg:maxx-2xl:h-[42px] bg-[#EFF0F6] border-none"
                    />
                  </Form.Item>
                </div>
                <div className="flex gap-8 w-full">
                  {editCompanyInformation ? (
                    <Form.Item
                      name="phone"
                      className="w-full lg:max-s2xl:w-72 lg:max-s2xl:-mt-2"
                      label={
                        <span className="text-[#0F0F47] pl-1 text-sm font-[605]">
                          Phone Number
                        </span>
                      }
                    >
                      <PhoneInput
                        // onChange={handleCountryChange}
                        country={"bw"}
                        buttonStyle={{
                          height: "52px",
                          border: "none",
                          borderRight: "3px solid #fff",
                          backgroundColor: "#EFF0F6",
                        }}
                        inputStyle={{
                          height: "52px",
                          width: "100%",
                          backgroundColor: "#EFF0F6",
                          border: "none",
                        }}
                        containerClass="phone-container"
                        placeholder="+250 780 000 000"
                      />
                    </Form.Item>
                  ) : (
                    <Form.Item
                      style={{
                        position: "relative",
                        opacity: 0.5,
                        pointerEvents: "none",
                      }}
                      name="phone"
                      className="w-full lg:max-2xld:w-72 lg:max-2xl:-mt-2"
                      label={
                        <span className="text-[#0F0F47] pl-1 text-sm font-[605]">
                          Phone Number
                        </span>
                      }
                      //   initialValue={props?.dataToUpdate?.phone}
                    >
                      <PhoneInput
                        // onChange={handleCountryChange}
                        country={"bw"}
                        buttonStyle={{
                          height: "52px",
                          border: "none",
                          borderRight: "3px solid #fff",
                          backgroundColor: "#EFF0F6",
                        }}
                        inputStyle={{
                          height: "52px",
                          width: "100%",
                          backgroundColor: "#EFF0F6",
                          border: "none",
                        }}
                        containerClass="phone-container"
                        placeholder="+250 780 000 000"
                      />
                    </Form.Item>
                  )}

                  <Form.Item
                    name="country"
                    label={
                      <span className="text-[#0F0F47] pl-1 text-sm font-[600]">
                        Country
                      </span>
                    }
                    className="w-full"
                  >
                    <Select
                      disabled={!editCompanyInformation}
                      placeholder="Please select your country"
                      className="h-[52px] w-[100%] lg:maxx-2xl:w-[190px] lg:max-2xlx:h-[42px]"
                      style={{ backgroundColor: "#EFF0F6" }}
                      onChange={handleCountryChange}
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.children as unknown as string)
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {countriesList?.map((country: any) => (
                        <Option key={country.code} value={country.name}>
                          {country.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
                <div className="flex gap-8 w-full">
                  <Form.Item
                    name="provence"
                    label={
                      <span className="text-[#0F0F47] pl-1 text-sm font-[600]">
                        Province
                      </span>
                    }
                    className="w-full"
                  >
                    <Input
                      disabled={!editCompanyInformation}
                      placeholder="Please enter your Province"
                      className="pl-4 h-[52px] w-[100%] lg:max-2xxl:w-[190px] lg:max-2xxl:h-[42px] bg-[#EFF0F6] border-none"
                    />
                  </Form.Item>
                  <Form.Item
                    name="zipCode"
                    label={
                      <span className="text-[#0F0F47] pl-1 text-sm font-[600]">
                        Zip/Postal Code
                      </span>
                    }
                    className="w-full"
                  >
                    <Input
                      disabled={!editCompanyInformation}
                      className="pl-4 h-[52px] w-[100%] lg:maxx-2xl:w-[190px] lg:max-2xlx:h-[42px] bg-[#EFF0F6] border-none"
                    />
                  </Form.Item>
                </div>
                <div className="w-full">
                  <Form.Item
                    name="city"
                    label={
                      <span className="text-[#0F0F47] pl-1 text-sm font-[600]">
                        City
                      </span>
                    }
                    className="w-full"
                  >
                    <Input
                      disabled={true}
                      placeholder="City/Province/Country"
                      className="pl-4 h-[52px] w-full lg:max-2xlx:w-[190px] lg:max-2xxl:h-[42px] bg-[#EFF0F6] border-none"
                    />
                  </Form.Item>
                </div>
                {editCompanyInformation && (
                  <Form.Item>
                    <div className="float-right flex gap-6 mt-20">
                      <SettingButton
                        btnName={`${
                          company?.isFetching ? "Submitting" : "Save Changes"
                        } `}
                        btnBgColor="[#605BFF]"
                        textColor="white"
                      />{" "}
                      <p
                        className="px-5 py-2 bg-[#605BFF] cursor-pointer rounded-md text-white"
                        onClick={() => setEditCompanyInformation(false)}
                      >
                        Cancel
                      </p>
                    </div>
                  </Form.Item>
                )}
              </Form>
            </div>
          ) : (
            <div
              className="max-w-6xl mt-6 p-6 bg-white text-gray-700 flex xitems-center xmx-auto"
              style={styles.customShadowCard}
            >
              <div className="flex my-6 space-x-10 ">
                <div className="w-[100px] h-[100px] rounded-full border border-gray-400">
                  <img
                    src={company?.selected?.logo}
                    alt=""
                    className="rounded-full w-[100px] h-[100px]"
                  />
                </div>
                <div className="grid grid-cols-2 gap-x-60">
                  <p className="flex items-center space-x-5">
                    <HiUserGroup /> <span>{company?.selected?.name}</span>
                  </p>
                  <p className="flex items-center space-x-5">
                    <PiFlagBold />{" "}
                    <span className="capitalize">
                      {company?.selected?.address?.country}
                    </span>
                  </p>
                  <p className="flex items-center space-x-5">
                    <MdEmail /> <span>{company?.selected?.email}</span>
                  </p>
                  <p className="flex items-center space-x-5">
                    <FaLocationDot />{" "}
                    <span className="capitalize">
                      {company?.selected?.address?.provence}
                    </span>
                  </p>
                  <p className="flex items-center space-x-5">
                    <BsTelephoneFill /> <span>{company?.selected?.phone}</span>
                  </p>
                  <p className="flex items-center space-x-5">
                    <FaCity />{" "}
                    <span className="capitalize">
                      {company?.selected?.address?.city}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
        <div
          className="bg-white p-2 rounded-md w-full h-[85vh] px-[5rem]"
          style={styless}
        >
          <Tabs defaultActiveKey="1" items={items} onChange={onChangeTabs} />
        </div>
      </div>
    </>
  );
};
export default CompanyInformation;
