import React from "react";
import { Table } from "antd";
import { useSelector } from "react-redux";

const CustomerDevicesTable = (props: any) => {
  const { customer } = useSelector((state: any) => state);

  const columns = [
    { title: "Type", dataIndex: "type" },
    { title: "Brand", dataIndex: "brand" },
    { title: "Model", dataIndex: "model" },
    { title: "IMEI", dataIndex: "imei" },
    { title: "Specifications", dataIndex: "specs" },
    // {
    //   title: "Action",
    //   dataIndex: "data",
    //   width: 150,
    //   render: () => {
    //     return (
    //       <>
    //         <div className="flex gap-2 rounded-md border border-[#03022940] py-1 px-1">  TO DO ADD UNLOCK FUNCTIONALITIES
    //           <p className="text-[#030229B2] text-[12px]">Unlocked</p>
    //           <Switch
    //             defaultChecked
    //             onChange={onChange}
    //             style={{
    //               backgroundColor: "#0FA958",
    //               borderColor: "#0FA958",
    //             }}
    //           />
    //         </div>
    //       </>
    //     );
    //   },
    // },
  ];

  const data = props?.data?.map((el: any) => {
    const product = el?.warehouseProduct
      ? el?.warehouseProduct?.product
      : el?.wareHouseItem?.product;

    return {
      key: el._id,
      type: product?.map((d: any) => d?.type).join(", "),
      brand: product?.map((d: any) => d?.brand).join(", "),
      model: product?.map((d: any) => d?.model).join(", "),
      specs: product
        ?.map((d: any) =>
          d?.specs
            ?.map((spec: any) => {
              const values = Array.isArray(spec.value)
                ? spec.value.join(", ")
                : spec.value;
              return `${spec.label}: ${values}`;
            })
            .join(", ")
        )
        .join(", "),
      imei:
        el?.warehouseProduct?.serialNumber ?? el?.wareHouseItem?.serialNumber,
      data: el,
    };
  });

  return (
    <>
      <Table
        columns={columns}
        dataSource={data}
        loading={customer?.isFetching}
      />
    </>
  );
};

export default CustomerDevicesTable;
