import * as React from "react";
import ProfileButton from "./ProfileButton";
import { useSelector } from "react-redux";
import { Stack, useMediaQuery } from "@mui/material";
import { getActiveShop } from "../../utils/converter";
import SearchInput from "../buttons/SearchButton";
import { useLocation } from "react-router-dom";
import { listManages } from "../../assets/data/pages";
import SideNav from "./SideNav";
import ButtonSearch from "../buttons/buttonSearch";
import * as aiIcons from "react-icons/ai";
import * as bsIcons from "react-icons/bs";
import DisabledTabs from "../../pages/dashboard/subscription/billings/components/FilterButton";
import { Input } from "antd";
import type { MenuProps } from "antd";
import { Button, Dropdown, Space } from "antd";
const Component: React.FC<{
  nav: any;
  title: any;
  handleFullScreenClick: any;
  isOpen: boolean;
  setIsOpen: (state: boolean) => void;
}> = ({ nav, title, handleFullScreenClick, isOpen, setIsOpen }) => {
  const { layout } = useSelector((state: any) => state);
  const location = useLocation();
  const route = location.pathname;
  const text = route.includes("channel");
  const { auth, shop } = useSelector((state: any) => state);
  const shopInfo = getActiveShop(auth?.user?.shop?.assigned)[0] || shop?.myShop;

  const smallScreen = useMediaQuery("(max-width:1023px)");
  const verySmallScreen = useMediaQuery("(max-width:639px)");
  const extraSmallScreen = useMediaQuery("(max-width:330px)");
  const [searchBtn, setSearchBtn] = React.useState(false);
  const [profileComponent, setProfileComponent] = React.useState(false);
  const showSearchBtn = () => {
    setSearchBtn(!searchBtn);
  };
  const showProfileComponent = () => {
    if (verySmallScreen) {
      setProfileComponent(!profileComponent);
    } else {
      setProfileComponent(false);
    }
  };
  const { Search } = Input;
  const items = [
    {
      key: "1",
      label: <ProfileButton />,
    },
  ];
  return (
    <div className="fixed top-0 w-full z-10">
      <div
        className={`relative w-full  px-4 sm-h-13   gap-1 bg-white text-black ${
          layout.isSideNavOpen && !smallScreen ? "pl-[229px]" : "pl-[70px]"
        } ${verySmallScreen && "py-2"} ${
          !verySmallScreen && smallScreen && "py-1 "
        } }
        `}
      >
       
        <div
          className={`flex justify-between items-center space-x-0 z-50 flex-1 pr-10 ${
            extraSmallScreen && " pr-0"
          }`}
        >
          {useMediaQuery("(max-width:500px)") && searchBtn ? (
            ""
          ) : (
            <p
              className={`text-gray-500 capitalize pl-6 ${
                smallScreen && "pr-6 pl-2"
              } ${extraSmallScreen && "pl-0"} ${
                smallScreen &&
                layout.isSideNavOpen &&
                !verySmallScreen &&
                "pl-40"
              }`}
            >
              {route?.split("/")[1]}
            </p>
          )}

          {auth.user?.tag !== "mascom" && text ? (
            nav
          ) : route === "/subscription/invoice/" ||
            route === "/subscription/invoice/billings" ? (
            <DisabledTabs />
          ) : smallScreen && (
            !searchBtn &&
            (extraSmallScreen ? (
              <aiIcons.AiOutlineSearch size={18} onClick={showSearchBtn} />
            ) : (
              <aiIcons.AiOutlineSearch size={24} onClick={showSearchBtn} />
            ))
          )}
          {verySmallScreen && !searchBtn && !profileComponent ? (
            <>
              <Dropdown
                menu={{
                  items,
                }}
                placement="bottom"
              >
                <bsIcons.BsThreeDots />
              </Dropdown>
            </>
          ) : (
            !searchBtn && (
              <>
                <ProfileButton handleFullScreenClick={handleFullScreenClick} />
                {verySmallScreen && (
                  <aiIcons.AiOutlineClose
                    onClick={showProfileComponent}
                    className="float-right"
                  />
                )}{" "}
              </>
            )
          )}
          {/* {searchBtn && smallScreen && (
            <>
              <Search
                placeholder="input search text"
                enterButton
                className=""
              />{" "}
              <div className=" pl-4 ">
                <aiIcons.AiOutlineClose onClick={showSearchBtn} size={20} />
              </div>
            </>
          )} */}
        </div>
       
      </div>
    </div>
  );
};

export default Component;
