import HeaderComponent from "../../../../components/HeaderComponent";
import { Stack, Skeleton } from "@mui/material";
import WareHouseOutGrid from "../../../../components/grids/warehouseOut/WareHouseOutGrid";
import StockOutGrid from "../../../../components/grids/warehouseOut/StockOutGrid";
import RequestGrid from "../../../../components/grids/RequestGrid";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import DetailsHeaderActionBar from "../../../../components/cards/DetailsPage/DetailsHeaderActionBar";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/system";
import {
  getAllWarehouseOutAction,
  getAllStockRequestSentAction,
  getStatusCountAction,
} from "../../../../store/wareHouseOut/actions";
import ScrollableFrame from "../../../../components/layout/ScrollableFrame";
import { myChannelActions } from "../../../../store/channel";
import { warehouseOutIncludeItem } from "../../../../assets/data/includedItem";
import WarehouseFilter from "../../../../components/filters/WarehouseFilter";
import { filterByStatus } from "../../../../utils/converter";
import { Radio } from "antd";
import SearchInput from "../../../../components/buttons/SearchButton";
const stockRequestStatus = ["request", "review", "approved", "done"];
const warehouseoutStatus = ["completed", "Pending", "In-Transit", "approved"];

const PageView = (props: any) => {
  const isChannel = props.route === "channel";
  const { wareHouseOut, auth, layout } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchKey, setSearchKey] = useState();
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(20);
  const [selecteStatus, setSelecteStatus] = useState(
    isChannel ? "review" : "approved"
  );
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const [status, SetStatus] = useState<any>("");
  console.log("status", status);
  const statusChange = (e: any) => {
    SetStatus(e.target.value);
  };
  useEffect(() => {
    if (status) {
      auth?.token &&
        getAllStockRequestSentAction(
          auth?.token,
          `?status=${status}&limit=${limit}&page=${
            page - 1
          }&field=${warehouseOutIncludeItem}`
        )(dispatch);
    } else {
      if (props.route === "channel") {
        auth?.token &&
          getAllStockRequestSentAction(
            auth?.token,
            `?limit=${limit}&page=${page - 1}&field=${warehouseOutIncludeItem}`
          )(dispatch);
      } else {
        auth?.token &&
          getAllWarehouseOutAction(
            auth?.token,
            `?limit=${limit}&page=${page - 1}&field=${warehouseOutIncludeItem}`
          )(dispatch);
      }
    }
    auth?.token &&
      getAllWarehouseOutAction(
        auth?.token,
        `?limit=${limit}&status=${status}&page=${
          page - 1
        }&field=${warehouseOutIncludeItem}`
      )(dispatch);
  }, [auth?.token, dispatch, isChannel, limit, page, status]);

  useEffect(() => {
    if (auth?.token) {
      getStatusCountAction(auth?.token)(dispatch);
    }
  }, [auth?.token, dispatch]);

  const values = wareHouseOut?.allStockRequested?.data;
  console.log("valuesssss", wareHouseOut);
  const stockOutData: {
    id: any;
    warehouseOutId: any;
    shopName: any;
    status: any;
    date: any;
    data: any;
  }[] = [];

  wareHouseOut?.warehouseOut?.data?.forEach((el: any) => {
    stockOutData?.push({
      id: el?._id,
      warehouseOutId: el?.warehouseOutId,
      shopName: el?.list[0]?.shop?.name,
      status: el?.status,
      data: el,
      date: el?.createdAt?.split("T")[0],
    });
  });

  const data: {
    id: any;
    requestId: any;
    shopName: any;
    status: any;
    numberReq: any;
    date: any;
    data: any;
  }[] = [];

  values &&
    values.forEach((el: any) => {
      data.push({
        id: el.requestId,
        requestId: el?.requestId,
        shopName: el?.shopId?.name,
        status: el?.status,
        data: el,
        date: el?.createdAt?.split("T")[0],
        numberReq: el?.list?.reduce(
          (sum: number, a: any) => (sum = sum + a?.qtyRequested),
          0
        ),
      });
    });

  const goBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    dispatch(myChannelActions.setRfsSelected(null));
  }, []);

  const handleSearch = () => {
    if (props.route === "channel") {
      if (searchKey) {
        auth?.token &&
          getAllStockRequestSentAction(
            auth?.token,
            `?limit=${limit}&page=${
              page - 1
            }&field=${warehouseOutIncludeItem}&sk=${searchKey}`
          )(dispatch);
      } else {
        auth?.token &&
          getAllStockRequestSentAction(
            auth?.token,
            `?limit=${limit}&page=${page - 1}&field=${warehouseOutIncludeItem}`
          )(dispatch);
      }
    } else {
      if (searchKey) {
        auth?.token &&
          getAllWarehouseOutAction(
            auth?.token,
            `?limit=${limit}&page=${
              page - 1
            }&field=${warehouseOutIncludeItem}&sk=${searchKey}`
          )(dispatch);
      } else {
        auth?.token &&
          getAllWarehouseOutAction(
            auth?.token,
            `?limit=${limit}&page=${page - 1}&field=${warehouseOutIncludeItem}`
          )(dispatch);
      }
    }
  };

  return (
    <Stack spacing={1}>
      <div className="text-black pt-5 pb-2 pl-2">
        <div className="text-black pt-5 pb-2 pl-2 flex justify-between">
          <DetailsHeaderActionBar
            title={"Out"}
            pageName={props.route ?? "Warehouse"}
            goBack={goBack}
          />
          <SearchInput
            onSearch={handleSearch}
            onChange={(e: any) => {
              e.preventDefault();
              setSearchKey(e.target.value);
            }}
          />
        </div>
      </div>
      <Stack
        direction={"row"}
        alignItems="center"
        justifyContent={"space-between"}
        paddingLeft={2}
      >
        <Radio.Group
          value={status}
          defaultValue=" "
          buttonStyle="solid"
          onChange={statusChange}
        >
          <Radio.Button value="">All</Radio.Button>
          {props.route === "channel" ? (
            <>
              <Radio.Button value="request">Request</Radio.Button>
              <Radio.Button value="review">Review</Radio.Button>
              <Radio.Button value="approved">Approved</Radio.Button>
              <Radio.Button value="done">Done</Radio.Button>
            </>
          ) : (
            <>
              <Radio.Button value="approved">Approved</Radio.Button>
              <Radio.Button value="Pending">Pending</Radio.Button>
              <Radio.Button value="Ready For Pickup">
                Ready For Pickup
              </Radio.Button>
              <Radio.Button value="Pickup">Pickup</Radio.Button>
              <Radio.Button value="In-Transit">In-Transit</Radio.Button>
              <Radio.Button value="Delivered">Delivered</Radio.Button>
            </>
          )}
        </Radio.Group>
        {/* <HeaderComponent
          isNotCollapse={true}
          isNotAddButton={true}
          filterCard={
            <WarehouseFilter
              setSelecteStataus={setSelecteStatus}
              total={0}
              data={
                props.route === "channel"
                  ? filterByStatus(
                      wareHouseOut?.warehouseOutTabs?.data || [],
                      stockRequestStatus
                    )
                  : filterByStatus(
                      wareHouseOut?.warehouseOutTabs?.data || [],
                      Statuses
                    )
              }
              router={props.route}
            />
          }
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          handleCancel={handleCancel}
        /> */}
      </Stack>

      <ScrollableFrame
        // loading={wareHouseOut.isFetching || wareHouseOut?.requestSentIsFetching}
        setPage={setPage}
        setLimit={setLimit}
        limit={limit}
        total={
          wareHouseOut?.warehouseOut?.total ||
          wareHouseOut?.allStockRequested?.total
        }
        count={Math.ceil(
          (wareHouseOut?.warehouseOut?.total ||
            wareHouseOut?.allStockRequested?.total) / limit
        )}
      >
        <Stack spacing={1}>
          {props.route === "channel" && (
            <Box>
              {/* <div className="flex flex-wrap gap-5 justify-start items-start"> */}
              {wareHouseOut?.requestSentIsFetching ? (
                <Stack direction={"row"} spacing={2}>
                  {Array.from({ length: 3 }).map(() => (
                    <Box sx={{ width: 270 }}>
                      <Skeleton sx={{ height: 70, m: 0 }} />
                      <Stack direction={"row"} justifyContent="space-between">
                        <Skeleton animation="wave" sx={{ width: "40%" }} />
                        <Skeleton animation={false} sx={{ width: "30%" }} />
                      </Stack>
                    </Box>
                  ))}
                </Stack>
              ) : (
                <div
                  className={`grid ${
                    layout?.isSideNavOpen
                      ? "sm:grid-cols-1 lg:grid-cols-2"
                      : "sm:grid-cols-2 lg:grid-cols-3"
                  } xl:grid-cols-3 2xl:grid-cols-4 gap-4`}
                >
                  <RequestGrid data={values} />
                </div>
              )}
            </Box>
          )}

          {props.route !== "channel" && (
            <>
              <Box>
                <div className="flex flex-wrap gap-5 justify-start items-start">
                  {wareHouseOut?.requestSentIsFetching && (
                    <Stack direction={"row"} spacing={2}>
                      {Array.from({ length: 3 }).map(() => (
                        <Box sx={{ width: 270 }}>
                          <Skeleton sx={{ height: 70, m: 0 }} />
                          <Stack
                            direction={"row"}
                            justifyContent="space-between"
                          >
                            <Skeleton animation="wave" sx={{ width: "40%" }} />
                            <Skeleton animation={false} sx={{ width: "30%" }} />
                          </Stack>
                        </Box>
                      ))}
                    </Stack>
                  )}
                  {!wareHouseOut?.requestSentIsFetching && (
                    <div
                      className={`grid ${
                        layout?.isSideNavOpen
                          ? "sm:grid-cols-1 lg:grid-cols-2"
                          : "sm:grid-cols-2 lg:grid-cols-3"
                      } xl:grid-cols-3 2xl:grid-cols-4 gap-4`}
                    >
                      <WareHouseOutGrid data={data} />
                    </div>
                  )}
                </div>
              </Box>
              <Box>
                <div className="items-start mt-4">
                  {wareHouseOut?.isFetching && (
                    <Stack direction={"row"} spacing={2}>
                      {Array.from({ length: 3 }).map(() => (
                        <Box sx={{ width: 270 }}>
                          <Skeleton sx={{ height: 70, m: 0 }} />
                          <Stack
                            direction={"row"}
                            justifyContent="space-between"
                          >
                            <Skeleton animation="wave" sx={{ width: "40%" }} />
                            <Skeleton animation={false} sx={{ width: "30%" }} />
                          </Stack>
                        </Box>
                      ))}
                    </Stack>
                  )}
                  {!wareHouseOut?.isFetching && (
                    <div
                      className={`grid ${
                        layout?.isSideNavOpen
                          ? "sm:grid-cols-1 lg:grid-cols-2"
                          : "sm:grid-cols-2 lg:grid-cols-3"
                      } xl:grid-cols-3 2xl:grid-cols-4 gap-4`}
                    >
                      <StockOutGrid route={props?.route} data={stockOutData} />
                    </div>
                  )}
                </div>
              </Box>
            </>
          )}
        </Stack>
      </ScrollableFrame>
    </Stack>
  );
};

export default PageView;
