import React from "react";
import { DatePicker, Table } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { getAllNosaleAction } from "../../store/pos/actions";
import ScrollableFrame from "../layout/ScrollableFrame";
import { NosalesHeaders } from "../csvHeaders/Headers";
import dayjs from "dayjs";
import moment from "moment";
import { useMediaQuery } from "@mui/material";
import type { TimeRangePickerProps } from "antd";
import type { Dayjs } from "dayjs";
import CSVExportButton from "../cards/NoSale/CSVExportButton";
import { getAllNoSales_CSVAction } from "../../store/csvDownload/actions";

const { RangePicker } = DatePicker;

const NosaleTable = () => {
  const isTablet = useMediaQuery("(max-width: 1100px)");
  const { pos, auth, layout, CSV } = useSelector((state: any) => state);
  const today = moment();
  const formattedDate = new Date().toISOString().slice(0, 10);
  const previousDate = today.subtract(7, "days");
  const formattedPreviousDate = previousDate.format("YYYY-MM-DD");
  const [startDate, setStartDate] = React.useState<any>(formattedPreviousDate);
  const [endDate, setEndDate] = React.useState<any>(formattedDate);

  const dispatch = useDispatch();
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(15);

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
    },
    {
      title: "Shop",
      dataIndex: "shop",
    },
    {
      title: "Agent",
      dataIndex: "agent",
    },
    {
      title: "Customer",
      dataIndex: "name",
    },
    {
      title: "MSISDN",
      dataIndex: "phone",
    },
    {
      title: "Model",
      dataIndex: "model",
    },
    {
      title: "Specifications",
      dataIndex: "specification",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
    },
    {
      title: "Reason",
      dataIndex: "comment",
    },
  ];

  React.useEffect(() => {
    if (startDate || endDate) {
      auth?.token &&
        getAllNosaleAction(
          auth?.token,
          `?limit=${limit}&page=${
            page - 1
          }&startingDate=${startDate}&endingDate=${endDate}`
        )(dispatch);
    } else {
      auth?.token &&
        getAllNosaleAction(
          auth?.token,
          `?limit=${limit}&page=${page - 1}`
        )(dispatch);
    }
  }, [auth, dispatch, limit, page, startDate, endDate]);

  React.useEffect(() => {
    if (layout?.openCSVButton)
      auth?.token &&
        getAllNoSales_CSVAction(
          auth?.token,
          `?limit=${pos?.nosale?.total}&page=0&startingDate=${startDate}&endingDate=${endDate}`
        )(dispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layout?.openCSVButton]);

  const dataToDisplay = pos?.nosale?.data?.noSales?.map((el: any) => {
    return {
      key: el?._id,
      date: el?.createdAt?.slice(0, 10),
      shop: el?.shopId?.name,
      agent: el?.createdBy?.names,
      name: el?.customerId?.name,
      comment: el?.comment,
      price: el?.priceListItemId?.prices
        ?.filter((d: any) => d.isActive === true)
        ?.map((d: any) => d.value)
        ?.toLocaleString(),
      model: el?.newProduct,
      quantity: el?.quantity,
      phone: el?.customerId?.phone,
      specification: el?.specification?.flatMap((el: any) =>
        el?.map((d: any) => d)
      ),
      data: el,
    };
  });

  const csvData = CSV?.csvDownloadedData?.data?.noSales?.map((el: any) => {
    return {
      date: el?.createdAt?.slice(0, 10),
      shop: el?.shopId?.name,
      agent: el?.createdBy?.names,
      customer: el?.customerId?.name,
      customerPhone: el?.customerId?.phone,
      model: el?.newProduct,
      specification: el?.specification?.flatMap((el: any) =>
        el?.map((d: any) => d)
      ),
      quantity: el?.quantity,
      reason: el?.comment,
    };
  });

  const onRangeChange = (
    dates: null | (Dayjs | null)[],
    dateStrings: string[]
  ) => {
    if (dates) {
      setStartDate(dateStrings[0]);
      setEndDate(dateStrings[1]);
    } else {
      console.log("Clear");
    }
  };

  const rangePresets: TimeRangePickerProps["presets"] = [
    { label: "Last 7 Days", value: [dayjs().add(-7, "d"), dayjs()] },
    { label: "Last 14 Days", value: [dayjs().add(-14, "d"), dayjs()] },
    { label: "Last 30 Days", value: [dayjs().add(-30, "d"), dayjs()] },
    { label: "Last 90 Days", value: [dayjs().add(-90, "d"), dayjs()] },
  ];

  return (
    <div className="relative bg-white py-4 rounded-lg">
      <div className="flex items-center justify-between mx-4 my-4">
        <div>
          <p className="text-xl font-poppins">No sale details</p>
        </div>
        <div className="flex items-center space-x-4">
          <RangePicker
            presets={rangePresets}
            onChange={onRangeChange}
            defaultValue={[dayjs(formattedPreviousDate), dayjs(formattedDate)]}
          />
          <CSVExportButton
            csvHeaders={NosalesHeaders}
            csvData={csvData}
            filename={`no_sales_report.csv`}
          />
        </div>
      </div>

      <ScrollableFrame
        setPage={setPage}
        setLimit={setLimit}
        limit={limit}
        total={pos?.nosale?.total}
        count={Math.ceil(pos?.nosale?.total / limit)}
      >
        <Table
          columns={columns}
          dataSource={dataToDisplay}
          pagination={false}
          loading={pos?.isFetching}
          scroll={isTablet ? { x: 1300 } : undefined}
        />
      </ScrollableFrame>
    </div>
  );
};

export default NosaleTable;
