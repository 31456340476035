import React from "react";
import { useSelector } from "react-redux";

const CustomerDetals = () => {
  const { customer } = useSelector((state: any) => state);

  return (
    <div className="text-gray-700">
      <h1 className="mb-4 font-semibold text-lg">Personal details</h1>
      <div className="border border-gray-400 rounded-md p-3">
        <div className="flex items-center">
          <p className="w-44 text-[#03022980] font-medium text-base">Names</p>
          <span className="flex-1 text-[#030229] text-base capitalize">
            {customer?.selected?.name}
          </span>
        </div>
        <div className="flex items-center">
          <p className="w-44 text-[#03022980] font-medium text-base">Gender</p>
          <span className="flex-1 text-[#030229] text-base capitalize">
            {customer?.selected?.gender}
          </span>
        </div>
        <div className="flex items-center">
          {" "}
          <p className="w-44 text-[#03022980] font-medium text-base">DOB</p>
          <span className="flex-1 text-[#030229] text-base ">
            {customer?.selected?.dateOfBirth?.slice(0, 10)}
          </span>
        </div>
        <div className="flex items-center">
          <p className="w-44 text-[#03022980] font-medium text-base">
            Customer ID
          </p>
          <span className="flex-1 text-[#030229] text-base">
            {customer?.selected?.customerId}
          </span>
        </div>
        <div className="flex items-center">
          <p className="w-44 text-[#03022980] font-medium text-base">
            National ID
          </p>
          <span className="flex-1 text-[#030229] text-base">
            {customer?.selected?.nationalId}
          </span>
        </div>
        <div className="flex items-center">
          <p className="w-44 text-[#03022980] font-medium text-base">
            Customer Type
          </p>
          <span className="flex-1 text-[#030229] text-base capitalize">
            {customer?.selected?.customerType}
          </span>
        </div>
      </div>
      <h1 className="my-4 font-semibold text-lg">Address details</h1>
      <div className="border border-gray-400 rounded-md p-3">
        <div className="flex items-center">
          {" "}
          <p className="w-44 text-[#03022980] font-medium text-base">
            Nationality
          </p>
          <span className="flex-1 text-[#030229] text-base capitalize">
            {customer?.selected?.nationality}
          </span>
        </div>
        <div className="flex items-center">
          {" "}
          <p className="w-44 text-[#03022980] font-medium text-base">City</p>
          <span className="flex-1 text-[#030229] text-base capitalize">
            {customer?.selected?.city}
          </span>{" "}
        </div>
        {/* <div className="flex items-center">
          <p className="w-44 text-[#03022980] font-medium text-base">Address</p>
          <span className="flex-1 text-[#030229] text-base">
            {customer?.selected?.address}
          </span>
        </div> */}
        <div className="flex items-center">
          <p className="w-44 text-[#03022980] font-medium text-base">
            Current Residence
          </p>{" "}
          <span className="flex-1 text-[#030229] text-base">
            {customer?.selected?.currentResidence}
          </span>
        </div>
      </div>
    </div>
  );
};

export default CustomerDetals;
