import React, { useState, useEffect } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Stack } from "@mui/material";
import { Form, Input } from "antd";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSelector, useDispatch } from "react-redux";
import UploadComponent from "./Upload";
import {
  createCustomerAction,
  updateCustomerAction,
} from "../../store/customer/actions";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const App = (props: any) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { customer, auth } = useSelector((state: any) => state);
  const [pictures, setPictures] = useState([]);
  const isUpdate = props?.action === "update";
  const onFinish = async (values: any) => {
    const images = pictures.map((pic: any) => pic?.response?.data?.secure_url);
    const name = `${values?.firstName}  ${values?.lastName}`;
    delete values.firstName;
    delete values.lastName;

    // console.log("Values", { ...values, name });
    if (isUpdate) {
      auth?.token &&
        (await updateCustomerAction(auth?.token, props?.dataToUpdate?._id, {
          ...values,
          name,
          picture: images[0],
        })(dispatch));
    } else {
      // console.log("Received values of form: ", values);
      auth?.token &&
        createCustomerAction(auth?.token, {
          ...values,
          name,
          picture: images[0],
        })(dispatch);
    }
    props?.onCancel();
    form.resetFields();
  };

  // dispatch(myProductActions.setQuery(`?`));
  useEffect(() => {
    // auth?.token &&
    //   getAllCustomersAction(
    //     auth?.token,
    //     `?page=${props?.page}&limit=${props?.limit}`
    //   )(dispatch);
  }, [auth, dispatch, props?.limit, props?.page]);

  return (
    <Form
      // {...formItemLayout}
      form={form}
      name="register"
      onFinish={onFinish}
      initialValues={{}}
      scrollToFirstError
    >
      <div className="flex justify-center items-center  mb-2">
        <UploadComponent
          setPictures={setPictures}
          limit={1}
          default={
            props?.data && [
              {
                status: "done",
                url: props?.data?.picture,
              },
            ]
          }
        />
      </div>
      <div className="flex flex-col md:flex-row gap-5  mb-2 w-full  px-6">
        <Form.Item
          name="firstName"
          // label="First Name"
          tooltip="Please enter customer's first name"
          initialValue={props?.dataToUpdate?.firstName}
          rules={[
            {
              required: true,
              message: "First Name is required!",
              whitespace: true,
            },
          ]}
          getValueFromEvent={(e) =>
            e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1)
          }
          className="w-full"
        >
          <Input placeholder="First Name" className="w-full" />
        </Form.Item>
        <Form.Item
          name="lastName"
          // label="Last Name"
          tooltip="Please enter customer's last name"
          initialValue={props?.dataToUpdate?.lastName}
          rules={[
            {
              required: true,
              message: "Last Name is required!",
              whitespace: true,
            },
          ]}
          getValueFromEvent={(e) =>
            e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1)
          }
          className="w-full"
        >
          <Input placeholder="Last Name" className="w-full" />
        </Form.Item>
      </div>

      <div className="flex flex-col md:flex-row gap-5  mb-2 w-full  px-6">
        <Form.Item
          name="address"
          // label="Address"
          initialValue={props?.dataToUpdate?.email}
          rules={[
            {
              required: true,
              message: "Address is required!",
            },
          ]}
          className="w-full"
        >
          <Input placeholder="Address" />
        </Form.Item>

        <Form.Item
          name="businessName"
          // label="Business Name"
          tooltip="Please enter customer names?"
          initialValue={props?.dataToUpdate?.businessName}
          className="w-full"
        >
          <Input placeholder="Business Name" />
        </Form.Item>
      </div>
      <div className="flex flex-col md:flex-row gap-5  mb-2 w-full  px-6">
        <Form.Item
          name="tinNumber"
          // label="TIN Number"
          tooltip="Please enter TIN number"
          initialValue={props?.dataToUpdate?.tinNumber}
          className="w-full"
        >
          <Input placeholder="TIN Number" />
        </Form.Item>

        <Form.Item
          name="nid"
          // label="National ID"
          tooltip="Please enter NID?"
          initialValue={props?.dataToUpdate?.nid}
          rules={[
            {
              required: true,
              message: "NID is required!",
              whitespace: true,
            },
          ]}
          className="w-full"
        >
          <Input placeholder="National ID" />
        </Form.Item>
      </div>
      <div className="flex flex-col md:flex-row gap-5  mb-2 w-full  px-6">
        <Form.Item
          // label="Telephone"
          name="phone"
          initialValue={props?.dataToUpdate?.phone}
          rules={[
            {
              required: true,
              message: "Phone Number is required!",
              max: 18,
            },
            // {
            //   //this phone number is rwandan
            //   pattern:
            //     /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{6}$/im,

            //   message: "Phone Number is required!",
            // },
          ]}
          className="w-full"
          hasFeedback
        >
          <PhoneInput
            country={"bw"}
            inputStyle={{
              width: "100%",
            }}
            containerClass="phone-container"
            placeholder="+250 780 000 000"
          />
        </Form.Item>

        <Form.Item
          name="email"
          // label="Email"
          tooltip="Please enter customer email?"
          initialValue={props?.dataToUpdate?.email}
          rules={[
            {
              required: true,
              message: "Email is required!",
            },
          ]}
          className="w-full"
        >
          <Input placeholder="Email" />
        </Form.Item>
      </div>
     
      <div className="flex justify-center items-center py-4">
        <LoadingButton
          type="submit"
          variant="contained"
          loading={customer?.isFetching}
          className="w-[100%] md:w-[50%]"
        >
          {isUpdate ? "Update" : "Save"}
        </LoadingButton>
      </div>

      <Stack
        direction={"row"}
        spacing={2}
        alignItems="center"
        justifyContent={"center"}
        sx={{ width: "100%" }}
      >
        {/* <Form.Item {...tailFormItemLayout}>
            <LoadingButton
              type="submit"
              variant="contained"
              sx={{ minWidth: "100%" }}
              loading={customer?.isFetching}
            >
              {isUpdate ? "Update" : "Save"}
            </LoadingButton>
          </Form.Item> */}
        {/* <Stack
          direction={"row"}
          sx={{ width: "45%" }}
          alignItems="center"
          justifyContent={"center"}
        >
          <Form.Item valuePropName="fileList">
            <Upload action="/upload.do" listType="picture-card">
              <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>Upload</div>
              </div>
            </Upload>
          </Form.Item>
        </Stack> */}
      </Stack>
    </Form>
  );
};

export default App;
