import React from "react";
import { Divider, Table } from "antd";
import type { TableColumnsType } from "antd";

interface DataType {
  key: React.Key;
  shop: string;
  device: string;
  number: number;
  price: string;
}

const columns: TableColumnsType<DataType> = [
  {
    title: "Shop",
    dataIndex: "shop",
  },
  {
    title: "Device",
    dataIndex: "device",
  },
  {
    title: "Amount",
    dataIndex: "price",
  },
  {
    title: "Number Of Request",
    dataIndex: "number",
  },
];

const data: DataType[] = [];

const NoSaleSubscriptionTable: React.FC = () => (
  <>
    <Table<DataType>
      columns={columns}
      dataSource={data}
      size="small"
      pagination={false}
    />
  </>
);

export default NoSaleSubscriptionTable;
