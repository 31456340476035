import { useState } from "react";
import { Paper, Box, Stack } from "@mui/material";
import { generatePath, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Modal, Tag } from "antd";
import { deleteSupplierActions } from "./../../store/supplier/actions";

import ProfileAvatal from "../buttons/ProfileAvatal";

import SupplierForm from "../../components/forms/NewSupplierForm";
import SupplierDetailsCard from "../cards/SupplierDetailsCard";
import DeleteModal from "../Modals/DeleteModal";
import { searchValue } from "../../utils/setColor";

const Component = (props: any) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const [id, setId] = useState("");
  const [supplierId, setSupplierId] = useState("");
  const [data, setData] = useState<any>("");
  const [showData, setShowData] = useState<any>("");
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();

  const deleteModel = (itemId: any) => {
    setVisible(true);
    setId(itemId);
  };

  const updateModelOpen = (data: any, id: any) => {
    setIsModalOpen(true);
    setIsInfoModalOpen(false);
    setData(data);
    setSupplierId(id);
  };

  const handleCancels = () => {
    setVisible(false);
  };

  const showModelOpen = (items: any) => {
    const Id = items.id;
    const path = generatePath(`/purchase/supplier/${items._id}`, { Id });
    navigate(path);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleInfoCancel = () => {
    setIsInfoModalOpen(false);
  };

  const { itemId, auth, supplier } = useSelector((state: any) => state);
  const dispatch = useDispatch();

  const style = {
    width: "100vh",
    color: "black",
    p: 2,
  };

  const handleDelete = (e: any) => {
    e.preventDefault();
    auth?.token &&
      id &&
      deleteSupplierActions(id, auth?.token || "?")(dispatch);
    setVisible(false);
  };

  return (
    <>
      <Paper elevation={1} sx={{ width: "23%", p: 1 }} className="cardhover">
        <Stack spacing={2} className="relative">
          <div
            onClick={() => showModelOpen(props?.data?.data)}
            className="pt-2"
          >
            <Stack
              direction={"row"}
              spacing={1}
              alignItems="flex-start"
              justifyContent={"space-between"}
            >
              <div style={{ width: "2rem" }}>
                <ProfileAvatal
                  picture={props?.data?.data?.logo}
                  name={props?.data?.name}
                  noActiveBadge={true}
                />
              </div>
              <Stack direction={"column"} spacing={2}>
                <Stack spacing={0}>
                  <h1>{props.data?.name}</h1>
                  <p className="text-xs" style={{ color: "blue" }}>
                    {props.data?.type}
                  </p>
                </Stack>
                <Box sx={{ width: "100%" }}>
                  <Stack
                    direction="row"
                    justifyContent={"space-between"}
                    alignItems="flex-end"
                    sx={{ width: "100%" }}
                  >
                    <Stack spacing={1}>
                      <p className="text-xs">{props.data?.email}</p>
                      {/* <p className="text-xs">{props.data?.phone}</p> */}
                      <p className="text-xs">
                        {/* <LocationOnIcon sx={{ fontSize: 15 }} /> */}
                        {props.data?.data.phone}
                      </p>
                    </Stack>
                  </Stack>
                </Box>
              </Stack>
              <Stack>
                <Tag color={searchValue(props.data?.data.status)}>
                  {props.data?.data.status}
                </Tag>

                {/* <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent={"space-between"}
                  spacing={1}
                  className="absolute right-2 bottom-0"
                >
                  <IconButton
                    aria-label="edit"
                    size="small"
                    // onClick={() => setData(props.data.data)}
                    onClick={() =>
                      updateModelOpen(props.data.data, props.data.data._id)
                    }
                  >
                    <EditIcon fontSize="inherit" color="primary" />
                  </IconButton>
                  <IconButton
                    aria-label="delete"
                    size="small"
                    onClick={() => deleteModel(props.data.data._id)}
                  >
                    <DeleteIcon fontSize="inherit" color="warning" />
                  </IconButton>
                </Stack> */}
              </Stack>
            </Stack>
          </div>
        </Stack>
      </Paper>

      <DeleteModal
        visible={visible}
        onOk={handleDelete}
        isLoading={supplier.isFetching}
        onCancel={handleCancels}
        itemName="Supplier"
      />

      <Modal
        title={"Update Supplier"}
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        className="min-w-min"
        // icon={props?.icon || <HomeIcon />}
      >
        <div>
          <Paper sx={style}>
            <SupplierForm
              data={data}
              supplierId={supplierId}
              onCancel={handleCancel}
            />
          </Paper>
        </div>
      </Modal>
      <Modal
        title={"Supplier Info"}
        open={isInfoModalOpen}
        onCancel={handleInfoCancel}
        footer={null}
        className="min-w-min"
        // icon={props?.icon || <HomeIcon />}
      >
        <div>
          <Paper style={{ width: "100%" }}>
            <div className="flex flex-col pl-4">
              <SupplierDetailsCard showData={showData} />
            </div>
          </Paper>
        </div>
      </Modal>
    </>
  );
};

export default Component;
