import { Button } from "antd";

const TableHeader = ({ columns = [], actions = [] }: any) => (
  <div className="flex bg-[#f7f8fb] border rounded-lg py-5 border-gray-200 my-3">
    {columns?.map((column: any, index: number) => (
      <div
        key={index}
        className="w-1/4 capitalize px-4 text-left text-sm font-semibold text-gray-700"
      >
        {column.header}
      </div>
    ))}
    {actions?.length > 0 && (
      <div className="w-1/4  px-4 text-left text-sm font-semibold text-gray-700">
        Actions
      </div>
    )}
  </div>
);

const TableRow = ({ item, columns = [], actions = [] }: any) => (
  <div className="flex border my-2 py-4 rounded-lg border-gray-100">
    {columns?.map((column: any, index: number) => (
      <div
        key={index}
        className="w-1/4 px-4 text-sm text-gray-800 overflow-hidden break-words"
        style={{ maxWidth: "100%" }}
      >
        {item[column.key]}
      </div>
    ))}
    {actions?.length > 0 && (
      <div className="w-1/4 gap-x-2 px-4 text-sm text-gray-800 flex">
        {actions?.map((action: any, index: number) => (
          <Button
            key={index}
            type={action.type}
            danger={action.redButton || false}
            loading={action.loading || false}
            disabled={action.disabled || false}
            className={`px-3 text-xs h-8`}
            onClick={() => action.onClick(item)}
          >
            {action.label}
          </Button>
        ))}
      </div>
    )}
  </div>
);

const CustomTable = ({ data, columns, actions, height, isLoading }: any) => {
  if (data?.length === 0) {
    return (
      <div className="w-full rounded-lg overflow-hidden shadow-sm px-4">
        <TableHeader columns={columns} />
        <div className="text-center text-sm text-gray-400 py-4">
          No data available
        </div>
      </div>
    );
  }

  return (
    <div className="w-full rounded-lg xoverflow-hidden shadow-sm px-4 bg-white">
      <TableHeader columns={columns} actions={actions} />
      <div className={`h-[${height}] overflow-y-auto`}>
        {isLoading ? (
          <div role="status" className="animate-pulse">
            <div className="h-6 bg-gray-100 rounded-lg mb-2.5 "></div>
            <div className="h-6 bg-gray-100 rounded-lg mb-2.5 "></div>
            <div className="h-6 bg-gray-100 rounded-lg mb-2.5 "></div>
            <div className="h-6 bg-gray-100 rounded-lg mb-2.5 "></div>
          </div>
        ) : (
          <>
            {data?.map((item: any, index: number) => (
              <TableRow
                key={index}
                item={item}
                columns={columns}
                actions={actions}
              />
            ))}
          </>
        )}
      </div>
    </div>
  );
};
export default CustomTable;
