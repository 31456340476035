import React from 'react';
import { Table } from 'antd';
import HoverDropDown from '../DropDown/HoverDropDown';
import type { MenuProps } from 'antd';
import { GrFormView } from 'react-icons/gr';
import { FiEdit } from "react-icons/fi";
import { MdDeleteOutline } from "react-icons/md";


const StockOutHistoryTable = (props:any) => {
  console.log("props",props)
  const items: MenuProps['items'] = [
    {
        label: <div className='flex gap-2'><GrFormView size={25}/><span className="text-sm pt-1">View Details</span></div>,
        key: '0',
        onClick: () => props?.open(),
    },
    {
        label: <div className='flex gap-3'><FiEdit  size={22}/><span className="text-sm pt-1">Modify</span></div>,
        key: '1',
        onClick: () => props?.modalOpen(),
    },
    {
        label: <div className='flex gap-2'><MdDeleteOutline  size={25}/><span className="text-sm pt-1">Delete</span></div>,
        key: '3',
    },
];

  const columns= [
    {
      title: 'Item Code',
      dataIndex: 'code',
    },
    {
      title: 'Item Name',
      dataIndex: 'name',
    },
    {
      title: 'Sales Qty',
      dataIndex: 'quantity',
    },
    {
      title: 'Price',
      dataIndex: 'price',
    },
    {
      title: 'Total Amount',
      dataIndex: 'amount',
    },
    {
      title: 'Shipment Out Qty',
      dataIndex: 'shipment',
    },
    
    {
      title: '',
      dataIndex: 'status',
      render :(text:any)=>(
        <>
        <button className='border border-[#605BFF] px-3 py-1  rounded-lg text-[#605BFF]' >Adjust Stock</button>
        </>
    )
    }
  ];
  
  const data= [
    {
      key: '1',
      code:"AE2CTXU0000320",
      name: 'Samsung Galaxy A55 (128GB + 8GB)',
      quantity:"10",
      date:"01-12-2024",
      amount:"150",
      price:"7,749,789",
      shipment:"0",
      status:"Wait for approval"
    },
    {
      key: '2',
      code:"AE2CTXU0000331",
      name: 'Samsung Galaxy S24 Plus (256GB + 12GB)',
      quantity:"30",
      date:"01-12-2024",
      amount:"10",
      price:"7,749,789",
      shipment:"0",
      status:"Approved"
    },
    {
    key: '3',
      code:"AE2CTXU0000332",
      name: 'Samsung Galaxy A55 (128GB + 8GB)',
      quantity:"25",
      date:"01-12-2024",
      amount:"15",
      price:"7,749,789",
      shipment:"0",
      status:"Wait for approval"
    },
    {
      key: '4',
      code:"AE2CTXU0000333",
      name: 'Samsung Galaxy S23 Plus (256GB)',
      quantity:"0",
      date:"01-12-2024",
      amount:"1",
      price:"7,749,789",
      shipment:"0",
      status:"Approved"
    },
    
   
  ];
  return(
  
  <>
    <Table columns={columns} dataSource={data} size="middle" />
  </>
)};

export default StockOutHistoryTable;