import { myLayoutActions } from ".";

export const toggleSideNavOpenClose = (isOpen: boolean) => {
  return async (dispatch: any) => {
    dispatch(myLayoutActions.setIsSideNavOpen(!isOpen));
  };
};
export const viewUserDetails = (isOpen: boolean, data: any) => {
  return async (dispatch: any) => {
    dispatch(myLayoutActions.setViewUser(isOpen));
    dispatch(myLayoutActions.setUserInfo(data));
  };
};
export const viewChannelDetails = (isOpen: boolean, data: any) => {
  return async (dispatch: any) => {
    dispatch(myLayoutActions.setViewChannel(isOpen));
    dispatch(myLayoutActions.setChannelInfo(data));
  };
};
export const productHistoryInformation = (isOpen: boolean, data: any) => {
  return async (dispatch: any) => {
    dispatch(myLayoutActions.setViewProductHistory(isOpen));
    dispatch(myLayoutActions.setProductHistoryInfo(data));
    console.log("preeeeeee", data);
  };
};

export const setActiveMenuAction = (currentMenu: number) => {
  return async (dispatch: any) => {
    dispatch(myLayoutActions.setSelectedMenu(currentMenu));
  };
};
export const setRectirectPathAction = (currentPath: any) => {
  return async (dispatch: any) => {
    dispatch(myLayoutActions.setRedirectPath(currentPath));
  };
};
export const setPrevPathAction = (prevtPath: any) => {
  return async (dispatch: any) => {
    dispatch(myLayoutActions.setPrevRoute(prevtPath));
  };
};

export const handleSelectedChannelAction = (channel: any) => {
  return async (dispatch: any) => {
    dispatch(myLayoutActions.setSelectedChannel(channel));
  };
};

export const handleSelectedShop = (shop: any) => {
  return async (dispatch: any) => {
    dispatch(myLayoutActions.setSelectedShop(shop));
  };
};

export const activeSelectedTheme = (data: any) => {
  return async (dispatch: any) => {
    dispatch(myLayoutActions.setSelectedTheme(data));
  };
};

export const openCSVButton = (isOpen: boolean) => {
  return async (dispatch: any) => {
    dispatch(myLayoutActions.setOpenCSVButton(isOpen));
  };
};

export const showFranchiseeDash = (isShown: boolean) => {
  return async (dispatch: any) => {
    dispatch(myLayoutActions.setIsFranchiseeDash(isShown));
  };
};

export const handlerSelectTabs = (tab: number) => {
  return async (dispatch: any) => {
    dispatch(myLayoutActions.setActiveTab(tab));
  };
};

export const handlerSelectedAccountTypeAction = (type: number) => {
  return async (dispatch: any) => {
    dispatch(myLayoutActions.setSelectedAccountType(type));
  };
};

export const handlerUpdateAccountAction = (isUpdate: boolean) => {
  return async (dispatch: any) => {
    dispatch(myLayoutActions.setIsUpdateAccount(isUpdate));
  };
};

export const handlerSearchFromAction = (route: string) => {
  return async (dispatch: any) => {
    dispatch(myLayoutActions.setSearchFrom(route));
  };
};

export const handlerSearchResultAction = (result: string) => {
  return async (dispatch: any) => {
    dispatch(myLayoutActions.setSearchResult(result));
  };
};

export const handleSubscriptionPlanType = (planType: string) => {
  return async (dispatch: any) => {
    dispatch(myLayoutActions.setSubscriptionType(planType));
  };
};

export const handleSetSubscriptionDealSteps = (step: number) => {
  return async (dispatch: any) => {
    dispatch(myLayoutActions.setSubscriptionDealSteps(step));
  };
};

export const handleFoundCustomerByPhone = (item: any) => {
  return async (dispatch: any) => {
    dispatch(myLayoutActions.setFoundCustomerByPhone(item));
  };
};

export const handleNewCustomer = (item: boolean) => {
  return async (dispatch: any) => {
    dispatch(myLayoutActions.setIsNewCustomer(item));
  };
};

export const handlePricelistTab = (tab: string) => {
  return async (dispatch: any) => {
    dispatch(myLayoutActions.setPriceListTab(tab));
  };
};

export const handleCheckAuthorisation = (check: boolean) => {
  return async (dispatch: any) => {
    dispatch(myLayoutActions.setIsNotAuthorised(check));
  };
};

export const handleHoverSettingSidebar = (check: boolean) => {
  return async (dispatch: any) => {
    dispatch(myLayoutActions.setIsSettingSidebarHovered(check));
  };
};

export const handleDataToExport = (data: any) => {
  return async (dispatch: any) => {
    dispatch(myLayoutActions.setDataToExport(data));
  };
};
