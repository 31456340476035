import React from "react";
import { Table, Tag } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useSelector, useDispatch } from "react-redux";
import { getAllShopsAgentAction } from "../../../store/pos/actions";
import { useParams } from "react-router-dom";
import ForceLogo from "../../../assets/icons/dashboard/Samphone_Logo.svg";

interface DataType {
  [x: string]: any;
  key: React.Key;
  name: string;
  age: number;
  address: string;
  checked: boolean;
}

const ShopAgentTable = (props: any) => {
  const dispatch = useDispatch();
  const { pos, auth } = useSelector((state: any) => state);
  const { shopId } = useParams();
  const dataMapper: any[] = [];
  const [formData, setformData] = React.useState(dataMapper);

  const value = pos?.shopAgent?.data;

  const columns: ColumnsType<DataType> = [
    {
      title: "Names",
      dataIndex: "data",
      render: (text, record) => (
        <p className="capitalize">{record?.data?.user?.names}</p>
      ),
    },
    {
      title: "Phone",
      dataIndex: "data",
      render: (text, record) => <p>{record?.data?.user?.phone}</p>,
    },
    {
      title: "Email",
      dataIndex: "data",
      render: (text, record) => <p>{record?.data?.user?.email}</p>,
    },
    {
      title: "Status",
      dataIndex: "data",
      render: (text, record) => (
        <Tag color="green">{record?.data?.user?.isActive && "Active"}</Tag>
      ),
    },
  ];

  React.useEffect(() => {
    auth?.token &&
      getAllShopsAgentAction(auth?.token, shopId as string)(dispatch);
  }, [auth?.token, dispatch]);

  React.useEffect(() => {
    value &&
      value.forEach((el: any) => {
        dataMapper.push({
          id: el._id,
          data: el,
          company:el?.user?.tag,
          names: el?.user?.names,
        });
      });

    // Sort dataMapper by model in alphabetical order
    dataMapper.sort((a, b) => a.names.localeCompare(b.names));
    setformData(dataMapper);
  }, [pos?.shopAgent]);

  const [pagination, setPagination] = React.useState({
    pageSize: 15,
    current: 1,
    total: formData.length,
    showSizeChanger: true,
  });

  const handleTableChange = (pagination: any) => {
    setPagination(pagination);
  };

  return (
    <div className="relative">
      <Table
        columns={columns}
        dataSource={formData}
        loading={pos?.isFetching}
        pagination={pagination}
        onChange={handleTableChange}
      />
    </div>
  );
};

export default ShopAgentTable;
