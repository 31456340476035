import React, { useEffect, useState } from "react";
import SettingButton from "../../../pages/dashboard/setting/SettingButton";
import { BiPlus } from "react-icons/bi";
import { ReactComponent as ImportIcons } from "../assets/images/icons/vscode-icons_file-type-excel2.svg";
import EbmButton from "../components/EbmButton";
import { Input } from "antd";
import type { SearchProps } from "antd/es/input/Search";
import GenearalModal from "../components/modals/GeneralModal";
import CustomButton from "../components/buttons/CustomButton";
import GeneralDrawer from "../components/GeneralDrawer";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import TwoIconsCard from "../components/cards/TwoIconsCard";
import { GoPlus } from "react-icons/go";
import { ReactComponent as NewIcons } from "../assets/images/icons/box.svg";
import { ReactComponent as DateIcons } from "../assets/images/icons/date.svg";
import type { RadioChangeEvent, SelectProps } from "antd";
import PurchaseDetails from "../components/PuchaseDetails";
import AddPurchaseForm from "../components/forms/AddPurchaseForm";
import StockStatusTable from "../components/tables/StockStatusTable";
import { useDispatch, useSelector } from "react-redux";
import { getAllStockStatusAction } from "../store/action";

dayjs.extend(customParseFormat);

const dateFormat = "YYYY-MM-DD";
type SelectCommonPlacement = SelectProps["placement"];
const StockStatus = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [placement, SetPlacement] = useState<SelectCommonPlacement>("topLeft");
  const { auth, ebmGeneral,stockStatus } = useSelector((state: any) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    auth?.token && getAllStockStatusAction(auth?.token, "")(dispatch);
  }, [dispatch, auth]);

  const currentStock = ebmGeneral?.stockStatus?.data?.reduce(
    (total: any, item: any) => total + item?.qty,
    0
  );
  const finishedProducts =
    ebmGeneral?.stockStatus?.data?.map((d: any) => d?.itemType === "2") &&
    ebmGeneral?.stockStatus?.data?.reduce(
      (total: any, item: any) => total + item?.qty,
      0
    );
  const placementChange = (e: RadioChangeEvent) => {
    SetPlacement(e.target.value);
  };
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showDrawer = () => {
    setOpenDrawer(true);
  };

  const onClose = () => {
    setOpenDrawer(false);
  };
  const { Search } = Input;
 
  return (
    <div>
      <GeneralDrawer
        openDrawer={openDrawer}
        closeDrawer={onClose}
        width={650}
        title={
          <h1 className="text-base font-medium text-[#030229]">
            Purchase Information Details
          </h1>
        }
        component={
          <div>
            <div className="flex justify-between ">
              <div>
                <div className="bg-[#F4F6FA] flex justify-center items-center gap-2">
                  {" "}
                  <h1 className="text-base text-black pl-2">CurrentStatus:</h1>
                  <CustomButton
                    title={"Wait for Approval"}
                    textColor="white"
                    bgColor="green-500"
                    textSize="base"
                  />
                </div>
              </div>
              <EbmButton
                textColor="black"
                btnName={"Export"}
                icon={<ImportIcons />}
                btnBgColor="gray-50"
              />
            </div>
            <div className="mt-5">
              <PurchaseDetails />
            </div>
          </div>
        }
      />
      <div className="flex justify-between">
        <h1 className="text-[#030229] text-lg">Stock Status</h1>
        <div className="flex gap-5">
          <EbmButton
            btnName={"Stock Date"}
            icon={<DateIcons />}
            btnBgColor="white"
          />
          <EbmButton
            btnName={"Export"}
            icon={<ImportIcons />}
            btnBgColor="white"
          />
          <SettingButton
            btnName={"New"}
            icon={<BiPlus color="white" size={20} />}
            btnBgColor="[#605BFF]"
            textColor="white"
            // onClick={showModal}
          />
        </div>
      </div>
      <div className="flex justify-between mt-5">
        <div></div>
        <div className="flex gap-5">
          <h1 className="pt-1">From</h1>
          <DatePicker
            defaultValue={dayjs("2019-09-03", dateFormat)}
            minDate={dayjs("2019-08-01", dateFormat)}
            maxDate={dayjs("2020-10-31", dateFormat)}
          />
          <h1 className="pt-1">To</h1>
          <DatePicker
            defaultValue={dayjs("2019-09-03", dateFormat)}
            minDate={dayjs("2019-08-01", dateFormat)}
            maxDate={dayjs("2020-10-31", dateFormat)}
          />
        </div>
      </div>
      <div className="grid grid-cols-3 gap-5 mt-7">
        <TwoIconsCard
          amount={finishedProducts}
          title={"Total Finished Product"}
          name={"Increased than last month"}
          icon={<GoPlus color="#43900C" />}
          iconTwo={<NewIcons />}
        />
        <TwoIconsCard
          amount={currentStock}
          title={"Total Current Stock"}
          name={"Increased than last month"}
          icon={<GoPlus color="#43900C" />}
          iconTwo={<NewIcons />}
        />
      </div>
      <div className="bg-white p-3 rounded-md mt-5">
        <div className="flex justify-between">
          <div></div>
          <Search
            placeholder="Search by item code or name"
         
            style={{ width: "250px" }}
          />
        </div>
        <div className="flex justify-between">
          <GenearalModal
            openModal={isModalOpen}
            closeModal={handleOk}
            title={
              <h1 className="text-[#030229] font-semibold text-lg text-center py-2">
                Purchase Registration
              </h1>
            }
            component={
              <div className="px-4 h-full w-full">
                <div className="mt-10 w-full">
                  <AddPurchaseForm />
                </div>
              </div>
            }
            width={1100}
          />
        </div>
        <div className="mt-5">
          <StockStatusTable open={showDrawer} modalOpen={showModal} />
        </div>
      </div>
    </div>
  );
};
export default StockStatus;
