import { Button, Drawer, Form, Modal, Spin, Steps } from "antd";
import React, { useEffect, useState } from "react";
import { CiClock2, CiEdit } from "react-icons/ci";
import { MdSocialDistance } from "react-icons/md";
import "./delivery.css";
import { FaRegUserCircle } from "react-icons/fa";
import packageimg from "../../../assets/images/package.png";
import type { DrawerProps, RadioChangeEvent } from "antd";
import { useDispatch, useSelector } from "react-redux";
import PhoneInput from "react-phone-input-2";
import { getCheckParkageAction, getOneDelivererOutAction } from "../../../store/delivery/actions";
import { LoadingOutlined } from "@ant-design/icons";
import RecieverDetails from "./ReceiverDetails";
const ConfirmPage = () => {
  const { tracking } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const statuses = ["ReadyForPickup", "Pickup", "In-Transit", "Delivered"];
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isReceiverModalOpen, setReceiverIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const showReceiverModal = () => {
    setReceiverIsModalOpen(true);
  };

  const handleReceiverOk = () => {
    setReceiverIsModalOpen(false);
  };

  const handleReceiverCancel = () => {
    setReceiverIsModalOpen(false);
  };
  const [form] = Form.useForm();
  useEffect(() => {
    form.setFieldsValue({
      phone: tracking?.checkParckage?.deliverer?.phone,
    });
  }, [tracking?.checkParckage?.reciever]);

  const currentStep = statuses.findIndex(
    (status) => status === tracking?.checkParckage?.trackingStatus
  );
  const Transit = "In-Transit";
  const statusMap: { [key: string]: "wait" | "process" | "finish" | "error" } =
    {
      ReadyForPickup: "finish",
      PickUp: "process",
      Transit: "wait",
      delivered: "wait",
    };
  const item = tracking?.checkParckage?.trackingType?.steps?.map((el: any) => {
    return {
      title: el?.title,
      status: statusMap[el?.title],
    };
  });
  const onFinish = async (value: any) => {
    await getOneDelivererOutAction(`?phone=${value?.phone}`)(dispatch);
    await getCheckParkageAction(
      `?trackingId=${tracking?.checkParckage?.packageId}&delivererId=${tracking?.selectedDriver?._id}`
    )(dispatch);
      handleOk();
  };
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  return (
    <div className="text-black w-full px-4">
      <h1 className="text-[#030229] text-lg font-medium py-3 pb-6 text-center">
        Order Preview
      </h1>
      <div className="border border-[#0302291A] h-full w-full p-3 rounded-md">
        <p className="text-[#030229] text-lg  uppercase">
          <span className="text-[#B0B0B0] text-base ">Order id: </span>
          {tracking?.checkParckage?.packageId}
        </p>
        <div className=" lg:flex justify-between mt-3">
          <div className=" flex lg:gap-5 justify-between mt-3 ">
            <div className="mt-1 lg:border-r-2 lg:pr-3">
              <p className="text-[#030229] text-lg ">Est. Delivery Time</p>
              <p className="text-[#030229B2] text-base flex gap-2">
                <CiClock2 color="#605BFF" className="mt-0.5" size={20} /> 2h 40m
              </p>
            </div>
            <div className="mt-1">
              <p className="text-[#030229] text-lg">Total Distance</p>
              <p className="text-[#030229B2] text-base flex gap-2">
                <MdSocialDistance
                  color="#030229B2"
                  className="mt-0.5"
                  size={20}
                />
                {tracking?.checkParckage?.distance} Km
              </p>
            </div>
          </div>
          <div className="deliveryContianer mt-5">
            <Steps
              current={currentStep}
              labelPlacement="vertical"
              items={item}
            />
          </div>
        </div>
      </div>
      <div className="md:flex gap-5">
        <div className="border border-[#0302291A] w-full p-4 mt-5 rounded-md">
          <div className="flex justify-between">
            <h1 className="text-[#030229] text-base font-medium py-3 pb-6 text-center">
              Deliver Details
            </h1>
            <div
              className="bg-[#E5E5E5] rounded-md h-[26px] w-[56px] flex justify-center items-center gap-2 cursor-pointer"
              onClick={showModal}
            >
              <CiEdit size={15} />
              <p className="text-[12px]">Edit</p>
            </div>
          </div>
          <div className="border text-black border-[#E3E5F1] bg-[#e3e5f1c0] p-4 mt-5 rounded-lg flex gap-2">
            <FaRegUserCircle size={32} color="#605BFF" />
            <div>
              <p className="text-[#605BFFB2]">Courier</p>
              <p>{tracking?.selectedDriver?.name}</p>
            </div>
          </div>
          <div className="flex w-full gap-10 mt-6">
            <p className="text-[#03022980]  text-sm">Email</p>
            <p className="text-[#030229] text-sm">
              {tracking?.selectedDriver?.email}
            </p>
          </div>
          <div className="flex w-full gap-5 mt-3">
            <p className="text-[#03022980]  text-sm">Phone Number</p>
            <p className="text-[#030229] text-sm">
              {tracking?.selectedDriver?.phone}
            </p>
          </div>
          <div className="flex w-full gap-10 mt-3">
            <p className="text-[#03022980]  text-sm">Company</p>
            <p className="text-[#030229] text-sm">
              {tracking?.selectedDriver?.company}
            </p>
          </div>
        </div>
        <div className="border border-[#0302291A] p-4 mt-5 rounded-md w-full">
          <div className="flex justify-between">
            <h1 className="text-[#030229] text-base font-medium py-3 pb-6 text-center">
              Receiver Details
            </h1>
            <div
              className="bg-[#E5E5E5] rounded-md h-[26px] w-[56px] flex justify-center items-center gap-2 cursor-pointer"
              onClick={showReceiverModal}
            >
              <CiEdit size={15} />
              <p className="text-[12px]">Edit</p>
            </div>
          </div>
          <div className="border text-black border-[#E3E5F1] bg-[#e3e5f1c0] p-4 mt-5 rounded-lg flex gap-2">
            <FaRegUserCircle size={32} color="#605BFF" />
            <div>
              <p className="text-[#605BFFB2]">RECEIVER</p>
              <p>{tracking?.checkParckage?.receiver?.name}</p>
            </div>
          </div>
          <div className="flex w-full gap-10 mt-3">
            <p className="text-[#03022980]  text-sm"> Phone Number:</p>
            <p className="text-[#030229] text-sm">
              {tracking?.checkParckage?.receiver?.phone}
            </p>
          </div>
          <div className="flex w-full gap-10 mt-3">
            <p className="text-[#03022980]  text-sm"> Email:</p>
            <p className="text-[#030229] text-sm">
              {tracking?.checkParckage?.receiver?.email}
            </p>
          </div>
          <div className="flex w-full gap-10 mt-3">
            <p className="text-[#03022980]  text-sm">Comment</p>
            <p className="text-[#030229] text-sm">
              {tracking?.checkParckage?.receiver?.comment}
            </p>
          </div>
        </div>
      </div>
      <div className="bg-white rounded-lg p-3">
        <h1 className="text-[#030229] text-lg font-medium py-3 pb-6 text-center">
          Package pictures
        </h1>
        <div className="flex justify-between">
          <div className="pb-10 ml-4 border border-[#03022926] border-dashed pt-5 w-full flex items-center justify-center">
            <img src={tracking?.checkParckage?.receiver?.picture[0]} />
          </div>
          <div className="pb-10 ml-4 border border-[#03022926] border-dashed pt-5 w-full flex items-center justify-center">
            <img src={tracking?.checkParckage?.receiver?.picture[1]} />
          </div>
        </div>
      </div>
      <Modal
        title={<h1 className="text-[#030229] text-lg font-medium py-3 pb-6 text-center">Edit Deliverer</h1>}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <Form
          className="mt-5"
          name="basic"
          style={{ maxWidth: 600 }}
          initialValues={tracking?.checkParckage?.deliverer}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            className="w-full"
            label={<p className="pt-5 text-lg">Phone number</p>}
            name="phone"
            rules={[{ required: true, message: "Please input your phone!" }]}
          >
            <PhoneInput
              country={"rw"}
              inputStyle={{
                width: "100%",
                height: "70px",
                color: "black",
              }}
              containerClass="phone-container"
              placeholder="+250 780 000 000"
            />
          </Form.Item>
          <Form.Item className="w-1/2 m-auto bg-wred-400 ">
            <Button
              htmlType="submit"
              className="text-center bg-[#605BFF] text-white w-full py-4 m-auto rounded-md ml-10"
            >
              {tracking?.isFetching ? (
                <div className="flex h-fit ">
                  <Spin indicator={antIcon} />
                </div>
              ) : (
                "Send"
              )}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title={<h1 className="text-[#030229] text-lg font-medium py-3 pb-6 text-center">Edit Receiver</h1>}
        width={800}
        open={isReceiverModalOpen}
        onOk={handleReceiverOk}
        onCancel={handleReceiverCancel}
        footer={null}
      >
        <RecieverDetails handleReceiverOk={handleReceiverOk}/>
      </Modal>
    </div>
  );
};

export default ConfirmPage;
