import React, { useState } from "react";
import SideBar from "./sideBar";
import VaultOverView from "./vaultOverview";
import AddDevices from "./manage devices/addDevices";
import PrepaidDevices from "./manage devices/prepaidDevices";
import PostPaidDevices from "./manage devices/postPaidDevices";
import IdleDevices from "./manage devices/idleDevices";

const Index = () => {
  const [isHover, setIsHover] = useState(false);
  const [activeTabs, setActiveTabs] = useState(
    `${localStorage.getItem("activeVaultTabs")
      ? localStorage.getItem("activeVaultTabs")
      : "overview"
    }`
  );
  localStorage.setItem("activeVaultTabs", activeTabs);
  return (
    <>
      <div className="text-[#030229]">
        <SideBar activeTabs={activeTabs} setActiveTabs={setActiveTabs} isHover={isHover} setIsHover={setIsHover} />
        <div className={`transition-all duration-700 ${isHover ? "ml-[20rem] " : "ml-[7rem]"}  pt-5 pb-4`}>
          {activeTabs === "overview" && <VaultOverView />}
          {activeTabs === "addDevice" && <AddDevices />}
          {activeTabs === "prePaidDevices" && <PrepaidDevices />}
          {activeTabs === "postPaidDevices" && <PostPaidDevices />}
          {activeTabs === "idleDevices" && <IdleDevices />}
        </div>
      </div>
    </>
  );
};
export default Index;
