import React, { useEffect, useState } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { Divider, Modal, Popover, Tag } from "antd";
import AssignShopForm from "../../forms/AssignShopForm";
import ProfileAvatal from "../../buttons/ProfileAvatal";
import { searchValue } from "../../../utils/setColor";
import { useDispatch, useSelector } from "react-redux";
import { getOneAgentShopAction } from "../../../store/setting/actions";

const Component = (props: any) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const { auth, setting } = useSelector((state: any) => state);
  const [referenceId, setReferenceId] = useState("");
  // const [loading, setLoading] = useState(setting?.isFetchingShop)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState<any>([]);

  // console.log("loading",loading)
  const contentLoading = <h1>Loading...</h1>;

  const content = () => {
    return setting?.selected?.assigned
      ?.filter((el: any) => el?.isActive === true)
      ?.map((d: any) => (
        <div className="">
          <p className="text-sm font-medium">
            Channel:{" "}
            <span className="text-xs font-normal">{d?.channel?.name}</span>
          </p>
          <p className="text-sm font-medium">
            Shop: <span className="text-xs font-normal">{d?.shop?.name}</span>
          </p>
          <p className="text-sm font-medium">
            <Tag
              className="capitalize "
              color={d?.shop?.type === "connect-shop" ? "cyan" : "lime"}
            >
              {d?.shop?.type?.split("-")?.join(" ")}
            </Tag>
          </p>
          <Divider />
        </div>
      ));
  };

  const showUserDetails = (items: any) => {
    const Id = items.id;
    const path = generatePath(`/setting/employee/${items._id}`, { Id });
    navigate(path);
  };

  const style = {
    width: "100vh",
    color: "black",
    p: 2,
  };

  const AssignShopModelOpen = (value: any) => {
    setIsModalOpen(true);
    setData(value);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setData(null);
  };
  const handleVisibleChange = (d: any) => {
    setReferenceId(d);
    setTimeout(() => {
      setReferenceId(" ");
    }, 2000);
  };

  useEffect(() => {
    auth?.token &&
      referenceId &&
      getOneAgentShopAction(referenceId as string, auth?.token)(dispatch);
  }, [referenceId, auth.token, dispatch]);

  // console.log("employee>>>>", props?.data?.data?.role);

  return (
    <>
      <div className="relative xl:w-[30%] 2xl:w-[23%] min-w-screen-639 max-w-screen-sm w-[90%] bg-white sm:w-[70%] md:w-[47%] lg:w-[45%]  text-black rounded-lg p-1 border border-gray-100  hover:bg-white h-fit sm:h-40 hover:border-gray-300">
        <div className="flex items-start justify-between p-3 ">
          <div className="w-[65%] flex gap-2">
            <div>
              <div className="flex items-center justify-center w-16 text-center">
                <ProfileAvatal
                  picture={props?.data?.data?.picture}
                  name={props?.data?.data?.names}
                  userId={props?.data?.data?._id}
                />
              </div>
              {props?.data?.data?.reference &&
                props?.data?.data?.role === "sales-agent" && (
                  <Popover
                    placement="bottom"
                    content={setting?.isFetchingShop ? contentLoading : content}
                    trigger="click"
                  >
                    <Tag
                      className="text-center cursor-pointer"
                      onClick={() =>
                        handleVisibleChange(props?.data?.data?.reference)
                      }
                    >
                      {"My Shop"}
                    </Tag>
                  </Popover>
                )}
            </div>
            <div
              onClick={() => showUserDetails(props?.data?.data)}
              className="cursor-pointer w-full h-36"
            >
              <div>
                <h1 className="capitalize">{props.data?.data?.names}</h1>
                <p
                  className="text-xs capitalize pt-2"
                  style={{ color: "blue" }}
                >
                  {props.data?.data?.role}
                </p>
              </div>
              <div className="mt-2">
                <p className="text-xs break-words pb-2 sm:pb-0">
                  {props.data?.data?.email}
                </p>
                <p className="text-xs sm:pt-1">{props.data?.data.phone}</p>
              </div>
            </div>
          </div>

          <Tag
            color={searchValue(
              props.data?.data?.isActive === true ? "Active" : "Not Active"
            )}
            className="absolute top-0 right-0 lg:top-3 md:right-2"
          >
            {props.data?.data?.isActive === true ? "Active" : "Not Active"}
          </Tag>
          <div className="flex items-end justify-end absolute bottom-1 md:bottom-2 right-2">
            {props.data?.data?.role?.toLowerCase() === "sales-agent" && (
              <div className="w-fit hover:text-white ">
                <button
                  className="text-xs flex items-center justify-center bg-transparent border-2 py-1.5 px-2 rounded-md hover:bg-black"
                  onClick={(e: any) => AssignShopModelOpen(props?.data?.data)}
                >
                  {props?.data?.data?.reference
                    ? "Re-Assign Shop"
                    : "Assign Shop"}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <Modal
        title={`Assign Shop To ${data?.names}`}
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        className="min-w-min"
      >
        <div>
          <div style={style}>
            <AssignShopForm onCancel={handleCancel} agentId={data?._id} />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Component;
