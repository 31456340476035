import { createSlice } from "@reduxjs/toolkit";

const mySlice = createSlice({
  name: "EmployeeLibrary",
  initialState: {
    isFetching: false,
    all: { data: [] },
    selected: null,
    attributes: null,
    new: null,
    updated: null,
    query: null,
    agentShop: null,
    pay: null,
    integration: null,
    role: null,
    permission: null,
    preference: null,
    theme: null,
    selectedTheme: null,
    agentTransaction: null,
    activityLogs: null,
    userLogedInLogs: null,
    selectedUserLogs: null,
    isFetchingShop: false,
    userStatistics: null,
    userStats: null,
  },
  reducers: {
    setAll(state, action) {
      state.all = action.payload;
    },
    setAttributes(state, action) {
      state.attributes = action.payload;
    },
    setQuery(state, action) {
      state.query = action.payload;
    },
    setNew(state, action) {
      state.new = action.payload;
    },
    setUpdated(state, action) {
      state.updated = action.payload;
    },
    setSelected(state, action) {
      state.selected = action.payload;
    },
    // setDeleted(state, action) {
    //   state.updated = action.payload;
    // },

    setIsFetching(state, action) {
      state.isFetching = action.payload;
    },
    setIsFetchingShop(state, action) {
      state.isFetchingShop = action.payload;
    },
    setAgentTransaction(state, action) {
      state.agentTransaction = action.payload;
    },
    setAgent(state, action) {
      state.agentShop = action.payload;
    },
    setIntegration(state, action) {
      state.integration = action.payload;
    },
    setRole(state, action) {
      state.role = action.payload;
    },
    setPermission(state, action) {
      state.permission = action.payload;
    },
    setPreference(state, action) {
      state.preference = action.payload;
    },
    setTheme(state, action) {
      state.theme = action.payload;
    },
    setSelectedTheme(state, action) {
      state.selectedTheme = action.payload;
    },
    setActivityLogs(state, action) {
      state.activityLogs = action.payload;
    },
    setUserLogedInLogs(state, action) {
      state.userLogedInLogs = action.payload;
    },
    setSelectedUserLogs(state, action) {
      state.selectedUserLogs = action.payload;
    },
    setPay(state, action) {
      state.pay = action.payload;
    },
    setUserStatistics(state, action) {
      state.userStatistics = action.payload;
    },
    setUserStats(state, action) {
      state.userStats = action.payload;
    },
  },
});

export const myEmployeeActions = mySlice.actions;

export default mySlice.reducer;
