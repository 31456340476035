import React from "react";
import { Badge } from "antd";
import { searchValue } from "../../utils/setColor";
import { useNavigate } from "react-router-dom";
import { getDefaultCurrencyCode } from "../../utils/helper";
import { useSelector } from "react-redux";

const PurchaseOrderCard = (props: any) => {
  const navigate = useNavigate();
  const { company } = useSelector((state: any) => state);
  return (
    <Badge.Ribbon
      text={
        <span
          style={{
            fontSize: "12px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            fontWeight: 400,
            paddingTop: "1.5px",
          }}
        >
          {props?.data?.poStatus}
        </span>
      }
      color={searchValue(props?.data?.poStatus)}
      style={{ width: "7rem", padding: "4px 1px" }}
    >
      <div className="relative text-[#030229]">
        <div
          className="bg-white relative h-[154px] border-t-[0.5px] border-t-[rgba(217,219,233,0.5)] rounded-[10px] shadow-[1px_3px_16px_0px_rgba(0,0,0,0.07)] text-[#030229] p-4 px-4"
          onClick={() => navigate(`details/${props?.data?._id}`)}
        >
          <div>
            <div className="flex flex-col gap-1">
              <h1 className="font-normal text-[14px]  w-[18.3rem]">
                {props?.data?.supplier?.toUpperCase()}
              </h1>
              <p className="text-[13px] font-normal text-[#605BFF]">
                {props?.data?.poId}
              </p>
            </div>
            <p className="absolute bottom-4 left-4 opacity-70 text-[14px] font-normal">
              {props?.data?.deliveryDeadline}
            </p>
            <p className="absolute bottom-4 right-4 text-[14px] font-medium">
              {props?.data?.totalAmount?.toLocaleString()}{" "}
              {getDefaultCurrencyCode(company)}
            </p>
          </div>
        </div>
      </div>
    </Badge.Ribbon>
  );
};
export default PurchaseOrderCard;
