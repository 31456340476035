import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Modal,
  Form,
  Input,
  InputNumber,
  Button,
  UploadProps,
  Upload,
  message,
} from "antd";
import { useDispatch, useSelector } from "react-redux";

import SettingButton from "../SettingButton";

import { HiOutlinePlus } from "react-icons/hi";
import { UploadOutlined } from "@ant-design/icons";
import {
  createTrackingAction,
  getAllTrackingTypeAction,
  updateTrackingTypeServiceActions,

} from "../../../../store/setting/company/actions"; // Add update action
import { CiEdit } from "react-icons/ci";

interface TrackingStep {
  title: string;
  sortNumber: number;
  icon: string; // Icon URL from Cloudinary
}

// Function to upload the image to Cloudinary
const uploadToCloudinary = async (file: File) => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("upload_preset", "samphone");
  formData.append("cloud_name", "dsx5egcz3");

  try {
    const response = await axios.post(
      "https://api.cloudinary.com/v1_1/dsx5egcz3/image/upload",
      formData
    );
    return response.data.secure_url; // Return the uploaded image URL
  } catch (error) {
    console.error("Error uploading to Cloudinary:", error);
    message.error("Failed to upload image. Please try again.");
    return null;
  }
};

const props: UploadProps = {
  beforeUpload: async (file: any) => {
    const isPNG = file.type === "image/png";
    if (!isPNG) {
      message.error(`${file.name} is not a PNG file`);
      return Upload.LIST_IGNORE;
    }

    const uploadedUrl = await uploadToCloudinary(file); // Upload to Cloudinary
    if (uploadedUrl) {
      file.url = uploadedUrl; // Attach the URL to the file object
      message.success(`${file.name} uploaded successfully!`);
    }

    return false; // Prevent default upload behavior
  },
  onChange: (info) => {
    console.log(info.fileList); // You can now see the URL in the file object
  },
};

const TrackingComponent: React.FC = () => {
  const dispatch = useDispatch();
  const { auth, company } = useSelector((state: any) => state);

  // State for tracking types
  const [trackingTypes, setTrackingTypes] = useState<TrackingStep[]>([
    {
      title: "ReadyForPickup",
      sortNumber: 1,
      icon: "String",
    },
    {
      title: "Pickup",
      sortNumber: 2,
      icon: "String",
    },
    {
      title: "In-transit",
      sortNumber: 3,
      icon: "String",
    },
    {
      title: "Delivered",
      sortNumber: 4,
      icon: "String",
    },
  ]);

  // State for modal
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  // State for form and editing
  const [form] = Form.useForm();
  const [editingTracking, setEditingTracking] = useState<any>(null); // State to keep track of the tracking type being edited

  // Function to handle form submission
  const handleAddOrEditStep = (values: any) => {
    const { Name, steps } = values;

    if (editingTracking) {
      // If editing
      auth.token &&
      // editingTracking.id,
      updateTrackingTypeServiceActions(editingTracking?._id, {
        name: Name,
        steps: steps.map((step: any) => ({
          title: step.Title,
          sortNumber: step.sortNumber,
          icon: step.icon[0]?.url, // Use the Cloudinary URL
        })),
      },auth.token)(dispatch);

      // Update local state
      const updatedTypes = trackingTypes.map((type:any) =>
        type?._id === editingTracking?.id ? { ...type, name: Name, steps } : type
      );
      setTrackingTypes(updatedTypes);
    } else {
      // If creating new
      auth.token &&
        createTrackingAction(auth.token, {
          name: Name,
          steps: steps.map((step: any) => ({
            title: step.Title,
            sortNumber: step.sortNumber,
            icon: step.icon[0]?.url, // Use the Cloudinary URL
          })),
        })(dispatch);

      setTrackingTypes([...trackingTypes, ...steps]);
    }

    setIsModalOpen(false);
    setEditingTracking(null); // Clear editing state
    form.resetFields();
  };

  useEffect(() => {
    auth?.token && getAllTrackingTypeAction(auth.token)(dispatch);
  }, [auth.token, dispatch]);

  // Function to handle editing
  const handleEditClick = (trackingType: any) => {
    setEditingTracking(trackingType); // Set the tracking type being edited
    setIsModalOpen(true);
    form.setFieldsValue({
      Name: trackingType.name,
      steps: trackingType.steps.map((step: any) => ({
        Title: step.title,
        sortNumber: step.sortNumber,
        icon: [{ url: step.icon }],
      })),
    });
  };
  return (
    <div className="p-6">
      <div className="flex flex-row justify-between">
        <h3 className="text-xl font-bold mb-4">Tracking Types</h3>
        <SettingButton
          btnName="New Tracking Type"
          btnBgColor="[#605BFF]"
          textColor="white"
          icon={<HiOutlinePlus size={20} />}
          onClick={() => {
            setIsModalOpen(true);
            setEditingTracking(null); // Reset editing state
            form.resetFields();
          }}
        />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 pt-3">
        {company &&
          company?.trackingType?.data?.map((step: any, index: any) => (
            <div
              key={index}
              className="border border-gray-300 rounded-lg p-4 shadow-sm"
            >
              <div className="flex flex-row justify-between">
                <h5 className="text-lg font-semibold">{step?.name}</h5>
                <div
                  className="cursor-pointer text-gray-400"
                  onClick={() => handleEditClick(step)}
                >
                  <CiEdit />
                </div>
              </div>
              {step?.steps?.map((step: any) => (
                <div className="flex flex-row gap-4 items-center">
                  <div className="text-gray-600">
                    Sort Number: {step?.sortNumber}
                  </div>
                  <div className="text-gray-600 flex flex-row items-center gap-2">
                    <p>Icon:</p> <img src={step?.icon} width={20} />
                  </div>
                </div>
              ))}
            </div>
          ))}
      </div>
      <Modal
        title={editingTracking ? "Edit Tracking Step" : "Add New Tracking Step"}
        visible={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={null}
      >
        <Form form={form} layout="vertical" onFinish={handleAddOrEditStep}>
          <Form.Item
            label="Name"
            name="Name"
            rules={[{ required: true, message: "Please enter a Name!" }]}
          >
            <Input placeholder="Name" />
          </Form.Item>

          <Form.List name="steps">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <div
                    key={key}
                    className="flex flex-col space-y-2 mb-4 border-b pb-4"
                  >
                    <Form.Item
                      {...restField}
                      label="Title"
                      name={[name, "Title"]}
                      rules={[
                        { required: true, message: "Please enter Title" },
                      ]}
                    >
                      <Input min={1} className="w-full" placeholder="Title" />
                    </Form.Item>

                    <Form.Item
                      {...restField}
                      label="Sort Number"
                      name={[name, "sortNumber"]}
                      rules={[
                        {
                          required: true,
                          message: "Please enter a sort number!",
                        },
                      ]}
                    >
                      <InputNumber
                        min={1}
                        className="w-full"
                        placeholder="Sort Number"
                      />
                    </Form.Item>

                    <Form.Item
                      {...restField}
                      label="Icon"
                      name={[name, "icon"]}
                      rules={[
                        { required: true, message: "Please upload an icon!" },
                      ]}
                    >
                      <Upload {...props}>
                        <Button icon={<UploadOutlined />}>Click to Upload</Button>
                      </Upload>
                    </Form.Item>

                    <Button
                      onClick={() => remove(name)}
                      type="dashed"
                      className="bg-red-600 text-white"
                    >
                      Remove
                    </Button>
                  </div>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    icon={<HiOutlinePlus />}
                  >
                    Add Step
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
          <Form.Item>
            <Button type="primary" htmlType="submit" className="bg-blue-600">
              {editingTracking ? "Update Step" : "Add Step"}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default TrackingComponent;
