export const customerIncludeItem = [
  "email",
  "name",
  "status",
  "picture",
  "phone",
  "nid",
  "nationalId",
  "address",
  "-createdBy",
  "msisdn",
  "firstName",
  "nationality",
];

export const productIncludeItem = [
  "type",
  "brand",
  "model",
  "images",
  "specs",
  "-createdBy",
];

export const warehouseInIncludeItem = [
  "deliveryID",
  "arrivalDate",
  "status",
  "warehouseID",
  "updatedAt",
  "ebmRegistered",
];

export const warehouseOutIncludeItem = [
  "requestId",
  "status",
  "approvedBy",
  "isTransferred",
  "list",
  "createdAt",
  "updatedAt",
  "warehouseOutId",
  "deliverer",
];

export const accountIncludeItem = [
  "accountID",
  "status",
  "type",
  "_id",
  "customerDetails",
  "-createdBy",
  "-channel",
  "createdAt",
];

export const picklistIncludeItem = [
  "SOID",
  "status",
  "createdAt",
  "_id",
  "createdBy",
];

export const deliveryNoteIncludeItem = [
  "SDNID",
  "status",
  "deliverer",
  "createdAt",
  "list",
  "_id",
  "-createdBy",
];

export const supplierIncludeItem = [
  "email",
  "status",
  "name",
  "phone",
  "type",
  "_id",
  "-createdBy",
];

export const purchaseIncludeItem = [
  "pOrderID",
  "status",
  "createdAt",
  "_id",
  "-createdBy",
  "-quotation",
];

export const rfqIncludeItem = [
  "supplier",
  "status",
  "createdAt",
  "confirmationDate",
  "_id",
  "quotation",
];
