import { SERVER_URL } from "../../utils/constants";
import HttpRequest from "../../utils/HttpRequest";

export const gettrTypeService = async (token: string) => {
  return HttpRequest.get(`${SERVER_URL}/trType`, token);
};

export const getAllPackageTrackService = async (
  token: string,
  query?: string
) => {
  return HttpRequest.get(`${SERVER_URL}/trPackage${query}`, token);
};

export const getOnePackageTrackService = async (token: string, id: string) => {
  return HttpRequest.get(`${SERVER_URL}/trPackage/one/${id}`, token);
};

export const gettrackingStasService = async (token: string) => {
  return HttpRequest.get(`${SERVER_URL}/trPackage/stats`, token);
};

export const getAllTrackActivitiesService = async (token: string) => {
  return HttpRequest.get(`${SERVER_URL}/trActivity`, token);
};
export const createDelivererServices = async (data: any, token: string) => {
  return HttpRequest.post(`${SERVER_URL}/trDeliverer`, data, token);
};

export const getAllDelivererService = async (token: string) => {
  return HttpRequest.get(`${SERVER_URL}/trDeliverer`, token);
};
export const getTrackingTypeServices = async (token: string) => {
  return HttpRequest.get(`${SERVER_URL}/trType`, token);
};
export const getOneDeliverer = async (id: string, token?: string) => {
  return HttpRequest.get(`${SERVER_URL}/trDeliverer/one/${id}`, token);
};
export const getOneDelivererOut = async (phone: string) => {
  return HttpRequest.get(`${SERVER_URL}/trDeliverer/phone${phone}`);
};
export const getCheckParkage = async (itemId: string) => {
  return HttpRequest.get(`${SERVER_URL}/trPackage/check${itemId}`);
};
export const createDeliveryActivityServices = async (
  data: any,
  token: string
) => {
  return HttpRequest.post(`${SERVER_URL}/trActivity`, data, token);
};
export const createOtpServices = async (data: any, token: string) => {
  return HttpRequest.post(`${SERVER_URL}/shopstock/createOtp`, data, token);
};
export const getOtpServices = async (token: string, query?: string) => {
  return HttpRequest.get(`${SERVER_URL}/shopstock/verifyOtp${query}`, token);
};
export const createShippingDetails = async (data: any, token: string) => {
  return HttpRequest.post(`${SERVER_URL}/trPackage`, data, token);
};
export const updateParkingServices = async (
  data: any,
  token: string,
  itemId: string
) => {
  return HttpRequest.update(
    `${SERVER_URL}/trPackage/one/${itemId}`,
    data,
    token
  );
};

export const updateParkageOutServices = async (
  itemId: any,
  data: string,
  token?: any
) => {
  return HttpRequest.update(
    `${SERVER_URL}/trPackage/one/${itemId}`,
    data,
    token
  );
};
export const recieverSignatureService = async (data: any, token?: string) => {
  return await HttpRequest.post(`${SERVER_URL}/product/upload`, data, token);
};
