import React, { useEffect, useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Form,
  Select,
  Row,
  Col,
  DatePicker,
  DatePickerProps,
  Checkbox,
  Space,
  Table,
  Tag,
} from "antd";
import { useSelector, useDispatch } from "react-redux";
import {
  getAllChannelAction,
  getAllShopAction,
} from "../../store/channel/actions";
import {
  assignAgentToShopAction,
  getAgentShopsAction,
} from "../../store/setting/actions";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import type { ColumnsType } from "antd/es/table";
import { searchFuction } from "../../utils/converter";

const { Option } = Select;
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

interface DataType {
  key: string;
  channel: string;
  shop: string;
  type: string;
  status: string;
}

const columns: ColumnsType<DataType> = [
  {
    title: "Channel",
    dataIndex: "channel",
    key: "channel",
  },
  {
    title: "Shop",
    dataIndex: "shop",
    key: "shop",
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
  },
];

const App = (props: any) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [startDate, setstartDate] = useState("");
  const [shopType, setShopType] = useState("");
  const { channel, auth, setting } = useSelector((state: any) => state);
  const [selectedChannelId, setSelectedChannelId] = useState("");
  const [isAsAddShop, setIsAsAddShop] = useState(false);
  const [shopFilter, setShopFilter] = useState([]);

  useEffect(() => {
    auth?.token && getAllChannelAction(auth?.token)(dispatch);
  }, [auth?.token, dispatch]);

  useEffect(() => {
    getAgentShopsAction(auth?.token, `?user=${props?.agentId}`)(dispatch);
  }, [auth?.token, dispatch, props?.agentId]);

  React.useEffect(() => {
    auth?.token &&
      selectedChannelId &&
      getAllShopAction(
        auth?.token,
        `channel=${selectedChannelId}&limit=1000${
          !shopType ? "" : `&type=${shopType}`
        }`
      )(dispatch);
  }, [auth.token, dispatch, selectedChannelId, shopType]);

  console.log("selectedChannelId::::>>>>", selectedChannelId);

  const onFinish = async (values: any) => {
    auth?.token &&
      selectedChannelId &&
      (await assignAgentToShopAction(
        props?.agentId,
        { ...values, startDate: startDate, isAsAddShop },
        auth?.token
      )(dispatch));
    form.resetFields();
    props?.onCancel();
  };

  const handleSelectChange = (value: any) => {
    setSelectedChannelId(value);
  };

  const onChangeDate: DatePickerProps["onChange"] = async (
    date: any,
    dateString: any
  ) => {
    setstartDate(dateString);
  };

  const onChange = (value: string) => {
    console.log(`selected ${value}`);
    setShopType(value);
  };

  const dataToDisplays = setting?.agentShop?.data
    ?.at(0)
    ?.assigned?.map((item: any) => {
      return {
        key: item?._id,
        channel: item?.channel?.name,
        shop: item?.shop?.name,
        type: item?.shop?.type,
        status: item?.shop?.status,
      };
    });

  const customPagination = {
    pageSize: 3,
    showTotal: (total: any, range: any) => {
      const limit = 5;
      return `${range[0]}-${range[1]} of ${
        total <= limit ? total : limit
      } items`;
    },
  };

  return (
    <>
      <Form
        {...formItemLayout}
        form={form}
        name="register"
        onFinish={onFinish}
        initialValues={props?.data || {}}
        style={{ minWidth: 600 }}
        scrollToFirstError
      >
        <div className="pl-20 pb-6">
          <Checkbox
            onChange={(e: CheckboxChangeEvent) => {
              console.log(isAsAddShop);
              setIsAsAddShop(e.target.checked);
            }}
          >
            Add As New Shop?
          </Checkbox>
        </div>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item
              name="channel"
              label="Channel"
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Channel is required!",
                  whitespace: true,
                },
              ]}
            >
              <Select onChange={handleSelectChange}>
                {channel?.all?.data?.map((el: any) => (
                  <Option value={el?._id}>{el?.name}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="type" label="Shop Type">
              <Select className="capitalize" onChange={onChange}>
                {[
                  "service-center",
                  "connect-shop",
                  "express-shop",
                  "expo",
                  "refugees-camp",
                ].map((el: any) => (
                  <Option value={el} className="capitalize">
                    {el.split("-").join(" ")}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="shop"
              label="Shop"
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Shop is required!",
                  whitespace: true,
                },
              ]}
            >
              <Select
                showSearch
                allowClear
                onSearch={(value: any) =>
                  searchFuction(
                    value,
                    channel?.allShop?.data,
                    (option: any, val) =>
                      option?.name?.toLowerCase().includes(val.toLowerCase()),
                    setShopFilter
                  )
                }
                className="capitalize"
                filterOption={false}
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toString()
                    .toLowerCase()
                    .localeCompare(
                      (optionB?.label ?? "").toString().toLowerCase()
                    )
                }
              >
                {shopFilter.length > 0
                  ? shopFilter.map((el: any) => {
                      let isIncluded = auth?.user?.regions?.includes(el?._id);
                      if (
                        !isIncluded &&
                        auth?.user?.role === "sales-supervisor"
                      )
                        return null;
                      return (
                        <Option
                          key={el._id}
                          value={el?._id}
                          className="capitalize"
                        >
                          {el?.name + " ~ " + el?.type?.replace(/-/g, " ")}
                        </Option>
                      );
                    })
                  : channel?.allShop?.data?.map((el: any) => {
                      let isIncluded = auth?.user?.regions?.includes(el?._id);
                      if (
                        !isIncluded &&
                        auth?.user?.role === "sales-supervisor"
                      )
                        return null;
                      return (
                        <Option
                          key={el._id}
                          value={el?._id}
                          className="capitalize"
                        >
                          {el?.name + " ~ " + el?.type?.replace(/-/g, " ")}
                        </Option>
                      );
                    })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="startDate"
              label="Start Date"
              rules={[{ type: "date", required: true }]}
            >
              <DatePicker style={{ width: 210 }} onChange={onChangeDate} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item {...tailFormItemLayout}>
              <LoadingButton
                type="submit"
                variant="contained"
                sx={{ minWidth: "60%" }}
                loading={channel.isFetching}
              >
                Save
              </LoadingButton>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <div className="pl-24 pt-6 border-t-2">
        <h1 className="font-semibold text-lg">Recent Shops</h1>
        <Table
          columns={columns}
          dataSource={dataToDisplays}
          pagination={customPagination}
          loading={setting?.isFetching}
        />
      </div>
    </>
  );
};

export default App;
