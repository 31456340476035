import React from "react";
import BillingCard from "./BillingCard";
import InstallmentCard from "./InstallmentCard";
import MascomLogo from "../../../../assets/icons/dashboard/logomascom.png";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { getDefaultCurrencyCode } from "../../../../utils/helper";
import BellingDataTable from "../../../../components/tables/BellingDataTable";
import { Select } from "antd";
import CSVExportButton from "../../../../components/cards/NoSale/CSVExportButton";
import { customerInvoiceReportHeaders } from "../../../../components/csvHeaders/Headers";

const Index = (props: any) => {
  const { account, company, customer, CSV, layout } = useSelector(
    (state: any) => state
  );

  const csvData = CSV?.csvDownloadedData?.data?.map((el: any) => {
    return {
      key: el?._id,
      invoice: el?.transaction,
      installmentAmount: el?.amountToBePaid,
      amountPaid: el?.amountPaid,
      startingDate: el?.account?.startingDate?.substring(0, 10),
      dueDate: el?.account?.endingDate?.substring(0, 10),
      accountId: el?.account?.accountID,
      AccountStatus: el?.account?.status,
      paidOn: el?.payment?.at(0)?.date?.substring(0, 10),
      status: el?.status,
    };
  });

  const handleChange = (value: string) => {
    props?.setStatus(value);
  };

  return (
    <>
      <div className="flex items-end justify-end">
        {layout?.dataToExport !== "transaction" && (
          <CSVExportButton
            csvHeaders={customerInvoiceReportHeaders}
            csvData={csvData}
            filename={`${customer?.selected?.firstName}_invoice's_report.csv`}
            directory={"invoice"}
          />
        )}
      </div>
      <div>
        <div className="block xl:flex gap-5 mt-5">
          <div className="flex flex-col md:flex-row gap-5 mt-5 w-full">
            <div className="bg-white rounded-md border border-[#EBEFF2] pt-2 md:pt-auto md:w-[600px] w-full">
              <div className="border-b px-4">
                <div className="flex flex-wrap items-center justify-between font-poppins font-medium text-base leading-6 ml-2 pt-1 pb-4">
                  <p> Payment Method </p>
                </div>
              </div>
              <div className="p-4">
                <InstallmentCard
                  name={
                    <div className="flex items-center gap-x-5">
                      <img src={MascomLogo} alt="*image" />
                      <p className="text-[#101828] font-medium xpt-1">
                        {/* {customer?.customerInvoice?.data[0]?.payment?.mode
                        ?.split("_")                                            TODO UNCOMENT THIS
                        ?.join(" ")} */}
                        Mascom Money
                      </p>
                    </div>
                  }
                />
              </div>
            </div>
            <div className=" bg-white flex-1 w-full rounded-md mt-0 md:mt-0">
              <div className="flex justify-between border-b px-4 py-4">
                <h1 className="text-[#030229] text-base">
                  Current plan Summary
                </h1>
              </div>
              <div className="grid sm:grid-2 lg:grid-cols-3 gap-5 mt-3 p-4">
                <BillingCard
                  name={"Installment"}
                  secondName={account?.all?.data[0]?.planType}
                />
                <BillingCard
                  name={"Plan duration"}
                  secondName={
                    account?.all?.data[0]?.duration
                      ? `${account?.all?.data[0]?.duration} Months`
                      : ""
                  }
                />
                <BillingCard
                  name={"Billing Type"}
                  secondName={
                    "Subscription"
                    // account?.all?.data[0]?.billingType
                  }
                />
                <BillingCard
                  name={"Starting Date"}
                  secondName={dayjs(account?.all?.data[0]?.startingDate).format(
                    "YYYY-MM-DD"
                  )}
                />
                <BillingCard
                  name={"Ending Date"}
                  secondName={dayjs(account?.all?.data[0]?.endingDate).format(
                    "YYYY-MM-DD"
                  )}
                />
                <BillingCard
                  name={"Total Amount"}
                  secondName={`${getDefaultCurrencyCode(
                    company
                  )} ${customer?.customerMetrics?.totalExpectedAmount?.toLocaleString()}`}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-between mt-8">
          <Select
            defaultValue=""
            style={{ width: 120 }}
            onChange={handleChange}
            options={[
              { value: "", label: "All" },
              { value: "paid", label: "Paid" },
              { value: "pending", label: "Pending" },
              { value: "late", label: "Late" },
            ]}
          />
        </div>
        <div className="h-[40vh] overflow-y-auto my-5">
          <BellingDataTable
            data={props?.data?.customerInvoice}
            limit={props?.limit}
            setPage={props?.setPage}
            setLimit={props?.setLimit}
          />
        </div>
      </div>
    </>
  );
};

export default Index;
