import React, { useEffect, useState } from "react";
import { Table, DatePicker } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useSelector, useDispatch } from "react-redux";
import { ShopReportHeader } from "../../csvHeaders/Headers";
import { VscFilePdf } from "react-icons/vsc";
import FilesCard from "../../cards/NoSale/FilesCard";
import CSVExportButton from "../../cards/NoSale/CSVExportButton";
import ReportFiltering from "../../filters/ReportFiltering";
import { getAllInventoryReportAction } from "../../../store/report/actions";
import { getAllStockReport_CSVAction } from "../../../store/csvDownload/actions";
import ScrollableFrame from "../../layout/ScrollableFrame";
import type { DatePickerProps, TableColumnsType } from "antd";
import dayjs from "dayjs";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

interface DataType {
  [x: string]: any;
  key: React.Key;
  name: string;
  age: number;
  address: string;
}

interface ExpandedDataType {
  imei: string;
}

const StockReportTable = (props: any) => {
  const { auth, CSV, report, layout } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const dateFormat = "YYYY/MM/DD";
  const [selectedShop, setSelectedShop] = useState("");
  const [selectedModel, setSelectedBrand] = useState("");
  const [selectedRagion, setSelectedRagion] = useState("");
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [expandedRowKey, setExpandedRowKey] = React.useState<any>(null);
  const [expandedRowData, setExpandedRowData] = React.useState<any>(null);
  const formattedDate = new Date().toISOString().slice(0, 10);
  const [dateFrom, setDateFrom] = useState<any>(formattedDate);
  const encodedModel = selectedModel && encodeURIComponent(selectedModel);

  const columns: ColumnsType<DataType> = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Shop",
      dataIndex: "shop",
      key: "shop",
    },
    {
      title: "Region",
      dataIndex: "region",
      key: "region",
    },
    {
      title: "Brand",
      dataIndex: "brand",
      key: "brand",
      render: (text: any, _) => <p className="capitalize">{text}</p>,
    },
    {
      title: "Model",
      dataIndex: "model",
      key: "model",
    },
    {
      title: "ROM",
      dataIndex: "capacity",
      key: "capacity",
    },
    {
      title: "RAM",
      dataIndex: "memory",
      key: "memory",
    },
    {
      title: "Opening Stock",
      dataIndex: "openingStock",
      key: "openingStock",
    },
    {
      title: "Closing Stock",
      dataIndex: "closingStock",
      key: "closingStock",
    },
  ];

  const handleRowExpand = (expanded: any, record: any) => {
    if (expanded) {
      setExpandedRowKey(record?.key);
      setExpandedRowData(record);
    } else {
      setExpandedRowKey(null);
      setExpandedRowData(null);
    }
  };

  const expandedRowRender = () => {
    const columns: TableColumnsType<ExpandedDataType> = [
      {
        title: "IMEI",
        dataIndex: "imei",
        key: "imei",
      },
    ];

    const imeiData = expandedRowData?.data?.Imei?.map(
      (el: ExpandedDataType) => {
        return {
          imei: el,
        };
      }
    );
    if (false) {
      return <Table columns={columns} dataSource={imeiData} />;
    }
  };

  useEffect(() => {
    auth?.token &&
      getAllInventoryReportAction(
        auth?.token,
        `?date=${dateFrom}&limit=${limit}&page=${page - 1}${selectedShop ? `&shop=${selectedShop}` : ""
        }${selectedModel ? `&model=${encodedModel}` : ""}`
      )(dispatch);
  }, [
    auth?.token,
    dispatch,
    selectedShop,
    selectedModel,
    selectedRagion,
    limit,
    page,
    dateFrom,
    encodedModel,
  ]);

  useEffect(() => {
    if (layout?.openCSVButton)
      auth?.token &&
        getAllStockReport_CSVAction(
          auth?.token,
          `?date=${dateFrom}&limit=${report?.stockReport?.total}&page=0${selectedShop ? `&shop=${selectedShop}` : ""
          }${selectedModel ? `&model=${encodedModel}` : ""}
          `
        )(dispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layout?.openCSVButton]);

  const dataToDisplay = report?.stockReport?.data?.map((el: any, i: number) => {
    return {
      key: i,
      date: el?.Date?.slice(0, 10),
      closingStock: el?.currentStoCK,
      openingStock: el?.OpeningStock,
      shop: el?.shopName,
      region: el?.region,
      brand: el?.Brand,
      model: el?.Model,
      capacity:
        el?.Storage?.filter((item: any) =>
          ["capacity", "capacity (rom)", "rom"]?.includes(
            item[0]?.toLowerCase()
          )
        )
          ?.map((item: any) => item[1])
          ?.at(0) ?? "N/A",
      memory:
        el?.Storage?.filter((item: any) =>
          ["memory", "memory (ram)", "ram"]?.includes(item[0]?.toLowerCase())
        )
          ?.map((item: any) => item[1])
          ?.at(0) ?? "N/A",
      data: el,
    };
  });

  const csvData = CSV?.csvDownloadedData?.data?.map((el: any) => {
    return {
      date: el?.Date?.slice(0, 10),
      closingStock: el?.currentStoCK,
      openingStock: el?.OpeningStock,
      shop: el?.shopName,
      region: el?.region,
      brand: el?.Brand,
      model: el?.Model,
      capacity:
        el?.Storage?.filter((item: any) =>
          ["capacity", "capacity (rom)", "rom"]?.includes(
            item[0]?.toLowerCase()
          )
        )
          ?.map((item: any) => item[1])
          ?.at(0) ?? "N/A",
      memory:
        el?.Storage?.filter((item: any) =>
          ["memory", "memory (ram)", "ram"]?.includes(item[0]?.toLowerCase())
        )
          ?.map((item: any) => item[1])
          ?.at(0) ?? "N/A",
    };
  });

  useEffect(() => {
    if (selectedShop !== "") {
      setSelectedRagion("");
    }
  }, [selectedShop]);

  const onChange: DatePickerProps["onChange"] = (date, dateString) => {
    setDateFrom(dateString);
  };

  const handlePDFDownload = async () => {
    const doc = new jsPDF();

    // Capture the table as an image
    const input = document.getElementById("report-table");
    if (input) {
      const canvas = await html2canvas(input);
      const imgData = canvas.toDataURL("image/png");

      // Add the image to the PDF
      doc.addImage(imgData, "PNG", 10, 10, 190, 0);
      doc.save("Inventory_Report.pdf");
    }
  };

  return (
  <div className="relative text-black mt-4">
      <ScrollableFrame /* Add props as required */>
        <div className="flex items-center justify-between">
          {/* Other filter components */}

          <div className="flex justify-end gap-4 py-4 w-full">
            <DatePicker onChange={onChange} allowClear={false} />
            <FilesCard
              Icon={VscFilePdf}
              onClick={handlePDFDownload} // Attach PDF download handler
              tailwindTextColor="text-white"
              tailwindBackgroundColor="bg-orange-400"
            />
            <CSVExportButton
              csvHeaders={ShopReportHeader}
              csvData={csvData}
              filename={`Inventory Report.csv`}
            />
          </div>
        </div>

        {/* Table for displaying data */}
        <Table
          id="report-table" // Add an ID for capturing the element
          columns={columns}
          dataSource={dataToDisplay}
          pagination={false}
          expandable={{ expandedRowRender }}
        />
      </ScrollableFrame>
    </div>
  );
};

export default StockReportTable;
