import { Badge, Carousel } from "antd";
import React from "react";
import { getActivePrice } from "../../../../utils/converter";
import { useDispatch, useSelector } from "react-redux";
import { PiImageThin } from "react-icons/pi";
import { getEbmCartItemAction } from "../../store/SalesManagement/actions";
import { getDefaultCurrencyCode } from "../../../../utils/helper";

interface cardPorps {
  bargeoneName?: any;
  bargeTwoName?: any;
  component: any;
  onClick?: any;
}
const TwoBergeSalesCard = (props: any) => {
  const { auth, company } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const styles = {
    border: {
      border: "0px 10px 20px 0px rgba(194, 194, 194, 0.16)",
    },
    shadow: {
      boxShadow: "1px 4px 24px 0px rgba(3, 2, 41, 0.06)",
    },
  };
  const getSingleCart = async (data: any) => {
    auth?.token &&
      (await getEbmCartItemAction(auth?.token, data?._id)(dispatch));
    props.open();
  };
  console.log("props", props?.data);
  return (
    <>
      {props?.data?.length > 0 &&
        props?.data?.map((el: any) => {
          return (
            <div
              className="w-[388px] pl-4 cursor-pointer"
              onClick={() => getSingleCart(el)}
              style={styles.shadow}
            >
              <Badge.Ribbon
                color="#E1F7DE"
                className="h-7 pt-1 w-36 text-center"
                text=<span className="text-[#3AC722] text-sm">
                  {getActivePrice(
                    el?.list?.priceProduct?.prices
                  )?.toLocaleString()}{" "}
                  {getDefaultCurrencyCode(company)}
                </span>
              >
                <Badge.Ribbon
                  color="#EDEDFE"
                  className="h-7 pt-1 mt-10 w-36 text-center"
                  text=<span className="text-[#605BFF] text-[12px]">
                    Ext-Warranty {el?.list?.priceProduct?.extendedWarranty}%
                  </span>
                >
                  <div className="border border-[#03022940] rounded-lg p-3 pl-5 mt-2">
                    <div className="">
                      <div className="bg-[#E5E5E5] w-[100px] h-[100px] p-2 rounded-xl">
                        {el?.list?.priceProduct?.product?.images?.length <=
                        0 ? (
                          <PiImageThin className="w-full h-full" color="gray" />
                        ) : (
                          <Carousel autoplay className="w-full h-full">
                            {el?.list?.priceProduct?.product?.images?.map(
                              (im: any) => (
                                <img
                                  src={im}
                                  className="w-full h-full"
                                  alt=""
                                />
                              )
                            )}
                          </Carousel>
                        )}
                      </div>
                      <div className="mt-3">
                        <h1 className="text-[rgb(3,2,41)] font-semibold text-[16px]">
                          {el?.list?.priceProduct?.product?.model}
                        </h1>
                        <p className="mt-1">
                          <span className="text-[#030229] text-[.8rem]">
                            Type:
                          </span>{" "}
                          <span className="text-[#030229B2] text-[.8rem] pl-1 font-light">
                            {el?.list?.priceProduct?.product?.type}
                          </span>
                        </p>
                        <p>
                          <span className="text-[#030229] text-[.8rem]">
                            Brand:
                          </span>{" "}
                          <span className="text-[#030229B2] text-[.8rem] pl-1 font-light">
                            {el?.list?.priceProduct?.product?.brand}
                          </span>
                        </p>

                        {el?.list?.priceProduct?.product?.specs?.map(
                          (item: any) => {
                            return (
                              <p>
                                <span className="text-[#030229] text-[.8rem]">
                                  {item?.label}:
                                </span>
                                <span className="text-[#030229B2] text-[.8rem] pl-2 font-light">
                                  {item?.value}
                                </span>
                              </p>
                            );
                          }
                        )}
                      </div>
                    </div>
                  </div>
                </Badge.Ribbon>
              </Badge.Ribbon>
            </div>
          );
        })}
    </>
  );
};

export default TwoBergeSalesCard;
