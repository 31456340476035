import { useSelector } from "react-redux";
import { getActivePrice } from "../../utils/converter";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Link } from "react-router-dom";
import { getDefaultCurrencyCode } from "../../utils/helper";
import CustomTable from "../reUsableTable/CustomTable";

const NoSale = (props: any) => {
  const { dashboard, company } = useSelector((state: any) => state);

  const formData = props?.data?.data?.date1noSales?.map((d: any) => {
    return {
      device: d?.priceList?.product?.model || d?.newProduct,
      price: getActivePrice(d?.priceList?.prices)?.toLocaleString(),
      reason: d?.comment,
      shop: d?.shop?.name,
      application: d?.numberOfRequests,
    };
  });
  const nosaleColumn = [
    { header: "Shop", key: "shop" },
    { header: "Device", key: "device" },
    { header: `Price (${getDefaultCurrencyCode(company)})`, key: "price" },
    { header: "No Of Requests", key: "application" },
    { header: "Reason", key: "reason" },
  ];
  return (
    <div className="relative w-full mt-4 2xl:mt-0 x2xl:w-[50%] min-h-full overflow-y-auto bg-white p-2  rounded-md text-[#030229]">
      <div className="flex items-center justify-between p-1">
        <p className="font-normal text-[16px] opacity-90 my-1 pl-3">No Sales</p>
        <Link to="/report/nosales">
          <OpenInNewIcon />
        </Link>
      </div>
      <div className=" text-[#030229]  font-medium text-[14px] pl-4">
        <p>
          {getDefaultCurrencyCode(company)}
          {props?.data?.data?.date1totalAmount?.toLocaleString() || 0}
        </p>
      </div>
      <div className="text-[#030229]  opacity-50 font-normal text-[14px] pl-4">
        {props?.periodValue === "daily" && (
          <p className="font-medium">
            {getDefaultCurrencyCode(company)}{" "}
            {props?.data?.data?.date2totalAmount?.toLocaleString() || 0}
          </p>
        )}
      </div>

      <CustomTable
        columns={nosaleColumn}
        data={formData}
        height={"300px"}
        isLoading={dashboard?.isFetching}
      />
    </div>
  );
};
export default NoSale;
