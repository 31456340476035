import React, { useEffect } from "react";
import DetailsHeaderActionBar from "../../../../components/cards/DetailsPage/DetailsHeaderActionBar";
import { ReactComponent as OverviewIcon } from "../../../../assets/icons/dashboard/Icon.svg";
import { Select } from "antd";

import moment from "moment";
import { useDispatch, useSelector } from "react-redux";

import OverviewChart from "./overviewChart";
import OrdersHistory from "./ordersHistory";
import {
  getAllOrderHistoryAction,
  getAllOverViewAction,
  getAllPaymentHistoryAction,
} from "../../../../store/subscription/overview/actions";
import OverViewCard from "./OverViewCard";
import { FaArrowUpLong } from "react-icons/fa6";
import CustomButton from "../../../../components/buttons/CustomButton";
import { FaLongArrowAltDown } from "react-icons/fa";
import NoSaleSubscriptionTable from "../../../../components/tables/subscription/NoSaleSubscriptionTable";
import PaymentHistorySubscriptionTable from "../../../../components/tables/subscription/PaymenHistorySubscriptionTable";
import DevicesView from "./DevicesView";
import TopSellingPlans from "./TopSellingPlans";
import { COLORS, vauldData } from "../../../../assets/data/data";
import { getDefaultCurrencyCode } from "../../../../utils/helper";
import SubscriptionCustomizedPieChart from "./SubscriptionCustomizedPieChart";
interface secProp {
  name?: any;
  value?: any;
  bgColor?: any;
}
export const DeviceSecurity = ({ name, value, bgColor }: secProp) => {
  return (
    <>
      <div className="flex gap-3">
        <div
          className={`bg-${bgColor} w-[16px] h-[12px] rounded-sm mt-1`}
        ></div>
        <div>
          <p className="text-[#605BFF] text-[14px] font-medium">{name}</p>
          <p className="text-[#030229] text-[12px] pl-1">{value}</p>
        </div>
      </div>
    </>
  );
};
const PageView = () => {
  const { auth, company } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const options = [
    { label: "All Channels", value: "all" },
    { label: "MTN", value: "mtn" },
    { label: "MTN UGANDA", value: "uganda" },
  ];
  const periodOptions = [
    { label: "Monhtly", value: "month" },
    { label: "Weekly", value: "week" },
    { label: "Day", value: "day" },
  ];
  const businessProductptions = [
    { label: "All Bussiness Products", value: "all" },
    { label: "B2B Cooperate", value: "cooperate" },
    { label: "B2C", value: "b2c" },
    { label: "B2B Product", value: "b2bproduct" },
    { label: "B2B2C", value: "b2b2c" },
  ];
  const onChange = (value: string) => {
    console.log(`selected ${value}`);
  };

  const onSearch = (value: string) => {
    console.log("search:", value);
  };
  const formattedDate = new Date().toISOString().slice(0, 10);
  let today = moment();
  let previousDate = today.subtract(1, "days");
  let formattedPreviousDate = previousDate.format("YYYY-MM-DD");

  useEffect(() => {
    if (auth.token) {
      getAllOverViewAction(auth.token, "?")(dispatch);
      getAllOrderHistoryAction(auth.token, "?")(dispatch);
      getAllPaymentHistoryAction(auth.token, "?")(dispatch);
    }
  }, [dispatch, auth.token]);

  return (
    <div className="w-full min-h-screen mt-10 mb-10 ">
      <div className="text-[#0F0F47] mb-8 flex flex-row justify-between">
        <DetailsHeaderActionBar pageName="Subscription" title={"Overview"} />
        <div></div>
      </div>
      <div className={`flex space-x-2 items-center justify-between`}>
        {["partner"]?.includes(auth?.user?.role) ? (
          <h1 className="font-medium"> {`RWANDA's SALES KPI DASHBOARD`}</h1>
        ) : (
          <div className="sm:flex gap-5">
            <Select
              showSearch
              placeholder="Select Channel"
              style={{ width: 200 }}
              defaultValue="all"
              optionFilterProp="label"
              onChange={onChange}
              onSearch={onSearch}
              options={options}
            />
            <Select
              showSearch
              className="mt-3 sm:mt-0"
              placeholder="Select Bussiness product"
              style={{ width: 220 }}
              defaultValue="all"
              onChange={onChange}
              onSearch={onSearch}
              options={businessProductptions}
            />
          </div>
        )}

        <div className="space-x-6 flex">
          <Select
            showSearch
            placeholder="Select Period"
            style={{ width: 160 }}
            defaultValue="month"
            optionFilterProp="label"
            onChange={onChange}
            onSearch={onSearch}
            options={periodOptions}
          />
        </div>
      </div>
      <div className={`w-full xl:flex gap-5 mt-5`}>
        <div className={`w-full xl:w-[60%]`}>
          <OverviewChart />
          <div className={`hidden xl:grid grid-cols-2 gap-5 mt-4`}>
            <OverViewCard
              title={"Total Device Financed"}
              amount={`${getDefaultCurrencyCode(company)} 0`}
              comparedAmount={`${getDefaultCurrencyCode(company)} 0`}
              icon={<OverviewIcon />}
              parcent={<p className="text-[#57C38A]">2.47%</p>}
              textColor="#57C38A"
              icon2={<FaArrowUpLong color="#57C38A" size={15} />}
              compareText={"Increased"}
              description={"All devices sold under device financing"}
            />
            <OverViewCard
              title={"Total Amount Earned"}
              amount={`${getDefaultCurrencyCode(company)} 0`}
              comparedAmount={`${getDefaultCurrencyCode(company)} 0`}
              icon={<OverviewIcon />}
              parcent={<p className="text-[#57C38A]">2.47%</p>}
              textColor={"[#57C38A]"}
              icon2={<FaArrowUpLong color="#57C38A" size={15} />}
              compareText={"Increased"}
              description={
                "Total Amount Earned (New Subscribers) on selected day."
              }
            />
          </div>
        </div>
        <div className={`w-full xl:w-[40%] mt-5 xl:mt-0`}>
          <div
            className={`w-full bg-white rounded-md h-[90vh] 2xl:h-[70vh] overflow-y-auto`}
          >
            <OrdersHistory />
            <div className={` overflow-y-auto px-3 mt-5 h-[25vh]}`}>
              <NoSaleSubscriptionTable />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-5 grid md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-3 gap-3">
        <OverViewCard
          title={"Instalment Amount Paid"}
          amount={`${getDefaultCurrencyCode(company)} 0`}
          comparedAmount={`${getDefaultCurrencyCode(company)} 0`}
          icon={<OverviewIcon />}
          parcent={<p className="text-[#F1595C]">2.47%</p>}
          textColor="[#57C38A]"
          icon2={<FaLongArrowAltDown color="#F1595C" size={15} />}
          compareText={"Decreased"}
          description={
            "Total amount paid by subscriber with loan active account on selected day."
          }
          btn={
            <CustomButton
              btnName={<p className="text-[12px] font-normal"> View Reports</p>}
              textColor={"[#605BFF]"}
              borderColor={"[#605BFF40]"}
            />
          }
        />
        <OverViewCard
          title={"Outstanding Balance"}
          amount={`${getDefaultCurrencyCode(company)} 0`}
          comparedAmount={`${getDefaultCurrencyCode(company)} 0`}
          icon={<OverviewIcon />}
          icon2={<FaArrowUpLong color="#57C38A" size={15} />}
          description={"Across all customers"}
          textColor="[#57C38A]"
          parcent={<p className="text-[#57C38A]">0%</p>}
          compareText={
            "Total amount expected to be paid by subscribers up to the day selected."
          }
          btn={
            <CustomButton
              btnName={<p className="text-[12px] font-normal"> View Reports</p>}
              textColor={"[#605BFF]"}
              borderColor={"[#605BFF40]"}
            />
          }
        />
        <OverViewCard
          title={"Late Payments Amount"}
          amount={`${getDefaultCurrencyCode(company)} 0`}
          comparedAmount={`${getDefaultCurrencyCode(company)} 0`}
          icon={<OverviewIcon />}
          textColor="[#57C38A]"
          parcent={<p className="text-[#57C38A]">2.47%</p>}
          icon2={<FaArrowUpLong color="#57C38A" size={15} />}
          description={"Across all customers"}
          compareText={"Total amount of missed payment."}
          btn={
            <CustomButton
              btnName={<p className="text-[12px] font-normal"> View Reports</p>}
              textColor={"[#605BFF]"}
              borderColor={"[#605BFF40]"}
            />
          }
        />
      </div>
      <div className="w-full xl:flex gap-5 mt-5">
        <div className="w-full xl:w-[70%] p-s5 xl:flex gap-5">
          <div className="w-full xl:w-1/2 bg-white p-5 mt-5 xl:mt-0">
            <DevicesView />
          </div>
          <div className="bg-white rounded-md p-5 w-full xl:w-1/2 mt-5 xl:mt-0">
            <h1 className="text-[#030229] text-lg">Vault Overview</h1>
            <SubscriptionCustomizedPieChart
              data={vauldData}
              colors={COLORS}
              totalLabel={<h1 className="text-[12px]">Total Devices</h1>}
              innerRad={43}
              height={"180px"}
              contentWidth={"w-full"}
              top={"50%"}
              left={"37%"}
              outerRad={60}
              totalValue={0}
              pieHeight={200}
              pieWidth={300}
            />
          </div>
        </div>
        <div className="w-full xl:w-[30%] xl:ml-3 mt-5 xl:mt-0">
          <TopSellingPlans />
        </div>
      </div>

      <div className="flex min-w-full gap-4 mt-6">
        <div className="w-full mt-5 text-black bg-white rounded-md p-5 h-[80vh] overflow-y-auto">
          <div className="flex justify-between py-3">
            <h1 className="text-[#030229] text-xl font-light">
              Recent Payment
            </h1>
            {/* <CustomButton btnName={"See More"} textColor={"[#605BFF]"} /> */}
          </div>
          <PaymentHistorySubscriptionTable />
        </div>
      </div>
    </div>
  );
};

export default PageView;
