import React from "react";
import { Tag, Modal, Badge } from "antd";
import { searchValue } from "../../utils/setColor";
import { useNavigate } from "react-router-dom";
import DeleteModal from "../Modals/DeleteModal";
import { useSelector, useDispatch } from "react-redux";
import { deleteShopAction } from "../../store/channel/actions";
import NewShopForm from "../forms/NewShopForm";
import { Stack, useMediaQuery } from "@mui/material";

const ShopCard = (props: any) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [data, setData] = React.useState<any>("");
  const [visible, setVisible] = React.useState(false);
  const [deleteId, setDeleteId] = React.useState("false");
  const { auth, channel, layout } = useSelector((state: any) => state);
  const smallScreen = useMediaQuery("(max-width:320px)");
  const dispatch = useDispatch();

  const handleDelete = async () => {
    auth?.token &&
      (await deleteShopAction(deleteId, auth?.token, {
        isDeleted: "true",
      })(dispatch));
    setVisible(false);
  };

  const handleCancels = () => {
    setVisible(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const navigate = useNavigate();
  return (
    <>
      <Badge.Ribbon
        text={
          <span style={{ fontSize: "11px" }}>{props?.data?.status}</span>
        }
        color={searchValue(props?.data?.status)}
        style={{ width: '7rem', height: '1.5rem', margin: '0 auto', textAlign: 'center' }}
      >
        <div className={`relative bg-white text-black border border-white hover:border-gray-200 min-h-[126px] md:min-h-[176px] ${smallScreen && "min-h-[176px]"} lg:min-h-[126px] rounded-md`}>
          <div
            className="p-2 2xl:pl-5 space-y-3 h-full"
            onClick={() => navigate(`${props?.data?.id}`)}
          >
            <div>
              <div className="flex justify-between w-full">
                <div>
                  <div>
                    <h1 className="font-semibold text-lg">{props?.data?.name}</h1>
                    <p className="text-xs" style={{ color: "blue" }}>
                      {props?.data?.region} - {props?.data?.address}
                    </p>
                    <div className="my-2">
                      {props?.data?.merchantName && (
                        <p className="text-xs capitalize">
                          <span className="font-medium text-xs">merchant:</span>{" "}
                          {props?.data?.merchantName}
                        </p>
                      )}
                      {props?.data?.msisdn && (
                        <p className="text-xs">
                          <span className="capitalize font-medium text-xs">
                            msisdn:
                          </span>{" "}
                          {props?.data?.msisdn}
                        </p>
                      )}
                      {props?.data?.momocode && (
                        <p className="text-xs">
                          <span className="capitalize font-medium text-xs">
                            myzaka code:
                          </span>{" "}
                          {props?.data?.momocode}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col">
              <div className="flex flex-col">
                {props?.data?.agents?.map((agent: any, _index: number) => (
                  <>
                    <span>{agent.name}</span>
                    <span>{agent.phone}</span>
                  </>
                ))}
              </div>

              <p className="text-xs">{props?.data?.phone}</p>
            </div>
            <div>
              <div className={`absolute bottom-2  ${layout?.isSideNavOpen ? " flex gap-2 md:block lg:flex" : "flex gap-2 "}`}>
                <Stack alignItems={"center"} spacing={2} direction={"row"}>
                  <Tag
                    className="capitalize "
                    color={props?.data?.type === "connect-shop" ? "cyan" : "lime"}
                  >
                    {props?.data?.type?.split("-")?.join(" ")}
                  </Tag>
                  {props?.data?.data?.shopLocation?.district && (
                    <Tag className="capitalize">
                      {props?.data?.data?.shopLocation?.district}
                    </Tag>
                  )}

                </Stack>
                {props.data.data.isCommissionEnabled && (
                  <div className={`border border-[#605BFF80] px-2 py-[2px] rounded-md ${layout?.isSideNavOpen ? "md:mt-2 lg:mt-0 mt-0" : ""} `}>
                    <h1 className="text-[11px] text-[#605BFF80]">
                      Commussioned
                    </h1>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="absolute bottom-2 right-2 text-gray-500 space-x-2">
            {/* <BorderColorIcon onClick={() => updateModelOpen(props?.data)} /> */}
            {/* <DeleteIcon onClick={() => deleteModal(props?.data?.id)} /> */}
          </div>
        </div></Badge.Ribbon >
      <DeleteModal
        visible={visible}
        onOk={handleDelete}
        isLoading={channel.isFetching}
        onCancel={handleCancels}
        itemName="Shop"
      />
      <Modal
        title={"Update Shop"}
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        className="min-w-min"
      >
        <div>
          <NewShopForm data={data} onCancel={handleCancel} />
        </div>
      </Modal>
    </>
  );
};

export default ShopCard;
