import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Stack, Box } from "@mui/material";
import { DatePicker, DatePickerProps, Tag, Table } from "antd";
import DetailsHeaderActionBar from "../../../../components/cards/DetailsPage/DetailsHeaderActionBar";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineUnorderedList } from "react-icons/ai";
import { getAllSubscriptionOrdersAction } from "../../../../store/subscription/order/actions";
import { searchValue } from "../../../../utils/setColor";
import ScrollableFrame from "../../../../components/layout/ScrollableFrame";
import { TbGridDots } from "react-icons/tb";
import { ColumnsType } from "antd/es/table";
// import CsvDownloadButton from "../../../../components/buttons/CsvDownloadButton";
import { VscFilePdf } from "react-icons/vsc";
import FilesCard from "../../../../components/cards/NoSale/FilesCard";
import CSVExportButton from "../../../../components/cards/NoSale/CSVExportButton";
import { handleClearAllState } from "../../../../utils/converter";
import { mySubscritionActions } from "../../../../store/subscription/order";
import { picklistIncludeItem } from "../../../../assets/data/includedItem";
import CurstomeCardSkeleton from "../../../../components/skeleton/CurstomeCardSkeleton";

const PicklistCard = (props: any) => {
  const navigate = useNavigate();
  const { auth, orders } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const dataMapper: any[] = [];
  const [formData, setformData] = useState(dataMapper);
  const [activePickList, setActivePickList] = useState("pickList as grid");
  const [limit, setLimit] = useState(15);
  const [page, setPage] = useState(1);
  const value = orders?.all?.data;

  const onChange: DatePickerProps["onChange"] = (date, dateString) => {
    console.log(dateString);
  };

  useEffect(() => {
    auth?.token &&
      getAllSubscriptionOrdersAction(
        auth?.token,
        `?status=pick-list&status=ready-for-pickup&status=pickup&limit=${limit}&page=${
          page - 1
        }&field=${picklistIncludeItem}`
      )(dispatch);
  }, [auth?.token, dispatch, limit, page]);
  interface DataType {
    key: React.Key;
    name: string;
    age: number;
    address: string;
  }

  const columns: ColumnsType<DataType> = [
    {
      title: "Name",
      dataIndex: "name",
      width: 150,
    },
    {
      title: "SID",
      dataIndex: "customerNumber",
      width: 150,
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
    },
    {
      title: "Date",
      dataIndex: "date",
    },
    {
      title: "Time",
      dataIndex: "time",
    },
    {
      title: "Devices",
      dataIndex: "deviceNumber",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (status) => {
        return (
          <>
            <Tag
              color={searchValue(status)}
              className="text-md rounded-md w-18 py-[2px] px-6"
            >
              {status}
            </Tag>
          </>
        );
      },
    },
  ];
  const csvHeaders = [
    { label: "Name", key: "name" },
    { label: "SID", key: "customerNumber" },
    { label: `Created By `, key: "createdBy" },
    { label: `Date`, key: "date" },
    { label: `Time `, key: "time" },
    { label: `Devices`, key: "deviceNumber" },
    { label: `Status`, key: "status" },
  ];
  useEffect(() => {
    value &&
      value.forEach((el: any) => {
        dataMapper.push({
          dataIndex: el._id,
          name: el?.account?.customerDetails?.name,
          customerNumber: el?.account?.customerDetails?.name,
          deviceNumber: el?.list?.length,
          createdBy: el?.createdBy?.names,
          date: el?.createdAt?.substring(0, 10),
          time: el?.createdAt?.substring(11, 16),
          status: el?.status,
          data: el,
        });
      });

    setformData(dataMapper);
  }, [value]);
  const handleIconClick = (iconType: string) => {
    setActivePickList(iconType);
  };

  const clearActions = [mySubscritionActions?.setSelected];
  useEffect(() => {
    handleClearAllState(dispatch, clearActions);
  }, []);

  return (
    <>
      <div className=" text-[#0F0F47] my-2">
        <DetailsHeaderActionBar pageName={props?.routeName} title="Picklist" />
      </div>
      <Stack spacing={3} className="bg-white rounded h-full">
        <div className="flex flex-wrap px-4 justify-between pt-4">
          <div className="flex flex-row items-center"></div>
          <div className="flex flex-row items-center gap-4">
            <DatePicker onChange={onChange} className={"w-48"} />
            <div className="text-gray-500 flex flex-row px-4">
              <div
                style={{
                  color:
                    activePickList === "pickList as grid" ? "#3b82f6" : "gray",
                  cursor: "pointer",
                }}
              >
                <TbGridDots
                  size={30}
                  onClick={() => handleIconClick("pickList as grid")}
                />
              </div>

              <AiOutlineUnorderedList
                size={30}
                onClick={() => handleIconClick("pickList as table")}
                className={`cursor-pointer ${
                  activePickList === "pickList as table"
                    ? "fill-blue-500"
                    : "fill-gray-500"
                }`}
              />
              {activePickList === "pickList as table" &&
                formData.length > 0 && (
                  <>
                    <div className="w-[3px] h-7 mx-2 bg-gray-300"></div>
                    <div className="flex justify-end gap-4 ">
                      <FilesCard
                        Icon={VscFilePdf}
                        tailwindTextColor="text-white"
                        tailwindBackgroundColor="bg-orange-400"
                        disabled
                      />
                      <CSVExportButton
                        csvHeaders={csvHeaders}
                        csvData={formData}
                        filename={`Pick List.csv`}
                      />
                    </div>
                  </>
                )}
            </div>
          </div>
        </div>
        {activePickList === "pickList as grid" && (
          <ScrollableFrame
            setPage={setPage}
            setLimit={setLimit}
            limit={limit}
            total={orders?.all?.total}
            count={Math.ceil(orders?.all?.total / limit)}
            customSize={300}
          >
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                pl: 2,
                gap: 2,
              }}
            >
              {orders?.isFetching ? (
                <div className="flex flex-wrap">
                  {[1, 2, 3, 4, 1, 2, 3, 4, 1, 2, 3, 4]?.map(() => (
                    <CurstomeCardSkeleton />
                  ))}
                </div>
              ) : (
                <>
                  {orders?.all?.data?.map((item: any) => {
                    const qnty = item?.list?.reduce(
                      (curr: any, item: any) => curr + item?.quantity,
                      0
                    );
                    return (
                      <div
                        key={item._id}
                        className="bg-white rounded-lg min-h-40 min-w-[30%] w-auto p-2 shadow relative"
                      >
                        <Link to={`/${props?.routeName}/picklist/${item._id}`}>
                          <div className="flex flex-row justify-between">
                            <div>
                              <h2 className="text-md text-[#0F0F47] font-poppins ">
                                {item?.account?.customerDetails?.name}
                              </h2>
                              <p className="text-[#605BFF] text-sm">
                                {item?.SOID}
                              </p>
                            </div>
                            <div>
                              <Tag
                                color={searchValue(item?.status)}
                                className="text-md rounded-md w-18 py-[2px] px-6 capitalize"
                              >
                                {item?.status?.split("-").join(" ")}
                              </Tag>
                            </div>
                          </div>
                          <p className="text-black pt-8">
                            Created By: {item?.createdBy?.names}
                          </p>
                          <div className=" mt-2 flex items-center justify-between">
                            <div className="flex flex-col">
                              <p className="text-sm text-[#0F0F47] font-poppins mb-2">
                                date: {item?.createdAt?.substring(0, 10)}
                              </p>
                              <p className="text-sm text-[#0F0F47] font-poppins mb-2">
                                Time: {item?.createdAt?.substring(11, 16)}
                              </p>
                            </div>
                            <div>
                              <p className="text-[#0F0F47] pl-3 pb-2 text-sm">
                                {`${item?.list?.length} Models / ${qnty} Qnty`}
                              </p>
                            </div>
                          </div>
                        </Link>
                      </div>
                    );
                  })}
                </>
              )}
            </Box>
          </ScrollableFrame>
        )}
        {activePickList === "pickList as table" && (
          <ScrollableFrame
            setPage={setPage}
            setLimit={setLimit}
            limit={limit}
            total={orders?.all?.total}
            count={Math.ceil(orders?.all?.total / limit)}
            customSize={300}
          >
            <Table
              onRow={(record: any, _rowIndex: any) => {
                return {
                  onClick: () => {
                    navigate(`/subscription/picklist/${record?._id}`);
                  },
                };
              }}
              columns={columns}
              dataSource={formData}
              pagination={false}
              loading={orders?.isFetching}
            />
          </ScrollableFrame>
        )}
      </Stack>
    </>
  );
};
export default PicklistCard;
