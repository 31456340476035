import { myReportActions } from ".";
import {
  getAllInventoryReportService,
  getAllSaveTheChildrenService,
  getAllTransferReportService,
  getTransactionByCustomerService,
} from "./services";

export const getAllSaveTheChildrenAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myReportActions.setIsFetching(true));
      const res = await getAllSaveTheChildrenService(token, query);
      if (res?.status === 200) {
        dispatch(myReportActions.setSaveTheChildren(res));
        dispatch(myReportActions.setIsFetching(false));
      }
      dispatch(myReportActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};



export const getAllTransferReportAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myReportActions.setIsFetching(true));
      const res = await getAllTransferReportService(token, query);
      if (res?.status === 200) {
        dispatch(myReportActions.setTranserReport(res));
        dispatch(myReportActions.setIsFetching(false));
      }
      dispatch(myReportActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getAllInventoryReportAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myReportActions.setIsFetching(true));
      const res = await getAllInventoryReportService(token, query);
      if (res?.status === 200) {
        dispatch(myReportActions.setStockReport(res));
        dispatch(myReportActions.setIsFetching(false));
      }
      dispatch(myReportActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const getTransactionByCustomersReportAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myReportActions.setIsFetching(true));
      const res = await getTransactionByCustomerService(token, query);
      if (res?.status === 200) {
        dispatch(myReportActions.setCustomerTransaction(res));
        dispatch(myReportActions.setIsFetching(false));
      }
      dispatch(myReportActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
