import React, { useEffect } from "react";
import { Table } from "antd";
import { getAllCustomerAction } from "../../store/action";
import { useDispatch, useSelector } from "react-redux";

const ManageCustomerTable = (props: any) => {
  const { auth, ebmGeneral } = useSelector((state: any) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    auth?.token && getAllCustomerAction(auth?.token, "?")(dispatch);
  }, [dispatch, auth]);

  const columns = [
    {
      title: "Names",
      dataIndex: "names",
    },
    {
      title: "Phone",
      dataIndex: "phone",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "NID / TIN",
      dataIndex: "id",
    },
    {
      title: "Type",
      dataIndex: "type",
    },
    {
      title: "Invoices",
      dataIndex: "invoices",
    },
    {
      title: "Actions",
      dataIndex: "data",
      render: (record: any) => {
        return (
          <>
            <button
              className="text-[#605BFF] border border-[#605BFF] px-3 py-1 rounded-md"
              onClick={() => props?.open(record)}
            >
              View
            </button>
          </>
        );
      },
    },
  ];
  const data = ebmGeneral?.AllCustomer?.map((d: any, _index: any) => {
    return {
      key: d?._id,
      names: d?.customer?.name,
      phone: d?.customer?.phone,
      email: d?.customer?.email,
      id: d?.customer?.nid || d?.customer?.tinNumber,
      type: d?.customer?.type,
      invoices: d?.invoices?.length,
      data: d
    };
  });
  return (
    <>
      <Table columns={columns} dataSource={data} size="middle" />
    </>
  );
};

export default ManageCustomerTable;
