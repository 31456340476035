import React from "react";
import { Table, Tag } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useSelector, useDispatch } from "react-redux";
import { getAllShopsAgentAction } from "../../../store/pos/actions";
import { useParams } from "react-router-dom";
import { searchValue } from "../../../utils/setColor";
import { getDefaultCurrencyCode } from "../../../utils/helper";

interface DataType {
  [x: string]: any;
  key: React.Key;
  name: string;
  age: number;
  address: string;
  checked: boolean;
}

const DeviceCommissionTable = (props: any) => {
  const dispatch = useDispatch();
  const { pos, auth, channel, company } = useSelector((state: any) => state);
  const { shopId } = useParams();
  const columns: ColumnsType<DataType> = [
    {
      title: "Type",
      dataIndex: "type",
      render: (text, record) => <p className="capitalize">{text}</p>,
    },
    {
      title: "Brand",
      dataIndex: "brand",
      render: (text, record) => <p className="capitalize">{text}</p>,
    },
    {
      title: "Model",
      dataIndex: "model",
      render: (text, record) => <p className="capitalize">{text}</p>,
    },
    {
      title: "Descriptions",
      dataIndex: "specification",
      key: "specification",
    },
    {
      title: "Shop Price (BWP)",
      dataIndex: "price",
      render: (text, record) => <p>{text}</p>,
    },
    {
      title: `Commission Price (${getDefaultCurrencyCode(company)})`,
      dataIndex: "commissionPrice",
      render: (text, record) => <p>{text}</p>,
    },
    {
      title: "Commission applied to All Shops",
      dataIndex: "isAppliedToAll",
      render: (text, record) => <p>{text}</p>,
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text, record) => {
        return <Tag color={searchValue(text)}>{text}</Tag>;
      },
    },
  ];

  React.useEffect(() => {
    auth?.token &&
      getAllShopsAgentAction(auth?.token, shopId as string)(dispatch);
  }, [auth?.token, dispatch]);
  const data = channel?.deviceWithCommission?.data?.map((el: any) => {
    return {
      id: el._id,
      data: el,
      type: el?.product?.product?.type,
      brand: el?.product?.product?.brand,
      model: el?.product?.product?.model,
      specification: el?.product?.specification?.map(
        (item: any) => (
          // item[1] && (
          <div className="flex items-center gap-2">
            <span className="font-bold text-xs">{item[0]}: </span>
            <span className=" text-sm">{item[1]}</span>
          </div>
        )
        // )
      ),
      price: el?.product?.prices
        ?.find((price: any) => price.isActive)
        ?.value?.toLocaleString(),
      commissionPrice: el?.price,
      isAppliedToAll: el?.isAppliedToAll ? "Yes" : "No",
      status: el?.isActive === true ? "Active" : "Inactive",
    };
  });
  const [pagination, setPagination] = React.useState({
    pageSize: 15,
    current: 1,
    total: data?.length,
    showSizeChanger: true,
  });

  const handleTableChange = (pagination: any) => {
    setPagination(pagination);
  };

  return (
    <div className="relative">
      <Table
        columns={columns}
        dataSource={data}
        loading={channel?.isFetching}
        pagination={pagination}
        onChange={handleTableChange}
      />
    </div>
  );
};

export default DeviceCommissionTable;
