import { notification } from "antd";
import { myCompanyActions } from ".";
import {
  campanyResizedLogoService,
  createBusinesService,
  createCompanyService,
  createTrackingService,
  deleteBusinesService,
  getAllBusinesService,
  getAllCompanyService,
  getOneBusinesService,
  getOneCompanyService,
  updateBusinesService,
  updateCompanyService,
  getTrackingTypeService,
  getOnetrackingTypeService,
  updateTrackingTypeService,
  addBussinessProductToChannelService,
  removerBussinessProductToChannelService,
  CustomFormService,
  updateCustomFormService,
  getCustomFormService,
  getOneCustomFormService,
} from "./services";

export const createCompanyAction = (token: string, data: {}) => {
  return async (dispatch: any) => {
    try {
      dispatch(myCompanyActions.setIsFetching(true));
      const res = await createCompanyService(data, token);
      if (res?.status === 201) {
        dispatch(myCompanyActions.setNew(res));
        // dispatch(myCompanyActions.setAll(resAll));
        dispatch(myCompanyActions.setIsFetching(false));
        notification.success({ message: "Created Succesfully" });
      }
      dispatch(myCompanyActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getAllCompanyAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myCompanyActions.setIsFetching(true));
      const res = await getAllCompanyService(token, query);
      if (res?.status === 200) {
        dispatch(myCompanyActions.setAll(res));
        dispatch(myCompanyActions.setIsFetching(false));
      }
      dispatch(myCompanyActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getOneCompanyAction = (id: string, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myCompanyActions.setIsFetching(true));
      const res = await getOneCompanyService(id, token);
      if (res?.status === 200) {
        dispatch(myCompanyActions.setSelected(res?.data));
        dispatch(myCompanyActions.setIsFetching(false));
      }
      dispatch(myCompanyActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const updateCompanyActions = (
  itemId: string,
  data: any,
  token: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myCompanyActions.setIsFetching(true));
      const res = await updateCompanyService(itemId, data, token);
      // console.log("<<<<updated data>>>>", res)
      if (res?.status === 200) {
        const resAll = await getAllCompanyService(token, "");
        dispatch(myCompanyActions.setSelected(res?.data));
        dispatch(myCompanyActions.setAll(resAll));
        dispatch(myCompanyActions.setIsFetching(false));
        dispatch(myCompanyActions.setIsFetching(false));
        notification.success({ message: "Updated Succesfully" });
      }
      dispatch(myCompanyActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const companySizedImageAction = (token: string, data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myCompanyActions.setIsFetching(true));
      const res = await campanyResizedLogoService(data, token);
      if (res?.status === 200) {
        dispatch(myCompanyActions.setSizedLog(res));
        dispatch(myCompanyActions.setIsFetching(false));
      }
      dispatch(myCompanyActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const CreateBusinessAction = (token: string, data: {}) => {
  return async (dispatch: any) => {
    try {
      dispatch(myCompanyActions.setIsFetching(true));
      const res = await createBusinesService(data, token);
      const resAllBusiness = await getAllBusinesService(token, "");
      if (res?.status === 201) {
        dispatch(myCompanyActions.setNewBusiness(res));
        dispatch(myCompanyActions.setAllBusiness(resAllBusiness));
        dispatch(myCompanyActions.setIsFetching(false));
        notification.success({ message: "Created Succesfully" });
      }
      dispatch(myCompanyActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const getAllBusinessAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myCompanyActions.setIsFetching(true));
      const res = await getAllBusinesService(token, query);
      if (res?.status === 200) {
        dispatch(myCompanyActions.setAllBusiness(res));
        dispatch(myCompanyActions.setIsFetching(false));
      }
      dispatch(myCompanyActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getOneBusinessAction = (itemId: string, token: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myCompanyActions.setIsGettingSelectedOne(true));
      const res = await getOneBusinesService(itemId, token);
      if (res?.status === 200) {
        dispatch(myCompanyActions.setSelectedBusinessProduct(res?.data));
        dispatch(myCompanyActions.setIsGettingSelectedOne(false));
      }
      dispatch(myCompanyActions.setIsGettingSelectedOne(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const updateBusinesAction = (
  itemId: string,
  data: any,
  token: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myCompanyActions.setIsUpdatingFetching(true));
      const res = await updateBusinesService(itemId, data, token);
      if (res?.status === 200) {
        const resOne = await getOneBusinesService(itemId, token);
        dispatch(myCompanyActions.setSelectedBusinessProduct(resOne?.data));
        dispatch(myCompanyActions.setUpdated(res));
        dispatch(myCompanyActions.setIsUpdatingFetching(false));
        notification.success({ message: "updated Succesfully" });
        return true;
      }
      dispatch(myCompanyActions.setIsUpdatingFetching(false));
      return false;
    } catch (err) {
      console.log(err);
    }
  };
};
export const addBussinessProductToChannelAction = (
  itemId: string,
  data: any,
  token: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myCompanyActions.setIsUpdatingFetching(true));
      const res = await addBussinessProductToChannelService(data, token);
      if (res?.status === 200) {
        const resOne = await getOneBusinesService(itemId, token);
        dispatch(myCompanyActions.setSelectedBusinessProduct(resOne?.data));
        dispatch(myCompanyActions.setUpdated(res));
        dispatch(myCompanyActions.setIsUpdatingFetching(false));
        return true;
      }
      dispatch(myCompanyActions.setIsUpdatingFetching(false));
      return false;
    } catch (err) {
      console.log(err);
    }
  };
};
export const removeBussinessProductToChannelAction = (
  itemId: string,
  data: any,
  token: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myCompanyActions.setIsUpdatingFetching(true));
      const res = await removerBussinessProductToChannelService(data, token);
      if (res?.status === 200) {
        const resOne = await getOneBusinesService(itemId, token);
        dispatch(myCompanyActions.setSelectedBusinessProduct(resOne?.data));
        dispatch(myCompanyActions.setUpdated(res));
        dispatch(myCompanyActions.setIsUpdatingFetching(false));
        return true;
      }
      dispatch(myCompanyActions.setIsUpdatingFetching(false));
      return false;
    } catch (err) {
      console.log(err);
    }
  };
};
export const deleteBusinessActions = (
  itemId: string,
  data: any,
  token: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myCompanyActions.setIsFetching(true));
      const res = await deleteBusinesService(itemId, data, token);
      if (res?.status === 200) {
        const resAllBusiness = await getAllBusinesService(token, "");
        dispatch(myCompanyActions.setAllBusiness(resAllBusiness));
        dispatch(myCompanyActions.setIsFetching(false));
        notification.success({ message: "deleted Succesfully" });
        return true;
      }
      dispatch(myCompanyActions.setIsFetching(false));
      return false;
    } catch (err) {
      console.log(err);
    }
  };
};

export const createTrackingAction = (token: string, data: {}) => {
  return async (dispatch: any) => {
    try {
      dispatch(myCompanyActions.setIsFetching(true));
      const res = await createTrackingService(data, token);
      if (res?.status === 201) {
        dispatch(myCompanyActions.setTrackingType(res));
        // dispatch(myCompanyActions.setAll(resAll));
        dispatch(myCompanyActions.setIsFetching(false));
        notification.success({ message: "Created Succesfully" });
      }
      dispatch(myCompanyActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getAllTrackingTypeAction = (token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myCompanyActions.setIsFetching(true));
      const res = await getTrackingTypeService(token);
      if (res?.status === 200) {
        dispatch(myCompanyActions.setTrackingType(res));
        dispatch(myCompanyActions.setIsFetching(false));
      }
      dispatch(myCompanyActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getOnetrackingTypeAction = (id: string, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myCompanyActions.setIsFetching(true));
      const res = await getOnetrackingTypeService(id, token);
      if (res?.status === 200) {
        dispatch(myCompanyActions.setTrackingType(res?.data));
        dispatch(myCompanyActions.setIsFetching(false));
      }
      dispatch(myCompanyActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const updateTrackingTypeServiceActions = (
  itemId: string,
  data: any,
  token: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myCompanyActions.setIsFetching(true));
      const res = await updateTrackingTypeService(itemId, data, token);
      if (res?.status === 200) {
        const resAll = await getTrackingTypeService(token);
        dispatch(myCompanyActions.setSelected(res?.data));
        dispatch(myCompanyActions.setTrackingType(resAll));
        dispatch(myCompanyActions.setIsFetching(false));
        notification.success({ message: "Updated Succesfully" });
      }
      dispatch(myCompanyActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const createCustomFormAction = (token: string, data: {}) => {
  return async (dispatch: any) => {
    try {
      dispatch(myCompanyActions.setCustomFormIsFetching(true));
      const res = await CustomFormService(data, token);
      if (res?.status === 201) {
        dispatch(myCompanyActions.setIsCustomFormCreated(true));
        dispatch(myCompanyActions.setNewCustomForm(res?.data));
        dispatch(myCompanyActions.setCustomFormIsFetching(false));
        notification.success({ message: "Added Succesfully" });
        return { type: true, data: res?.data };
      }
      dispatch(myCompanyActions.setCustomFormIsFetching(false));
      return { type: false, data: null };
    } catch (err) {
      console.log(err);
    }
  };
};

export const updateCustomFormActions = (
  itemId: string,
  data: any,
  token: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myCompanyActions.setCustomFormIsFetching(true));
      const res = await updateCustomFormService(itemId, data, token);
      if (res?.status === 200) {
        const resOne = await getOneCustomFormService(itemId, token);
        dispatch(myCompanyActions.setSelectedCustomForm(resOne?.data));
        dispatch(myCompanyActions.setCustomFormIsFetching(false));
        notification.success({ message: "Added Succesfully" });
      }
      dispatch(myCompanyActions.setCustomFormIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getCustomFormAction = (query: string, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myCompanyActions.setCustomFormIsFetching(true));
      const res = await getCustomFormService(query, token);
      if (res?.status === 200) {
        dispatch(myCompanyActions.setCustomForm(res?.data));
        dispatch(myCompanyActions.setCustomFormIsFetching(false));
      }
      dispatch(myCompanyActions.setCustomFormIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getOneCustomFormAction = (id: string, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myCompanyActions.setCustomFormIsFetching(true));
      const res = await getOneCustomFormService(id, token);
      if (res?.status === 200) {
        dispatch(myCompanyActions.setSelectedCustomForm(res?.data));
        dispatch(myCompanyActions.setCustomFormIsFetching(false));
      }
      dispatch(myCompanyActions.setCustomFormIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
