import React from "react";

const ResetPasswordSkeleton = () => {
  return (
    <div role="status" className="w-[500px] animate-pulse rounded p-4 md:p-6">
      <div className="flex flex-col items-center justify-center">
        <div className="mb-2 h-2 w-48 rounded bg-gray-400"></div>
        <div className="mb-1 h-1 w-48 rounded bg-gray-400"></div>
      </div>
      <div className="mt-5 rounded-md p-4 shadow md:p-6">
        <div className="mb-2 h-2.5 w-32 rounded bg-gray-400"></div>
        <div className="mb-6 h-10 rounded bg-gray-400"></div>
        <div className="mb-2 h-2.5 w-32 rounded bg-gray-400"></div>
        <div className="mb-2.5 h-10 rounded bg-gray-400"></div>
        <div className="mt-8 h-1 rounded bg-gray-400"></div>
        <div className="mt-1 h-1 rounded bg-gray-400"></div>
        <div className="mt-6 h-10 rounded bg-gray-400"></div>
      </div>
    </div>
  );
};

export default ResetPasswordSkeleton;
