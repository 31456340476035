import React, { useEffect, useState } from "react";
import {
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Select,
  Tag,
} from "antd";
import CustomButton from "../buttons/CustomButton";
import { ReactComponent as SaveIcon } from "../../assets/images/icons/save.svg";
import { useDispatch, useSelector } from "react-redux";
import { getEbmSelectOptionAction } from "../../store/action";
import { addEbmSalesAction } from "../../store/SalesManagement/actions";
import { LoadingOutlined, SyncOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { getNameByCode } from "../../utils/ConvertCodeToName";
import EbmButton from "../EbmButton";
import { ReactComponent as ImportIcons } from "../../assets/images/icons/vscode-icons_file-type-excel2.svg";
import SettingButton from "../../../../pages/dashboard/setting/SettingButton";
import { BsPrinter } from "react-icons/bs";
import GeneralModal from "../modals/GeneralModal";
import CheckInformation from "../CheckInformation";
dayjs.extend(customParseFormat);

const dateFormat = "YYYY-MM-DD";
const ModifyRegistrationForm = (props: any) => {
  const { ebmSales, auth, ebmGeneral } = useSelector((state: any) => state);
  const [form] = Form.useForm();
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [isRollBackModalOpen, setIsRollBackModalOpen] = useState(false);
  const [isRefundModalOpen, setIsRefundModalOpen] = useState(false);
  const [isCheckModalOpen, setIsCheckModalOpen] = useState(false);
  const [showCheck, setShowCheck] = useState(false);
  const showModal = () => {
    setIsCancelModalOpen(true);
    props?.setIsModalOpen(false);
  };
  const showRollBackModal = async () => {
    setIsRollBackModalOpen(true);
    props?.setIsModalOpen(false);
  };
  const addSalesToEbm = async () => {
    auth?.token &&
      (await addEbmSalesAction(auth?.token, {
        salesStatusCode: "03",
        cartId: props?.data?._id,
      })(dispatch));
  };
  const modifySalesToEbm = async () => {
    auth?.token &&
      (await addEbmSalesAction(auth?.token, {
        salesStatusCode: "02",
        cartId: props?.data?._id,
      })(dispatch));
  };
  const handleOk = async () => {
    await addSalesToEbm();
    setIsCancelModalOpen(false);
  };

  const handleRollBackCancel = () => {
    setIsRollBackModalOpen(false);
    props?.setIsModalOpen(true);
  };
  const handleRollBackOk = async () => {
    await modifySalesToEbm();
    setIsRollBackModalOpen(false);
  };

  const handleCancel = () => {
    setIsCancelModalOpen(false);
    props?.setIsModalOpen(true);
  };
  const currentStatus =
    ebmGeneral?.selectOption &&
    getNameByCode(
      ebmGeneral?.selectOption["Sale Status"],
      props?.data?.ebmSaleStatus
    );
  const statusValue = ebmGeneral?.selectOption["Sale Status"]?.map(
    (i: any) => i?.value
  );
  console.log(statusValue, "currentStatuscurrentStatus");
  const showRefundModal = () => {
    setIsRefundModalOpen(true);
    // props?.setIsModalOpen(false)
  };

  const handleRefundOk = () => {
    setIsRefundModalOpen(false);
    setIsCheckModalOpen(true);
  };

  const handleRefundCancel = () => {
    setIsRefundModalOpen(false);
    props?.setIsModalOpen(false);
  };
  const showCheckModal = () => {
    setIsCheckModalOpen(true);
    // props?.setIsModalOpen(false)
  };

  const handleCheckOk = () => {
    setIsCheckModalOpen(false);
  };

  const handleCheckCancel = () => {
    setIsCheckModalOpen(false);
    setShowCheck(false);
  };
  const dispatch = useDispatch();
  useEffect(() => {
    getEbmSelectOptionAction(auth?.token, "")(dispatch);
  }, []);
  const TaxOptions =
    ebmGeneral?.selectOption &&
    ebmGeneral?.selectOption["Taxation Type"]?.map((el: any) => ({
      value: el?.value,
      label: el?.label,
    }));
  useEffect(() => {
    form.setFieldsValue({
      nid: ebmSales?.ebmCartItem?.data?.cart?.customer?.nid,
      invoiceId: props?.data?.cartId,
      customerId: props?.data?.customer?.nid,
      customerName: props?.data?.customer?.name,
      amount: props?.data?.list?.reduce(
        (accumulator: any, item: any) => accumulator + item?.payment?.amount,
        0
      ),
      itemTyCd:
        ebmGeneral?.selectOption &&
        getNameByCode(
          ebmGeneral?.selectOption["Item Type"],
          props?.data?.list?.map(
            (d: any) => d?.warehouseProduct?.product?.ebm?.itemTyCd[0]
          )
        ),
      itemClsCd:
        ebmGeneral?.selectOption &&
        getNameByCode(
          ebmGeneral?.selectOption?.itemClassList,
          ebmSales?.ebmCartItem?.data?.warehouseProduct?.product?.ebm.itemClsCd
        ),
      qtyUnitCode:
        ebmGeneral?.selectOption &&
        getNameByCode(
          ebmGeneral?.selectOption["Quantity Unit"],
          ebmSales?.ebmCartItem?.data?.warehouseProduct?.product?.ebm
            .qtyUnitCode
        ),
    });
  }, [ebmSales?.ebmCartItem?.data]);

  return (
    <Form
      initialValues={ebmSales?.ebmCartItem?.data}
      form={form}
      // onFinish={addSalesToEbm}
      layout="horizontal"
      style={{ width: "100%" }}
    >
      <GeneralModal
        openModal={isRefundModalOpen}
        closeModal={handleRefundOk}
        title={
          <h1 className="text-[#030229] font-semibold text-lg text-center py-2">
            Confirm
          </h1>
        }
        component={
          <div className="px-4 py-4 w-full overflow-y-auto">
            <h1 className="text-center">
              You want to proccess the invoice Refunded?
            </h1>
            <div className="flex gap-5 mt-3 w-1/2 m-auto">
              <EbmButton
                textColor="white"
                btnName={"Yes"}
                btnBgColor="[#030229]"
                onClick={handleRefundOk}
              />
              <EbmButton
                btnName={"No"}
                btnBgColor="gray-400"
                textColor="white"
                onClick={handleRefundCancel}
              />
            </div>
          </div>
        }
        width={600}
      />
      <GeneralModal
        openModal={isCheckModalOpen}
        closeModal={handleCheckOk}
        title={
          <h1 className="text-[#030229] font-semibold text-lg text-center py-2">
            Check Information
          </h1>
        }
        component={
          <div>
            <CheckInformation
              onClose={handleCheckCancel}
              setShowCheck={setShowCheck}
              showCheck={showCheck}
              data={props}
            />
          </div>
        }
        width={800}
      />
      <GeneralModal
        openModal={isCancelModalOpen}
        closeModal={handleOk}
        title={
          <h1 className="text-[#030229] font-semibold text-lg text-center py-2">
            Confirm
          </h1>
        }
        component={
          <div className="px-4 py-4 w-full overflow-y-auto">
            <h1 className="text-center">
              Do you want to process the invoice Cancel Requested
            </h1>
            <div className="flex gap-5 mt-3 w-1/2 m-auto">
              <EbmButton
                textColor="white"
                btnName={
                  ebmSales?.isFetching ? (
                    <Tag
                      icon={<SyncOutlined spin />}
                      className="w-1/2 m-auto mt-5"
                    ></Tag>
                  ) : (
                    "Yes"
                  )
                }
                btnBgColor="gray-400"
                onClick={handleOk}
              />
              <EbmButton
                btnName={"No"}
                btnBgColor="gray-400"
                textColor="white"
                onClick={handleCancel}
              />
            </div>
          </div>
        }
        width={600}
      />
      <GeneralModal
        openModal={isRollBackModalOpen}
        closeModal={handleRollBackOk}
        title={
          <h1 className="text-[#030229] font-semibold text-lg text-center py-2">
            Confirm
          </h1>
        }
        component={
          <div className="px-4 py-4 w-full overflow-y-auto">
            <h1 className="text-center">
              Do you want to process the invoice RollBack
            </h1>
            <div className="flex gap-5 mt-3 w-1/2 m-auto">
              <EbmButton
                textColor="white"
                btnName={
                  ebmSales?.isFetching ? (
                    <Tag
                      icon={<SyncOutlined spin />}
                      className="w-1/2 m-auto mt-5"
                    ></Tag>
                  ) : (
                    "Yes"
                  )
                }
                btnBgColor="gray-400"
                onClick={handleRollBackOk}
              />
              <EbmButton
                btnName={"No"}
                btnBgColor="gray-400"
                textColor="white"
                onClick={handleRollBackCancel}
              />
            </div>
          </div>
        }
        width={600}
      />
      <div className="flex justify-between ">
        <div>
          <div className="bg-[#F4F6FA] flex justify-center items-center gap-2 px-[.25rem] py-[.25rem] rounded-md">
            {" "}
            <h1 className="text-[.9rem] text-black pl-2">Current Status:</h1>
            <CustomButton
              title={currentStatus}
              textColor="white"
              bgColor="green-500"
              textSize="sm"
            />
          </div>
        </div>
        <div className="flex gap-5">
          <EbmButton
            textColor="black"
            btnName={"Export"}
            icon={<ImportIcons />}
            btnBgColor="gray-100"
          />
          <SettingButton
            btnName={"Print Receipt"}
            icon={<BsPrinter color="white" size={20} />}
            btnBgColor="[#605BFF]"
            textColor="white"
          />
        </div>
      </div>
      <div className="flex gap-5 mt-3">
        {props?.data?.ebmSaleStatus === "02" ? (
          <EbmButton
            btnName={"Cancel Request"}
            btnBgColor={"green-500"}
            textColor="white"
            onClick={showModal}
          />
        ) : props?.data?.ebmSaleStatus === "03" ? (
          <>
            <EbmButton
              textColor="white"
              btnName={"Rollback"}
              btnBgColor={"green-500"}
              onClick={showRollBackModal}
            />
            <EbmButton
              btnName={"Refund"}
              btnBgColor={"green-500"}
              textColor="white"
              onClick={showCheckModal}
            />
          </>
        ) : // currentStatus === "Cancel Requested" ? () :
        null}

        {/* <EbmButton
          btnName={"Cancel"}
          btnBgColor={"gray-400"}
          textColor="white"
          onClick={() => props?.setIsModalOpen(false)}
        />
        */}
      </div>
      <div className="flex gap-5 w-full mt-5">
        <div className="w-1/2">
          <Form.Item label="Invoice ID" name="invoiceId">
            <Input className="h-10 w-[25rem] ml-[2.65rem]" />
          </Form.Item>
          <Form.Item name="customerId" label="Customer ID">
            <Input className="h-10 ml-6 w-[25rem]" />
          </Form.Item>

          <Form.Item label="Customer Name" name="customerName">
            <Input className="h-10 w-[25rem]" />
          </Form.Item>
          <Form.Item label="Sale Date" className="w-full">
            <DatePicker
              className="w-[25rem] ml-[2.6rem] h-10"
              defaultValue={dayjs(
                props?.data?.paidOn?.substring(0, 10),
                dateFormat
              )}
            />
          </Form.Item>
          {/* <Form.Item label="Release Date"className='w-full'>
        <DatePicker className='w-full h-10'/>
      </Form.Item> */}
          <Form.Item label="Total Amount" name="amount">
            <Input className="h-10 w-[25rem] ml-4" />
          </Form.Item>

          <Form.Item label="Remark" name={"sale"}>
            <Input className="h-10 w-[25rem] ml-[3.1rem]" />
          </Form.Item>
        </div>
        <div className="w-1/2">
          <Form.Item label="VAT" name={"sale"}>
            <Input className="h-10 w-[25rem] ml-[4.35rem]" />
          </Form.Item>
          <Form.Item label="Purchase Code" name={"sale"}>
            <Input className="h-10 w-[25rem]" />
          </Form.Item>
          <Form.Item label="Item Code" name="itemTyCd">
            <Input className="h-10 w-[25rem] ml-7" />
          </Form.Item>
          <Form.Item label="Class Code" name="itemClsCd">
            <Input className="h-10 w-[25rem] ml-[1.46rem]" />
          </Form.Item>
          <div className="w-full flex gap-2">
            <div className="w-1/2">
              <Form.Item label="Unit Price" name={"sale"}>
                <Input className="h-10 w-[10rem] ml-6" />
              </Form.Item>
              <Form.Item>
                <div className="flex gap-[2.2rem]">
                  <span className="text-[12px] text-[#030229] pt-2">
                    Tax Type:
                  </span>
                  <Select
                    showSearch
                    style={{ width: 162, height: 40 }}
                    placeholder="Tax Type"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toString()
                        .includes(input.toString())
                    }
                    // filterSort={(optionA, optionB) =>
                    //   (optionA?.label ?? "")
                    //     .toLowerCase()
                    //     .localeCompare((optionB?.label ?? "").toLowerCase())
                    // }
                    options={TaxOptions}
                  />
                </div>
              </Form.Item>
              <Form.Item label="D/C Rate (%)" name={"sale"} className="mt-3">
                <Input className="h-10 w-[10rem]" />
              </Form.Item>
              <Form.Item label="sales Price" name={"sale"}>
                <Input className="h-10 w-[10rem] ml-[.8rem]" />
              </Form.Item>
            </div>
            <div className="w-1/2">
              <Form.Item label="Safety Qty" name="qtyUnitCode">
                <Input className="h-10 w-[10rem] ml-[.95rem]" />
              </Form.Item>
              <Form.Item label="VAT" name={"sale"}>
                <Input className="h-10 w-[10rem] ml-[3.3rem]" />
              </Form.Item>
              <Form.Item label="D/C Amount" name={"sale"}>
                <Input className="h-10 w-[10rem]" />
              </Form.Item>
              <Form.Item label="Total Price" className="w-full">
                <InputNumber className="w-[10rem] h-10 ml-3" />
              </Form.Item>
            </div>
          </div>
          <div className="flex justify-between">
            <div className="flex gap-5">
              <Form.Item>
                <CustomButton
                  title={"Clear"}
                  bgColor="[#F666591A]"
                  width="full"
                  textColor="red-500"
                />
              </Form.Item>
              <Form.Item>
                <CustomButton
                  title={"Confirm"}
                  bgColor="[#605BFF]"
                  width="full"
                  textColor="white"
                />
              </Form.Item>
            </div>
            <Form.Item>
              <CustomButton
                title={"Remove"}
                bgColor="[#0302291A]"
                width="full"
                textColor="black"
              />
            </Form.Item>
          </div>
        </div>
      </div>
      <Form.Item className="w-1/2 m-auto">
        <CustomButton
          title={
            ebmSales?.isFetching ? (
              <Spin
                indicator={
                  <LoadingOutlined
                    style={{ fontSize: 24, color: "white" }}
                    spin
                  />
                }
              />
            ) : (
              "Save"
            )
          }
          bgColor="[#605BFF]"
          width="full"
          textColor="white"
          icon={<SaveIcon />}
        />
      </Form.Item>
    </Form>
  );
};

export default ModifyRegistrationForm;
