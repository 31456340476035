import React, { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import DetailsHeaderActionBar from "../../../components/cards/DetailsPage/DetailsHeaderActionBar";
import PrimaryButton from "../../../components/buttons/PrimaryButton";
import DangerButton from "../../../components/buttons/DangerButton";
import SinglePurchaseOrderTable from "../../../components/tables/SinglePurchaseOrderTable";
import {
  getSinglePurchaseOrderAction,
  sendPurchaseOrderEmailAction,
  updatePurchaseOrderAction,
} from "../../../store/purchase/actions";
import { useDispatch, useSelector } from "react-redux";
import { searchValue } from "../../../utils/setColor";
import { Tag } from "antd";
import PurchaseOrderDetailsSkeleton from "../../../components/skeleton/PurchaseOrderDetailsSkeleton";
import TableSkeleton from "../../../components/skeleton/TableSkeleton";
import CustomSkeleton from "../../../components/skeleton/CustomSkeleton";

const SinglePurchaseOrderDetails = (props: any) => {
  const { auth, purchase } = useSelector((state: any) => state);
  const { po_id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const purchaseData = purchase?.selected?.data;

  // Conditions
  const isInTransit = ["fully-in-transit", "partial-in-transit"].includes(
    purchaseData?.status?.toLowerCase()
  );

  const isInFinance = ["finance"].includes(purchaseData?.status?.toLowerCase());
  const isCompleted = ["completed"].includes(
    purchaseData?.status?.toLowerCase()
  );
  const isDraft = ["draft"].includes(purchaseData?.status?.toLowerCase());
  const isSent = ["sent"].includes(purchaseData?.status?.toLowerCase());

  useEffect(() => {
    auth?.token &&
      getSinglePurchaseOrderAction(auth?.token, po_id as string)(dispatch);
  }, [auth?.token, po_id]);

  return (
    <div className="text-black p-5 mt-3 space-y-2 h-full overflow-y-auto scrollbar-hide">
      <DetailsHeaderActionBar
        pageName={props?.isSales ? "Orders" : "Finance"}
        title={"Purchase Order "}
      />
      {!purchaseData && (
        <div className="p-5 bg-white rounded-lg space-y-6">
          <PurchaseOrderDetailsSkeleton />
        </div>
      )}
      {purchaseData && (
        <div className="p-5 bg-white rounded-lg space-y-6">
          <div className="flex justify-between font-semibold text-lg space-y-2">
            <div>
              <p>Purchase Order</p>
            </div>
            {purchase.isFetching ? (
              <CustomSkeleton />
            ) : (
              <Tag
                color={searchValue(purchase?.selected?.data?.status)}
                className="h-8 px-4 min-w-24 text-center pt-1.5 text-xs font-semibold capitalize"
              >
                {purchase?.selected?.data?.status}
              </Tag>
            )}
          </div>

          {purchase.isFetching ? (
            <TableSkeleton />
          ) : (
            <>
              <div className="flex flex-col lg:flex-row justify-start lg:justify-between lg:items-center pt-5">
                <div className="grid grid-cols-2 gap-x-10 mb-2">
                  <p className="font-bold">Supplier Name</p>
                  <p>{purchase?.selected?.data?.supplier?.name}</p>
                  <p className="font-bold">Supplier Location</p>
                  <p>{purchase?.selected?.data?.supplier?.address}</p>
                  <p className="font-bold">Supplier Contact</p>
                  <p>{purchase?.selected?.data?.supplier?.phone}</p>
                </div>
                <div className="grid grid-cols-2 gap-x-10">
                  <p className="font-bold">Delivery Deadline</p>
                  <p>{purchase?.selected?.data?.deliveryDate ?? " "}</p>
                </div>
              </div>
              <div>
                <SinglePurchaseOrderTable />
                {purchase?.selected?.data.status !== "Cancelled" && (
                  <div className="space-x-2 mt-4">
                    <PrimaryButton
                      name="Print"
                      onClick={() => console.log("Printing...")}
                    />
                    {purchase?.selected?.data.status === "Fully-In-Transit" && (
                      <PrimaryButton
                        name="Completed"
                        onClick={async () => {
                          if (auth?.token) {
                            const res = await updatePurchaseOrderAction(
                              auth?.token,
                              po_id as string,
                              {
                                status: "Completed",
                              }
                            )(dispatch);
                            res && navigate("/purchase/po");
                          }
                        }}
                        isLoading={purchase.isFetching}
                      />
                    )}
                    {["sent", "partial-in-transit"].includes(
                      purchase?.selected?.data?.status?.toLowerCase()
                    ) && (
                      <Link
                        to={`/delivery/note/${purchase?.selected?.data?._id}`}
                        target="_blank"
                      >
                        <PrimaryButton
                          // onClick={async () => ()}
                          name="Manual Reply "
                        />
                      </Link>
                    )}
                    {props?.isFinance && !isInTransit && (
                      <PrimaryButton
                        name={`${
                          purchaseData?.status?.toLowerCase() === "sent"
                            ? "Re-send"
                            : "Send"
                        } by Email`}
                        onClick={async () => {
                          if (auth?.token) {
                            const res = await sendPurchaseOrderEmailAction(
                              auth?.token,
                              {
                                purchaseOrderID: po_id,
                                isNotify: true,
                                notification: {
                                  action: "PO Sent to Supplier",
                                  role: ["admin", "finance", "finance-manager"],
                                  message: `PO With ID: ${purchase?.selected?.data?.pOrderID} has been Sent to ${purchase?.selected?.data?.supplier?.name}`,
                                  title: `Purchase Order Sent to ${purchase?.selected?.data?.supplier?.name}`,
                                },
                              }
                            )(dispatch);

                            res && navigate("/finance/po");
                          }
                        }}
                        isLoading={purchase.isFetching}
                      />
                    )}

                    {props?.isSales &&
                      !isInFinance &&
                      !isInTransit &&
                      !isCompleted &&
                      !isSent && (
                        <PrimaryButton
                          name="Request Finance Approval"
                          onClick={() => {
                            auth?.token &&
                              updatePurchaseOrderAction(
                                auth?.token,
                                po_id as string,
                                {
                                  status: "Finance",
                                  isNotify: true,
                                  notification: {
                                    action: "PO Waiting for approval",
                                    role: [
                                      "admin",
                                      "finance",
                                      "finance-manager",
                                    ],
                                    message: `Purchase Order ${purchase?.selected?.data?.pOrderID} is Waiting For Approval`,
                                    title: "Purchase Order",
                                  },
                                }
                              )(dispatch);
                          }}
                          isLoading={purchase?.isUpdating}
                        />
                      )}
                    {(!isInTransit || isInFinance || isDraft) &&
                      !isSent &&
                      !isCompleted && (
                        <DangerButton
                          name="Cancel"
                          onClick={async () => {
                            if (auth?.token) {
                              const res = await updatePurchaseOrderAction(
                                auth?.token,
                                po_id as string,
                                {
                                  status: "Cancelled",
                                }
                              )(dispatch);
                              res && navigate("/purchase/po");
                            }
                          }}
                          isLoading={purchase.isFetching}
                        />
                      )}
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default SinglePurchaseOrderDetails;
