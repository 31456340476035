import { SERVER_URL } from "../../../utils/constants";
import HttpRequest from "../../../utils/HttpRequest";
export const getAllOverViewService = async (token: string, query?: string) => {
  return HttpRequest.get(`${SERVER_URL}/statistics/${query}`, token);
};
export const getAllOrderHistoryService = async (
  token: string,
  query?: string
) => {
  return HttpRequest.get(
    `${SERVER_URL}/statistics/orderHistory/${query}`,
    token
  );
};
export const getAllPaymentHistoryService = async (
  token: string,
  query?: string
) => {
  return HttpRequest.get(`${SERVER_URL}/statistics/history/${query}`, token);
};
export const getAllNoSaleService = async (token: string, query?: string) => {
  return HttpRequest.get(`${SERVER_URL}/statistics/nosale${query}`, token);
};
export const getTotalRevenueService = async (token: string, query?: string) => {
  return HttpRequest.get(`${SERVER_URL}/statistics/revenue${query}`, token);
};
export const getRevenueQuantityService = async (
  token: string,
  query?: string
) => {
  return HttpRequest.get(
    `${SERVER_URL}/statistics/revenueQuantity${query}`,
    token
  );
};
export const getPlanOverviewService = async (token: string, query?: string) => {
  return HttpRequest.get(`${SERVER_URL}/statistics/plan${query}`, token);
};
export const getStockByShopService = async (token: string, query?: string) => {
  return HttpRequest.get(`${SERVER_URL}/statistics/stockByshop${query}`, token);
};
export const getVaultStatService = async (token: string, query?: string) => {
  return HttpRequest.get(`${SERVER_URL}/vault/statusCount${query}`, token);
};
export const getApplicationStatsService = async (
  token: string,
  query?: string
) => {
  return HttpRequest.get(`${SERVER_URL}/statistics/application${query}`, token);
};
