import React, { useState } from "react";
import SettingButton from "../../../pages/dashboard/setting/SettingButton";
import { BiPlus } from "react-icons/bi";
import { ReactComponent as ImportIcons } from "../assets/images/icons/vscode-icons_file-type-excel2.svg";
import EbmButton from "../components/EbmButton";
import EbmCard from "../components/EbmCard";
import type { SearchProps } from "antd/es/input/Search";
import GenearalModal from "../components/modals/GeneralModal";
import GeneralDrawer from "../components/GeneralDrawer";
import ManageCustomerTable from "../components/tables/ManageCustomerTable";
import CustomerDetails from "../components/CustomerDetails";
import AddCustomerForm from "../components/forms/AddCustomerForm";
import { useSelector } from "react-redux";
import { Input } from "antd";
import LoadingCard from "../components/LoadingCard";
const CustomerManagement = () => {
  const { ebmGeneral } = useSelector((state: any) => state);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [customer, setCustomer] = useState("");

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showDrawer = (values: any) => {
    setOpenDrawer(true);
    setCustomer(values);
  };

  const onClose = () => {
    setOpenDrawer(false);
  };
  const { Search } = Input;
  const onSearch: SearchProps["onSearch"] = (value, _e, info) =>
    console.log(info?.source, value);
  return (
    <div>
      <GeneralDrawer
        openDrawer={openDrawer}
        closeDrawer={onClose}
        width={550}
        title={
          <h1 className="text-base font-medium text-[#030229]">
            Customer Details
          </h1>
        }
        component={
          <div>
            <CustomerDetails data={customer} />
          </div>
        }
      />
      <div className="flex justify-between">
        <h1 className="text-[#030229] text-lg">Customer Management</h1>
        <div className="flex gap-5">
          <EbmButton
            btnName={"Import"}
            icon={<ImportIcons />}
            btnBgColor="white"
          />
          <EbmButton
            btnName={"Export"}
            icon={<ImportIcons />}
            btnBgColor="white"
          />
          <SettingButton
            btnName={"New"}
            icon={<BiPlus color="white" size={20} />}
            btnBgColor="[#605BFF]"
            textColor="white"
            onClick={showModal}
          />
        </div>
      </div>{" "}
      <div className="flex gap-5 mt-5 justify-between items-end">
        <div className="flex gap-5 mt-5">
          {ebmGeneral?.isFetching ? (
            <LoadingCard />
          ) : (
            <EbmCard
              total={ebmGeneral?.ebmOverview?.totalCustomers || 0}
              title={"Total Customer"}
            />
          )}
          <EbmCard title={"Individual"} total={`400`} />
          <EbmCard title={"Corperate"} total={`52`} />
        </div>

        <div className="flex gap-5">
          <Search
            placeholder="Search"
            onSearch={onSearch}
            style={{ width: "200px" }}
          />
        </div>
      </div>
      <div className="bg-white p-3 rounded-md mt-5">
        <div className="flex justify-between">
          <GenearalModal
            openModal={isModalOpen}
            closeModal={handleOk}
            title={
              <h1 className="text-[#030229] font-semibold text-lg text-center py-2">
                Add New 
              </h1>
            }
            component={
              <div className="h-full w-full">
                <div className="mt-10 w-full">
                  <AddCustomerForm />
                </div>
              </div>
            }
            width={1100}
          />
        </div>
        <div className="mt-5">
          <ManageCustomerTable open={showDrawer} />
        </div>
      </div>
    </div>
  );
};

export default CustomerManagement;
