import { createSlice } from "@reduxjs/toolkit";
import store from "store";

const authSlice = createSlice({
  name: "authentication",
  initialState: {
    isFetching: false,
    isAuth: false,
    isUserLoggedIn: false,
    profileIsFetching: false,
    isLoggingIn: false,
    isResetPasswordFetching: false,
    token: "",
    user: null,
    resetLink: "",
    resetPassword: "",
    resetTokenExpiredStatus: "",
    status: "",
    error: null,
    pathUrl: null,
    onlineUser: [],
    profile: null,
  },
  reducers: {
    login(state, action) {
      state.token = action.payload.data?.token;
      state.user = action.payload.data?.user;
      state.status = action.payload.status;
      state.isAuth = action.payload.isAuth;
      state.error = action.payload?.error;
    },
    setToken(state, action) {
      state.token = action.payload.token;
    },
    setUser(state, action) {
      state.user = action.payload;
    },
    logout(state) {
      store.remove("user");
      store.remove("authToken");
      state.token = "";
      state.status = "";
      state.user = null;
      state.isAuth = false;
    },
    setIsFetching(state, action) {
      state.isFetching = action.payload;
    },
    setPathUrl(state, action) {
      state.pathUrl = action.payload;
    },
    setOnlineUser(state, action) {
      state.onlineUser = action.payload;
    },
    setProfile(state, action) {
      state.profile = action.payload;
    },
    setResetLink(state, action) {
      state.resetLink = action.payload;
    },
    setResetPassword(state, action) {
      state.resetPassword = action.payload;
    },
    setResetTokenExpiredStatus(state, action) {
      state.resetTokenExpiredStatus = action.payload;
    },
    setIsUserLoggedIn(state, action) {
      state.isUserLoggedIn = action.payload;
    },
    setProfileIsFetching(state, action) {
      state.profileIsFetching = action.payload;
    },
    setIsLoggingIn(state, action) {
      state.isLoggingIn = action.payload;
    },
    setIsResetPasswordFetching(state, action) {
      state.isResetPasswordFetching = action.payload;
    },
  },
});

export const authActions = authSlice.actions;

export default authSlice.reducer;
