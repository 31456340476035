import { mySubscritionActions } from ".";
import {
  getAllNoSaleService,
  getAllOrderHistoryService,
  getAllOverViewService,
  getAllPaymentHistoryService,
  getApplicationStatsService,
  getPlanOverviewService,
  getRevenueQuantityService,
  getStockByShopService,
  getTotalRevenueService,
  getVaultStatService,
} from "./services";
export const getAllOverViewAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(mySubscritionActions.setIsFetching(true));
      const res = await getAllOverViewService(token, query);
      if (res?.status === 200) {
        dispatch(mySubscritionActions.setAll(res));
        dispatch(mySubscritionActions.setIsFetching(false));
      }
      dispatch(mySubscritionActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const getAllOrderHistoryAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(mySubscritionActions.setIsFetching(true));
      const res = await getAllOrderHistoryService(token, query);
      if (res?.status === 200) {
        dispatch(mySubscritionActions.setAllOrderHistory(res));
        dispatch(mySubscritionActions.setIsFetching(false));
      }
      dispatch(mySubscritionActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const getAllPaymentHistoryAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(mySubscritionActions.setIsFetching(true));
      const res = await getAllPaymentHistoryService(token, query);
      if (res?.status === 200) {
        dispatch(mySubscritionActions.setAllPaymentHistory(res));
        dispatch(mySubscritionActions.setIsFetching(false));
      }
      dispatch(mySubscritionActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const getAllNoSaleAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(mySubscritionActions.setIsFetching(true));
      const res = await getAllNoSaleService(token, query);
      if (res?.status === 200) {
        dispatch(mySubscritionActions.setNoSale(res));
        dispatch(mySubscritionActions.setIsFetching(false));
      }
      dispatch(mySubscritionActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const getTotalRevenueAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(mySubscritionActions.setIsFetching(true));
      const res = await getTotalRevenueService(token, query);
      if (res?.status === 200) {
        dispatch(mySubscritionActions.setTotalRevenue(res));
        dispatch(mySubscritionActions.setIsFetching(false));
      }
      dispatch(mySubscritionActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const getRevenueQuantityAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(mySubscritionActions.setIsFetching(true));
      const res = await getRevenueQuantityService(token, query);
      if (res?.status === 200) {
        dispatch(mySubscritionActions.setQuantityRevenue(res));
        dispatch(mySubscritionActions.setIsFetching(false));
      }
      dispatch(mySubscritionActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const getPlanOverviewAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(mySubscritionActions.setIsFetching(true));
      const res = await getPlanOverviewService(token, query);
      if (res?.status === 200) {
        dispatch(mySubscritionActions.setPlanOverview(res));
        dispatch(mySubscritionActions.setIsFetching(false));
      }
      dispatch(mySubscritionActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const getShopStockAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(mySubscritionActions.setIsFetching(true));
      const res = await getStockByShopService(token, query);
      if (res?.status === 200) {
        dispatch(mySubscritionActions.setShopStock(res));
        dispatch(mySubscritionActions.setIsFetching(false));
      }
      dispatch(mySubscritionActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const getVaultStatsAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(mySubscritionActions.setIsFetching(true));
      const res = await getVaultStatService(token, query);
      if (res?.status === 200) {
        dispatch(mySubscritionActions.setVaultStats(res));
        dispatch(mySubscritionActions.setIsFetching(false));
      }
      dispatch(mySubscritionActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const getApplicationStatsAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(mySubscritionActions.setIsFetching(true));
      const res = await getApplicationStatsService(token, query);
      if (res?.status === 200) {
        dispatch(mySubscritionActions.setAppStats(res));
        dispatch(mySubscritionActions.setIsFetching(false));
      }
      dispatch(mySubscritionActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
