import { Button, Form, Input, Spin } from "antd";
import TextArea from "antd/es/input/TextArea";
import PhoneInput from "react-phone-input-2";
import UploadComponent from "../../../components/forms/Upload";
import { useEffect, useState } from "react";
import {
  getCheckParkageAction,
  updateParkageOutAction,
} from "../../../store/delivery/actions";
import { useDispatch, useSelector } from "react-redux";
import { LoadingOutlined } from "@ant-design/icons";
const RecieverDetails = (props: any) => {
  const { tracking } = useSelector((state: any) => state);
  const [pictures, setPictures] = useState([]);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  useEffect(() => {
    form.setFieldsValue({
      name: tracking?.checkParckage?.receiver?.name,
      email: tracking?.checkParckage?.receiver?.email,
      phone: tracking?.checkParckage?.receiver?.phone,
      comment: tracking?.checkParckage?.receiver?.comment,
    });
  }, [tracking?.checkParckage?.reciever]);
  const onFinish = async (values: any) => {
    const images = pictures.map((pic: any) => pic?.response?.data?.secure_url);
    await updateParkageOutAction(tracking?.checkParckage?._id, {
      receiver: {
        ...values,
        picture: images,
      },
    })(dispatch);
    await getCheckParkageAction(
      `?trackingId=${tracking?.checkParckage?.packageId}&delivererId=${tracking?.selectedDriver?._id}`
    )(dispatch);
    props?.handleReceiverOk()
  };
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  return (
    <>
      <div className="text-black w-full">
        <h1 className="text-[#030229] text-lg font-medium py-3 pb-6 text-center">
          Insert receiver details
        </h1>
        <p className="text-[#030229] text-lg text-center">
          And also picture proof the package delivered
        </p>
        <Form
          className="pt-5 w-full xl:w-1/2 m-auto"
          name="basic"
          labelCol={{ span: 6 }}
          form={form}
          initialValues={tracking?.checkParckage?.reciever}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            label="Full names"
            name="name"
            rules={[{ required: true, message: "Please input your name!" }]}
          >
            <Input className="h-12 w-full" />
          </Form.Item>

          <Form.Item
            label="phone number"
            name="phone"
            rules={[{ required: true, message: "Please input your phone!" }]}
          >
            <PhoneInput
              country={"bw"}
              inputStyle={{
                width: "100%",
                height: "70px",
                color: "black",
              }}
              containerClass="phone-container"
              placeholder="+250 780 000 000"
            />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: "Please input your name!" }]}
          >
            <Input className="h-12" />
          </Form.Item>
          <Form.Item
            label="Comments"
            name="comment"
            rules={[{ required: true, message: "Please input comment!" }]}
          >
            <TextArea rows={4} placeholder="Comment" />
          </Form.Item>
          <div className="pb-10 dml-4 border border-[#03022926] border-dashed pt-5 w-full flex items-center justify-center">
            <UploadComponent
              className="bg-blue-500"
              setPictures={setPictures}
              limit={2}
            />
          </div>
          <Form.Item className="mt-5 w-full">
            <Button
              htmlType="submit"
              className="text-center bg-[#605BFF] text-white py-4 w-1/2 rounded-md px-8 ml-20"
            >
              {tracking?.isFetching ? (
                <div className="flex h-fit ">
                  <Spin indicator={antIcon} />
                </div>
              ) : (
                "Send"
              )}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};
export default RecieverDetails;
