import * as React from "react";
import { PieChart } from "@mui/x-charts/PieChart";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { getImportSumaryAction } from "../store/action";

const CenteredLabel = styled("div")({
  position: "absolute",
  top: "45%",
  left: "44%",
  transform: "translate(-50%, -50%)",
  fontSize: "18px",
  fontWeight: "semiBold",
  color: "#030229",
});

export default function PieChartWithCenterLabel() {
  const { auth, ebmGeneral } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  React.useEffect(() => {
    auth?.token && getImportSumaryAction(auth?.token, "?")(dispatch);
  }, [dispatch, auth]);

  const generateColors = (numColors: number): string[] => {
    const baseColor = "#0000FF";
    const colors = ["#605BFF", "#807BFF", "#A09DFF", "#BFBDFF", "#DFDEFF"];
    const step = 255 / (numColors + 1);
    const baseColorInt = parseInt(baseColor.substring(1), 16);
    const generatedColors = [];
    for (let i = 0; i < numColors; i++) {
      const newColorInt = baseColorInt + Math.round(step * (i + 1));
      generatedColors?.push(`#${newColorInt.toString(16)?.padStart(6, "0")}`);
    }
    return [...colors, ...generatedColors];
  };

  const data =
    ebmGeneral?.importSummary?.data?.map((item: any) => ({
      value: item.count,
      label: `${item._id} (${item.count})`,
    })) || [];

  const colors =
    data && data?.length > 0 ? generateColors(data?.length) : generateColors(6);
  const totalImports = data.reduce(
    (acc: any, curr: { value: any }) => acc + curr.value,
    0
  );

  const size = {
    width: 800,
    height: 200,
  };
  return (
    <div style={{ right: "80px", position: "relative", ...size }}>
      <PieChart
        series={[{ data, innerRadius: 65 }]}
        colors={colors}
        {...size}
      />
      <CenteredLabel>
        <p className="text-center">{totalImports}</p>
        <p className="text-center"> Total Import</p>
      </CenteredLabel>
    </div>
  );
}
