import React from "react";
import UserManagement from "../manage users/UserManagement";

const MascomUserManagement = () => {
  return (
    <div className="mt-6">
      <UserManagement />
    </div>
  );
};

export default MascomUserManagement;
