import React from "react";
import { FaArrowUpRightFromSquare } from "react-icons/fa6";
import { COLORS } from "../../../../assets/data/data";
import { useSelector } from "react-redux";
import { getDefaultCurrencyCode } from "../../../../utils/helper";
import SubscriptionCustomizedPieChart from "./SubscriptionCustomizedPieChart";

const OrdersHistory = () => {
  const { company } = useSelector((state: any) => state);
  const data = [
    { name: "Apple", value: 80 },
    { name: "Samsung", value: 45 },
    { name: "Tecno", value: 25 },
    { name: "IKOSORA", value: 25 },
    { name: "Itel", value: 25 },
    { name: "Google Pixel", value: 25 },
  ];

  return (
    <div className="w-full bg-[#fff] h-[28rem] overflow-hidsden p-5">
      <div className="text-[#030229] text-lg flex justify-between">
        <p>No Sales</p>
        <FaArrowUpRightFromSquare color={"#03022980"} />
      </div>
      <div className="text-[#0F0F47] font-medium text-base pt-3">
        <p>{`${getDefaultCurrencyCode(company)} 0`}</p>
      </div>
      <div className="text-[#03022980] font-medium text-base">
        <p>{`${getDefaultCurrencyCode(company)} 0`}</p>
      </div>

      <SubscriptionCustomizedPieChart
        data={data}
        colors={COLORS}
        totalLabel="Total Devices"
        totalValue={0}
        height={"270px"}
        contentWidth="w-[75%]"
        top={"50%"}
        left={"50%"}
        innerRad={80}
        outerRad={110}
        pieHeight={300}
        pieWidth={400}
      />
    </div>
  );
};

export default OrdersHistory;
