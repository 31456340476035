import React from "react";

interface btnProps {
  btnName?: any;
  btnBgColor?: any;
  borderColor?:any,
  textColor?:any,
}
const CustomButton = ({btnName,btnBgColor,borderColor,textColor}:btnProps) => {
  return (
    <button
      type="submit"
      className={`text-${textColor} font-medium text-center border border-${borderColor} py-1 px-4 rounded-md bg-${btnBgColor} text-base`}
    >
      {btnName}
    </button>
  );
};

export default CustomButton;
