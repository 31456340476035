import React, { useEffect } from "react";
import { LineChart } from "react-chartkick";
import "chartkick/chart.js";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { getSalesStatsActions } from "../../../../store/dashboard/actions";
import { getDaysBetweenDates } from "../../../../utils/converter";
import { ProductDetailsHeaders } from "../../../../components/csvHeaders/Headers";
import CsvDownloadButton from "../../../../components/buttons/CsvDownloadButton";

const ShopStockTopGraph = () => {
  const { auth, dashboard, inventory, product } = useSelector(
    (state: any) => state
  );
  const dispatch = useDispatch();
  const { RangePicker } = DatePicker;
  const DEFAULT_DAYS = 7;
  useEffect(() => {
    auth?.token &&
      getSalesStatsActions(auth?.token, `days=${DEFAULT_DAYS}`)(dispatch);
  }, [auth?.token, dispatch]);

  const handleSelectDate = (date: any, dateString: any) => {
    auth?.token &&
      getSalesStatsActions(
        auth?.token,
        `days=${getDaysBetweenDates(dateString)}`
      )(dispatch);
  };

  const csvData = inventory?.allShopStock?.data?.map((record: any) => ({
    product: product.selected?.model || "N/A",
    shop: record?.shop?.name || "N/A",
    stock: record?.statusCounts
      ?.filter((status: any) => status.status === "pending")
      ?.map((status: any) => status.count),
    transfer: record?.statusCounts
      ?.filter((status: any) => status.status === "transfer")
      ?.map((status: any) => status.count),
    sold: record?.statusCounts
      ?.filter((status: any) => status.status === "sold")
      ?.map((status: any) => status.count),
  }));

  return (
    <div className="flex items-center bg-white justify-between px-4 py-6 space-y-4 relative">
      <div className="flex items-center justify-between absolute right-0 left-0 px-4 top-1">
        <div className="space-x-2">
          <span>Choose date:</span>
          <RangePicker
            defaultValue={[dayjs().subtract(7, "day"), dayjs()]}
            onChange={handleSelectDate}
            disabled={[false, true]}
          />
        </div>
        <CsvDownloadButton
          csvHeaders={ProductDetailsHeaders}
          csvData={csvData}
          filename={`${product?.selected?.model}-Details.csv`}
        />
      </div>
      {dashboard?.salesStats?.data?.salesByCash && (
        <LineChart
          data={dashboard?.salesStats?.data?.salesByCash}
          prefix="BWP "
          thousands=","
          width={900}
        />
      )}
      <div className="min-h-[10rem] w-[30%] flex items-center justify-center rounded-lg mt-5 border">
        <div className="p-4 relative block">
          <p className="text-gray-400 capitalize mb-2">iPhone 13</p>
          <p className="text-gray-400 capitalize mb-2">50 pieces /Day</p>
        </div>
      </div>
    </div>
  );
};

export default ShopStockTopGraph;
