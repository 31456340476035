import React from "react";
import { FaArrowUpLong } from "react-icons/fa6";
import CustomButton from "../../../../components/buttons/CustomButton";

interface cardProps {
  title?: any;
  icon?: any;
  amount?: any;
  comparedAmount?: any;
  parcent?: any;
  icon2?: any;
  description?: any;
  btn?: any;
  compareText?: any;
  textColor?: string;
}
const OverViewCard = ({
  title,
  icon,
  amount,
  comparedAmount,
  parcent,
  icon2,
  description,
  btn,
  compareText,
  textColor,
}: cardProps) => {
  return (
    <div className="bg-white rounded-md p-5 w-[384pdx] h-[200px] relative">
      <div className="flex justify-between">
        <h1 className="text-[#03022993] text-base ">{title}</h1>
        {icon}
      </div>
      <div className="border-dotted border-b-2 border-[#03022940]">
        <p className="text-[#030229] text-xl font-medium">{amount}</p>
        <p className="text-[#03022980] text-base pb-3">{comparedAmount}</p>
        {/* <div className="flex gap-2">
          <p className="text-[#03022980] text-sm pb-4 ">{compareText} By </p>
          <p className={`!text-[${textColor}] text-sm`}>{parcent}</p>
          {icon2}
        </div> */}
      </div>
      <div className="flex justify-between">
        <p className="text-[#030229] text-base pt-4">{description}</p>
      </div>
      {/* <div className="absolute right-2 bottom-2">{btn}</div> */}
    </div>
  );
};

export default OverViewCard;
