import React from "react";
import { Select, Table, Tag } from "antd";
import type { ColumnsType } from "antd/es/table";
import { searchValue } from "../../../utils/setColor";
import { useSelector } from "react-redux";
import ScrollableFrame from "../../layout/ScrollableFrame";
import { getDefaultCurrencyCode } from "../../../utils/helper";
import dayjs from "dayjs";

interface DataType {
  key: React.Key;
  fname: string;
  lname: string;
  age: number;
  address: string;
  msisdn: string;
}

const SavetheChildrentable = (props: any) => {
  const { report, company } = useSelector((state: any) => state);
  const columns: ColumnsType<DataType> = [
    {
      title: "Due Date",
      dataIndex: "date",
    },
    {
      title: "Customer",
      dataIndex: "customer",
      render: (text, record) => (
        <>
          <p>{text}</p>
          <p>{record?.msisdn}</p>
        </>
      ),
    },
    {
      title: `Monthly Payment(${getDefaultCurrencyCode(company)})`,
      dataIndex: "mPayment",
    },
    {
      title: `Amount Paid (${getDefaultCurrencyCode(company)})`,
      dataIndex: "mPaid",
    },
    {
      title: `Amount Remain (${getDefaultCurrencyCode(company)})`,
      dataIndex: "mPaidremain",
    },
    {
      title: "Paid At",
      dataIndex: "paidAt",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text: any, record: any) => (
        <Tag
          color={searchValue(text)}
          className="capitalize py-0.5 min-w-[60px] text-center"
        >
          {text}
        </Tag>
      ),
    },
  ];

  const dataToDisplay = report?.saveTheChildren?.data?.list?.map(
    (item: any) => {
      return {
        key: "",
        date: dayjs(item?.dueDate).format("YYYY-MM-DD HH:mm:ss"),
        customer: item?.account?.customer?.name,
        msisdn: item?.account?.customer?.msisdn,
        mode: item?.account?.deliveryNote?.list?.at(0)?.orderItem?.product
          ?.product?.model,
        imei: item?.account?.deliveryNote?.list?.at(0)?.wareHouseItem
          ?.serialNumber,
        capacity: item?.account?.deliveryNote?.list
          ?.at(0)
          ?.orderItem?.product?.specification.filter(
            (item: any) => item[0]?.toLowerCase() === "capacity (rom)"
          )
          .map((item: any) => item[1]),
        mPayment: item?.amountToBePaid?.toLocaleString(),
        mPaid: item?.amountPaid?.toLocaleString(),
        paidAt:item?.payment?.map((data:any)=>{
          const  formattedDate = dayjs(data?.date).format("YYYY-MM-DD HH:mm:ss");
          return `${formattedDate}${item?.payment?.length > 1 ? " ; " : ""}`;
          }),
        mPaidremain: (
          item?.amountToBePaid - item?.amountPaid
        )?.toLocaleString(),
        status: item?.status,
      };
    }
  );

  const onChange = (value: string) => {
    props?.setSelectedStatus(value);
  };

  const onSearch = (value: string) => {
    console.log("search:", value);
  };

  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <div className="bg-white p-4 text-gray-900 xh-[67vh]">
      <div className="flex items-center space-x-4 pb-6">
        <h1 className="font-semibold">Payment History</h1>
        <Select
          showSearch
          placeholder="Filter by Status"
          optionFilterProp="children"
          onChange={onChange}
          onSearch={onSearch}
          filterOption={filterOption}
          defaultValue={"All"}
          allowClear
          style={{ width: "10%" }}
          options={[
            {
              value: "",
              label: "All",
            },
            {
              value: "pending",
              label: "Pending",
            },
            {
              value: "paid",
              label: "Paid",
            },
            {
              value: "overpaid",
              label: "Overpaid",
            },
            {
              value: "partialrpaid",
              label: "Partialpaid",
            },
            {
              value: "late",
              label: "Late",
            },
          ]}
        />
      </div>
      <ScrollableFrame
        customSize={500}
        setPage={props?.setPage}
        setLimit={props?.setLimit}
        limit={props?.limit}
        total={
          props?.selectedStatus === "pending"
            ? report?.saveTheChildren?.data?.counts?.pending
            : props?.selectedStatus === "paid"
            ? report?.saveTheChildren?.data?.counts?.paid
            : props?.selectedStatus === "late"
            ? report?.saveTheChildren?.data?.counts?.late
            : report?.saveTheChildren?.total
        }
        count={Math.ceil(
          (props?.selectedStatus === "pending"
            ? report?.saveTheChildren?.data?.counts?.pending
            : props?.selectedStatus === "paid"
            ? report?.saveTheChildren?.data?.counts?.paid
            : props?.selectedStatus === "late"
            ? report?.saveTheChildren?.data?.counts?.late
            : report?.saveTheChildren?.total) / props?.limit
        )}
      >
        <Table
          columns={columns}
          dataSource={dataToDisplay}
          loading={report?.isFetching}
          pagination={false}
        />
      </ScrollableFrame>
    </div>
  );
};

export default SavetheChildrentable;
