import React from "react";
import iconOne from "../../../assets/icons/new/profile-2user.svg";
import OverViewCard from "../../../pages/dashboard/dashboardComponents/OveviewCards";
import { getCountsByStatus } from "../../../utils/helper";
import { useSelector } from "react-redux";

const EligibilitySection = () => {
  const { overView } = useSelector((state: any) => state);

  return (
    <div>
      <h1 className="pl-1 pr-4 py-6 mb-3 text-lg font-medium">
        Loan Application Overview
      </h1>
      <div className="grid grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-5">
        <OverViewCard
          title={"Total Applications Received"}
          amount={
            getCountsByStatus(overView?.applicationStats?.data, "ALLREQUEST")
              ?.totalCountDay2
          }
          icon={<img src={iconOne} alt="" />}
          comparedAmount={
            getCountsByStatus(overView?.applicationStats?.data, "ALLREQUEST")
              ?.totalCountDay1
          }
          textColor={"[#F1595C]"}
          description={<div>Total number of loan application received</div>}
        />
        <OverViewCard
          title={"Total Applications Rejected"}
          amount={
            getCountsByStatus(overView?.applicationStats?.data, "REJECTED")
              ?.totalCountDay2
          }
          icon={<img src={iconOne} alt="" />}
          comparedAmount={
            getCountsByStatus(overView?.applicationStats?.data, "REJECTED")
              ?.totalCountDay1
          }
          textColor={"[#F1595C]"}
          description={
            <div>
              {" "}
              Applications where customers have selected a device in the USSD
              loan application flow .
            </div>
          }
        />
        <OverViewCard
          title={"Total Applications Closed"}
          amount={
            getCountsByStatus(overView?.applicationStats?.data, "CLOSED")
              ?.totalCountDay2
          }
          icon={<img src={iconOne} alt="" />}
          comparedAmount={
            getCountsByStatus(overView?.applicationStats?.data, "CLOSED")
              ?.totalCountDay1
          }
          textColor={"[#F1595C]"}
          description={
            <div>
              {" "}
              Applications where customer have confirmed their selection by
              paying
            </div>
          }
        />
        <OverViewCard
          title={"Total Applications Approved"}
          amount={
            getCountsByStatus(overView?.applicationStats?.data, "APPROVED")
              ?.totalCountDay2
          }
          icon={<img src={iconOne} alt="" />}
          comparedAmount={
            getCountsByStatus(overView?.applicationStats?.data, "APPROVED")
              ?.totalCountDay1
          }
          textColor={"[#F1595C]"}
          description={
            <div>
              Applications where payments have been approved by EWP , and
              customers are ready for device pickup
            </div>
          }
        />
      </div>
    </div>
  );
};
export default EligibilitySection;
