import { Radio } from "antd";
import React, { useState } from "react";

const borderColors = [
  "#6D4C41",
  "#FF8F00",
  "#00C853",
  "#605BFF",
  "#FF6F61",
  "#6B8E23",
  "#4682B4",
  "#D2691E",
];

const ViewSubscriptionForm = (props: any) => {
  const [selectePlan, setSelectePlan] = useState("day");

  const handleSelectedPlan = (value: any) => {
    setSelectePlan(value);
  };

  return (
    <div className="py-10 pl-10">
      <div className="mb-6">
        <h1 className="text-xl font-bold text-gray-800 pb-4">Plan Details</h1>
        <p className="text-gray-600 text-sm w-96">
          See different subscription plans & data plans. Filter accordingly to
          different offers
        </p>
        <div className="flex items-center space-x-2 py-6 justify-center">
          <Radio.Group
            defaultValue="day"
            buttonStyle="solid"
            onChange={(e: any) => handleSelectedPlan(e.target.value)}
          >
            <Radio.Button value="day">Daily</Radio.Button>
            <Radio.Button value="week">Weekly</Radio.Button>
            <Radio.Button value="month">Monthly</Radio.Button>
          </Radio.Group>
        </div>
      </div>
      <div className="flex overflow-y-auto pb-4">
        {props?.data?.subscriptionPlan?.map((item: any, index: number) => (
          <div
            className="min-w-[15rem] mr-4 bg-white rounded-lg shadow-md p-6 border-l-4"
            style={{
              borderColor: borderColors[index % borderColors.length],
            }}
          >
            <div className="text-gray-500 text-sm mb-2">
              {`${item?.duration} Months Duration`}{" "}
            </div>
            <div className="text-lg font-bold text-gray-800 mb-4">
              {`${
                selectePlan === "day"
                  ? item?.dailyInstalment?.toLocaleString() ?? 0
                  : selectePlan === "week"
                  ? item?.weeklyInstalment?.toLocaleString() ?? 0
                  : item?.monthlyInstalment?.toLocaleString() ?? 0
              } BWP`}{" "}
              <span className="text-sm font-normal capitalize">{`/${selectePlan}`}</span>
            </div>
            <ul className="text-gray-600 space-y-2">
              <li>
                ➕{" "}
                {`Bundles (${item?.bundle
                  ?.map((el: any) => el?.toLocaleString())
                  ?.join(", ")})`}
              </li>
              <li>
                ➕{" "}
                {`SMS (${item?.sms
                  ?.map((el: any) => el?.toLocaleString())
                  ?.join(", ")})`}
              </li>
              <li>
                ➕{" "}
                {`Talk Time (${item?.call
                  ?.map((el: any) => el?.toLocaleString())
                  ?.join(", ")})`}
              </li>
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ViewSubscriptionForm;
