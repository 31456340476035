import React, { useEffect } from "react";
import "react-phone-input-2/lib/style.css";
import { Button } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import {
  approveInvoicePaymentAction,
  checkPaymentStatusAction,
  getAllAccountInvoiceAction,
  resetPaymentRelatedStates,
} from "../../store/invoice/actions";
import { getLastPaymentStatus } from "../../utils/converter";
import LoadingAnimation from "../../assets/images/circle-loading-lines.gif";
import SuccessAnimation from "../../assets/images/successfully-done.gif";
import { myInvoices } from "../../store/invoice";
import { MdError } from "react-icons/md";
import { getDefaultCurrencyCode } from "../../utils/helper";

const App = (props: any) => {
  const dispatch = useDispatch();
  const { invoice, auth, account, company } = useSelector(
    (state: any) => state
  );

  const checkPayment = async (resData: any) => {
    console.log("RES DATA:::::", resData);
    const payload = {
      amountPaid: resData?.amount,
      momoTransactionId: resData?.momoTransactionId,
      comments: "Paid through myzaka pay",
      date: new Date(),
    };

    await approveInvoicePaymentAction(
      invoice?.selectedForPayment?.data?._id,
      auth?.token,
      payload
    )(dispatch);

    await getAllAccountInvoiceAction(
      auth?.token,
      `?account=${account?.selected?._id}`
    )(dispatch);
  };

  const paymentStatus = async () => {
    await checkPaymentStatusAction(
      auth?.token,
      invoice?.selectedForPayment?.data?._id as string
    )(dispatch);
  };

  const lastPaymentStatus = getLastPaymentStatus(
    invoice?.statusResult?.data?.autoPayments
  );
  const latestAutoPayment =
    invoice?.statusResult?.data?.autoPayments?.[
      invoice?.statusResult?.data?.autoPayments?.length - 1
    ];

  useEffect(() => {
    if (!invoice.paymentInitiationStatus) {
      dispatch(myInvoices.setStatusResult(null));
      return;
    }

    let timerId: any = null;
    if (lastPaymentStatus === "failed") {
      dispatch(myInvoices.setStatusResult(null));
      dispatch(myInvoices.setPaymentInitiationStatus(false));
      props?.onCancel();
    } else {
      timerId = setInterval(async () => {
        await paymentStatus();
      }, 20000);
    }

    if (lastPaymentStatus === "successful") {
      clearInterval(timerId);
      console.log("HEEEEEEEE", latestAutoPayment);
      checkPayment(latestAutoPayment);
    }

    return () => {
      if (timerId) {
        clearInterval(timerId);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoice.paymentInitiationStatus, latestAutoPayment, lastPaymentStatus]);

  console.log("INVOICE:::BIG ONE", invoice.paymentDoneStatus);

  return (
    <div className="flex justify-center items-center flex-col h-full max-w-[100%]">
      {lastPaymentStatus?.toLowerCase() === "failed" ? (
        <div className="flex justify-center items-center flex-col w-fit">
          <MdError className="h-60 w-60 text-red-500" />
          <div className="flex flex-col text-center">
            <p>This operation failed</p>
            <p>Make sure the customer have sufficient funds</p>
          </div>
        </div>
      ) : (
        <>
          {lastPaymentStatus?.toLowerCase() !== "successful" ? (
            <>
              <p className="lg:text-lg md:text-sm font-semibold">
                Complete Payment of RWF{" "}
                {props?.data?.amountTobePaid?.toLocaleString()}
              </p>
              <p>
                If there is no popup on your phone press *182*7*1# and confirm
                payment
              </p>
            </>
          ) : (
            <div className="text-center">
              <p className="lg:text-lg md:text-sm ">
                You have successfuly completed payment of
              </p>
              <p className="font-semibold">
                {" "}
                {`${getDefaultCurrencyCode(
                  company
                )} ${props?.data?.amountTobePaid?.toLocaleString()}`}
              </p>
            </div>
          )}
          {lastPaymentStatus?.toLowerCase() === "successful" ? (
            <>
              <img
                width="20%"
                height="20%"
                src={SuccessAnimation}
                alt="Successful"
              />
              <Button
                onClick={() => {
                  // navigate("/pos/transactions");
                  resetPaymentRelatedStates()(dispatch);
                }}
                sx={{ minWidth: 250 }}
                className="mt-4 mb-2"
              >
                Finish
              </Button>
            </>
          ) : (
            <img
              width="20%"
              height="20%"
              src={LoadingAnimation}
              alt="loading"
            />
          )}
        </>
      )}
    </div>
  );
};

export default App;
