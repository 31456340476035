import React from "react";
import NoSale from "../NoSale";
import { getDefaultCurrencyCode } from "../../../utils/helper";
import { useSelector } from "react-redux";
import iconOne from "../../../assets/icons/new/moneys.svg";
import iconTwo from "../../../assets/icons/new/box-tick.svg";
import { FaArrowDownLong, FaArrowUpLong } from "react-icons/fa6";
import { calculatePercentageChange } from "../../../utils/converter";
import OverViewCard from "../../../pages/dashboard/dashboardComponents/OveviewCards";

const NosalesSection = (props: any) => {
  const { company } = useSelector((state: any) => state);

  // Total amount copmarison
  const totalCurrentAmount = props?.data?.quantityRevenue?.data?.day2Amount;
  const totalComparedAmount = props?.data?.quantityRevenue?.data?.day1Amount;
  const percentageChange = calculatePercentageChange(
    totalCurrentAmount,
    totalComparedAmount
  );

  // Upcoming amount copmarison
  const currentAmount =
    props?.data?.quantityRevenue?.data?.upcomingPaymentsDay2;
  const comparedAmount =
    props?.data?.quantityRevenue?.data?.upcomingPaymentsDay1;
  const upcomingChange = calculatePercentageChange(
    currentAmount,
    comparedAmount
  );
  // expected amount comparison
  const totalCurrentexpected =
    props?.data?.quantityRevenue?.data?.expectedAmountDay2;
  const totalComparedexpected =
    props?.data?.quantityRevenue?.data?.dexpectedAmountDay1;
  const outstandChange = calculatePercentageChange(
    totalCurrentexpected - totalCurrentAmount,
    totalComparedexpected - totalComparedAmount
  );

  return (
    <div className="flex flex-col 2xl:flex-row  w-full gap-4">
      <div className="flex-1">
        <NoSale data={props?.data?.noSale} periodValue={props?.periodValue} />
      </div>
      <div className="grid grid-cols-2 gap-5 2xl:w-1/2">
        <OverViewCard
          title={"Instalment Amount Paid"}
          amount={`${getDefaultCurrencyCode(company)} ${
            props?.data?.quantityRevenue?.data?.installmentPaid2 || 0
          }`}
          icon={<img src={iconOne} alt="" />}
          comparedAmount={`${getDefaultCurrencyCode(company)} ${
            props?.data?.quantityRevenue?.data?.installmentPaid1 || 0
          }`}
          textColor={"[#F1595C]"}
          parcent={
            <p
              className={`${
                percentageChange?.type === "increase"
                  ? "text-[#57C38A]"
                  : percentageChange?.type === "decrease"
                  ? "text-[#F1595C]"
                  : "text-[#605BFF]"
              }`}
            >
              {" "}
              {`${percentageChange?.percentage}%`}
            </p>
          }
          icon2={
            percentageChange?.type === "increase" ? (
              <FaArrowUpLong color="#57C38A" />
            ) : percentageChange?.type === "decrease" ? (
              <FaArrowDownLong color="#F1595C" />
            ) : (
              ""
            )
          }
          compareText={`${
            percentageChange?.type === "increase"
              ? "Increased"
              : percentageChange?.type === "decrease"
              ? "Decreased"
              : "Increased"
          }`}
          description={
            <div>
              <p>
                Total amount paid by subscriber with loan active account on
                selected day.
              </p>
              {/* <p>
                $2,000{" "}
                <span className="text-[#03022980] text-[12px]">(Upfront Payment)</span>
              </p>
              <p>
                $7,000{" "}
                <span className="text-[#03022980] text-[12px]">(Other Intallment)</span>
              </p> */}
            </div>
          }
        />
        <OverViewCard
          title={"Outstanding Balance"}
          icon={<img src={iconTwo} alt="" />}
          amount={`${getDefaultCurrencyCode(
            company
          )} ${props?.data?.quantityRevenue?.data?.outstandingAmount?.allOutstandingBalance?.toLocaleString()}`}
          textColor={"[#F1595C]"}
          parcent={
            <p
              className={`${
                outstandChange?.type === "increase"
                  ? "text-[#57C38A]"
                  : outstandChange?.type === "decrease"
                  ? "text-[#F1595C]"
                  : "text-[#605BFF]"
              }`}
            >
              {" "}
              {`${outstandChange?.percentage}%`}
            </p>
          }
          icon2={
            outstandChange?.type === "increase" ? (
              <FaArrowUpLong color="#57C38A" />
            ) : outstandChange?.type === "decrease" ? (
              <FaArrowDownLong color="#F1595C" />
            ) : (
              ""
            )
          }
          compareText={`${
            outstandChange?.type === "increase"
              ? "Increased"
              : outstandChange?.type === "decrease"
              ? "Decreased"
              : "Increased"
          }`}
          description={
            <div>Total amount expected to be paid by subscribers</div>
          }
        />
        <OverViewCard
          title={"Late Payments Amount"}
          amount={`${getDefaultCurrencyCode(company)} ${
            props?.data?.quantityRevenue?.data?.latePaymentsDay2 || 0
          }`}
          icon={<img src={iconOne} alt="" />}
          comparedAmount={`${getDefaultCurrencyCode(company)} ${
            props?.data?.quantityRevenue?.data?.latePaymentsDay1 || 0
          }`}
          textColor={"[#F1595C]"}
          parcent={
            <p
              className={`${
                upcomingChange?.type === "increase"
                  ? "text-[#57C38A]"
                  : upcomingChange?.type === "decrease"
                  ? "text-[#F1595C]"
                  : "text-[#605BFF]"
              }`}
            >
              {" "}
              {`${upcomingChange?.percentage}%`}
            </p>
          }
          icon2={
            upcomingChange?.type === "increase" ? (
              <FaArrowUpLong color="#57C38A" />
            ) : upcomingChange?.type === "decrease" ? (
              <FaArrowDownLong color="#F1595C" />
            ) : (
              ""
            )
          }
          compareText={`${
            upcomingChange?.type === "increase"
              ? "Increased"
              : upcomingChange?.type === "decrease"
              ? "Decreased"
              : "Increased"
          }`}
          description={<div>Total amount of missed payment.</div>}
        />
        <OverViewCard
          title={"Total Refunded Amount"}
          icon={<img src={iconOne} alt="" />}
          amount={`${getDefaultCurrencyCode(company)} 0 `}
          comparedAmount={`${getDefaultCurrencyCode(company)} 0 `}
          parcent={"0%"}
          description={
            <div className="flex items-center justify-between pb-2">
              <div className="flex flex-col">
                <div className="text-gray-700 text-sm">
                  <span className="text-gray-500 text-xs">
                    View of refunded payments
                  </span>{" "}
                </div>
              </div>
              {/* <LoadingButton variant="outlined">View Reports</LoadingButton> */}
            </div>
          }
          textColor={"[#F1595C]"}
        />
      </div>
    </div>
  );
};
export default NosalesSection;
