
import { useMediaQuery } from "@mui/material";
import React, { useState, useCallback } from "react";
import { useSelector } from "react-redux";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from "recharts";

const data = [
  {
    name: "Mon",
    sales: 4000,
    purchase: 2400,
    amt: 2400
  },
  {
    name: "Feb",
    sales: 3000,
    purchase: 1398,
    amt: 2210
  },
  {
    name: "Mar",
    sales: 2000,
    purchase: 9800,
    amt: 2290
  },
  {
    name: "Apr",
    sales: 2780,
    purchase: 3908,
    amt: 2000
  },
  {
    name: "May",
    sales: 1890,
    purchase: 4800,
    amt: 2181
  },
  {
    name: "Jun",
    sales: 2390,
    purchase: 3800,
    amt: 2500
  },
  {
    name: "Jul",
    sales: 3490,
    purchase: 4300,
    amt: 2100
  },
  {
    name: "Aug",
    sales: 3590,
    purchase: 4400,
    amt: 2000
  },
  {
    name: "Sep",
    sales: 3690,
    purchase: 4600,
    amt: 2200
  },
  {
    name: "Oct",
    sales: 3790,
    purchase: 4700,
    amt: 2500
  },
  {
    name: "Nov",
    sales: 3890,
    purchase: 4800,
    amt: 2700
  },
  {
    name: "dec",
    sales: 3990,
    purchase: 4900,
    amt: 2100
  }
];

export default function OverviewChart() {
  const [opacity, setOpacity] = useState({
    sales: 1,
    purchase: 1
  });
  const {ebmLayout } = useSelector((state: any) => state);
  const largeScreen = useMediaQuery("(max-width:1280px)");
  const smallScreen = useMediaQuery("(max-width:1024px)");
  return (
    <div style={{ width: "10%"}}>
    <LineChart
      width={ebmLayout?.isSideNavOpen?(largeScreen? 500:700): (largeScreen? 600 :900)}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        {/* <Legend
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        /> */}
        <Line
          type="monotone"
          dataKey="purchase"
          strokeOpacity={opacity.purchase}
          stroke="#DEDEDE"
          activeDot={{ r: 8 }}
        />
        <Line
          type="monotone"
          dataKey="sales"
          strokeOpacity={opacity.sales}
          stroke="#AFADFF"
        />
      </LineChart>
    </div>
  );
}
