import { notification } from "antd";
import { myQuotationActions } from ".";
import {
  createServiceQuotation,
  getAllServiceQuotation,
  updateServiceQuotation,
  getOneServiceQuotationRfqSent,
  getAllQuotationFilters,
  getAllServiceQuotationSupplier,
  updateServiceQuotationRfq,
  createServiceQuotationRfq,
  addOneItemServiceQuotation,
  getOneServiceQuotationRfq,
  sendEmail,
  getOneQuotationItemService,
  deleteQuotationItemService,
  updateQuotationItemService,
  deleteServiceQuotation,
  deleteServiceQuotationToSupplier,
} from "./services";

export const createQuotationAction = (token: string, data: {}) => {
  return async (dispatch: any) => {
    try {
      dispatch(myQuotationActions.setNewIsFetching(true));
      const res = await createServiceQuotation(data, token);
      const resAll = await getAllServiceQuotation(token, "");

      // console.log(">>>>>>>:: ", res);
      if (res?.status === 201) {
        dispatch(myQuotationActions.setNew(res));
        dispatch(myQuotationActions.setAll(resAll));
        dispatch(myQuotationActions.setNewIsFetching(false));
      }
      dispatch(myQuotationActions.setNewIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const deleteQuotationAction = (
  token: string,
  quotId: string,
  query?: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myQuotationActions.setIsFetching(true));
      const res = await deleteServiceQuotation(quotId, token);
      const resAll = await getAllServiceQuotation(token, query);

      // console.log(">>>>>>>:: ", res);
      if (res?.status === 200) {
        dispatch(myQuotationActions.setAll(resAll));
        dispatch(myQuotationActions.setIsFetching(false));
      }
      dispatch(myQuotationActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getAllQuotationsAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myQuotationActions.setIsFetching(true));
      const res = await getAllServiceQuotation(token, query);
      // console.log(">>>>>>>:: ", res);
      if (res?.status === 200) {
        dispatch(myQuotationActions.setAll(res));
        dispatch(myQuotationActions.setIsFetching(false));
      }
      dispatch(myQuotationActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getSelectedQuotationAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myQuotationActions.setIsFetching(true));
      const res = await getAllServiceQuotation(token, query);
      // console.log(">>>>>>>:: ", res);
      if (res?.status === 200 && res?.data.length === 1) {
        dispatch(myQuotationActions.setSelected(res?.data[0]));
        dispatch(myQuotationActions.setIsFetching(false));
      } else if (res?.status === 200 && res?.data.length !== 1) {
        console.log(res);
        notification.error({ message: "RFQ ID Is Not Exist!" });
      }
      dispatch(myQuotationActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const updateSelectedQuotationItemAction = (
  token: string,
  itemId: string,
  data: any,
  query?: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myQuotationActions.setIsFetching(true));
      const res = await updateQuotationItemService(itemId, data, token);
      const resSelected = await getAllServiceQuotation(token, query);
      if (res?.status === 200) {
        dispatch(myQuotationActions.setSelected(resSelected?.data[0]));
        dispatch(myQuotationActions.setIsFetching(false));
        notification.success({ message: "Updated Successfully!" });
      } else {
        console.log(res);
        notification.error({ message: "Failed To Update" });
      }
      dispatch(myQuotationActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const deleteSelectedQuotationItemAction = (
  token: string,
  itemId: string,
  query?: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myQuotationActions.setIsFetching(true));
      const res = await deleteQuotationItemService(itemId, token);
      const resSelected = await getAllServiceQuotation(token, query);
      if (res?.status === 200) {
        dispatch(myQuotationActions.setSelected(resSelected?.data[0]));
        dispatch(myQuotationActions.setIsFetching(false));
        notification.success({ message: "Deleted Successfully!" });
      } else {
        console.log(res);
        notification.error({ message: "Deleting Failed" });
      }
      dispatch(myQuotationActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const addItemToSelectedQuotationAction = (
  id: string,
  data: any,
  token: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myQuotationActions.setIsAddingItem(true));
      const res = await addOneItemServiceQuotation(id, data, token);

      console.log(">>>>>>>:: ", res);
      if (res?.status === 200) {
        const resSelected = await getAllServiceQuotation(
          token,
          `?quotationId=${res?.data.quotationId}`
        );
        dispatch(myQuotationActions.setSelected(resSelected?.data[0]));
        dispatch(myQuotationActions.setIsAddingItem(false));
        return true;
      } else if (res?.status === 200 && res?.data.length !== 1) {
        console.log(res);
        notification.error({ message: "RFQ ID Is Not Exist!" });
        dispatch(myQuotationActions.setIsAddingItem(false));
      }
      dispatch(myQuotationActions.setIsAddingItem(false));
      return false;
    } catch (err) {
      console.log(err);
    }
  };
};

//Rfq Actions

export const createQuotationActionRfq = (token: string, data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myQuotationActions.setIsFetching(true));
      const res = await createServiceQuotationRfq(data, token);
      // console.log("BBBB>>>>",data)
      const resAll = await getAllServiceQuotationSupplier(
        token,
        `?quotation=${data?.quotation}`
      );

      // console.log(">>>>>>>:: ", resAll);
      if (res?.status === 200) {
        dispatch(myQuotationActions.setNewRfq(res));
        dispatch(myQuotationActions.setAllRfqSupplier(resAll));
        dispatch(myQuotationActions.setIsFetching(false));
      }
      dispatch(myQuotationActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const updateQuotationActionRfq = (
  id: string,
  token: string,
  data: any,
  query?: any
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myQuotationActions.setIsFetching(true));
      const res = await updateServiceQuotationRfq(id, data, token);
      // const resAll = await getAllServiceQuotationSupplier(
      //   token,
      //   `?quotation=${data?.quotation}`
      // );
      const resAll = await getAllServiceQuotationSupplier(token, query);
      if (res?.status === 201) {
        dispatch(myQuotationActions.setNewRfq(res));
        dispatch(myQuotationActions.setAllRfqSupplier(resAll));
        dispatch(myQuotationActions.setIsFetching(false));
      }
      dispatch(myQuotationActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const updateQuotationAction = (id: string, data: any, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myQuotationActions.setIsFetching(true));
      const res = await updateServiceQuotation(id, data, token);
      if (res?.status === 200) {
        dispatch(myQuotationActions.setIsFetching(false));
      }
      dispatch(myQuotationActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getAllQuotationSupplierAction = (
  token: string,
  query?: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myQuotationActions.setIsFetching(true));
      const res = await getAllServiceQuotationSupplier(token, query);
      const resFilters = await getAllQuotationFilters(token, query);
      // console.log(">>>>>>>:: ", res);
      if (res?.status === 200) {
        dispatch(myQuotationActions.setAllRfqSupplier(res));
        dispatch(myQuotationActions.setFilters(resFilters.data));
        dispatch(myQuotationActions.setIsFetching(false));
      }
      dispatch(myQuotationActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getOneSupplierQuotationRfq = (id: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myQuotationActions.setIsFetching(true));
      const res = await getOneServiceQuotationRfqSent(id);
      console.log(">>>>Supplier quotation>>>::)) ", res);
      if (res?.status === 200) {
        dispatch(myQuotationActions.setSelectedRfq(res.data));
        dispatch(myQuotationActions.setIsFetching(false));
      }
      dispatch(myQuotationActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getOneSupplierQuotationRfqSent = (id: string, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myQuotationActions.setIsFetching(true));
      const res = await getOneServiceQuotationRfq(id, token);
      // console.log(">>>>Supplier Sent quotation>>>::)) ", res);
      if (res?.status === 200) {
        dispatch(myQuotationActions.setSelectedRfq(res.data));
        dispatch(myQuotationActions.setIsFetching(false));
      }
      dispatch(myQuotationActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const sendEmailAction = (token: string, data: {}) => {
  return async (dispatch: any) => {
    try {
      dispatch(myQuotationActions.setIsSendingEmail(true));
      const res = await sendEmail(data, token);

      if (res?.status === 200) {
        dispatch(myQuotationActions.setIsSendingEmail(false));
        notification.success({ message: "Email Sent Successfully!" });
        return true;
      }
      dispatch(myQuotationActions.setIsSendingEmail(false));
      return false;
    } catch (err) {
      console.log(err);
    }
  };
};

export const getOneQuotationItemAction = (token: string, quotId: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myQuotationActions.setIsFetching(true));
      const res = await getOneQuotationItemService(quotId, token);
      // console.log(">>>>>>>:: ", res);
      if (res?.status === 200) {
        dispatch(myQuotationActions.setSelected(res?.data));
        dispatch(myQuotationActions.setIsFetching(false));
      }
      dispatch(myQuotationActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const deleteQuotToSupplierAction = (
  token: string,
  itemId: string,
  data: any,
  query?: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myQuotationActions.setIsFetching(true));
      const res = await deleteServiceQuotationToSupplier(itemId, data, token);
      const resAll = await getAllServiceQuotationSupplier(token, query);
      if (res?.status === 200) {
        dispatch(myQuotationActions.setAllRfqSupplier(resAll));
        dispatch(myQuotationActions.setIsFetching(false));
      }
      dispatch(myQuotationActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
