import { notification } from "antd";
import { addEbmPurchaseService, getEbmPurchasedServices } from "./service";
import { ebmPurchaseActions } from ".";

export const getEbmPurchasedAction = (token: string, query: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(ebmPurchaseActions.setIsFetching(true));
      const res = await getEbmPurchasedServices(token, query);
      if (res?.status === 200) {
        dispatch(ebmPurchaseActions.setAll(res?.data));
        dispatch(ebmPurchaseActions.setIsFetching(false));
      }
      dispatch(ebmPurchaseActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
      return null;
    }
  };
};
export const addEbmPurchaseAction = (token: string, data: {},query?:string) => {
  return async (dispatch: any) => {
    try {
      dispatch(ebmPurchaseActions.setIsFetching(true));
      const res = await addEbmPurchaseService(data, token);
      // const resAll = await getEbmPurchasedServices(token, query);

      // console.log(">>>>>>>:: ", res);
      if (res?.status === 201) {
        dispatch(ebmPurchaseActions.setNew(res));
        // dispatch(ebmPurchaseActions.setAll(resAll));
        dispatch(ebmPurchaseActions.setIsFetching(false));
        notification.success({ message: "Successfully" });
      }
      dispatch(ebmPurchaseActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
      notification.error({ message: "Failed" });
    }
  };
};