import { BrowserRouter } from "react-router-dom";
// import ResponseDialog from "./components/UI/others/ResponseDialog";
import MainRouter from "./routes/MainRouter";
import { ConfigProvider } from "antd";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import SplashScreen from "./components/layout/SplashScreen_02";
import { useDispatch, useSelector } from "react-redux";
import socketIOClient from "socket.io-client";
import { myLayoutActions } from "./store/layout";
import { COMPANY_PROFILE_ID, SOCKET_SERVER_URL } from "./utils/constants";
import { socketActions } from "./store/socket";
import { Chat } from "./apps";
import AutoLogout from "./components/layout/AutoLogout";
import { getOneCompanyAction } from "./store/setting/company/actions";
// import "@coreui/coreui/dist/css/coreui.min.css";
// import "bootstrap/dist/css/bootstrap.min.css";

const theme = createTheme({
  palette: {
    primary: {
      main: "#4C39D4",
    },
  },
  typography: {
    // fontFamily: "'SF Pro Display', sans-serif",
    fontFamily: "'Poppins', sans-serif",
    fontSize: 12,
    fontWeightLight: 800,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
  components: {},
});

const App = () => {
  const [showSplash, setShowSplash] = useState(true);
  const [staticUser, setStaticUser] = useState<any>(null);
  const { user, token } = useSelector((state: any) => state.auth);
  const { auth, layout, company } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  useEffect(() => {
    setTimeout(() => setShowSplash(false), 7000);
    dispatch(
      myLayoutActions.setIsSideNavOpen(window.innerWidth < 992 ? true : false)
    );
  }, []);

  useEffect(() => {
    if ("Notification" in window) {
      if (Notification.permission === "granted") {
      } else if (Notification.permission !== "denied") {
        Notification.requestPermission().then((permission) => {
          if (permission === "granted") {
          } else {
          }
        });
      }
    }
  }, []);

  const handleNewNotification = (notification: {
    message: string;
    names: string;
    action: string;
    createdAt: string;
  }) => {
    const newNotification = new Notification(`${notification.action}`, {
      body: notification.message,
      icon: "/Force_F_White.svg",
      requireInteraction: true,
    });
    newNotification.onclick = (event) => {
      event.preventDefault();
      window.open("http://force.samphone.co/dashboard", "_blank");
    };
    newNotification.removeEventListener("onClick", () => {});
  };

  // useEffect(() => {
  //   if (staticUser) {
  //     const socket = socketIOClient(`${SOCKET_SERVER_URL}`, {
  //       auth: {
  //         token: user._id,
  //       },
  //     });

  //     socket.on("connect", () => {
  //       console.log("connected");
  //     });

  //     socket.on("newNotification", (notification) => {
  //       handleNewNotification(notification);
  //     });

  //     socket.on("onlineUsers", (onlineUsers) => {
  //       dispatch(socketActions.setOnlineUser(onlineUsers)); //Changes dispatch state
  //     });

  //     dispatch(socketActions.storeSocket(socket));

  //     return () => {
  //       socket.disconnect();
  //     };
  //   }
  // }, [staticUser]);
  const companyId = COMPANY_PROFILE_ID;
  useEffect(() => {
    if (user && user != staticUser) {
      setStaticUser(user);
      auth?.token &&
        getOneCompanyAction(companyId as string, auth.token)(dispatch);
    }
  }, [user, dispatch]);

  return (
    <BrowserRouter>
      <AutoLogout />
      {showSplash || (auth?.profileIsFetching && !auth?.isLoggingIn) ? (
        <SplashScreen />
      ) : (
        <ConfigProvider
          theme={{
            token: {
              fontSize: 12,
              colorPrimary: "#4C39D4",
              // fontFamily: "'SF Pro Display', sans-serif",
              fontFamily: "'Poppins', sans-serif",
            },
            components: { Button: { colorBgElevated: "yellow" } },
          }}
        >
          <ThemeProvider theme={theme}>
            <MainRouter />
            {/* <ResponseDialog /> */}
            {/* {token ? <Chat /> : null} */}
          </ThemeProvider>
        </ConfigProvider>
      )}
    </BrowserRouter>
  );
};

export default App;
