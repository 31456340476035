import React from "react";
import { Divider, Drawer, Image } from "antd";
import { getDefaultCurrencyCode } from "../../utils/helper";
import { useSelector } from "react-redux";

interface DescriptionItemProps {
  title: string;
  content?: React.ReactNode;
}

const DescriptionItem = ({ title, content }: DescriptionItemProps) => (
  <div className="grid grid-cols-2 w-full">
    <p className="font-semibold text-sm w-2/5">{title}</p>
    <span className="w-3/5">{content}</span>
  </div>
);

const SectionTitle: React.FC<{ title: string }> = ({ title }) => (
  <p className="font-bold text-lg" style={{ marginBottom: 10 }}>
    {title}
  </p>
);

const SubscriptionDrawer: React.FC<{
  open: boolean;
  onClose: any;
  data: any;
}> = ({ open, onClose, data }) => {
  const { company } = useSelector((state: any) => state);

  const renderAddress = () => {
    const addressFields = [
      "country",
      "province",
      "district",
      "sector",
      "cell",
      "village",
    ];
    return addressFields.map((field) => (
      <DescriptionItem
        key={field}
        title={field.charAt(0).toUpperCase() + field.slice(1)}
        content={data?.data?.customer?.subscriptionInfo?.address?.[field]}
      />
    ));
  };

  const renderWitnesses = () =>
    data?.data?.customer?.subscriptionInfo?.witnessInfo?.map(
      (el: any, index: number) => (
        <React.Fragment key={index}>
          <div className="flex flex-col items-center w-full">
            <DescriptionItem
              title="Name"
              content={`${el?.name} ${el?.lastName}`}
            />
            <DescriptionItem title="Telephone" content={el?.phone} />
            <DescriptionItem title="Relationship" content={el?.relationship} />
          </div>
          <Divider />
        </React.Fragment>
      )
    );

  const renderDeviceDetails = () => (
    <>
      <DescriptionItem
        title="Type"
        content={data?.data?.list[0]?.priceProduct?.product?.type}
      />
      <DescriptionItem
        title="Brand"
        content={data?.data?.list[0]?.priceProduct?.product?.brand}
      />
      <DescriptionItem title="Model" content={data?.model} />
      <DescriptionItem title="Specification" content={data?.specification} />
      <DescriptionItem title="IMEI" content={data?.imei} />
      <DescriptionItem
        title="Upfront"
        content={`${data?.unpaidAmount} ${getDefaultCurrencyCode(company)}`}
      />
      <DescriptionItem title="Shop" content={data?.shop} />
      <DescriptionItem title="Agent" content={data?.agent} />
    </>
  );

  const renderDocuments = () => (
    <div className="flex space-x-4 items-center w-full">
      <DocumentItem
        title="National ID"
        src={data?.data?.customer?.subscriptionInfo?.nationalIDDoc}
      />
      <DocumentItem
        title="Customer Profile"
        src={data?.data?.customer?.subscriptionInfo?.photoCustomerHoldingID}
      />
    </div>
  );

  const DocumentItem: React.FC<{ title: string; src?: string }> = ({
    title,
    src,
  }) => (
    <div className="flex flex-col space-y-1">
      <h1 className="text-lg font-medium">{title}</h1>
      <Image width={150} src={src} />
    </div>
  );

  return (
    <Drawer
      width={800}
      placement="right"
      closable={false}
      onClose={onClose}
      open={open}
      style={{ color: "black" }}
    >
      <SectionTitle title="Customer Details" />
      <DescriptionItem title="Name" content={data?.data?.customer?.name} />
      <DescriptionItem
        title="Telephone"
        content={data?.data?.customer?.phone}
      />
      <DescriptionItem
        title="National ID"
        content={data?.data?.customer?.nationalId}
      />
      <DescriptionItem title="Email" content={data?.data?.customer?.email} />
      {data?.data?.customer?.otherPhone?.length > 0 && (
        <>
          <p className="font-medium text-sm pt-2" style={{ marginBottom: 4 }}>
            Other Phone:
          </p>
          {data?.data?.customer?.otherPhone?.map((el: any, index: number) => (
            <DescriptionItem
              key={index}
              title={`Tel ${index + 1}:`}
              content={el?.tel}
            />
          ))}
        </>
      )}

      <Divider />
      <SectionTitle title="Address Details" />
      {renderAddress()}

      <Divider />
      <SectionTitle title="Witness Details" />
      {renderWitnesses()}

      <Divider />
      <SectionTitle title="Documents" />
      {renderDocuments()}

      <Divider />
      <SectionTitle title="Device" />
      {renderDeviceDetails()}

      <Divider />
      <SectionTitle title="Allowances" />
      <DescriptionItem title="Bundles" content={data?.data?.other?.bundle} />
      <DescriptionItem title="Call" content={data?.data?.other?.call} />
      <DescriptionItem title="SMS" content={data?.data?.other?.sms} />
    </Drawer>
  );
};

export default SubscriptionDrawer;
