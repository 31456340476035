import React, { useState } from "react";
import { Modal, Table, Tag, message, Popconfirm, Button } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { searchValue } from "../../utils/setColor";
import { getCustomerSubscriptionAction } from "../../store/customer/actions";
import { updateShopStockCartDetailsAction } from "../../store/wareHouse/actions";
import RejectSubscriptionReqForm from "../forms/RejectSubscriptionReqForm";
import SubscriptionDrower from "../Modals/SubscriptionDrower";

const CustomerSubscriptionTable = (props: any) => {
  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(15);
  const { auth, customer, wareHouse } = useSelector((state: any) => state);
  const dataMapper: any[] = [];
  const [formData, setformData] = React.useState(dataMapper);
  const dispatch = useDispatch();
  const { customerId } = useParams();
  const [openModel, setOpenModel] = React.useState(false);
  const [requestId, setRequestId] = useState("");
  const [open, setOpen] = useState(false);
  const [data, setData] = useState("");

  const showDrawer = (d: any) => {
    setOpen(true);
    setData(d);
  };

  const onClose = () => {
    setOpen(false);
    //  setDataToUpdate([]);
  };

  React.useEffect(() => {
    auth?.token &&
      getCustomerSubscriptionAction(
        auth?.token,
        `?customer=${customerId}&isSubscriptionSubmitted=true`
      )(dispatch);
  }, [auth?.token, customerId, dispatch]);

  const value = customer?.customerSubscription;

  React.useEffect(() => {
    customer?.customerSubscription &&
      customer?.customerSubscription?.forEach((el: any) => {
        console.log("el", el);
        dataMapper.push({
          key: el._id,
          date: el?.createdAt?.slice(0, 10),
          nid: el?.customer?.subscriptionInfo?.nationalIDDoc,
          momo:
            el?.customer?.subscriptionInfo?.MomoStatementDoc?.length > 1
              ? el?.customer?.subscriptionInfo?.MomoStatementDoc[0]
              : null,
          shop: el?.shop?.name,
          agent: el?.createdBy?.names,
          imei: el?.list[0]?.warehouseProduct?.serialNumber,
          model: el?.list[0]?.priceProduct?.product?.model,
          plan: el?.subscriptionPlan,
          specification:
            el?.list[0]?.shopStockItem?.requestedItem?.product?.specification
              ?.slice(2, 10)
              ?.map((d: any) => d[1] && `${d[0]}:${d[1]}`)
              ?.join(", "),
          amountPaid: el?.payment?.reduce(
            (total: number, current: any) =>
              parseInt(total + current?.amount)?.toLocaleString(),
            0
          ),
          unpaidAmount: el?.list[0]?.payment?.amount,
          status: el?.status,
          data: el,
        });
      });

    setformData(dataMapper);
  }, [customer?.customerSubscription]);

  const handleAcceptRequest = async () => {
    auth?.token &&
      value[0]?.status === "requested" &&
      (await updateShopStockCartDetailsAction(auth?.token, value[0]?._id, {
        status: "accepted",
      })(dispatch));

    await getCustomerSubscriptionAction(
      auth?.token,
      `?customer=${customerId}&isSubscriptionSubmitted=true`
    )(dispatch);
  };

  const showModal = (value: any) => {
    setOpenModel(true);
    setRequestId(value);
  };

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "NID",
      dataIndex: "nid",
      key: "nid",
      render: (text: any, record: any) => (
        <a
          href={`${text}`}
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-400"
        >
          View NID
        </a>
      ),
    },
    {
      title: "myzaka Statement",
      dataIndex: "momo",
      key: "momo",
      render: (text: any, record: any) =>
        !text ? (
          <p>N/A</p>
        ) : (
          <a
            href={`${text}`}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-400"
          >
            View Myzaka Statement
          </a>
        ),
    },
    {
      title: "Shop",
      dataIndex: "shop",
      key: "shop",
    },
    {
      title: "Agent",
      dataIndex: "agent",
      key: "agent",
    },
    {
      title: "Model",
      dataIndex: "model",
      key: "model",
    },
    {
      title: "IMEI",
      dataIndex: "imei",
      key: "imei",
    },
    {
      title: "Amount Paid",
      dataIndex: "amountPaid",
      key: "amountPaid",
    },
    {
      title: "Unpaid Amount",
      dataIndex: "unpaidAmount",
      key: "unpaidAmount",
      render: (text: any, record: any) => (
        <p>
          {record?.plan === 3
            ? (text + (text * 1.5) / 100)?.toLocaleString()
            : record?.plan === 6
            ? (text + (text * 2.8) / 100)?.toLocaleString()
            : (text + (text * 3.5) / 100)?.toLocaleString()}
        </p>
      ),
    },
    {
      title: "Subscription Period",
      dataIndex: "plan",
      key: "plan",
      render: (text: any, record: any) => (
        <p className="capitalize">{`${text} Months`}</p>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text: any, record: any) => (
        <Tag color={searchValue(text)} className="capitalize">
          {text}
        </Tag>
      ),
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      width: 100,
      render: (text: any, record: any) => (
        <div className="flex items-center space-x-1 cursor-pointer">
          {!["accepted", "rejected", "paid"]?.includes(record?.status) && (
            <div className="flex items-center space-x-1 cursor-pointer">
              <Popconfirm
                title="Accept Request"
                description="Are you sure to Accept this Subscription Request?"
                onConfirm={handleAcceptRequest}
                okText="Confirm"
                cancelText="Cancel"
                okButtonProps={{
                  loading: customer?.isFetching || wareHouse?.isFetching,
                  className: "bg-blue-500",
                }}
              >
                <Button
                  className="capitalize min-w-[50px] bg-green-500 text-white text-center border-none outline-none"
                  style={{ color: "white" }}
                >
                  Accept
                </Button>
              </Popconfirm>
              <Button
                className="capitalize min-w-[50px] text-center outline-none"
                danger
                onClick={() => showModal(record?.key)}
              >
                Reject
              </Button>
            </div>
          )}
          <Button
            className="capitalize min-w-[50px] text-center outline-none"
            onClick={() => {
              showDrawer(record);
            }}
          >
            View
          </Button>
        </div>
      ),
    },
  ];

  const cancelModal = () => {
    setOpenModel(false);
  };

  return (
    <>
      <Table
        columns={columns}
        dataSource={formData}
        pagination={{
          current: currentPage,
          pageSize: pageSize,
          onChange: (page, pageSize) => {
            setCurrentPage(page);
            setPageSize(pageSize);
          },
        }}
        loading={customer?.isFetching || wareHouse?.isFetching}
      />
      <Modal
        open={openModel}
        onCancel={cancelModal}
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { display: "none" } }}
      >
        <RejectSubscriptionReqForm
          onCancel={cancelModal}
          requestId={requestId}
        />
      </Modal>
      <SubscriptionDrower open={open} onClose={onClose} data={data} />
    </>
  );
};

export default CustomerSubscriptionTable;
