import { useEffect, useState } from "react";
import { Stack } from "@mui/material";
import type { DatePickerProps } from "antd";
import { searchValue } from "../../../../utils/setColor";
import { Select, Form, DatePicker, Space } from "antd";
import { selectOptionData } from "../../../../assets/data/interfaces";
import { createQuotationActionRfq } from "../../../../store/quotation/actions";
import dayjs from "dayjs";
import { useSelector, useDispatch } from "react-redux";
import { getAllSuppliersAction } from "../../../../store/supplier/actions";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
};

const PageView = (props: any) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [confirmationDate, setConfirmationDate] = useState<string | string[]>(
    dayjs().format("DD/MM/YYYY")
  );
  const [suppliers, setSuppliers] = useState([""]);
  const { auth, supplier, quotation } = useSelector((state: any) => state);
  const [itemFilter, setItemFilter] = useState([]);
  const onChangeDate: DatePickerProps["onChange"] = async (
    date,
    dateString
  ) => {
    console.log(dateString);
    setConfirmationDate(dateString);
  };

  const handleSaving = async () => {
    auth?.token &&
      quotation.selected &&
      createQuotationActionRfq(auth.token, {
        quotation: quotation.selected._id,
        suppliers: suppliers,
        confirmationDate,
      })(dispatch);
  };
  const onFinish = async (values: any) => {
    console.log("Received values of form: ", values);
  };
  const listSuppliers = supplier?.all?.data.map(
    (sup: any): selectOptionData => {
      return { label: `${sup?.name} (${sup?.type})`, value: sup._id };
    }
  );

  useEffect(() => {
    handleSaving();
  }, [confirmationDate, suppliers]);

  useEffect(() => {
    auth?.token && getAllSuppliersAction(auth?.token, "?")(dispatch);
  }, [auth?.token, dispatch]);

  type Option = {
    product?: {
      model?: string;
    };
    label?: string;
  };

  type SetFilteredOptions = React.Dispatch<
    React.SetStateAction<Option[] | any>
  >;

  const handleSearch = (
    value: string,
    data: any,
    filterFunction: (option: Option, value: string) => boolean,
    setFilteredOptions: SetFilteredOptions
  ) => {
    const filtered = data?.filter((option: any) =>
      filterFunction(option, value)
    );
    setFilteredOptions(filtered);
  };

  return (
    <div className="px-8 py-6 bg-white rounded-sm">
      <Stack spacing={2}>
        <Stack spacing={1} direction="row" justifyContent={"space-between"}>
          <Space direction="vertical">
            <h4 className="text-[#030229] font-medium text-sm"> Request For Quotation</h4>
            <p className="text-sm font-normal text-[#605BFF]">
              {quotation.selected.quotationId}
            </p>
          </Space>
          <Stack>
            <div className="bg-[#F4F6FA] rounded-[5px] w-[156px] h-[34px] pl-[.65rem] pt-[.21rem]">
              <Space><p className="text-[.8rem] font-normal">Status</p>
                <p className="text-white text-[.8rem] text-center pt-[.19rem] font-normal rounded-[5px] w-[85px] h-[24px] capitalize" style={{ background: searchValue(quotation?.selected?.status) }}> {quotation?.selected?.status}</p>
              </Space>
            </div>
          </Stack>
        </Stack>

        <Form
          {...formItemLayout}
          form={form}
          name="register"
          onFinish={onFinish}
          initialValues={{}}
          layout="vertical"
          scrollToFirstError
        >
          <Stack
            direction="row"
            justifyContent={"space-between"}
            alignItems="center"
          >
            {quotation?.selected?.status === "draft" && (
              <Form.Item
                name="supplier"
                label="Select Supplier"
                rules={[
                  {
                    required: true,
                    message: "Please select Supplier!",
                  },
                ]}
              >
                <Select
                  onSearch={(value: any) =>
                    handleSearch(
                      value,
                      supplier?.all?.data,
                      (option: any, val) =>
                        option?.product?.model
                          ?.toLowerCase()
                          .includes(val.toLowerCase()),
                      setItemFilter
                    )
                  }
                  showSearch
                  style={{ width: 400 }}
                  allowClear
                  loading={supplier.isFetching || quotation.isFetching}
                  disabled={
                    quotation?.selected?.status !== "draft" ||
                    supplier.isFetching ||
                    quotation.isFetching
                  }
                  placeholder="Select Supplier"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "").toString().includes(input)
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toString()
                      .toLowerCase()
                      .localeCompare(
                        (optionB?.label ?? "").toString().toLowerCase()
                      )
                  }
                  options={listSuppliers}
                  mode="multiple"
                  onChange={async (value: any) => {
                    console.log(
                      "Supplier::",
                      value,
                      "  quotation::",
                      quotation.selected._id
                    );
                    quotation?.selected?.status === "draft" &&
                      setSuppliers(value);
                    // await handleSaving();
                  }}
                />
              </Form.Item>
            )}
            <Space>
              <p className="text-[#030229] mb-6 font-medium">Confirmation Deadline : </p>
              <Form.Item style={{ color: '#030229' }}>
                <DatePicker
                  disabled={
                    quotation?.selected?.status !== "draft" ||
                    supplier.isFetching ||
                    quotation.isFetching
                  }
                  defaultValue={dayjs(confirmationDate.toString(), "DD/MM/YYYY")}
                  format="DD/MM/YYYY"
                  onChange={onChangeDate}
                />
              </Form.Item>{" "}
            </Space>
          </Stack>
        </Form>
      </Stack>
    </div>
  );
};
export default PageView;
