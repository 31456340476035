import React from "react";
import ArrowUpwardOutlinedIcon from "@mui/icons-material/ArrowUpwardOutlined";
import ArrowDownwardOutlinedIcon from "@mui/icons-material/ArrowDownwardOutlined";
import { Select } from "antd";
import { useMediaQuery } from "@mui/material";
import { useSelector } from "react-redux";
import { getDefaultCurrencyCode } from "../../utils/helper";

export const StatsCard = (props: any) => {
  const { company } = useSelector((state: any) => state);
  const isMobile = useMediaQuery("(max-width: 600px)");
  return (
    <div
      className={`d-flex shadow ${
        isMobile ? "min-w-[80vw]" : "min-w-[30vw] h-[22vh]"
      } bg-white rounded-lg p-4 text-black space-y-3`}
    >
      <div className="flex justify-between">
        <span
          className={`text-gray-500 ${
            isMobile ? "text-[4vw]" : "text-[1.5vw]"
          } font-bold`}
        >
          {props?.title}
        </span>
        {props.type && (
          <Select
            defaultValue={props.type}
            showSearch
            style={{
              width: 110,
            }}
          />
        )}
      </div>
      <div className="flex justify-between">
        <div className="flex flex-col">
          <div className="flex flex-row">
            <div
              style={{
                border: "3px solid #4c39d4",
              }}
              className="flex w-fit h-fit rounded-md p-3"
            >
              <img
                src={props?.icon}
                alt=""
                className={isMobile ? `w-[50px] h-[50px]` : `w-11 h-11`}
              />
            </div>

            <div className="flex flex-1 items-center">
              <span
                className={`pl-2 ${
                  isMobile ? "text-[6vw]" : "text-[2.2vw]"
                } font-bold`}
              >
                {!props.isQuantity ? `${getDefaultCurrencyCode(company)}` : ""}
                {props?.today?.toLocaleString()}
              </span>{" "}
            </div>
          </div>
          <span
            className={`pt-2 pb-2 ${
              isMobile ? "text-[3vw]" : "text-[1.4vw]"
            } text-gray-500`}
          >
            Compared to{" "}
            {!props.isQuantity ? `${getDefaultCurrencyCode(company)}` : ""}
            {props?.yesterday?.toLocaleString()} of yesterday
          </span>
        </div>
        {/* {props?.percent && (
          <div
            className={`flex items-center w-fit min-w-[70px] min-h-[30px] text-lg ${
              props?.percent?.type === "increase"
                ? "text-[#0FA958]"
                : props?.percent?.type === "decrease"
                ? "text-red-500"
                : "text-blue-900"
            } rounded-xl px-2`}
          >
            {props?.percent?.type === "increase" ? (
              <ArrowUpwardOutlinedIcon className="" />
            ) : props?.percent?.type === "decrease" ? (
              <ArrowDownwardOutlinedIcon className="" />
            ) : (
              ""
            )}
            {`${props?.percent?.percentage}%`}
          </div>
        )} */}
      </div>
      {props?.compareDate && (
        <p className="absolute bottom-2.5 right-6 text-[#6F767E]">
          Compared to {props?.compareDate}
        </p>
      )}
    </div>
  );
};
