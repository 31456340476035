import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Stack } from "@mui/material";
import DetailsHeaderActionBar from "../../../components/cards/DetailsPage/DetailsHeaderActionBar";
import SubscriptionRequestTable from "../../../components/tables/SubscriptionRequestTable";
import SearchInput from "../../../components/buttons/SearchButton";
import { getAllApplicationAction } from "../../../store/myZaka/actions";
import { useDispatch, useSelector } from "react-redux";
import CSVExportButton from "../../../components/cards/NoSale/CSVExportButton";
import { applicantRequest_CSVAction } from "../../../store/csvDownload/actions";
import { applicantRequestHeader } from "../../../components/csvHeaders/Headers";
import ScrollableFrame from "../../../components/layout/ScrollableFrame";

const SubscriptionRequest = (_props: any) => {
  const { auth, layout, CSV, myZaka } = useSelector((state: any) => state);
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  const dispatch = useDispatch();
  const [searchKey, setSearchKey] = useState("");

  const [limit, setLimit] = useState(15);
  const [page, setPage] = useState(1);

  const handleSearch = () => {
    if (searchKey && auth?.token) {
      getAllApplicationAction(auth?.token, `?sk=${searchKey}`)(dispatch);
    }
  };

  useEffect(() => {
    if (layout?.openCSVButton && auth?.token)
      applicantRequest_CSVAction(
        auth?.token,
        `?limit=${myZaka?.all?.total}&page=0`
      )(dispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layout?.openCSVButton]);

  useEffect(() => {
    if (auth?.token) {
      getAllApplicationAction(
        auth?.token,
        `?limit=${limit}&page=${page - 1}`
      )(dispatch);
    }
  }, [auth?.token, dispatch, limit, page]);

  useEffect(() => {
    if ([""]?.includes(searchKey)) {
      getAllApplicationAction(
        auth?.token,
        `?limit=${limit}&page=${page - 1}`
      )(dispatch);
    }
  }, [auth?.token, searchKey, dispatch, limit, page]);

  const csvData = CSV?.csvDownloadedData?.data?.map((el: any) => {
    return {
      date: el?.createdAt?.slice(0, 10),
      shop: el?.cart?.shop?.name || "N/A",
      customerId: el?.cart?.customer?.customerId,
      fname: el?.cart?.customer?.firstName,
      lname: el?.cart?.customer?.lastname,
      msisdn: el?.msisdn,
      city: el?.cart?.customer?.city,
      currentResidence: el?.cart?.customer?.currentResidence,
      tnc: el?.cart?.customer?.tnc,
      loanAppID: el?.loanapplicationid,
      plan: "Monthly",
      upfront: el?.cart?.payment?.at(0)?.amount?.toLocaleString() || "",
      planPeriod: `${el?.cart?.account?.duration} Month` || "N/A",
      businessProductType: el?.cart?.selectedPlan?.businessProductType || "N/A",
      pickedUpDevice: el?.cart?.status?.toLowerCase() === "paid" ? "Yes" : "No",
      status: el?.status,
    };
  });

  return (
    <div className="text-black  py-6 scrollbar-hide overflow-y-auto h-screen">
      <div className="flex flex-row items-center text-[#0F0F47] pb-4">
        <DetailsHeaderActionBar
          pageName={"Application"}
          goBack={goBack}
          title="Request"
        />
      </div>

      <div className="px-5 py-0.5 rounded-md flex justify-end gap-5 mb-4">
        <SearchInput
          onSearch={handleSearch}
          placehorder="Search by MSISDN or Loarn ID"
          onChange={(e: any) => {
            e.preventDefault();
            setSearchKey(e.target.value?.trimStart());
          }}
        />
        <CSVExportButton
          csvHeaders={applicantRequestHeader}
          csvData={csvData}
          filename={`application_request.csv`}
        />
      </div>
      <Stack
        spacing={3}
        className="flex-grow flex flex-col justify-between bg-white scrollbar-hide"
      >
        <ScrollableFrame
          setPage={setPage}
          setLimit={setLimit}
          limit={limit}
          total={myZaka?.all?.total}
          count={Math.ceil(myZaka?.all?.total / limit)}
        >
          <SubscriptionRequestTable
            setLimit={setLimit}
            setPage={setPage}
            limit={limit}
          />
        </ScrollableFrame>
      </Stack>
    </div>
  );
};

export default SubscriptionRequest;
