import React from "react";
import { Select, Table, Tag } from "antd";
import type { ColumnsType } from "antd/es/table";
import { searchValue } from "../../../utils/setColor";
import { useSelector } from "react-redux";
import ScrollableFrame from "../../layout/ScrollableFrame";
import { getDefaultCurrencyCode } from "../../../utils/helper";
import dayjs from "dayjs";

interface DataType {
  key: React.Key;
  fname: string;
  lname: string;
  age: number;
  address: string;
  msisdn: string;
}

const CustomerTransactionTable = (props: any) => {
  const { report } = useSelector((state: any) => state);

  const dataRow = report?.customerTransaction?.data?.map((el: any) => {
    return {
      amountPaid: el?.AmountPaid,
      amountToBePaid: el?.amountToBePaid,
      AmountRemain: el?.AmountRemain,
      // allInvoices: el?.allInvoices?.map((item?: any) => {
      //   return {
      //     transaction: item?.transaction,
      //     account: {
      //       accountId: item?.account?.accountID,
      //       customer: item?.account?.customer?.name,
      //       phone:
      //         item?.account?.customer?.msisdn || item?.account?.customer?.phone,
      //     },
      //     dueDate: item?.dueDate,
      //     status: item?.status,
      //   };
      // }),
      customer: `${el?.customer?.firstName ?? " "} ${
        el?.customer?.lastname ?? ""
      }`,
      duration: el?.duration,
      status: el?.status,
      dueDate: dayjs(el?.endingDate).format("YYYY-MM-DD HH:mm:ss"),
      phone: el?.customer?.otherMsisdns?.at(0),
    };
  });

  // Flatten the data
  // const transactionData = dataRow?.flatMap((item: any) => {
  //   return (
  //     item?.allInvoices.map((invoice: any) => ({
  //     {  mPayment: item?.amountToBePaid,
  //       customerName: item?.account?.customer || 'N/A',
  //       mPaid: item?.amountPaid,
  //       mPaidremain: item?.AmountRemain,
  //       transactionId: item?.transaction,
  //       accountId: item?.account.accountId,
  //       customerTel: item?.account?.phone,
  //       date: item?.dueDate?.slice(0, 10),
  //       status: item?.status,}
  //     }))
  //     || []
  //   );
  // });

  // const mergedData = transactionData?.map(
  //   (item: any, index: number, arr: any) => {
  //     const isFirstInstanceName =
  //       index === 0 || item?.customerName !== arr[index - 1]?.customerName;

  //     const isFirstInstanceTel =
  //       index === 0 || item.customerTel !== arr[index - 1]?.customerTel;
  //     return {
  //       ...item,
  //       rowSpanName: isFirstInstanceName
  //         ? arr.filter((i: any) => i?.customerName === item?.customerName)?.length
  //         : 0,
  //       rowSpanTel: isFirstInstanceTel
  //         ? arr?.filter((i: any) => i?.customerTel === item?.customerTel)?.length
  //         : 0,
  //     };
  //   }
  // );

  const columns = [
    {
      title: "Customer Name",
      dataIndex: "customer",
      key: "customer",
      //   render: (value: string, _: any, index: number) => {
      //     const { rowSpanName } = mergedData[index];
      //     return {
      //       children: value,
      //       props: { rowSpan: rowSpanName },
      //     };
      //   },
    },
    {
      title: "MSISDN",
      dataIndex: "phone",
      key: "phone",
      // render: (value: string, _: any, index: number) => {
      //   const { rowSpanTel } = mergedData[index];
      //   return {
      //     children: value,
      //     props: { rowSpan: rowSpanTel },
      //   };
      // },
    },
    // {
    //   title: "Transaction Id",
    //   dataIndex: "transactionId",
    //   key: "transactionId",
    // },
    // {
    //   title: "Account Id",
    //   dataIndex: "accountId",
    //   key: "accountId",
    // },
    {
      title: "Amount to be paid",
      dataIndex: "amountToBePaid",
      key: "amountToBePaid",
    },
    {
      title: "Amount paid",
      dataIndex: "amountPaid",
      key: "amountPaid",
    },
    {
      title: "Amount remain",
      dataIndex: "AmountRemain",
      key: "AmountRemain",
    },
    {
      title: "Duration",
      dataIndex: "duration",
      key: "duration",
    },
    {
      title: "Due date",
      dataIndex: "dueDate",
      key: "dueDate",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text: any, record: any) => (
        <Tag
          color={searchValue(text)}
          className="capitalize py-0.5 min-w-[60px] text-center"
        >
          {text}
        </Tag>
      ),
    },
  ];
  const onChange = (value: string) => {
    props?.setSelectedStatus(value);
  };

  const onSearch = (value: string) => {
    console.log("search:", value);
  };

  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <div className="bg-white p-4 text-gray-900 xh-[67vh]">
      <div className="flex items-center space-x-4 pb-6">
        <h1 className="font-semibold">Payment History</h1>
        {/* <Select
          showSearch
          placeholder="Filter by Status"
          optionFilterProp="children"
          onChange={onChange}
          onSearch={onSearch}
          filterOption={filterOption}
          defaultValue={"All"}
          allowClear
          style={{ width: "10%" }}
          options={[
            {
              value: "",
              label: "All",
            },
            {
              value: "active",
              label: "Active",
            },
            {
              value: "closed",
              label: "Closed",
            },
            {
              value: "ongoing",
              label: "Ongoing",
            },
            {
              value: "draft",
              label: "Draft",
            },
          ]}
        /> */}
      </div>
      <ScrollableFrame
        customSize={500}
        setPage={props?.setPage}
        setLimit={props?.setLimit}
        limit={props?.limit}
        total={report?.customerTransaction?.total}
        count={Math.ceil(report?.customerTransaction?.total / props?.limit)}
      >
        <Table
          dataSource={dataRow}
          columns={columns}
          loading={report?.isFetching}
          // rowKey={(record) => record?.transactionId}
          pagination={false}
        />
      </ScrollableFrame>
    </div>
  );
};

export default CustomerTransactionTable;
