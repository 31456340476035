import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Paper, Stack } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { Select, Form, Input, notification, SelectProps } from "antd";
import {
  createSubscriptionPriceListAction,
  updateSubscriptionPriceListAction,
} from "../../../../store/subscription/priceList/actions";
import { getSubscriptionPricelistAction } from "../../../../store/channel/actions";
import { useLocation, useParams } from "react-router-dom";
import { getActivePrice } from "../../../../utils/converter";
import "./subscription.css";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 22 },
  },
};

const PageView = (props: any) => {
  const [form] = Form.useForm();
  const { auth, channel, subPriceList } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const { subChannelId } = useParams();
  const [selectedSubType, setSelectedSubType] = useState<any>("");
  const [selectBeforeValue, setSelectBeforeValue] = useState<any>(0.0);
  const [amountToshow, setAmountToshow] = useState<any>(0);
  const location = useLocation();

  const bundleOptions: SelectProps["options"] = [];
  const smsOptions: SelectProps["options"] = [];
  const callOptions: SelectProps["options"] = [];

  const hasNotChannel = () => {
    return ["/subscription/devices"].some((path) =>
      location.pathname.includes(path)
    );
  };
  const isChannelNotExist = hasNotChannel();

  const phonesPrice = getActivePrice(
    channel?.selectedPriceListItems?.data?.prices ?? props?.dataToUpdate?.prices
  );

  const onFinish = async (values: any) => {
    const payload = {
      ...values,
      _id: props?.dataToUpdate?._id,
      pricelistItem: channel?.selectedPriceListItems?.data?._id,
      initialPayment: amountToshow,
    };

    if (props?.dataToUpdate) {
      const updatePayload = { plansToUpdate: [payload] };
      await updateSubscriptionPriceListAction(
        updatePayload,
        auth?.token
      )(dispatch);
      notification.success({ message: "Updated Succesfully" });
    } else {
      await createSubscriptionPriceListAction(auth?.token, [payload])(dispatch);
    }
    props?.onCancel();

    await getSubscriptionPricelistAction(
      auth?.token,

      `?channel=${
        isChannelNotExist ? subChannelId : channel?.selected?._id
      }&paymentType=${props?.selectePlanPlanType}&product=${
        props?.setSelectedModelId
      }`
    )(dispatch);
    form.resetFields();
  };

  const initialData = props?.dataToUpdate?.subscriptionPlan?.map(
    (data: any) => {
      return {
        _id: data?._id,
        dailyInstalment: data?.dailyInstalment,
        duration: data?.duration,
        initialPayment: data?.initialPayment,
        monthlyInstalment: data?.monthlyInstalment,
        weeklyInstalment: data?.weeklyInstalment,
        bundle: data?.bundle,
        call: data?.call,
        sms: data?.sms,
      };
    }
  );

  useEffect(() => {
    form.setFieldsValue({
      initialPayment: amountToshow,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amountToshow]);

  const handleSelectBeforeChange = (value: any) => {
    setSelectBeforeValue(value);
    if (phonesPrice !== undefined) {
      const result = (phonesPrice * value) / 100;
      setAmountToshow(result);
    } else {
      setAmountToshow(0);
    }
  };

  useEffect(() => {
    handleSelectBeforeChange(selectBeforeValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phonesPrice]);

  const handleAmountInputChange = (amount: any) => {
    const result = (amount / phonesPrice) * 100;
    setSelectBeforeValue(result?.toFixed(1));
    setAmountToshow(amount);
  };

  useEffect(() => {
    if (props?.dataToUpdate) {
      setAmountToshow(props?.dataToUpdate?.initialPayment);
      handleAmountInputChange(props?.dataToUpdate?.initialPayment);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.dataToUpdate]);

  return (
    <Stack spacing={0}>
      {/* {!props?.dataToUpdate && <PriceListFilter />} */}
      <Paper elevation={0}>
        <Stack spacing={0}>
          <Paper elevation={0} className="pl-4 pr-4 pt-2 pb-2">
            <Form
              {...formItemLayout}
              form={form}
              name="register"
              layout="vertical"
              onFinish={onFinish}
              initialValues={props?.dataToUpdate || {}}
              style={{ maxWidth: "100%" }}
              scrollToFirstError
            >
              <Stack spacing={0} direction="column">
                {/* {!initialData && ( */}
                <div className="grid grid-cols-2">
                  {!initialData && (
                    <>
                      <Form.Item
                        name="businessProductType"
                        label="Business Product Type"
                        rules={[
                          {
                            required: true,
                            message: "Subscription Type is required!",
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          loading={channel.isFetching}
                          disabled={channel.isFetching}
                          placeholder="Select Subscription Type"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            (option?.label ?? "")?.toString().includes(input)
                          }
                          // onChange={(value: any) => {
                          //   setSelectedSubType([value]);
                          //   dispatch(
                          //     myLayoutActions.setSubscriptionType(value)
                          //   );
                          // }}
                          filterSort={(optionA, optionB) =>
                            (optionA?.label ?? "")
                              ?.toString()
                              ?.toLowerCase()
                              ?.localeCompare(
                                (optionB?.label ?? "")
                                  ?.toString()
                                  ?.toLowerCase()
                              )
                          }
                          options={channel?.selected?.services?.map(
                            (pro: any) => ({
                              label: `${pro?.businessProduct?.name}`,
                              value: pro?.businessProduct?.name,
                            })
                          )}
                        />
                      </Form.Item>
                      <Form.Item
                        name="paymentType"
                        label="Payment Type"
                        rules={[
                          {
                            required: true,
                            message: "Payment Type is required!",
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          loading={channel.isFetching}
                          disabled={channel.isFetching}
                          placeholder="Select Payment Type"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            (option?.label ?? "").toString().includes(input)
                          }
                          onChange={(value: any) => {
                            setSelectedSubType(value);
                          }}
                          filterSort={(optionA, optionB) =>
                            (optionA?.label ?? "")
                              ?.toString()
                              ?.toLowerCase()
                              ?.localeCompare(
                                (optionB?.label ?? "")
                                  ?.toString()
                                  ?.toLowerCase()
                              )
                          }
                          options={["Subscription"]?.map((pro: any) => ({
                            label: `${pro}`,
                            value: pro.toLowerCase(),
                          }))}
                        />
                      </Form.Item>
                    </>
                  )}

                  <Form.Item
                    name="initialPayment"
                    label={`Initial Payment (${selectBeforeValue}%)`}
                    initialValue={
                      props?.dataToUpdate?.subscriptionPlan?.[0]?.initialPayment
                    }
                  >
                    <Input
                      type="number"
                      disabled={!phonesPrice && !props?.dataToUpdate}
                      addonBefore={
                        <>
                          <Input
                            disabled={!phonesPrice && !props?.dataToUpdate}
                            style={{
                              width: "60px",
                              border: "none",
                              outline: "none",
                              backgroundColor: "#fafafa",
                            }}
                            className="bg-red-200"
                            value={`${selectBeforeValue}`}
                            onChange={(e) =>
                              handleSelectBeforeChange(e.target.value)
                            }
                          />
                        </>
                      }
                      value={amountToshow}
                      onChange={(e) => handleAmountInputChange(e.target.value)}
                      placeholder={`${amountToshow}`}
                    />
                  </Form.Item>

                  <>
                    <Form.Item
                      name="duration"
                      label="Plan Duration"
                      initialValue={
                        props?.dataToUpdate?.subscriptionPlan?.[0]?.duration
                      }
                      rules={[
                        {
                          required: true,
                          message: "Subscription Plan is required!",
                        },
                      ]}
                    >
                      <Input type="number" />
                    </Form.Item>

                    {!initialData && (
                      <Form.Item
                        name="monthlyInstalment"
                        initialValue={
                          props?.dataToUpdate?.subscriptionPlan?.[0]
                            ?.monthlyInstalment
                        }
                        label="Monthly Instalment"
                        rules={[
                          {
                            required: true,
                            message: "Monthly Instalment is required!",
                          },
                        ]}
                      >
                        <Input type="number" />
                      </Form.Item>
                    )}
                  </>

                  {/* Subscription data plan */}
                  {
                    <div className="flex gap-x-2 flex-wrap">
                      <Form.Item
                        name="bundle"
                        initialValue={
                          props?.dataToUpdate?.subscriptionPlan?.[0]?.bundle
                        }
                        label="Bundle"
                      >
                        <Select
                          mode="tags"
                          style={{ width: 150 }}
                          placeholder="Values in form of Tags"
                          defaultValue={
                            props?.dataToUpdate?.subscriptionPlan?.[0]?.bundle
                          }
                          options={bundleOptions}
                        />
                      </Form.Item>
                      <Form.Item
                        name="sms"
                        label="SMS"
                        initialValue={
                          props?.dataToUpdate?.subscriptionPlan?.[0]?.sms
                        }
                      >
                        <Select
                          style={{ width: 150 }}
                          mode="tags"
                          placeholder="Values in form of Tags"
                          defaultValue={
                            props?.dataToUpdate?.subscriptionPlan?.[0]?.sms
                          }
                          options={smsOptions}
                        />
                      </Form.Item>
                      <Form.Item
                        name="call"
                        label="Call"
                        initialValue={
                          props?.dataToUpdate?.subscriptionPlan?.[0]?.call
                        }
                      >
                        <Select
                          mode="tags"
                          style={{ width: 150, marginLeft: "2px" }}
                          placeholder="Values in form of Tags"
                          defaultValue={
                            props?.dataToUpdate?.subscriptionPlan?.[0]?.call
                          }
                          options={callOptions}
                        />
                      </Form.Item>
                    </div>
                  }
                </div>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  className="w-32"
                  loading={subPriceList?.isFetching}
                >
                  {props?.dataToUpdate ? "Update" : "Submit"}
                </LoadingButton>
              </Stack>
            </Form>
          </Paper>
        </Stack>
      </Paper>
    </Stack>
  );
};

export default PageView;
