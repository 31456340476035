import React, { useEffect, useState } from "react";
import DetailsHeaderActionBar from "../../../components/cards/DetailsPage/DetailsHeaderActionBar";
import { useNavigate, useParams } from "react-router-dom";
import CustomerCard from "../../../components/cards/customerCard";
import { Card, Tabs } from "antd";
import Index from "./Billings";
import { useDispatch, useSelector } from "react-redux";
import CustomerDevices from "./Devices/CustomerDevices";
import {
  getApplicationMyzakaStatusAction,
  getCustomerDevicesAction,
  getCustomerInvoiceAction,
  getCustomerMetricsAction,
  getCustomerNoSaleAction,
  getCustomerNotesAction,
} from "../../../store/customer/actions";
import { getAllAccountAction } from "../../../store/account/actions";
import { getOneCompanyAction } from "../../../store/setting/company/actions";
import CustomerRepairTable from "../../../components/tables/CustomerRepairTable";
import NotesCard from "../subscription/Accounts/components/NotesCard";
import CustomerNoSaleTable from "../../../components/tables/CustomerNoSaleTable";
import Overviewcard from "../../../components/cards/dashboard/Overviewcard";
import iconOne from "../../../assets/icons/new/box-tick.svg";
import iconTwo from "../../../assets/icons/new/profile-2user.svg";
import iconThree from "../../../assets/icons/new/moneys.svg";
import { getDefaultCurrencyCode } from "../../../utils/helper";
import { getAllTransactionByCustomerAction } from "../../../store/myZaka/actions";
import TransactionTable from "../../../components/tables/salesTable/TransactionTable";
import {
  getAllReportTransactions_CSVAction,
  getTransactionByCustomer_CSVAction,
} from "../../../store/csvDownload/actions";

const DetailsPage = (props: any) => {
  const { auth, customer, company, CSV, myZaka, layout } = useSelector(
    (state: any) => state
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [limit, setLimit] = useState(15);
  const [page, setPage] = useState(1);
  const [status, setStatus] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { customerId } = useParams();
  const goBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    auth?.token &&
      customer?.selected?._id &&
      getCustomerInvoiceAction(
        auth?.token,
        `${customer?.selected?._id}?limit=${limit}&page=${page}&status=${status}`
      )(dispatch);
  }, [customer?.selected?._id, dispatch, page, limit, status]);

  useEffect(() => {
    auth?.token &&
      getOneCompanyAction(
        company?.selected?._id as string,
        auth.token
      )(dispatch);
  }, [auth.token, dispatch, company?.selected?._id]);

  useEffect(() => {
    if (auth?.token && customerId) {
      getCustomerNotesAction(
        auth?.token,
        `?customerId=${customerId}`
      )(dispatch);
      getCustomerNoSaleAction(
        auth?.token,
        `?customerId=${customerId}`
      )(dispatch);
      getAllAccountAction(auth?.token, `?customer=${customerId}`)(dispatch);
      getCustomerDevicesAction(
        auth?.token,
        `?customerId=${customerId}`
      )(dispatch);
      getCustomerMetricsAction(customerId, auth.token)(dispatch);
      getApplicationMyzakaStatusAction(
        auth.token,
        `?customerId=${customerId}`
      )(dispatch);
    }
  }, [auth?.token, dispatch, customerId]);

  useEffect(() => {
    if (auth?.token && customer?.selected?.msisdn)
      getAllTransactionByCustomerAction(
        auth?.token,
        `?limit=10&page=0&msisdn=${customer?.selected?.msisdn}`
      )(dispatch);
  }, [auth?.token, dispatch, customer?.selected?.msisdn]);

  useEffect(() => {
    if (
      layout?.dataToExport === "invoice" &&
      layout?.openCSVButton &&
      auth?.token
    ) {
      getAllReportTransactions_CSVAction(
        auth?.token,
        `${customer?.selected?._id}?limit=${props?.data?.customerInvoice?.total}&page=0`
      )(dispatch);
    } else if (
      layout?.dataToExport === "transaction" &&
      layout?.openCSVButton &&
      auth?.token
    ) {
      getTransactionByCustomer_CSVAction(
        auth?.token,
        `?limit=${myZaka?.customerTransaction?.total}&page=0&msisdn=${customer?.selected?.msisdn}`
      )(dispatch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layout?.openCSVButton, layout?.dataToExport]);

  const tabsComponent = [
    {
      title: (
        <h1 className="text-base">
          {`Invoices & Billings ( ${customer?.customerInvoice?.total ?? 0}) `}
        </h1>
      ),
      components: (
        <div>
          <Index
            data={customer}
            limit={limit}
            setLimit={setLimit}
            page={page}
            setPage={setPage}
            setStatus={setStatus}
          />
        </div>
      ),
    },
    {
      title: (
        <h1 className="text-base">
          Devices ({customer?.customerDevices?.total ?? "0"})
        </h1>
      ),
      components: <CustomerDevices />,
    },
    // auth?.user?.tag !== "mascom" && {
    //   title: <h1 className="text-base">Files</h1>,  TO BE IMPLEMENTED
    //   components: <File />,
    // },
    {
      title: (
        <h1 className="text-base">
          Notes ({customer?.customerNotes?.total ?? 0})
        </h1>
      ),
      components: (
        <div>
          <NotesCard notes={customer?.customerNotes?.data} />
        </div>
      ),
    },
    {
      title: (
        <h1 className="text-base">
          Repairs ({customer?.customerRepairs?.total ?? 0})
        </h1>
      ),
      components: (
        <div>
          <CustomerRepairTable />
        </div>
      ),
    },
    {
      title: (
        <h1 className="text-base">{`No Sales (${
          customer?.customerNoSale?.total ?? 0
        })`}</h1>
      ),
      components: (
        <div>
          <CustomerNoSaleTable data={customer?.customerNoSale?.data} />
        </div>
      ),
    },
    {
      title: (
        <h1 className="text-base">{`Transactions (${
          myZaka?.customerTransaction?.total ?? 0
        })`}</h1>
      ),
      components: (
        <div>
          <TransactionTable />
        </div>
      ),
    },
  ];
  const items = tabsComponent.map((el: any, i) => {
    const id = String(i + 1);
    return {
      label: el?.title,
      key: id,
      children: el?.components,
    };
  });

  return (
    <div className="text-black p-1 mt-3 space-y-2">
      <div className="flex items-center justify-between">
        <DetailsHeaderActionBar
          goBack={goBack}
          pageName={props?.pageName}
          title={props?.title}
        />
      </div>
      <div className="xl:flex gap-5 mt-5">
        <div className="lg:flex gap-5 mt-5">
          {customer?.isFetching ? (
            <Card loading={loading} style={{ minWidth: 500 }}>
              <Card.Meta />
            </Card>
          ) : (
            <CustomerCard data={customer?.selected} />
          )}
        </div>

        {customer?.isMetrixFetching ? (
          <div className="mt-5 w-full grid grid-cols-3 gap-2">
            {[1, 2, 3, 1, 2, 3]?.map((el: any) => (
              <Card loading={true} style={{ minWidth: 300 }}>
                <Card.Meta />
              </Card>
            ))}
          </div>
        ) : (
          <div className="mt-5 w-full grid grid-cols-3 gap-2">
            <>
              <Overviewcard
                hiddePercentage={true}
                name={"All Devices Purchased"}
                icon={iconThree}
                currentNumber={`${
                  customer?.customerMetrics?.totalDevices?.toLocaleString() ?? 0
                } Piece(s)`}
                footer={
                  <div className="flex items-center justify-between pb-2.5">
                    <div className="flex flex-col">
                      <div className="text-gray-700 text-sm">
                        <span className="text-gray-500 text-xs">
                          The number of devices the customer has purchased
                        </span>{" "}
                      </div>
                    </div>
                  </div>
                }
              />
              <Overviewcard
                hiddePercentage={true}
                name={"Total Amount Paid"}
                icon={iconThree}
                currentNumber={`${
                  customer?.customerMetrics?.totalAmountPaid?.toLocaleString() ??
                  0
                } ${getDefaultCurrencyCode(company)}`}
                footer={
                  <div className="flex items-center justify-between pb-2.5">
                    <div className="flex flex-col">
                      <div className="text-gray-700 text-sm">
                        <span className="text-gray-500 text-xs">
                          Total amount the customer has paid
                        </span>{" "}
                      </div>
                    </div>
                  </div>
                }
              />
              <Overviewcard
                hiddePercentage={true}
                name={"Upcoming Payment Amount"}
                icon={iconThree}
                currentNumber={`${
                  customer?.customerMetrics?.nextBillingAmount?.toLocaleString() ??
                  0
                } ${getDefaultCurrencyCode(company)}`}
                footer={
                  <div className="flex items-center justify-between">
                    <div className="flex flex-col">
                      <div className="text-gray-700 text-sm">
                        <span className="text-gray-500 text-xs mr-2">
                          The next payment date:
                        </span>
                        <span className="text-gray-500 text-xs font-semibold">
                          {customer?.customerMetrics?.nextBillingDate?.slice(
                            0,
                            10
                          ) ?? "N/A"}
                        </span>
                      </div>
                    </div>
                  </div>
                }
              />
              <Overviewcard
                hiddePercentage={true}
                name={"Total Expected Amount"}
                icon={iconThree}
                currentNumber={`${customer?.customerMetrics?.totalExpectedAmount?.toLocaleString()} ${getDefaultCurrencyCode(
                  company
                )}`}
                footer={
                  <div className="flex items-center justify-between pb-2.5">
                    <div className="flex flex-col">
                      <div className="text-gray-700 text-sm">
                        <span className="text-gray-500 text-xs">
                          The total amount the customer will pay for the device
                        </span>{" "}
                      </div>
                    </div>
                  </div>
                }
              />
              <Overviewcard
                hiddePercentage={true}
                name={"Total Outstanding Amount"}
                icon={iconThree}
                currentNumber={`${
                  customer?.customerMetrics?.totalPendingLateAmount?.toLocaleString() ??
                  0
                } ${getDefaultCurrencyCode(company)}`}
                footer={
                  <div className="flex items-center justify-between pb-2.5">
                    <div className="flex flex-col">
                      <div className="text-gray-700 text-sm">
                        <span className="text-gray-500 text-xs">
                          The remaining amount
                        </span>{" "}
                      </div>
                    </div>
                  </div>
                }
              />
              <Overviewcard
                hiddePercentage={true}
                name={"No Sales Amount"}
                icon={iconOne}
                currentNumber={`${
                  customer?.customerMetrics?.totalNoSaleAmount?.toLocaleString() ??
                  0
                } ${getDefaultCurrencyCode(company)}`}
                footer={
                  <div className="flex items-center justify-between pb-2.5">
                    <div className="flex flex-col">
                      <div className="text-gray-700 text-sm">
                        <span className="text-gray-500 text-xs">
                          The total number no-sales
                        </span>{" "}
                      </div>
                    </div>
                  </div>
                }
              />
            </>
          </div>
        )}
      </div>

      <div className="mt-5 py-5 ">
        <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5">
          <Overviewcard
            hiddePercentage={true}
            name={"Total Applications Requested"}
            icon={iconTwo}
            currentNumber={
              customer?.applicationMyzakaStatus?.data &&
              customer?.applicationMyzakaStatus?.data?.totalRequested
            }
            footer={
              <div className="flex items-center justify-between pb-2.5">
                <div className="flex flex-col">
                  <div className="text-gray-700 text-sm">
                    <span className="text-gray-500 text-xs">
                      The number of applications that have been requested.
                    </span>{" "}
                  </div>
                </div>
              </div>
            }
          />
          <Overviewcard
            hiddePercentage={true}
            name={"Total Applications Closed"}
            icon={iconTwo}
            currentNumber={
              customer?.applicationMyzakaStatus?.data &&
              customer?.applicationMyzakaStatus?.data?.CLOSED
            }
            footer={
              <div className="flex items-center justify-between pb-2.5">
                <div className="flex flex-col">
                  <div className="text-gray-700 text-sm">
                    <span className="text-gray-500 text-xs">
                      The number of applications that have been Closed.
                    </span>{" "}
                  </div>
                </div>
              </div>
            }
          />
          <Overviewcard
            hiddePercentage={true}
            name={"Total Applications Rejected"}
            icon={iconTwo}
            currentNumber={
              customer?.applicationMyzakaStatus?.data &&
              customer?.applicationMyzakaStatus?.data?.REJECTED
            }
            footer={
              <div className="flex items-center justify-between pb-2.5">
                <div className="flex flex-col">
                  <div className="text-gray-700 text-sm">
                    <span className="text-gray-500 text-xs">
                      The number of applications that have been Rejected by the
                      customer
                    </span>{" "}
                  </div>
                </div>
              </div>
            }
          />
          <Overviewcard
            hiddePercentage={true}
            name={"Total Applications Approved"}
            icon={iconTwo}
            currentNumber={
              customer?.applicationMyzakaStatus?.data &&
              customer?.applicationMyzakaStatus?.data?.APPROVED
            }
            footer={
              <div className="flex items-center justify-between pb-2.5">
                <div className="flex flex-col">
                  <div className="text-gray-700 text-sm">
                    <span className="text-gray-500 text-xs">
                      The number of applications that have been approved.
                    </span>{" "}
                  </div>
                </div>
              </div>
            }
          />
        </div>
      </div>
      <Tabs items={items} />
    </div>
  );
};

export default DetailsPage;
