import { Paper, Box, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Badge, Divider } from "antd";
import { searchValue } from "../../../utils/setColor";

const Component = (props: any) => {
  const navigate = useNavigate();
console.log("props vv propsprops",props)
  return (
    <div
      className="relative min-w-[380px] h-44 xpx-2 pb-4 bg-white text-black rounded-md shadow"
      onClick={() => navigate(`/inventory/ws/out/req/${props.data.id}`)}
    >
      <Badge.Ribbon
        style={{ padding: "4px" }}
        color={searchValue(props?.data?.status)}
        text={<span className="capitalize">{props?.data?.status}</span>}
      >
        <Stack spacing={2} sx={{ p: 2 }}>
          <Stack
            direction="row"
            justifyContent={"space-between"}
            alignItems="center"
            sx={{ width: "100%" }}
          >
            <Stack spacing={0}>
              <h1 className="text-base font-semibold leading-3 text-gray-900 pb-2">
                {props?.data?.requestId}
              </h1>
              <p className="text-sm w-60" style={{ color: "blue" }}>
                {props?.data?.shopName}
              </p>
            </Stack>
          </Stack>
          <Divider />
          <Box sx={{ width: "100%" }}>
            <Stack
              direction="row"
              justifyContent={"space-between"}
              alignItems="flex-end"
              sx={{ width: "100%" }}
            >
              <Stack spacing={0}>
                <p className="text-xs">
                  Approved As:{" "}
                  <span className="font-semibold text-xs">
                    {props?.data?.data?.isTransferred
                      ? "Transfer"
                      : "Warehouse Out"}
                  </span>
                </p>
                <p className="text-xs">
                  Approved By:{" "}
                  <span className="font-semibold text-xs">
                    {props?.data?.data?.approvedBy?.names}
                  </span>
                </p>
                <p className="text-xs">
                  Approved On:{" "}
                  <span className="font-semibold text-xs">
                    {props?.data?.data?.updatedAt?.split("T")[0]}
                  </span>
                </p>
                <p className="text-xs">
                  Created On:{" "}
                  <span className="font-semibold text-xs">
                    {props?.data?.date}
                  </span>
                </p>
              </Stack>
              <p className="text-xs">
                <span className="font-semibold text-xs">
                  {props?.data?.numberReq} items
                </span>
              </p>
            </Stack>
          </Box>
        </Stack>
      </Badge.Ribbon>
    </div>
  );
};

export default Component;
