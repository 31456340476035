import React from "react";
import { Table } from "antd";
import { useSelector } from "react-redux";
import { getNameByCode } from "../../utils/ConvertCodeToName";

const ManageItemTable = (props: any) => {
  const { auth, ebmGeneral } = useSelector((state: any) => state);
  const columns = [
    {
      title: "Item Code",
      dataIndex: "code",
    },
    {
      title: "Item Name",
      dataIndex: "name",
    },
    {
      title: "Origin",
      dataIndex: "country",
      render: (text: any) => (
        <>
          <p className="capitalize">
            {ebmGeneral?.selectOption?.Cuntry &&
              getNameByCode(
                ebmGeneral?.selectOption?.Cuntry,
                text
              )?.toLowerCase()}
          </p>
        </>
      ),
    },
    {
      title: "Item Type",
      dataIndex: "type",
      render: (text: any) => (
        <>
          <p>
            {ebmGeneral?.selectOption["Item Type"] &&
              getNameByCode(ebmGeneral?.selectOption["Item Type"], text)}
          </p>
        </>
      ),
    },
    {
      title: "Pack Unit",
      dataIndex: "park",
      render: (text: any) => (
        <>
          <p>
            {ebmGeneral?.selectOption["Packing Unit"] &&
              getNameByCode(ebmGeneral?.selectOption["Packing Unit"], text)}
          </p>
        </>
      ),
    },
    {
      title: "Qty Unit",
      dataIndex: "unit",
      render: (text: any) => (
        <>
          <p>
            {ebmGeneral?.selectOption["Quantity Unit"] &&
              getNameByCode(ebmGeneral?.selectOption["Quantity Unit"], text)}
          </p>
        </>
      ),
    },
    {
      title: "Purchase Price",
      dataIndex: "purchase",
    },
    {
      title: "Sale Price",
      dataIndex: "sale",
    },
    {
      title: "Beginning Stock",
      dataIndex: "stock",
    },
    {
      title: " ",
      dataIndex: "sale",
      render: (text: any) => <></>,
    },
  ];
  const data = ebmGeneral?.allItems?.map((el: any, index: any) => {
    return {
      key: index,
      code: el?.itemCd,
      name: el?.itemNm,
      park: el?.pkgUnitCd,
      country: el?.orgnNatCd,
      type: el?.itemTyCd,
      unit: el?.qtyUnitCd,
      purchase: "y",
      sale: el?.dftPrc,
      stock: el?.sftyQty,
    };
  }) || [];
  return (
    <>
      <Table
        columns={columns}
        dataSource={data}
        size="middle"
        loading={ebmGeneral?.isFetching}
      />
    </>
  );
};

export default ManageItemTable;
