import store from "store";
import { authActions } from ".";
import {
  createResetPasswordLinkService,
  createResetPasswordService,
  getResetPasswordTokenStatus,
  loginService,
  profileService,
  updatePasswordService,
  updatePinService,
  userProfileBasicService,
} from "./authServices";
import { notification } from "antd";

export const loginAction = (data: {}) => {
  return async (dispatch: any) => {
    try {
      dispatch(authActions.setIsFetching(true));
      const res = await loginService(data);
      if (res?.status === 200) {
        dispatch(authActions.setIsLoggingIn(true));
        // store.set("authToken", res?.data?.data?.token);
        // store.set("user", res?.data?.data?.user);
        // dispatch(
        //   authActions.login({ ...res?.data, status: res?.status, isAuth: true })
        // );
        dispatch(authActions.setIsFetching(false));
        return { type: true, token: res?.data?.data?.token };
      } else {
        dispatch(authActions.login({ error: res.data, isAuth: false }));
        dispatch(authActions.login({ error: null }));
        dispatch(authActions.setIsFetching(false));
        return { type: false, token: "" };
      }
    } catch (err) {
      dispatch(authActions.setIsFetching(false));
      notification.error({
        message: "You might be experiencing network issues.",
      });
      console.error(err);
    }
  };
};

export const profileAction = (token: String) => {
  return async (dispatch: any) => {
    try {
      dispatch(authActions.setIsFetching(true));
      const res = await profileService(token);
      // console.log(">>>>>>>:: ", res);
      if (res?.status === 200) {
        dispatch(authActions.setUser(res.data));
        dispatch(authActions.setIsFetching(false));
      }
    } catch (err) {
      // dispatch(authActions.login({ isAuth: false }));
      // dispatch(authActions.setIsFetching(false));
      console.log(err);
    }
  };
};

export const getUserprofileAction = (token: String, navigate?: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(authActions.setProfileIsFetching(true));
      const res = await profileService(token);
      if (res?.status === 200) {
        dispatch(authActions.setIsLoggingIn(false));
        store.set("authToken", token);
        dispatch(authActions.setUser(res.data?.user));
        dispatch(authActions.setIsUserLoggedIn(true));
        dispatch(authActions.setProfileIsFetching(false));
      } else if ([undefined, 500, 401]?.includes(res?.status)) {
        localStorage.clear();
        dispatch(authActions.setToken(" "));
        navigate("/login");
        dispatch(authActions.setProfileIsFetching(false));
      }
      dispatch(authActions.setProfileIsFetching(false));
    } catch (err) {
      console.log(err);
      dispatch(authActions.setProfileIsFetching(false));
    }
  };
};

export const userProfileBasicAction = (email: String) => {
  return async (dispatch: any) => {
    try {
      dispatch(authActions?.setIsFetching(true));
      const res = await userProfileBasicService(email);
      // console.log(">>>>>>>:: ", res);
      if (res?.status === 200) {
        dispatch(authActions.setProfile(res.data));
        dispatch(authActions.setIsFetching(false));
      }
    } catch (err) {
      // dispatch(authActions.login({ isAuth: false }));
      // dispatch(authActions.setIsFetching(false));
      console.log(err);
    }
  };
};

export const updateUserPasswordActions = (data: any, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(authActions.setIsFetching(true));
      const res = await updatePasswordService(data, token);
      if (res?.status === 200) {
        dispatch(authActions.logout());
        notification.success({ message: "Password updated successfully" });
      } else {
        dispatch(authActions.setIsFetching(false));
        notification.error({ message: "Current Password is Incorrect " });
      }
    } catch (err) {
      console.log(err);
    }
  };
};
export const updateUserPinActions = (data: any, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(authActions.setIsFetching(true));
      const res = await updatePinService(data, token);
      if (res?.status === 200) {
        notification.success({ message: "Pin updated successfully" });
      } else {
        dispatch(authActions.setIsFetching(false));
        notification.error({ message: "Incorrect" });
      }
    } catch (err) {
      console.log(err);
    }
  };
};
export const createResetPasswordLinkAction = (data: {}) => {
  return async (dispatch: any) => {
    try {
      dispatch(authActions.setIsFetching(true));
      const res = await createResetPasswordLinkService(data);
      // notification.success({ message: "Email Send Check your Inbox" });
      // console.log(">>>>>>>:: ", res.data);
      if (res?.status === 201) {
        dispatch(authActions.setResetLink(res));
        dispatch(authActions.setIsFetching(false));
        notification.success({ message: "Email Sent Check Inbox" });
      }
      dispatch(authActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const createResetPasswordAction = (data: {}, resetToken: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(authActions.setIsFetching(true));
      const res = await createResetPasswordService(data, resetToken);

      // console.log(">>>>>>>:: ", res.data);
      if (res?.status === 200) {
        dispatch(authActions.setResetPassword(res));
        dispatch(authActions.setIsFetching(false));
        notification.success({ message: "Success" });
        return true;
      }
      dispatch(authActions.setIsFetching(false));
      return false;
    } catch (err) {
      console.log(err);
    }
  };
};
export const getResetPasswordTokenStatusAction = (query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(authActions.setIsResetPasswordFetching(true));
      const res = await getResetPasswordTokenStatus(query);
      // console.log(">>>>>>>All status:: ", res);
      if (res?.status === 200) {
        dispatch(authActions.setResetTokenExpiredStatus(res));
        dispatch(authActions.setIsResetPasswordFetching(false));
        return true;
      }
      dispatch(authActions.setIsResetPasswordFetching(false));
      return false;
    } catch (err) {
      dispatch(authActions.setIsResetPasswordFetching(false));
      console.log(err);
    }
  };
};

export const changeUserPasswordActions = (data: any, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(authActions.setIsFetching(true));
      const res = await updatePasswordService(data, token);
      if (res?.status === 200) {
        notification.success({ message: "Password updated successfully" });
      }
      dispatch(authActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
