import React from "react";
import { Divider, Table } from "antd";
import type { TableColumnsType } from "antd";

interface DataType {
  key: React.Key;
  names: string;
  paymentDate: string;
  bill: string;
  paid: string;
  method: string;
  date: string;
  status: string;
}

const columns: TableColumnsType<DataType> = [
  {
    title: "Customer Names",
    dataIndex: "names",
  },
  {
    title: "Payment Date",
    dataIndex: "paymentDate",
  },
  {
    title: "Bill Amount",
    dataIndex: "bill",
  },
  {
    title: "Amount Paid",
    dataIndex: "paid",
  },
  {
    title: "Payment Method",
    dataIndex: "method",
  },
  {
    title: "Due Date",
    dataIndex: "date",
  },
  {
    title: "Status",
    dataIndex: "status",
  },
];

const data: DataType[] = [
  {
    key: "1",
    names: "Jane Mirember",
    paymentDate: "3024-11-08",
    bill: "$300",
    paid: "$300",
    method: "Momo Pay",
    date: "3024-11-08",
    status: "Overdue",
  },
  {
    key: "2",
    names: "Jane Mirember",
    paymentDate: "3024-11-08",
    bill: "$300",
    paid: "$300",
    method: "Momo Pay",
    date: "3024-11-08",
    status: "Upcoming",
  },
  {
    key: "3",
    names: "Jane Mirember",
    paymentDate: "3024-11-08",
    bill: "$300",
    paid: "$300",
    method: "Momo Pay",
    date: "3024-11-08",
    status: "Paid",
  },
  {
    key: "4",
    names: "Jane Mirember",
    paymentDate: "3024-11-08",
    bill: "$300",
    paid: "$300",
    method: "Momo Pay",
    date: "3024-11-08",
    status: "Overdue",
  },
  {
    key: "5",
    names: "Jane Mirember",
    paymentDate: "3024-11-08",
    bill: "$300",
    paid: "$300",
    method: "Momo Pay",
    date: "3024-11-08",
    status: "Overdue",
  },
  {
    key: "6",
    names: "Jane Mirember",
    paymentDate: "3024-11-08",
    bill: "$300",
    paid: "$300",
    method: "Momo Pay",
    date: "3024-11-08",
    status: "Overdue",
  },
  {
    key: "7",
    names: "Jane Mirember",
    paymentDate: "3024-11-08",
    bill: "$300",
    paid: "$300",
    method: "Momo Pay",
    date: "3024-11-08",
    status: "Overdue",
  },
  {
    key: "8",
    names: "Jane Mirember",
    paymentDate: "3024-11-08",
    bill: "$300",
    paid: "$300",
    method: "Momo Pay",
    date: "3024-11-08",
    status: "Overdue",
  },
];

const PaymentHistorySubscriptionTable: React.FC = () => (
  <>
    <Table<DataType> columns={columns} dataSource={[]} pagination={false} />
  </>
);

export default PaymentHistorySubscriptionTable;
