import React, { useState } from "react";
import { Checkbox, Progress, Table, Tag } from "antd";
import type { ColumnsType } from "antd/es/table";
import Inactive from "../../../../assets/images/subscription/inactive.png";
import { useSelector } from "react-redux";
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from "recharts";
import { FaArrowUpLong, FaArrowUpRightFromSquare } from "react-icons/fa6";
import { Select } from "antd";
import { purple, red, blue, magenta } from "@ant-design/colors";
import "./style.css";
const handleChange = (value: string) => {
  console.log(`selected ${value}`);
};
interface DataType {
  key: string;
  brand: string;
  yesterday: string;
  today: string;
}

const columns: ColumnsType<DataType> = [
  {
    title: "Brand",
    dataIndex: "brand",
    key: "brand",
    width: 200,
    render: (text: any, record: any) => (
      <>
        <div className="flex justify-between">
          <div
            className={`bg-[#57C38A] w-[16px] h-[12px] rounded-sm mt-1`}
          ></div>{" "}
          <h1>{text}</h1>
        </div>
      </>
    ),
  },
  {
    title: "Yesterday's Qty",
    dataIndex: "yesterday",
    key: "yesterday",
    width: 200,
    render: (text: any, record: any) => (
      <>
        <div className="flex items-center space-x-2">
          {" "}
          <h1>{text}</h1>
        </div>
      </>
    ),
  },
  {
    title: "Today's Qty",
    dataIndex: "today",
    width: 200,
    key: "today",
    render: (text: any, record: any) => (
      <>
        <div className="flex items-center justify-between">
          <h1>{text}</h1>
          <div className="flex items-center gap-3">
            <div className={`text-[#57C38A]`}>0.21%</div>
            <FaArrowUpLong color="#57C38A" size={15} />
          </div>
        </div>
      </>
    ),
  },
];

const DevicesView = () => {
  const { overView } = useSelector((state: any) => state);
  const [checkedValue, setCheckedValue] = useState("type");
  const data: DataType[] = [
    {
      key: "1",
      brand: "Galaxy s32",
      yesterday: "100",
      today: "3000",
    },
    {
      key: "2",
      brand: "iPhone 30",
      yesterday: "200",
      today: "390",
    },
    {
      key: "3",
      brand: "Camon 30",
      yesterday: "40",
      today: "2000",
    },
  ];
  const plainOptions = ["type", "brand", "model"];
  const onChange = (e: any) => {
    const { value, checked } = e.target;
    setCheckedValue(checked ? value : "");
  };
  return (
    <div className="w-full bg-[#fff] h-[f28rem] px-5 rounded-md">
      <div className="text-[#030229] text-lg flex justify-between">
        <p>Total Device sold</p>
      </div>
      <div className="text-[#0F0F47] font-medium text-base pt-3">
        <p>0 Pieces</p>
      </div>
      <div className="text-[#03022980] font-medium text-base">
        <p>0 Pieces</p>
      </div>
      <div className="w-full flex gap-1 py-2">
        <div className="w-[45%] bg-[#5F00FF] rounded-l-lg h-2.5 "></div>
        <div className="w-1/4 bg-[#8A56E2] h-2.5 "></div>
        <div className="w-1/4 bg-[#CF56E2]  h-2.5"></div>
        <div className="w-1/4 bg-[#E256AE]  h-2.5 "></div>
        <div className="w-[15%] bg-[#E25668] rounded-r-lg h-2.5 "></div>
      </div>
      <div className="w-full flex justify-between">
        <p className="text-[#0F0F47]  text-sm pt-5">Overall Sales</p>
        {/* <div className="flex items-center gap-3">
          <div className={`text-[#57C38A]`}>0.21%</div>
          <FaArrowUpLong color="#57C38A" size={15} />
        </div> */}
      </div>

      <div className="my-5 flex items-center justify-center w-full">
        {plainOptions.map((option: any) => (
          <Checkbox
            key={option}
            value={option}
            checked={checkedValue === option}
            onChange={onChange}
            className="custom-checkbox"
          >
            <span className="capitalize">{option}</span>
          </Checkbox>
        ))}
      </div>
      <Table
        columns={columns}
        dataSource={[]}
        loading={overView?.isFetching}
        pagination={false}
      />
    </div>
  );
};

export default DevicesView;
