import React, { useState } from "react";
import { Checkbox, Progress, Table, Tag } from "antd";
import type { ColumnsType } from "antd/es/table";
import Inactive from "../../../../assets/images/subscription/inactive.png";
import { useSelector } from "react-redux";
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from "recharts";
import { FaArrowUpRightFromSquare } from "react-icons/fa6";
import { Select } from "antd";
import { purple, red, blue, magenta } from "@ant-design/colors";
import CustomizedPieChart from "./CustomizedPieChart";
import SubscriptionCustomizedPieChart from "./SubscriptionCustomizedPieChart";

interface planProps {
  title?: any;
  parcent?: any;
  number?: any;
}
interface DataType {
  key: string;
  name: string;
  level: string;
  history: string;
  payment: string;
}
export const PlanLevel = ({ title, parcent, number }: planProps) => {
  return (
    <>
      <div className="border border-[#0302291A] p-5 w-full rounded-md text-black flex justify-between mt-5">
        <div className="w-[80%]">
          <div className="flex justify-between mb-1">
            <span className="text-sm  text-[#030229] ">{title}</span>
            <span className="text-sm font-medium text-[#030229B2]">
              {parcent}
            </span>
          </div>
          <div className="w-full bg-[#605BFF26] rounded-full h-2.5 ">
            <div
              className="bg-[#605BFF] h-2.5 rounded-full"
              style={{ width: "45%" }}
            ></div>
          </div>
        </div>
        <p className="text-sm text-[#030229] pt-4">{number}</p>
      </div>
    </>
  );
};
const TopSellingPlans = () => {
  const handleChange = (value: string) => {
    console.log(`selected ${value}`);
  };
  const columns = [
    {
      title: "Plans",
      dataIndex: "plan",
      key: "plan",
      width: 100,
    },
    {
      title: "Customers",
      dataIndex: "customer",
      key: "customer",
      width: 200,
      render: (text: any, record: any) => (
        <>
          <div className="flex items-center space-x-2">
            <h1>{text}</h1>
          </div>
        </>
      ),
    },
    {
      title: "Total Amount",
      dataIndex: "amount",
      key: "amount",
    },
  ];
  const datas = [
    {
      key: "1",
      plan: "CHIC SC",
      customer: "Galaxy s32",
      amount: "$3000",
      history: "32",
    },
    {
      key: "2",
      name: "Jim Green",
      customer: "iPhone 30",
      amount: "London No. 1 Lake Park",
      history: "32",
    },
    {
      key: "3",
      name: "Joe Black",
      customer: "Camon 30",
      amount: "Sydney No. 1 Lake Park",
      history: "2",
    },
  ];
  const data = [
    { name: "3 Months", value: 80 },
    { name: "12 Months", value: 45 },
    { name: "6 Months", value: 25 },
    { name: "24 Months", value: 25 },
  ];

  const COLORS = ["#5F00FFB2", "#E25668B2", "#E256AEB2", "#E28956B2"];
  return (
    <div className="w-full bg-[#fff] overflow-hidden p-5 overflow-y-auto h-[650px] rounded-md">
      <div className="text-[#030229] text-lg flex justify-between">
        <p>Top Selling Plans</p>
      </div>
      <div>
        <SubscriptionCustomizedPieChart
          data={data}
          colors={COLORS}
          totalLabel={<h1 className="text-[12px]">Total Plans</h1>}
          innerRad={43}
          height={"180px"}
          contentWidth={"w-full"}
          top={"50%"}
          left={"37%"}
          outerRad={60}
          totalValue={0}
          pieHeight={200}
          pieWidth={300}
        />
      </div>
      <div className="mt-5">
        <Table columns={columns} dataSource={[]} pagination={false} />
      </div>
    </div>
  );
};

export default TopSellingPlans;
