import React from "react";
import "chartkick/chart.js";
import { useEffect, useState } from "react";
import { getKPIStatsAction } from "../../store/dashboard/actions";
import { RevenueKpiContainer } from "../../components/dashboard/Sections/StatsRevenueQuantity";
import { useDispatch, useSelector } from "react-redux";
import NoSaleKPI from "../../components/dashboard/NoSaleKPI";
import moment from "moment";
import { Carousel } from "@trendyol-js/react-carousel";
import { useMediaQuery } from "@mui/material";
import { RightCircleFilled, LeftCircleFilled } from "@ant-design/icons";
import { StatsCard } from "../../components/statistics/statsCard";
import SamphoneLogo from "../../assets/Samphone_Logo_White.png";

const StatisticsPage = () => {
  const { dashboard } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width: 600px)");
  const isTablet = useMediaQuery("(max-width: 960px)");

  const formattedDate = moment().format("YYYY-MM-DD");
  let today = moment();
  let previousDate = today.subtract(1, "days");
  let formattedPreviousDate = previousDate.format("YYYY-MM-DD");

  const [enteredDate, setEnteredDate] = useState<any>(formattedDate);
  const [compareDate, setCompareDate] = useState<any>(formattedPreviousDate);
  const [refetch, setRefetch] = useState<any>(0);
  const [isFullscreen, setIsFullscreen] = useState(true);

  const toggleFullscreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
      setIsFullscreen(true);
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
        setIsFullscreen(false);
      }
    }
  };

  useEffect(() => {
    setInterval(() => {
      setRefetch(Math.random());
    }, 20000);
  }, []);

  useEffect(() => {
    getKPIStatsAction(`?date1=${enteredDate}&date2=${compareDate}`)(dispatch);
  }, [enteredDate, compareDate, refetch]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: isMobile ? "100%" : "100vh",
        backgroundColor: "#041C32",
        background: `linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(3,27,75,1) 48%)`,
      }}
    >
      <div
        style={{
          cursor: "pointer",
        }}
        onClick={toggleFullscreen}
      >
        <img
          style={{
            fontSize: "1.3vw",
            fontWeight: "bold",
            color: "#fff",
            padding: "0 0 1vw 4vw",
            marginTop: "3vh",
            width: isMobile ? "60vw" : "20vw",
          }}
          src={SamphoneLogo}
          alt=""
        />
        <h1
          style={{
            fontSize: isMobile ? "3vw" : "1.3vw",
            color: "#fff",
            padding: "0 0 0 4vw",
          }}
        >
          MASCOM's SALES KPI DASHBOARD
        </h1>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          flexWrap: "wrap",
          justifyContent: "space-evenly",
          gap: isMobile ? "2vw" : "0.5vw",
          padding: isMobile ? `3vh 5vw 1vh 5vw` : `3vh 2.5vw 1vh 2.5vw`,
        }}
      >
        <div
          style={{
            display: "flex",
            minWidth: "65vw",
          }}
        >
          <RevenueKpiContainer
            enteredDate={enteredDate}
            compareDate={compareDate}
          />
        </div>
        <div
          style={{
            display: "flex",
            minWidth: "26vw",
          }}
        >
          <NoSaleKPI />
        </div>
      </div>
      <div className="mx-12">
        <Carousel
          hideArrows={false}
          autoSwipe={3000}
          swiping={true}
          slide={1}
          dynamic={true}
          show={isMobile ? 1 : isTablet ? 2 : 3}
          transition={2}
          infinite={true}
          useArrowKeys={true}
          className="py-4"
          leftArrow={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                backgroundColor: "#4c39d4",
                borderRadius: "0.5rem",
                margin: "0 0 0 1vw",
                height: "100%",
              }}
            >
              <button>
                <LeftCircleFilled
                  style={{
                    color: "#fff",
                    fontSize: "1.5rem",
                  }}
                />
              </button>
            </div>
          }
          rightArrow={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                backgroundColor: "#4c39d4",
                borderRadius: "0.5rem",
                alignContent: "center",
                margin: "0 1vw 0 0",
                height: "100%",
              }}
            >
              <button>
                <RightCircleFilled
                  style={{
                    fontSize: "1.5rem",
                    color: "#fff",
                  }}
                />
              </button>
            </div>
          }
        >
          <div
            key={1}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <StatsCard
              title={"Quantities sold"}
              icon="https://global-uploads.webflow.com/620f23faa47fca69300e0430/63ac68a00a308b71074a35c5_Stats%20-%20Qty%20Icon-svg.svg"
              isQuantity={true}
              today={dashboard?.kpiStats?.data?.quantity?.todaysQuantity}
              yesterday={dashboard?.kpiStats?.data?.quantity?.comparedQuantity}
            />
          </div>
          <div
            key={2}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <StatsCard
              title={"Data Activated"}
              icon="https://global-uploads.webflow.com/620f23faa47fca69300e0430/63ac67c7b966f513dd8d09ee_Stats%20-%20Data%20Icon-svg.svg"
              isQuantity={true}
              today={
                dashboard?.kpiStats?.data?.dataActivation?.todaysdataActivation
              }
              yesterday={
                dashboard?.kpiStats?.data?.dataActivation
                  ?.compareddataActivation
              }
            />
          </div>
          <div
            key={3}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <StatsCard
              title={"MyZaka"}
              icon="https://www.mobilemoneybw.co.bw/_next/image?url=%2Fmyzaka%2Fmyzakalogo.jpg&w=384&q=75"
              today={
                dashboard?.kpiStats?.data?.paymentMethod[0]?.revenueQuantity
                  ?.todaysSales?.revenueTotal
              }
              yesterday={
                dashboard?.kpiStats?.data?.paymentMethod[0]?.revenueQuantity
                  ?.comparedSales?.revenueTotal
              }
            />
          </div>
        </Carousel>
      </div>
    </div>
  );
};

export default StatisticsPage;
