import { createSlice } from "@reduxjs/toolkit";

const mySlice = createSlice({
  name: "overViewLibrary",
  initialState: {
    isFetching: false,
    all: { data: [] },
    allOrderHistory: { data: [] },
    allPaymentHistory: { data: [] },
    noSale: null,
    totalRevenue: null,
    quantityRevenue: null,
    planOverview: null,
    shopStock: null,
    vaultStats: null,
    applicationStats: null
  },
  reducers: {
    setAll(state, action) {
      state.all = action.payload;
    },
    setAllOrderHistory(state, action) {
      state.allOrderHistory = action.payload;
    },
    setIsFetching(state, action) {
      state.isFetching = action.payload;
    },
    setAllPaymentHistory(state, action) {
      state.allPaymentHistory = action.payload;
    },
    setNoSale(state, action) {
      state.noSale = action.payload;
    },
    setTotalRevenue(state, action) {
      state.totalRevenue = action.payload;
    },
    setQuantityRevenue(state, action) {
      state.quantityRevenue = action.payload;
    },
    setPlanOverview(state, action) {
      state.planOverview = action.payload;
    },
    setShopStock(state, action) {
      state.shopStock = action.payload;
    },
    setVaultStats(state, action) {
      state.vaultStats = action.payload;
    },
    setAppStats(state, action) {
      state.applicationStats = action.payload;
    },
  },
});

export const mySubscritionActions = mySlice.actions;

export default mySlice.reducer;
