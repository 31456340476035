import React, { useEffect, useState } from "react";
import DetailsHeaderActionBar from "../../../../components/details/supplier/DetailsPage/DetailsHeaderActionBar";
import { Dropdown, Tabs, Space, Divider } from "antd";
import { searchValue } from "../../../../utils/setColor";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as LockIcon } from "../../../../assets/images/vault/icons/majesticons_lock-line.svg";
import { ReactComponent as ReleaseIcon } from "../../../../assets/images/vault/icons/akar-icons_paper-airplane.svg";
import { ReactComponent as MessageIcon } from "../../../../assets/images/vault/icons/mingcute_message-4-line.svg";
import { getnotesAction } from "../../../../store/vault/actions";
import VaultModal from "../../../../components/Modals/vaultModal";
import UnlockDevice from "./unlockDevice";
import SendMessage from "./sendMessage";
import { RiArrowDropDownLine } from "react-icons/ri";
import VaultNotes from "./vaultNotes";
import LockDevice from "./lockDevice";
import ReloadDevices from "./ReloadDevices";
import ReleaseDevices from "./ReleaseDevices";
import {
  DeleteRowOutlined,
  DeliveredProcedureOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import type { TabsProps } from "antd";
import { GoHistory } from "react-icons/go";
import { SlInfo } from "react-icons/sl";
import { FiUser } from "react-icons/fi";
import DeviceInfos from "./deviceInfo";
import { SlRefresh } from "react-icons/sl";
import { format, parseISO, differenceInCalendarDays } from "date-fns";
import CustomerInfos from "./customerInfo";

interface detailsProps {
  viewDetail: any;
  setViewDetails: any;
  deviceData?: any;
}

const DeviceDetails = ({
  viewDetail,
  setViewDetails,
  deviceData,
}: detailsProps) => {
  const { auth, layout } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLockModalOpen, setIsLockModalOpen] = useState(false);
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [isReloadModalOpen, setIsReloadModalOpen] = useState(false);
  const [isReleaseModalOpen, setIsReleaseModalOpen] = useState(false);
  const [vaultData, setVaultData] = useState<any>();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(15);

  const handleReload = () => {
    auth?.token &&
      getnotesAction(
        auth?.token,
        `?serialNumber=${deviceData?.data?.deviceUniqueId}`
      )(dispatch);
  };

  const showModal = () => {
    setIsModalOpen(true);
    setVaultData(deviceData?.data?.enableVaultResponse);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const showLockModal = () => {
    setIsLockModalOpen(true);
    setVaultData(deviceData?.data?.enableVaultResponse);
  };

  const handleLockModalOk = () => {
    setIsLockModalOpen(false);
  };

  const handleLockModalCancel = () => {
    setIsLockModalOpen(false);
  };

  const showMessageModal = () => {
    setIsMessageModalOpen(true);
    setVaultData(deviceData?.data);
  };

  const showReloadModal = () => {
    setIsReloadModalOpen(true);
  };

  const showReleaseModal = () => {
    setIsReleaseModalOpen(true);
  };

  const handleReleaseCancel = () => {
    setIsReleaseModalOpen(false);
  };

  const handleMessageOk = () => {
    setIsMessageModalOpen(false);
  };
  const handleCancelReload = () => {
    setIsReloadModalOpen(false);
  };

  const handleMessageCancel = () => {
    setIsMessageModalOpen(false);
  };

  useEffect(() => {
    auth?.token &&
      getnotesAction(
        auth?.token,
        `?serialNumber=${deviceData?.data?.deviceUniqueId}&limit=${limit}&page=${page}`
      )(dispatch);
  }, [auth?.token, dispatch, page, limit, deviceData?.data?.deviceUniqueId]);

  const items: any = [
    {
      key: "1",
      label: (
        <span className="flex gap-2 text-sm">
          <DeleteRowOutlined style={{ fontSize: "12px" }} />
          Deregister
        </span>
      ),
    },
    ["Active", "Enrolled"]?.includes(deviceData?.data?.stateInfo) && {
      key: "2",
      label: (
        <span className="flex gap-2 text-sm" onClick={showLockModal}>
          <LockIcon style={{ fontSize: "12px" }} className="mt-[2px]" />
          Lock
        </span>
      ),
    },
    deviceData?.data?.stateInfo === "Locked" && {
      key: "3",
      label: (
        <span className="flex gap-2 text-sm" onClick={showModal}>
          <ReleaseIcon style={{ fontSize: "12px" }} className="mt-[2px]" />
          Unlock
        </span>
      ),
    },
    deviceData?.data?.stateInfo !== "Ready For Use" && {
      key: "4",
      label: (
        <span className="flex gap-2 text-sm" onClick={showMessageModal}>
          <MessageIcon style={{ fontSize: "12px" }} className="mt-[2px]" />
          Send message
        </span>
      ),
    },
    {
      key: "5",
      label: (
        <span className="flex gap-2 text-sm" onClick={showReloadModal}>
          <ReloadOutlined style={{ fontSize: "12px" }} />
          reload
        </span>
      ),
    },
    {
      key: "6",
      label: (
        <span className="flex gap-2 text-sm" onClick={showReleaseModal}>
          <DeliveredProcedureOutlined style={{ fontSize: "12px" }} />
          release
        </span>
      ),
    },
  ].filter(Boolean);

  function formatLastChangeDate(isoDateString: string): string {
    const date = parseISO(isoDateString);
    const currentDate = new Date();
    const difference = differenceInCalendarDays(currentDate, date);
    const formattedDate = format(date, "EEE, MMMM dd yyyy, HH:mm aa");
    return `${difference} days ago ${formattedDate}`;
  }

  const isoDateString = deviceData?.data?.lastChanged;
  const result = formatLastChangeDate(isoDateString);
  const onChange = (key: string) => {
    console.log(key);
  };

  const tabItems: TabsProps["items"] = [
    {
      key: "1",
      label: (
        <Space style={{ fontSize: "17px" }}>
          <SlInfo />
          Info
        </Space>
      ),
      children: (
        <DeviceInfos
          data={deviceData}
          setPage={setPage}
          setLimit={setLimit}
          limit={limit}
        />
      ),
    },
    {
      key: "2",
      label: (
        <Space style={{ fontSize: "17px" }}>
          <GoHistory />
          History
        </Space>
      ),
      children: (
        <VaultNotes setPage={setPage} setLimit={setLimit} limit={limit} />
      ),
    },
    {
      key: "3",
      label: (
        <Space style={{ fontSize: "17px" }}>
          <FiUser />
          Customer
        </Space>
      ),
      children: <CustomerInfos deviceData={deviceData} />,
    },
  ];

  return (
    <>
      <div className="">
        {viewDetail && (
          <>
            {" "}
            <DetailsHeaderActionBar
              goBack={() => setViewDetails(false)}
              pageName={`${deviceData?.data?.serviceDetails} devices`}
              title={`${deviceData?.brand} (IMEI :  ${deviceData?.imei})`}
            />
            <div className="px-2 text-[#030229]">
              <div className="flex flex-col md:flex-row  gap-4 mt-4 w-full h-full">
                <div
                  className={` ${layout?.isSideNavOpen ? "xl:w-[600px]" : "xl:w-[700px]"
                    }  rounded-lg py-4 px-8 bg-white p-4 w-full`}
                  style={{
                    boxShadow: "1px 4px 24px 0 rgba(3, 2, 41, 0.06)",
                  }}
                >
                  <div className="flex justify-between">
                    <h1 className="font-medium text-base">Last check in</h1>
                    <Space
                      style={{
                        color: "#605BFF",
                        fontWeight: 500,
                        fontSize: "14px",
                        cursor: "pointer",
                      }}
                      onClick={handleReload}
                    >
                      <SlRefresh /> Update Latest
                    </Space>
                  </div>
                  <Divider />
                  <div className="flex gap-4">
                    {" "}
                    <button
                      className="rounded-full px-3 font-medium  text-[#FFF] text-[13px] py-1 "
                      style={{
                        background: searchValue(deviceData?.data?.stateInfo),
                      }}
                    >
                      {deviceData?.data?.stateInfo}
                    </button>
                    <p className="ml-3 text-sm font-normal">{result}</p>
                  </div>
                </div>
                <div
                  className={` ${layout?.isSideNavOpen ? "xl:w-[600px]" : "xl:w-[700px]"
                    }  rounded-lg py-4 px-8 bg-white p-4 w-full`}
                  style={{
                    boxShadow: "1px 4px 24px 0 rgba(3, 2, 41, 0.06)",
                  }}
                >
                  <div className="flex justify-between">
                    <h1 className="font-medium text-base">Next check in</h1>
                  </div>
                  <Divider />
                  <div className="flex justify-between">
                    <button className="rounded-full px-3 font-medium bg-[#aca9fa71] text-[#605BFF] text-[13px]">
                      No change
                    </button>
                    <div className="w-28">
                      <Dropdown menu={{ items }} placement="bottomLeft">
                        <button className="bg-[#605BFF] flex gap-1 rounded-md px-4 text-white">
                          <span className=" py-2">Actions</span>{" "}
                          <RiArrowDropDownLine
                            size={30}
                            className=" mt-[3px]"
                          />
                        </button>
                      </Dropdown>
                    </div>
                  </div>
                </div>
                <VaultModal
                   onOpen={isModalOpen}
                   onClose={handleCancel}
                   title={
                     <h1 className="text-center text-[#030229] text-lg py-4 font-medium">
                       Unlock
                     </h1>
                   }
                  component={
                    <UnlockDevice
                      onClose={handleOk}
                      data={vaultData}
                      deviceData={deviceData?.data?.deviceUniqueId}
                    />
                  }
                />
                <VaultModal
                  onOpen={isMessageModalOpen}
                  onClose={handleMessageCancel}
                  width={700}
                  title={
                    <h1 className="text-center text-[#030229] text-lg py-4 font-medium">
                      Send Message
                    </h1>
                  }
                  component={
                    <SendMessage
                      onClose={handleMessageOk}
                      data={vaultData}
                      deviceData={deviceData?.data?.deviceUniqueId}
                    />
                  }
                />

                <VaultModal
                  onOpen={isReloadModalOpen}
                  onClose={handleCancelReload}
                  width={700}
                  title={
                    <h1 className="text-center text-[#030229] text-lg py-4 font-medium">
                      Reload Devices
                    </h1>
                  }
                  component={
                    <ReloadDevices
                      onClose={handleCancelReload}
                      data={vaultData}
                      deviceData={deviceData?.data?.deviceUniqueId}
                    />
                  }
                />
                <VaultModal
                  onOpen={isReleaseModalOpen}
                  onClose={handleReleaseCancel}
                  width={700}
                  title={
                    <h1 className="text-center text-[#030229] text-lg py-4 font-medium">
                      Release Devices
                    </h1>
                  }
                  component={
                    <ReleaseDevices
                      onClose={handleReleaseCancel}
                      data={vaultData}
                      deviceData={deviceData?.data?.deviceUniqueId}
                    />
                  }
                />
                <VaultModal
                  onOpen={isLockModalOpen}
                  onClose={handleLockModalCancel}
                  width={400}
                  title={
                    <h1 className="text-center text-[#030229] text-lg py-4">
                      Lock Device
                    </h1>
                  }
                  component={
                    <LockDevice
                      onClose={handleLockModalOk}
                      data={vaultData}
                      deviceData={deviceData?.data?.deviceUniqueId}
                    />
                  }
                />
              </div>
              <div className="mt-4 w-full min-h-[70vh]">
                <Tabs
                  defaultActiveKey="1"
                  items={tabItems}
                  onChange={onChange}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};
export default DeviceDetails;
