import React from "react";
import HeadersComponent from "./headerComponent";

const datas = [
  {
    title: "Current Cost",
    value: `${"BWP "}200000`,
  },
  {
    title: "Your Plan",
    value: "Standard",
  },
  {
    title: "Billing Period",
    value: "12 Aug, 2023 - 12 Nov, 2023",
  },
  {
    title: "Payment Method",
    value: "MTN MoMo Pay",
  },
  {
    title: "Last Invoice",
    value: "01234572",
  },
  {
    title: "Upcoming Payment",
    value: "12 September 2023",
  },
  {
    title: "Renewal Date",
    value: "12 Jan, 2024",
  },
  {
    title: "Current Cost",
    value: `${"BWP "}200000`,
  },
  {
    title: "Your Plan",
    value: "Standard",
  },
  {
    title: "Billing Period",
    value: "12 Aug, 2023 - 12 Nov, 2023",
  },
  {
    title: "Payment Method",
    value: "MTN MoMo Pay",
  },
  {
    title: "Last Invoice",
    value: "01234572",
  },
  {
    title: "Upcoming Payment",
    value: "12 September 2023",
  },
  {
    title: "Renewal Date",
    value: "12 Jan, 2024",
  },
];
const BillingHeader = () => {
  return <HeadersComponent data={datas} />;
};
export default BillingHeader;
