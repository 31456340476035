
import { notification } from "antd";
import { myTrackActions } from ".";
import {
  createDelivererServices,
  createDeliveryActivityServices,
  createOtpServices,
  getAllDelivererService,
  getAllPackageTrackService,
  getAllTrackActivitiesService,
  getOneDeliverer,
  getOtpServices,
  getTrackingTypeServices,
  getOnePackageTrackService,
  gettrackingStasService,
  getOneDelivererOut,
  getCheckParkage,
  updateParkingServices,
  updateParkageOutServices,
  recieverSignatureService,
} from "./services";

export const getAllPackageTrackAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myTrackActions.setIsFetching(true));
      const res = await getAllPackageTrackService(token, query);
      if (res?.status === 200) {
        dispatch(myTrackActions.setAll(res));
        dispatch(myTrackActions.setIsFetching(false));
      }
      dispatch(myTrackActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getOnePackageAction = (token: string, id: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myTrackActions.setIsFetching(true));
      const res = await getOnePackageTrackService(token, id);
      if (res?.status === 200) {
        dispatch(myTrackActions.setSelected(res));
        dispatch(myTrackActions.setIsFetching(false));
      }
      dispatch(myTrackActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const gettrackingStasAction = (token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myTrackActions.setIsFetching(true));
      const res = await gettrackingStasService(token);
      if (res?.status === 200) {
        dispatch(myTrackActions.setTrackingStas(res));
        dispatch(myTrackActions.setIsFetching(false));
      }
      dispatch(myTrackActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getOnePackageTrackAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myTrackActions.setIsFetching(true));
      const res = await getAllPackageTrackService(token, query);
      if (res?.status === 200) {
        dispatch(myTrackActions.setTrackHistory(res));
        dispatch(myTrackActions.setIsFetching(false));
      }
      dispatch(myTrackActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getAllTrackActivitiesAction = (token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myTrackActions.setIsFetching(true));
      const res = await getAllTrackActivitiesService(token);
      if (res?.status === 200) {
        dispatch(myTrackActions.settrActivities(res));
        dispatch(myTrackActions.setIsFetching(false));
      }
      dispatch(myTrackActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getPackageTrackIdAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myTrackActions.setIsFetching(true));
      const res = await getAllPackageTrackService(token, query);

      if (res?.status === 200) {
        dispatch(myTrackActions.setSelected(res));
        dispatch(myTrackActions.setIsFetching(false));
      }
      if (res.status === 400) {
        notification.error(res?.error);
      }
      dispatch(myTrackActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const createDelivererAction = (token: string, data: {}) => {
  return async (dispatch: any) => {
    try {
      dispatch(myTrackActions.setIsFetching(true));
      const res = await createDelivererServices(data, token);
      // const resAll = await getAllServiceWarehouse(token);

      // console.log(">>>>>>>::res>>> ", res);
      if (res?.status === 201) {
        //   dispatch(myTrackActions.setWarehouseItem(resAll));
        dispatch(myTrackActions.setNewDeliverer(res));
        dispatch(myTrackActions.setIsFetching(false));
        notification.success({ message: "Successfully Created!" });
        return res?.data;
      }
      dispatch(myTrackActions.setIsFetching(false));
      notification.error({ message: "Failed!" });
      return false;
    } catch (err) {
      console.log(err);
    }
  };
};
export const getDelivererAction = (token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myTrackActions.setIsFetching(true));
      const res = await getAllDelivererService(token);

      if (res?.status === 200) {
        dispatch(myTrackActions.setAllDeliverer(res));
        dispatch(myTrackActions.setIsFetching(false));
      }
      if (res.status === 400) {
        notification.error(res?.error);
      }
      dispatch(myTrackActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const getOneDelivererAction = (itemId: string, token?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myTrackActions.setIsFetching(true));
      const res = await getOneDeliverer(itemId, token);
      // console.log(">>>>!!!>>>::)) ", res);
      if (res?.status === 200) {
        dispatch(myTrackActions.setSelected(res?.data));
        dispatch(myTrackActions.setIsFetching(false));
      }
      dispatch(myTrackActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const getOneDelivererOutAction = (phoneNumber: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myTrackActions.setIsFetching(true));
      const res = await getOneDelivererOut(phoneNumber);
      // console.log(">>>>!!!>>>::)) ", res);
      if (res?.status === 200) {
        dispatch(myTrackActions.setSelectedDriver(res?.data));
        dispatch(myTrackActions.setIsFetching(false));
        notification.success({ message: "Successfully found" });
        return true;
      }
      dispatch(myTrackActions.setIsFetching(false));
      notification.error({ message: res?.response?.data?.error });
      return false;
    } catch (err) {
      console.log(err);
    }
  };
};
export const getCheckParkageAction = (itemId: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myTrackActions.setIsFetching(true));
      const res = await getCheckParkage(itemId);
      // console.log(">>>>!!!>>>::)) ", res);
      if (res?.status === 200) {
        dispatch(myTrackActions.setIsFetching(false));
        dispatch(myTrackActions.setCheckParkage(res?.data));
        notification.success({ message: "successfully verfied" });
        return true;
      }
      dispatch(myTrackActions.setIsFetching(false));
      notification.error({ message: res?.response?.data?.error });
      console.log(res);
      return false;
    } catch (err) {
      console.log(err);
    }
  };
};
export const getTrackingTypeAction = (token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myTrackActions.setIsFetching(true));
      const res = await getTrackingTypeServices(token);

      if (res?.status === 200) {
        dispatch(myTrackActions.setTrackingType(res));
        dispatch(myTrackActions.setIsFetching(false));
      }
      if (res.status === 400) {
        notification.error(res?.error);
      }
      dispatch(myTrackActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const createDeliveryActivityAction = (token: string, data: {}) => {
  return async (dispatch: any) => {
    try {
      dispatch(myTrackActions.setIsFetching(true));
      const res = await createDeliveryActivityServices(data, token);
      if (res?.status === 201) {
        dispatch(myTrackActions.setDeliveryActivity(res));
        dispatch(myTrackActions.setIsFetching(false));
        return res?.data;
      }
      dispatch(myTrackActions.setIsFetching(false));
      return false;
    } catch (err) {
      console.log(err);
    }
  };
};
export const createOtpActions = (token: string, data: {}) => {
  return async (dispatch: any) => {
    try {
      dispatch(myTrackActions.setSendOtpIsFetching(true));
      const res = await createOtpServices(data, token);
      if (res?.status === 201) {
        dispatch(myTrackActions.setNewOtp(res));
        dispatch(myTrackActions.setSendOtpIsFetching(false));
        notification.success({ message: "OTP Sent successfully" });
        return res?.data;
      }
      dispatch(myTrackActions.setSendOtpIsFetching(false));
      return false;
    } catch (err) {
      console.log(err);
    }
  };
};
export const getOtpActions = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myTrackActions.setIsFetching(true));
      const res = await getOtpServices(token, query);
      if (res?.status === 200) {
        dispatch(myTrackActions.setOtpValue(res));
        dispatch(myTrackActions.setIsFetching(false));
        return true;
      } else {
        notification.error({ message: res?.response?.data?.error });
        dispatch(myTrackActions.setIsFetching(false));
        return false;
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const updateParkingAction = (
  token: string,
  data: any,
  itemId: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myTrackActions.setIsFetching(true));
      const res = await updateParkingServices(data, token, itemId);
      if (res?.status === 200) {
        dispatch(myTrackActions.setIsFetching(false));
      }
      dispatch(myTrackActions.setIsFetching(false));
    } catch (err) {
      console.log("an error occured......");
      console.log(err);
    }
  };
};
export const updateParkageOutAction = (
  itemId: string,
  data: any,
  token?: any
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myTrackActions.setIsFetching(true));
      const res = await updateParkageOutServices(itemId, data, token);
      if (res?.status === 200) {
        dispatch(myTrackActions.setIsFetching(false));
        dispatch(myTrackActions.setUpdated(res));
        notification.success({ message: "Succefully Updated" });
      }
      dispatch(myTrackActions.setIsFetching(res));
    } catch (err) {
      notification.error({ message: "Failed to Updated" });
      console.log(err);
    }
  };
};

export const receiverSignatureAction = (data: any, token?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myTrackActions.setSignatureLoading(true));
      const res = await recieverSignatureService(data, token);
      if (res?.status === 200) {
        dispatch(myTrackActions.setReceiverSignatue(res));
        dispatch(myTrackActions.setSignatureLoading(false));
        return true;
      }
      dispatch(myTrackActions.setSignatureLoading(false));
      return false;
    } catch (err) {
      console.log(err);
    }
  };
};
