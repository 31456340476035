import React from "react";
import { Input } from "antd";

const { Search } = Input;

const SearchInput = (props: any) => {
  return (
    <>
      <Search
        placeholder={
          props?.placehorder ? props?.placehorder : "Input search text"
        }
        onSearch={props?.onSearch}
        onChange={props?.onChange}
        style={{ width: 300 }}
        size="large"
      />
    </>
  );
};

export default SearchInput;
