import React from "react";

interface cardProps {
  title?: any;
  icon?: any;
  amount?: any;
  comparedAmount?: any;
  parcent?: any;
  icon2?: any;
  description?: any;
  btn?: any;
  compareText?: any;
  textColor?: string;
}
const OverViewCard = ({
  title,
  icon,
  amount,
  comparedAmount,
  parcent,
  icon2,
  description,
  btn,
  compareText,
  textColor,
}: cardProps) => {
  return (
    <div
      className="bg-white rounded-md p-6 w-[370pdx] h-[230px] relative text-[#030229] "
      style={{ boxShadow: "1px 4px 24px 0 #F5F5F5" }}
    >
      <div className="flex justify-between">
        <h1 className="text-[#030229] opacity-80 text-base font-normal ">
          {title}
        </h1>
        <div className="w-10 h-10 rounded-full border border-grey-200 flex items-center justify-center">
          {" "}
          {icon}
        </div>
      </div>
      <div className="border-dashed border-b-[.5px] border-[#03022925] ">
        <p className="text-[#030229] text-lg font-medium">{amount}</p>
        <p className="text-[#03022980] text-base font-normal pb-4">
          {comparedAmount}
        </p>
        {/* <div className="flex gap-2 mt-2">
          <p className="text-[#03022980] text-sm pb-4 ">{compareText} by </p>
          <p className={`!text-[${textColor}] text-sm`}>{parcent}</p>
          {icon2}
        </div> */}
      </div>
      <div className="flex justify-between">
        <p className="text-[#03022995] text-[12px] pt-4 font-normal mr-14">
          {description}
        </p>
      </div>
      <div className="absolute right-2 bottom-2">{btn}</div>
    </div>
  );
};

export default OverViewCard;
